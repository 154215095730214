import React, { useState, useContext } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { auth } from "../../config/firebase";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { BaseContext } from "../../contexts/BaseContext";
import {
  fetchUserSecrets,
  fetchVDBProfiles,
  fetchLlmProfiles,
} from "../../services/utils";
import { tokenService } from "../../services/api";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorJSX, setErrorJSX] = useState(null);
  const [success, setSuccess] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormFadingOut, setIsFormFadingOut] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const { setSavedTags, setDeasyUserConfig, setVdbProfiles, setLlmProfiles } =
    useContext(BaseContext);
  const [searchParams] = useSearchParams();
  const marketplaceToken = searchParams.get("x-gcp-marketplace-token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setErrorJSX(null);
    setSuccess("");
    setIsLoading(true);

    // Enforce that the user provides all required data during sign-up
    if (isSignUp) {
      if (
        !firstName.trim() ||
        !lastName.trim() ||
        !company.trim() ||
        !email.trim() ||
        !password.trim()
      ) {
        setError("Please fill out all fields before signing up.");
        setErrorJSX(null);
        setIsLoading(false);
        return;
      }
    }

    try {
      if (isResetPassword) {
        await sendPasswordResetEmail(auth, email);
        setSuccess("Password reset email sent!");
        setIsLoading(false);
        return;
      }

      if (isSignUp) {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password,
        );

        try {
          await updateProfile(userCredential.user, {
            displayName: `${firstName} ${lastName}`,
          });

          // Create or update subscription with profile data

          if (marketplaceToken) {
            await tokenService.linkMarketplaceAccount({
              marketplace_token: marketplaceToken,
              first_name: firstName || undefined,
              last_name: lastName || undefined,
              company: company || undefined,
            });
          } else {
            // Regular signup without marketplace token
            await tokenService.linkMarketplaceAccount({
              first_name: firstName || undefined,
              last_name: lastName || undefined,
              company: company || undefined,
            });
          }

          await sendEmailVerification(userCredential.user);
          setSuccess(
            "Please check your email to verify your account. Once email is verified, complete sign up by logging in.",
          );
          setIsSignUp(false);
        } catch (verificationError) {
          setError(
            "Account created but failed to send verification email. Please try again later.",
          );
        }
        return;
      } else {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password,
        );
        if (!userCredential.user.emailVerified) {
          setError("Please verify your email before logging in.");
          setErrorJSX(null);
          await sendEmailVerification(userCredential.user);
          setSuccess("A new verification email has been sent.");
          setIsLoading(false);
          return;
        }

        // Fetch user secrets and check for deasy_verified
        const deasyUserConfig = await fetchUserSecrets(
          setSavedTags,
          setDeasyUserConfig,
        );
        if (deasyUserConfig.deasyApiKey) {
          await Promise.all([
            fetchVDBProfiles(setVdbProfiles, deasyUserConfig.deasyApiKey),
            fetchLlmProfiles(setLlmProfiles, deasyUserConfig.deasyApiKey),
          ]);
        }

        // Check if user is verified in Deasy system
        try {
          const subscriptionInfo = await tokenService.getSubscriptionInfo();
          if (!subscriptionInfo.data.deasy_verified) {
            setErrorJSX(
              <span>
                Your account is not verified in the Deasy system. Please
                schedule an onboarding session with our team to get verified:{" "}
                <a
                  href="https://calendar.app.google/i8RE5FFFdGTy9NL36"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline"
                >
                  Book an onboarding call
                </a>
              </span>,
            );
            // Sign out the user since they're not verified
            await auth.signOut();
            setIsLoading(false);
            return;
          }
        } catch (verificationError) {
          console.error("Error verifying user:", verificationError);
          setErrorJSX(
            <span>
              You have not been verified in the Deasy system. Please setup an
              onboarding call with our team to get verified:{" "}
              <a
                href="mailto:support@deasylabs.com"
                className="text-blue-600 underline"
              >
                Book an onboarding call
              </a>
              .
            </span>,
          );
          setError("");
          await auth.signOut();
          setIsLoading(false);
          return;
        }
      }

      navigate(location.state?.from || "/");
    } catch (err) {
      if (isSignUp) {
        // Signup error handling
        if (err.code === "auth/email-already-in-use") {
          setError("An account with this email already exists.");
          setErrorJSX(null);
        } else if (err.code === "auth/invalid-email") {
          setError("Please enter a valid email address.");
          setErrorJSX(null);
        } else if (err.code === "auth/operation-not-allowed") {
          setError(
            "Email/password accounts are not enabled. Please contact support.",
          );
          setErrorJSX(null);
        } else if (err.code === "auth/weak-password") {
          setError("Password should be at least 6 characters long.");
          setErrorJSX(null);
        } else {
          console.error("Error during signup:", err);
          setError("Failed to create account. Please try again.");
          setErrorJSX(null);
        }
      } else {
        // Login error handling
        switch (err.code) {
          case "auth/invalid-login-credentials":
          case "auth/user-not-found":
          case "auth/wrong-password":
            setError("Invalid email or password. Please try again.");
            setErrorJSX(null);
            break;
          case "auth/invalid-email":
            setError("Please enter a valid email address.");
            setErrorJSX(null);
            break;
          case "auth/user-disabled":
            setError("This account has been disabled. Please contact support.");
            setErrorJSX(null);
            break;
          case "auth/too-many-requests":
            setError("Too many failed attempts. Please try again later.");
            setErrorJSX(null);
            break;
          default:
            setError("An error occurred. Please try again.");
            setErrorJSX(null);
        }
        console.error("Auth error:", err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleSignUp = () => {
    setIsFormFadingOut(true);
    setTimeout(() => {
      setIsSignUp(!isSignUp);
      setTimeout(() => {
        setIsFormFadingOut(false);
      }, 10);
    }, 300);
  };

  return (
    <div className="flex h-screen w-full overflow-hidden transition-opacity duration-700 ease-in-out opacity-100">
      {/*
       Left Panel: Brand & Logo (added animation classes)
      */}
      <div
        className="hidden lg:flex flex-col justify-center items-center w-1/2 bg-gradient-to-br from-emerald-500 to-teal-500 text-white p-8
                   transform transition-all duration-700 hover:scale-105 hover:opacity-90 cursor-pointer"
        onClick={() =>
          window.open("https://deasylabs.com", "_blank", "noopener,noreferrer")
        }
      >
        <img
          src="/deasy-icon.png"
          alt="DeasyLabs Logo"
          className="w-24 h-24 mb-6"
        />
        <h1 className="text-5xl font-extrabold mb-4 tracking-tight">
          Deasy Labs
        </h1>
      </div>

      {/*
       Right Panel: Enhanced Form Styles
      */}
      <div className="flex w-full lg:w-1/2 items-center justify-center p-6 bg-white">
        {/* Container with fade/scale */}
        <div
          className={`
            max-w-lg w-full space-y-8 transform transition-all duration-300
            ${isFormFadingOut ? "opacity-0 scale-95" : "opacity-100 scale-100"}
            bg-white rounded-lg shadow-xl p-8
          `}
        >
          <div className="mb-6 text-left">
            <h2 className="text-3xl font-bold text-gray-700">
              {isResetPassword
                ? "Reset Password"
                : isSignUp
                  ? "Sign Up"
                  : "Login"}
            </h2>
          </div>

          {/* Error / Success Messages */}
          {error && (
            <div className="bg-red-50 text-red-700 p-3 rounded mb-4 text-sm border border-red-200 text-left">
              {error}
            </div>
          )}
          {errorJSX && (
            <div className="bg-red-50 text-red-700 p-3 rounded mb-4 text-sm border border-red-200 text-left">
              {errorJSX}
            </div>
          )}
          {success && (
            <div className="bg-green-50 text-green-700 p-3 rounded mb-4 text-sm border border-green-200 text-left">
              {success}
            </div>
          )}

          {/* Form */}
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Additional fields for Sign Up */}
            {isSignUp && !isResetPassword && (
              <>
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-gray-700 font-medium mb-1 text-left"
                  >
                    First Name
                  </label>
                  <input
                    id="firstName"
                    type="text"
                    placeholder="Enter your first name"
                    className="block w-full px-3 py-2 text-gray-900 rounded border border-gray-300
                             focus:outline-none focus:ring-2 focus:ring-emerald-200 focus:border-emerald-400
                             placeholder-gray-400"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-gray-700 font-medium mb-1 text-left"
                  >
                    Last Name
                  </label>
                  <input
                    id="lastName"
                    type="text"
                    placeholder="Enter your last name"
                    className="block w-full px-3 py-2 text-gray-900 rounded border border-gray-300
                             focus:outline-none focus:ring-2 focus:ring-emerald-200 focus:border-emerald-400
                             placeholder-gray-400"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="company"
                    className="block text-gray-700 font-medium mb-1 text-left"
                  >
                    Company
                  </label>
                  <input
                    id="company"
                    type="text"
                    placeholder="Enter your company name"
                    className="block w-full px-3 py-2 text-gray-900 rounded border border-gray-300
                             focus:outline-none focus:ring-2 focus:ring-emerald-200 focus:border-emerald-400
                             placeholder-gray-400"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required
                  />
                </div>
              </>
            )}

            {/* Email Field */}
            <div>
              <label
                htmlFor="email"
                className="block text-gray-700 font-medium mb-1 text-left"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                placeholder="Enter your email"
                className="block w-full px-3 py-2 text-gray-900 rounded border border-gray-300
                           focus:outline-none focus:ring-2 focus:ring-emerald-200 focus:border-emerald-400
                           placeholder-gray-400"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            {/* Password Field – only render if not resetting */}
            {!isResetPassword && (
              <div>
                <label
                  htmlFor="password"
                  className="block text-gray-700 font-medium mb-1 text-left"
                >
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  placeholder="Enter your password"
                  className="block w-full px-3 py-2 text-gray-900 rounded border border-gray-300
                             focus:outline-none focus:ring-2 focus:ring-emerald-200 focus:border-emerald-400
                             placeholder-gray-400"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            )}

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-primary text-white py-2 rounded-full font-medium
                         hover:bg-primary-dark focus:outline-none transition-colors
                         disabled:opacity-70 disabled:cursor-not-allowed"
              disabled={isLoading}
            >
              {isLoading
                ? isSignUp
                  ? "Welcome to Deasy..."
                  : "Logging you in..."
                : isResetPassword
                  ? "Send Reset Link"
                  : isSignUp
                    ? "Sign Up"
                    : "Login"}
            </button>
          </form>

          {/* Loading Spinner (conditionally rendered) */}
          {isLoading && (
            <div className="flex items-center justify-center mt-2">
              <div className="w-6 h-6 border-4 border-t-transparent border-emerald-500 rounded-full animate-spin"></div>
            </div>
          )}

          {/* Toggle Sign Up & Reset */}
          {!isResetPassword && (
            <button
              onClick={handleToggleSignUp}
              className="w-full mt-3 px-2 bg-gray-50 text-gray-700 py-2 rounded-full font-medium
                         hover:bg-gray-200 transition-colors text-left"
            >
              {isSignUp
                ? "Already have an account? Login"
                : "Need an account? Sign Up"}
            </button>
          )}
          <button
            onClick={() => setIsResetPassword(!isResetPassword)}
            className="w-full mt-3 bg-gray-5 px-2 text-gray-700 py-2 rounded-full font-medium
                       hover:bg-gray-200 transition-colors text-left"
          >
            {isResetPassword ? "Back to Login" : "Forgot Password?"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
