import React, { useState } from "react";

const TutorialModal = ({ isOpen, onClose }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  if (!isOpen) return null;

  const tutorials = [
    {
      title: "Introduction to Deasy's metadata platform",
      duration: "2 min",
      videoId: "1_LuCf6oKG1U3xuOJ8BI9EGMRS7yBbiaf",
    },
    {
      title: "API key set-up and tagging tiers",
      duration: "1 min",
      videoId: "1k4FbuW-Hb5oaHsIPkOxQ5lqtAnQHcREa",
    },
    {
      title: "Connecting to your data",
      duration: "2 min",
      videoId: "11-72lxmkKZCCMdtSEF2U__bAYsIAwjpc",
    },
    {
      title: "Defining metadata tags",
      duration: "2 min",
      videoId: "1Ext8EVh2xmpdzdXSuJLIyEMCGAdxR031",
    },
    {
      title: "Understanding Deasy's tagging tree structure",
      duration: "4 mins",
      videoId: "1ewaQy7JJP78miBlCuCLGzMLdHrcgqKMy",
    },
    {
      title: "Auto-suggest Metadata Schema",
      duration: "4 min",
      videoId: "1zGoEyWmezyPw1eLfa9MBqxLD5tr8cynI",
    },
    {
      title: "Testing and refining metadata",
      duration: "2 min",
      videoId: "1BGAFNg2MPTs8Zg3_jof8rbtVmmTbYuWK",
    },
    {
      title: "Extracting metadata across files",
      duration: "2 min",
      videoId: "1Cf_Vy940wOvn9D0zkEfxjJq5CfyzMSSr",
    },
    {
      title: "Standardizing your metadata outputs",
      duration: "1 min",
      videoId: "1_ncNy_Uhk9KMFCzLa3lZyKlfw3rHX25c",
    },
    {
      title: "Viewing your metadata outputs",
      duration: "1 min",
      videoId: "1lnExRD4hKtQNTef0pTK8Yy3LrhlZWoMu",
    },
    {
      title: "Creating a data slice",
      duration: "1 min",
      videoId: "1l6NCI7Aefa3kjGrFh2aSAkRYl_5JumDB",
    },
    {
      title: "Exporting your metadata",
      duration: "1 min",
      videoId: "1wdy1yyRBnubnGjgmSII68zk6VLvp38mM",
    },
  ];

  const handleNextVideo = () => {
    setCurrentVideoIndex((prev) => (prev + 1) % tutorials.length);
  };

  const handlePrevVideo = () => {
    setCurrentVideoIndex(
      (prev) => (prev - 1 + tutorials.length) % tutorials.length,
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 z-50 flex items-center justify-center backdrop-blur-sm">
      <div className="bg-[#1a1f2b] rounded-xl p-6 w-[90%] max-w-6xl border border-emerald-500/20 shadow-2xl">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-2xl font-semibold text-emerald-400 text-left">
              Video Guides
            </h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-emerald-300 transition-colors p-2 hover:bg-emerald-500/10 rounded-lg"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="flex gap-6">
          {/* Video List */}
          <div className="w-1/3 overflow-y-auto max-h-[600px] pr-4 text-left">
            {tutorials.map((tutorial, index) => (
              <div
                key={index}
                onClick={() => setCurrentVideoIndex(index)}
                className={`p-2 rounded-lg mb-2 cursor-pointer transition-all border-2 ${
                  currentVideoIndex === index
                    ? "bg-emerald-500/20 border-emerald-500/40"
                    : "hover:bg-emerald-500/10 border-gray-700"
                }`}
              >
                <h3 className="text-emerald-300 font-medium">
                  {tutorial.title}
                </h3>
                <p className="text-gray-400 text-sm">{tutorial.duration}</p>
              </div>
            ))}
          </div>

          {/* Video Player */}
          <div className="w-2/3 flex flex-col justify-center">
            <div
              className="relative rounded-lg overflow-hidden"
              style={{ paddingBottom: "56.25%" }}
            >
              <iframe
                title={`Tutorial: ${tutorials[currentVideoIndex].title}`}
                src={`https://drive.google.com/file/d/${tutorials[currentVideoIndex].videoId}/preview`}
                frameBorder="0"
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full"
              />
            </div>

            {/* Navigation Controls */}
            <div className="flex justify-between mt-4">
              <button
                onClick={handlePrevVideo}
                className="px-4 py-2 text-emerald-400 hover:text-emerald-300 transition-colors disabled:opacity-50"
                disabled={currentVideoIndex === 0}
              >
                ← Previous
              </button>
              <button
                onClick={handleNextVideo}
                className="px-4 py-2 text-emerald-400 hover:text-emerald-300 transition-colors disabled:opacity-50"
                disabled={currentVideoIndex === tutorials.length - 1}
              >
                Next →
              </button>
            </div>
          </div>
        </div>

        <div className="mt-6 p-4 bg-emerald-500/10 rounded-lg border border-emerald-500/20">
          <p className="text-gray-300 text-base">
            Still need assistance? We're here to help!{" "}
            <a
              href="mailto:help@deasylabs.com"
              className="text-emerald-400 hover:text-emerald-300 transition-colors"
            >
              Reach us at help@deasylabs.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TutorialModal;
