import React, { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { suggestionService } from "../../../../../services/api";
import { BaseContext } from "../../../../../contexts/BaseContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { getVdbLogo } from "../../../../../config/config";
import {
  DEFAULT_DATE_FORMAT,
  DATE_FORMAT_OPTIONS,
} from "../../../../../config/config";
const GeneralSection = ({
  formData,
  setFormData,
  handleInputChange,
  setTagInEditor,
  setEditingExistingTag = null,
  isAddingValue,
  setIsAddingValue,
  newValue,
  setNewValue,
}) => {
  const [isDescriptionLoading, setIsDescriptionLoading] = useState(false);
  const [extraContext, setExtraContext] = useState("");
  const [selectedVDB, setSelectedVDB] = useState(null);
  const [showVDBModal, setShowVDBModal] = useState(false);
  const [showSuggestionPreview, setShowSuggestionPreview] = useState(false);
  const { deasyUserConfig } = useContext(BaseContext);
  const [suggestedTag, setSuggestedTag] = useState({
    name: "",
    description: "",
    available_values: [],
    output_type: "word",
  });
  const [tagType, setTagType] = useState("classify");
  const [isContextExpanded, setIsContextExpanded] = useState(false);
  const [isDataSourceExpanded, setIsDataSourceExpanded] = useState(false);

  const deasyApiKey = deasyUserConfig.deasyApiKey;

  const canImproveTag = formData.name && formData.description;

  const handleVDBSelect = (configName) => {
    setSelectedVDB(configName);
  };

  const handleSuggest = async () => {
    try {
      setIsDescriptionLoading(true);
      const contextWithType = tagType
        ? `Tag Type: ${tagType}\n${extraContext}`
        : extraContext;

      const response = await suggestionService.suggestDescription(
        deasyUserConfig.vdbmConfig.LastActive,
        deasyUserConfig.llmConfig.LastActive,
        formData.name,
        contextWithType,
        deasyApiKey,
        formData.description,
        formData.available_values,
      );

      if (response.data.suggestion) {
        try {
          const suggestionData = JSON.parse(response.data.suggestion);
          setSuggestedTag({
            ...formData,
            ...suggestionData,
          });
          setShowSuggestionPreview(true);
        } catch (parseError) {
          console.error("Error parsing suggestion:", parseError);
          toast.error("Failed to parse Deasy suggestion");
        }
      }
    } catch (error) {
      console.error("Error suggesting description:", error);
      toast.error("Failed to generate tag suggestion");
    } finally {
      setIsDescriptionLoading(false);
    }
  };

  const handleConfirmSuggestion = () => {
    setFormData(suggestedTag);
    setShowSuggestionPreview(false);
    setShowVDBModal(false);
    setExtraContext("");
    toast.success("Tag updated with Deasy suggestion!");
  };

  const handleSuggestedTagChange = (field, value) => {
    setSuggestedTag((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleOutputTypeChange = (e) => {
    const newOutputType = e.target.value;
    setFormData((prev) => ({
      ...prev,
      output_type: newOutputType,
    }));
  };

  const handleAddValue = (e) => {
    e.preventDefault();
    if (newValue.trim()) {
      setFormData((prev) => ({
        ...prev,
        available_values: [...prev.available_values, newValue.trim()],
      }));
      setNewValue("");
      setIsAddingValue(false);
    }
  };

  const handleRemoveValue = (indexToRemove) => {
    setFormData((prev) => ({
      ...prev,
      available_values: prev.available_values.filter(
        (_, index) => index !== indexToRemove,
      ),
    }));
  };

  const handleMaxValuesChange = (e) => {
    const value = e.target.value;
    // Allow empty string (no limit) or positive integers
    if (value === "" || (parseInt(value) > 0 && !isNaN(parseInt(value)))) {
      setFormData((prev) => ({
        ...prev,
        maxValues: value === "" ? null : parseInt(value),
      }));
    }
  };

  return (
    <>
      <div className="space-y-6 relative pb-20">
        <div className="flex justify-between items-center gap-4 pb-2">
          <button
            onClick={() => {
              if (isAddingValue && newValue.trim()) {
                toast.error(
                  "You have an unconfirmed output value. Please click 'Add' or 'Cancel' before saving.",
                );
                return;
              }
              setTagInEditor({});
              if (setEditingExistingTag) {
                setEditingExistingTag(false);
              }
            }}
            className="p-2 rounded-lg hover:bg-gray-100 transition-colors"
            title="Reset form"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
          </button>
          <button
            onClick={() => {
              if (isAddingValue && newValue.trim()) {
                toast.error(
                  "You have an unconfirmed output value. Please click 'Add' or 'Cancel' before saving.",
                );
                return;
              }
              setShowVDBModal(true);
            }}
            disabled={!canImproveTag}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
              canImproveTag
                ? "bg-primary text-white hover:bg-primary/90"
                : "bg-gray-100 text-gray-400 cursor-not-allowed"
            }`}
            title={
              !canImproveTag
                ? "Please provide both name and description to improve the tag"
                : ""
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            {isDescriptionLoading ? "Suggesting..." : "Improve Tag"}
          </button>
        </div>

        {/* Name Section */}
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 mb-1 text-left">
            Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name || ""}
            onChange={handleInputChange}
            placeholder="Enter a name for the tag"
            className="p-3 border border-gray-300 rounded-lg focus:ring-emerald-500 focus:border-emerald-500 transition duration-200 ease-in-out"
          />
        </div>

        {/* Description Section */}
        <div className="flex flex-col">
          <div className="flex justify-between items-center mb-1">
            <label className="text-sm font-semibold text-gray-700 text-left">
              Description
            </label>
          </div>

          <div className="relative">
            <textarea
              name="description"
              value={formData.description || ""}
              onChange={handleInputChange}
              placeholder="Enter a description for the tag"
              className="p-3 border border-gray-300 rounded-lg w-full focus:ring-primary focus:border-primary transition duration-200 ease-in-out resize-none"
              rows="4"
            />
          </div>
        </div>

        {/* Output Type Section */}
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 mb-1 text-left">
            Output Type
          </label>
          <select
            name="output_type"
            value={formData.output_type || "word"}
            onChange={handleOutputTypeChange}
            className="p-3 border border-gray-300 rounded-lg bg-white focus:ring-emerald-500 focus:border-emerald-500 transition duration-200 ease-in-out"
          >
            <option value="word">Word</option>
            <option value="number">Number</option>
            <option value="date">Date</option>
            <option value="binary">Yes/No</option>
          </select>
        </div>

        {/* Date Format Section - Only visible when output_type is date */}
        {formData.output_type === "date" && (
          <div className="flex flex-col">
            <label className="text-sm font-semibold text-gray-700 mb-1 text-left">
              Date Format
              <span className="text-xs text-gray-500 ml-2">
                (How dates should be formatted)
              </span>
            </label>
            <select
              name="date_format"
              value={formData.date_format || DEFAULT_DATE_FORMAT}
              onChange={handleInputChange}
              className="p-3 border border-gray-300 rounded-lg bg-white focus:ring-emerald-500 focus:border-emerald-500 transition duration-200 ease-in-out"
            >
              {DATE_FORMAT_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <p className="text-xs text-gray-500 mt-1">
              This format will be used when extracting and displaying dates
            </p>
          </div>
        )}

        {/* Max Values Section */}
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 mb-1 text-left">
            Maximum Values
            <span className="text-xs text-gray-500 ml-2">
              (Leave empty for no limit)
            </span>
          </label>
          <input
            type="number"
            name="maxValues"
            value={formData.maxValues || ""}
            onChange={handleMaxValuesChange}
            min="1"
            placeholder="No limit"
            className="p-3 border border-gray-300 rounded-lg focus:ring-emerald-500 focus:border-emerald-500 transition duration-200 ease-in-out"
          />
          <p className="text-xs text-gray-500 mt-1">
            Limits the number of values that can be returned for this tag
          </p>
        </div>

        {/* Available Values Section */}
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 mb-1 text-left">
            Output Values (optional)
          </label>
          <div className="flex flex-wrap gap-2">
            {formData.available_values.map((value, index) => (
              <div key={index} className="flex items-center">
                <div className="flex items-center gap-1 bg-emerald-100 text-emerald-800 px-3 py-1 rounded-full shadow-md transition duration-200 ease-in-out hover:bg-emerald-200">
                  <span className="text-sm">{value}</span>
                  <button
                    onClick={() => handleRemoveValue(index)}
                    className="text-emerald-500 hover:text-emerald-700 focus:outline-none"
                    aria-label="Remove value"
                  >
                    ×
                  </button>
                </div>
              </div>
            ))}
            {isAddingValue ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddValue(e);
                }}
                className="flex items-center gap-2 bg-gray-50 p-2 rounded-lg shadow-inner"
              >
                <input
                  type="text"
                  value={newValue}
                  onChange={(e) => setNewValue(e.target.value)}
                  className="p-2 border border-gray-300 rounded-md text-sm focus:ring-emerald-500 focus:border-emerald-500 transition duration-200 ease-in-out"
                  placeholder="Enter value"
                  autoFocus
                />
                <div className="flex gap-1">
                  <button
                    type="submit"
                    className="text-emerald-600 hover:text-emerald-800 font-semibold"
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsAddingValue(false)}
                    className="text-gray-500 hover:text-gray-700 font-semibold"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            ) : (
              <button
                onClick={() => setIsAddingValue(true)}
                className="flex items-center justify-center w-8 h-8 rounded-full bg-emerald-100 hover:bg-emerald-200 text-emerald-600 transition duration-200 ease-in-out shadow-md"
                aria-label="Add new value"
              >
                +
              </button>
            )}
          </div>
        </div>
      </div>

      {/* VDB Selection Modal */}
      <Dialog
        open={showVDBModal}
        onClose={() => {
          if (!isDescriptionLoading) {
            if (isAddingValue && newValue.trim()) {
              toast.error(
                "You have an unconfirmed output value. Please click 'Add' or 'Cancel' before saving.",
              );
              return;
            }
            setShowVDBModal(false);
          }
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className="bg-primary/5 border-b border-primary/10">
          <div className="flex items-center gap-2 text-gray-800">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-primary"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            {isDescriptionLoading
              ? "Deasy is Working..."
              : showSuggestionPreview
                ? "Review Deasy Suggestion"
                : formData.description
                  ? "Let Deasy Improve Your Tag"
                  : "Let Deasy Help You Create a Tag"}
          </div>
        </DialogTitle>
        <DialogContent>
          {isDescriptionLoading ? (
            <div className="flex flex-col items-center justify-center py-12 space-y-4">
              <div className="w-16 h-16 relative">
                <div className="absolute inset-0 rounded-full border-t-2 border-primary animate-spin"></div>
                <div className="absolute inset-0 rounded-full border-2 border-gray-100"></div>
              </div>
              <div className="text-center space-y-2">
                <h3 className="text-lg font-medium text-gray-900">
                  {formData.description
                    ? "Deasy is Analyzing & Improving Your Tag"
                    : "Deasy is Creating Your Tag"}
                </h3>
                <p className="text-sm text-gray-500 max-w-sm">
                  {formData.description
                    ? "Determining tag type and making it more precise and searchable..."
                    : "Using your context to create a precise and searchable tag..."}
                </p>
              </div>
            </div>
          ) : !showSuggestionPreview ? (
            <div className="space-y-6 py-4">
              {formData.description && (
                <div className="p-4 bg-gray-50 border border-gray-200 rounded-lg mb-4">
                  <div className="space-y-2">
                    <div>
                      <span className="font-medium text-gray-600 text-lg">
                        Current Tag
                      </span>
                      <div className="mt-2 space-y-2">
                        <div>
                          <span className="text-sm font-medium text-gray-500">
                            Name:
                          </span>
                          <p className="text-sm text-gray-800">
                            {formData.name}
                          </p>
                        </div>
                        <div>
                          <span className="text-sm font-medium text-gray-500">
                            Description:
                          </span>
                          <p className="text-sm text-gray-800">
                            {formData.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Tag Type Selection - Always Visible */}
              <div className="space-y-2">
                <div className="flex items-center gap-2 mb-2">
                  <div>
                    <label className="text-lg font-medium text-gray-900">
                      Select Tag Type
                    </label>
                    <p className="text-sm text-gray-500">
                      Choose whether this tag will extract or classify content
                      of documents
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <button
                    onClick={() => setTagType("classify")}
                    className={`p-4 border rounded-lg text-left transition-all ${
                      tagType === "classify"
                        ? "border-primary bg-primary/5"
                        : "border-gray-200 hover:bg-gray-50"
                    }`}
                  >
                    <div className="flex items-center gap-2 mb-2">
                      <span className="text-lg">🏷️</span>
                      <span className="font-medium">Classify</span>
                    </div>
                    <p className="text-sm text-gray-600">
                      Categorize documents based on their content
                    </p>
                  </button>
                  <button
                    onClick={() => setTagType("extract")}
                    className={`p-4 border rounded-lg text-left transition-all ${
                      tagType === "extract"
                        ? "border-primary bg-primary/5"
                        : "border-gray-200 hover:bg-gray-50"
                    }`}
                  >
                    <div className="flex items-center gap-2 mb-2">
                      <span className="text-lg">🔍</span>
                      <span className="font-medium">Extract</span>
                    </div>
                    <p className="text-sm text-gray-600">
                      Extract specific sections, values, or data points from
                      documents
                    </p>
                  </button>
                </div>
              </div>

              {/* Optional Sections Header */}
              <div className="mb-4">
                <h3 className="text-lg font-medium text-gray-900">
                  Optional Improvements
                </h3>
                <p className="text-sm text-gray-500">
                  These sections can help Deasy provide better suggestions, but
                  are not required
                </p>
              </div>

              {/* Collapsible Optional Sections */}
              <div className="space-y-4 mt-6">
                {/* Additional Context Section */}
                <div className="border border-gray-200 rounded-lg overflow-hidden">
                  <button
                    onClick={() => setIsContextExpanded(!isContextExpanded)}
                    className="text-left w-full p-4 flex items-center justify-between bg-gray-50 hover:bg-gray-100 transition-colors"
                  >
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">
                        Additional Context
                      </h3>
                      <p className="text-sm text-gray-500">
                        {tagType === "extract"
                          ? "Specify what content should be extracted"
                          : tagType === "classify"
                            ? "Define classification criteria"
                            : "Help Deasy understand how this tag should be used"}
                      </p>
                    </div>
                    <svg
                      className={`w-5 h-5 text-gray-500 transition-transform ${
                        isContextExpanded ? "transform rotate-180" : ""
                      }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {isContextExpanded && (
                    <div className="p-4 border-t border-gray-200">
                      <div className="p-4 bg-primary/5 rounded-lg border border-primary/10">
                        <textarea
                          placeholder={
                            tagType === "extract"
                              ? 'E.g., "Extract the specific agency name and its regulatory authority from sections describing oversight responsibilities"'
                              : tagType === "classify"
                                ? 'E.g., "Classify documents based on whether they contain explicit mentions of regulatory agencies and their oversight scope"'
                                : "Please select a tag type above first"
                          }
                          className="p-3 border border-gray-300 rounded-lg w-full focus:ring-primary focus:border-primary transition duration-200 ease-in-out resize-none"
                          value={extraContext}
                          onChange={(e) => setExtraContext(e.target.value)}
                          rows="4"
                          disabled={!tagType}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* Data Source Selection Section */}
                <div className="border border-gray-200 rounded-lg overflow-hidden">
                  <button
                    onClick={() =>
                      setIsDataSourceExpanded(!isDataSourceExpanded)
                    }
                    className="w-full p-4 flex items-center justify-between bg-gray-50 hover:bg-gray-100 transition-colors"
                  >
                    <div>
                      <h3 className="text-lg font-medium text-gray-900 text-left">
                        Data Source Context
                      </h3>
                      <p className="text-sm text-gray-500">
                        Select a data source to help Deasy understand your
                        domain
                      </p>
                    </div>
                    <svg
                      className={`w-5 h-5 text-gray-500 transition-transform ${
                        isDataSourceExpanded ? "transform rotate-180" : ""
                      }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {isDataSourceExpanded && (
                    <div className="p-4 border-t border-gray-200">
                      <div className="space-y-2">
                        {Object.entries(deasyUserConfig.vdbmConfig.Configs)
                          .sort((a, b) => a[0].localeCompare(b[0]))
                          .map(([configName, config]) => (
                            <button
                              key={configName}
                              onClick={() => handleVDBSelect(configName)}
                              className={`flex items-center gap-3 w-full p-3 rounded-lg hover:bg-gray-50 transition-colors border ${
                                selectedVDB === configName
                                  ? "border-primary bg-primary/5"
                                  : "border-gray-200"
                              }`}
                            >
                              <img
                                src={getVdbLogo(config.type)}
                                alt={config.type}
                                className="w-6 h-6"
                              />
                              <div className="flex flex-col items-start">
                                <span className="font-medium">
                                  {configName}
                                </span>
                                <span className="text-sm text-gray-500">
                                  {config.type}
                                </span>
                              </div>
                            </button>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="space-y-6 py-4">
              {/* Name Section with Before/After */}
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Name
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="text-xs font-medium text-gray-500">
                      Current
                    </div>
                    <div className="p-3 bg-gray-50 border border-gray-200 rounded-lg text-gray-700">
                      {formData.name}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <div className="text-xs font-medium text-gray-500">
                        Suggested
                      </div>
                      {suggestedTag.name !== formData.name && (
                        <div className="text-xs font-medium text-green-600">
                          More precise ✨
                        </div>
                      )}
                    </div>
                    <input
                      type="text"
                      value={suggestedTag.name}
                      onChange={(e) =>
                        handleSuggestedTagChange("name", e.target.value)
                      }
                      className={`p-3 border rounded-lg w-full focus:ring-primary focus:border-primary ${
                        suggestedTag.name !== formData.name
                          ? "bg-green-50 border-green-200"
                          : "border-gray-300"
                      }`}
                    />
                  </div>
                </div>
              </div>

              {/* Description Section with Before/After */}
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Description
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="text-xs font-medium text-gray-500">
                      Current
                    </div>
                    <div className="p-3 bg-gray-50 border border-gray-200 rounded-lg text-gray-700 h-[120px] overflow-y-auto">
                      {formData.description}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <div className="text-xs font-medium text-gray-500">
                        Suggested
                      </div>
                      {suggestedTag.description !== formData.description && (
                        <div className="text-xs font-medium text-green-600">
                          {suggestedTag.description
                            .toLowerCase()
                            .includes("extract")
                            ? "Better extraction criteria ✨"
                            : "Better classification criteria ✨"}
                        </div>
                      )}
                    </div>
                    <textarea
                      value={suggestedTag.description}
                      onChange={(e) =>
                        handleSuggestedTagChange("description", e.target.value)
                      }
                      className={`p-3 border rounded-lg w-full focus:ring-primary focus:border-primary resize-none h-[120px] ${
                        suggestedTag.description !== formData.description
                          ? "bg-green-50 border-green-200"
                          : "border-gray-300"
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions className="p-4 bg-gray-50 border-t border-gray-100">
          <button
            onClick={() => {
              if (isAddingValue && newValue.trim()) {
                toast.error(
                  "You have an unconfirmed output value. Please click 'Add' or 'Cancel' before saving.",
                );
                return;
              }
              setShowSuggestionPreview(false);
              setShowVDBModal(false);
              setSelectedVDB(null);
            }}
            className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Cancel
          </button>
          {!showSuggestionPreview && (
            <button
              onClick={handleSuggest}
              className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors ml-2"
              disabled={isDescriptionLoading}
            >
              {formData.description ? "Improve Tag" : "Create Tag"}
            </button>
          )}
          {showSuggestionPreview && (
            <>
              <button
                onClick={() => {
                  setShowSuggestionPreview(false);
                }}
                className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
              >
                Back to Configuration
              </button>
              <button
                onClick={handleConfirmSuggestion}
                className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors ml-2"
              >
                {formData.description ? "Apply Improvements" : "Apply Changes"}
              </button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GeneralSection;
