import React from "react";
import { ProgressTracking } from "./ProgressTracking";

function ProgressModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose} />

      {/* Modal container */}
      <div className="relative bg-white rounded-lg shadow-lg w-full max-w-6xl max-h-full overflow-auto">
        <ProgressTracking filterTaskType="classify" />
      </div>
    </div>
  );
}

export default ProgressModal;
