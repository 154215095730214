import React, { useState, useContext, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FiDownload, FiUpload, FiX } from "react-icons/fi";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { toast } from "react-hot-toast";
import { tagService } from "../../../../../services/api";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";

export const ImportTagsModal = ({ isOpen, onClose }) => {
  const [selectedFiles, setSelectedFiles] = useState({
    tags: null,
    examples: null,
  });
  const [activeTab, setActiveTab] = useState("tags");
  const { deasyUserConfig, savedTags, setSavedTags } = useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;

  const onDropTags = useCallback((acceptedFiles) => {
    if (acceptedFiles?.[0]) {
      setSelectedFiles((prev) => ({ ...prev, tags: acceptedFiles[0] }));
    }
  }, []);

  const onDropExamples = useCallback((acceptedFiles) => {
    if (acceptedFiles?.[0]) {
      setSelectedFiles((prev) => ({ ...prev, examples: acceptedFiles[0] }));
    }
  }, []);

  const {
    getRootProps: getTagsRootProps,
    getInputProps: getTagsInputProps,
    isDragActive: isTagsDragActive,
  } = useDropzone({
    onDrop: onDropTags,
    accept: {
      "text/csv": [".csv"],
    },
    multiple: false,
  });

  const {
    getRootProps: getExamplesRootProps,
    getInputProps: getExamplesInputProps,
    isDragActive: isExamplesDragActive,
  } = useDropzone({
    onDrop: onDropExamples,
    accept: {
      "text/csv": [".csv"],
    },
    multiple: false,
  });

  const handleDownloadTemplates = (type) => {
    const data = [
      ["Name", "Description", "Output_Type", "Output_Values"],
      ["Test_tag_1", "Test_tag_1 description text", "Number", "1,2,3,4,5"],
      [
        "Test_tag_2",
        "Test_tag_2 description text",
        "Date",
        "2020-01-01, 2020-03-01",
      ],
      ["Test_tag_3", "Test_tag_3 description text", "Boolean", ""],
      [
        "Test_tag_4",
        "Test_tag_4 description text",
        "Word",
        "Entity1, Entity2, Entity3",
      ],
    ];

    const exampleData = [
      ["Tag Name", "Example Name", "Evidence", "Output", "Is Positive"],
      [
        "Customer_Satisfaction",
        "example_1",
        "This product is fantastic! Best purchase ever. The quality exceeded my expectations and I would definitely buy again.",
        "Yes",
        "true",
      ],
      [
        "Customer_Satisfaction",
        "example_2",
        "The product arrived damaged and late. The packaging was torn and customer service was unhelpful.",
        "No",
        "true",
      ],
      [
        "Customer_Satisfaction",
        "example_3",
        "Great service and quick delivery! The product works as expected but had some minor issues.",
        "No",
        "false",
      ],
      [
        "Product_Quality",
        "example_1",
        "The materials feel cheap and it broke after a few uses. Very disappointed.",
        "Poor",
        "true",
      ],
      [
        "Product_Quality",
        "example_2",
        "Excellent build quality, sturdy construction, and premium materials used throughout.",
        "Excellent",
        "true",
      ],
    ];

    function arrayToCSV(data) {
      return data
        .map((row) => row.map((cell) => `"${cell}"`).join(","))
        .join("\n");
    }

    function downloadCSV(csvContent, fileName) {
      const link = document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent),
      );
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (type === "tags") {
      downloadCSV(arrayToCSV(data), "tag-template.csv");
    } else if (type === "examples") {
      downloadCSV(arrayToCSV(exampleData), "example-template.csv");
    }
  };

  const handleBatchSaveTags = async (tags) => {
    if (!Array.isArray(tags) || tags.length === 0) {
      console.log("No tags provided for batch save");
      return [];
    }

    const results = [];
    const errors = [];

    await Promise.all(
      tags.map(async (formData) => {
        if (!formData.name || !formData.description || !formData.output_type) {
          errors.push(`Invalid data for tag: ${formData.name || "unnamed"}`);
          return;
        }

        try {
          const existingTag = savedTags.find(
            (tag) => tag.name === formData.name,
          );

          const dataToSave = {
            ...formData,
            output_type: formData.output_type || "word",
            available_values: formData.available_values || [],
            tuned: typeof formData.tuned === "number" ? formData.tuned : 0,
          };

          if (existingTag) {
            dataToSave.tag_id = existingTag.tag_id;
            dataToSave.name = formData.name;
          } else {
            dataToSave.name = formData.name;
          }

          console.log(
            `Tag ${formData.name} will be ${existingTag ? "updated" : "created"}`,
          );

          const response = existingTag
            ? await tagService.updateTag(dataToSave, deasyApiKey)
            : await tagService.upsertTag(dataToSave, deasyApiKey);

          results.push(response.data);
        } catch (error) {
          errors.push(`Error saving tag ${formData.name}: ${error.message}`);
        }
      }),
    );

    const tagsResponse = await tagService.getSavedTags(deasyApiKey);
    const processedTags = (tagsResponse.data.tags || []).map((tag) => ({
      ...tag,
      tuned: typeof tag.tuned === "number" ? tag.tuned : 0,
    }));
    setSavedTags(processedTags);

    if (errors.length > 0) {
      console.error("Batch save errors:", errors);
    }

    return { results, errors };
  };

  const handleTagsImport = async (tagsFile) => {
    try {
      const text = await tagsFile.text();
      const parsedTags = Papa.parse(text, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          console.log("Papa Parse results:", results);
        },
      }).data.map((row) => {
        const availableValues = row.Output_Values
          ? row.Output_Values.replace(/^"(.*)"$/, "$1")
              .split(/\s*,\s*/)
              .filter(Boolean)
          : [];
        return {
          name: row.Name,
          description: row.Description,
          output_type: row.Output_Type?.toLowerCase() || "word",
          available_values: availableValues,
          tuned: 0,
        };
      });

      console.log("Final parsed tags:", parsedTags);

      // Validate tags
      const invalidTags = parsedTags.filter(
        (tag) => !tag.name || !tag.description,
      );
      if (invalidTags.length > 0) {
        toast.error(
          "Some tags are missing required fields (name or description)",
        );
        return;
      }

      const { results, errors } = await handleBatchSaveTags(parsedTags);

      if (errors.length > 0) {
        errors.forEach((error) => toast.error(error));
      }

      if (results.length > 0) {
        toast.success(`Successfully imported ${results.length} tags`);
      }

      return true;
    } catch (error) {
      console.error("Error parsing tags file:", error);
      toast.error("Failed to parse tags file");
      return false;
    }
  };

  const handleExamplesImport = async (examplesFile) => {
    try {
      const text = await examplesFile.text();
      const parsedExamples = Papa.parse(text, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {},
      }).data;

      // Group examples by tag
      const examplesByTag = {};
      const errors = [];

      for (const row of parsedExamples) {
        const existingTag = savedTags.find(
          (tag) => tag.name === row["Tag Name"],
        );

        if (!existingTag) {
          errors.push(`Tag "${row["Tag Name"]}" not found in your saved tags`);
          continue;
        }

        if (!row.Evidence || !row.Output) {
          errors.push(
            `Example for tag "${row["Tag Name"]}" is missing required fields`,
          );
          continue;
        }

        if (!examplesByTag[existingTag.tag_id]) {
          examplesByTag[existingTag.tag_id] = {
            ...existingTag,
            examples: [],
          };
        }

        examplesByTag[existingTag.tag_id].examples.push({
          id: uuidv4(),
          name: row["Example Name"],
          evidence: row["Evidence"],
          value: row["Output"],
          isPositive: row["Is Positive"].toLowerCase() === "true",
        });
      }

      if (errors.length > 0) {
        errors.forEach((error) => toast.error(error));
        return false;
      }

      console.log("examplesByTag", examplesByTag);

      // Update tags with their examples
      for (const tagData of Object.values(examplesByTag)) {
        try {
          await tagService.updateTag(tagData, deasyApiKey);
        } catch (error) {
          console.error(`Error updating tag ${tagData.name}:`, error);
          toast.error(`Failed to update examples for tag "${tagData.name}"`);
        }
      }

      // Refresh the tags list after updating examples
      const tagsResponse = await tagService.getSavedTags(deasyApiKey);
      const processedTags = (tagsResponse.data.tags || []).map((tag) => ({
        ...tag,
        tuned: typeof tag.tuned === "number" ? tag.tuned : 0,
      }));
      setSavedTags(processedTags);

      toast.success(
        `Successfully imported examples for ${Object.keys(examplesByTag).length} tags`,
      );
      return true;
    } catch (error) {
      console.error("Error parsing examples file:", error);
      toast.error("Failed to parse examples file");
      return false;
    }
  };

  const handleImport = async () => {
    const { tags: tagsFile, examples: examplesFile } = selectedFiles;
    let success = false;

    if (tagsFile) {
      success = await handleTagsImport(tagsFile);
    }

    if (examplesFile) {
      success = await handleExamplesImport(examplesFile);
    }

    if (success) {
      onClose();
    }
  };

  const clearFile = (type) => {
    setSelectedFiles((prev) => ({ ...prev, [type]: null }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Import Tags</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <FiX className="w-6 h-6" />
          </button>
        </div>

        <div className="flex justify-start mb-6">
          <div className="inline-flex rounded-lg bg-gray-100 p-1">
            <button
              onClick={() => setActiveTab("tags")}
              className={`px-4 py-2 text-sm rounded-md transition-colors ${
                activeTab === "tags"
                  ? "bg-white text-primary shadow-sm"
                  : "text-gray-600 hover:text-gray-900"
              }`}
            >
              Tag Definition
            </button>
            <button
              onClick={() => setActiveTab("examples")}
              className={`px-4 py-2 text-sm rounded-md transition-colors ${
                activeTab === "examples"
                  ? "bg-white text-primary shadow-sm"
                  : "text-gray-600 hover:text-gray-900"
              }`}
            >
              Tag Examples
            </button>
          </div>
        </div>

        <div className="space-y-6">
          {/* Download Templates Section */}
          <div className="border rounded-lg p-4">
            <h3 className="font-medium mb-3">Step 1: Download Template</h3>
            <div className="space-y-3 flex flex-col items-center">
              <button
                onClick={() => handleDownloadTemplates(activeTab)}
                className="w-2/3 flex items-center justify-center gap-2 px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors text-sm"
              >
                <FiDownload />
                <span>
                  Download{" "}
                  <strong>
                    {activeTab === "tags" ? "Tag Definition" : "Tag Examples"}
                  </strong>{" "}
                  Template
                </span>
              </button>
            </div>
          </div>

          {/* Upload Files Section */}
          <div className="border rounded-lg p-4">
            <h3 className="font-medium mb-3">Step 2: Upload Your File</h3>
            <div className="space-y-4">
              {activeTab === "tags" ? (
                /* Tags dropzone */
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-2">
                    Tag Definition
                  </h4>
                  <div
                    {...getTagsRootProps()}
                    className={`border-2 border-dashed rounded-lg p-8 text-center ${
                      isTagsDragActive
                        ? "border-primary bg-primary/5"
                        : "border-gray-300"
                    }`}
                  >
                    <input {...getTagsInputProps()} />
                    {selectedFiles.tags ? (
                      <div className="flex flex-col items-center">
                        <div className="flex items-center gap-2">
                          <span className="text-green-500">✓</span>
                          <span>{selectedFiles.tags.name}</span>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              clearFile("tags");
                            }}
                            className="p-1 hover:bg-gray-100 rounded-full"
                          >
                            <FiX className="w-4 h-4" />
                          </button>
                        </div>
                        <span className="text-sm text-gray-500 mt-2">
                          Click or drag to replace file
                        </span>
                      </div>
                    ) : (
                      <label className="cursor-pointer flex flex-col items-center">
                        <FiUpload className="w-8 h-8 text-gray-400 mb-2" />
                        <span className="text-gray-600">
                          Drag and drop your tags file here or click to browse
                        </span>
                      </label>
                    )}
                  </div>
                </div>
              ) : (
                /* Examples dropzone */
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-2">
                    Tag Examples
                  </h4>
                  <p className="text-sm text-amber-600 mb-2">
                    Note: Examples will only be added to the Metadata library
                    for <strong>existing tags</strong>
                  </p>
                  <div
                    {...getExamplesRootProps()}
                    className={`border-2 border-dashed rounded-lg p-8 text-center ${
                      isExamplesDragActive
                        ? "border-primary bg-primary/5"
                        : "border-gray-300"
                    }`}
                  >
                    <input {...getExamplesInputProps()} />
                    {selectedFiles.examples ? (
                      <div className="flex flex-col items-center">
                        <div className="flex items-center gap-2">
                          <span className="text-green-500">✓</span>
                          <span>{selectedFiles.examples.name}</span>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              clearFile("examples");
                            }}
                            className="p-1 hover:bg-gray-100 rounded-full"
                          >
                            <FiX className="w-4 h-4" />
                          </button>
                        </div>
                        <span className="text-sm text-gray-500 mt-2">
                          Click or drag to replace file
                        </span>
                      </div>
                    ) : (
                      <label className="cursor-pointer flex flex-col items-center">
                        <FiUpload className="w-8 h-8 text-gray-400 mb-2" />
                        <span className="text-gray-600">
                          Drag and drop your examples file here or click to
                          browse
                        </span>
                      </label>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleImport}
              disabled={
                activeTab === "tags"
                  ? !selectedFiles.tags
                  : !selectedFiles.examples
              }
              className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Import
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
