import React, { useState, useContext } from "react";
import { TokenContext } from "../../contexts/TokenContext";

const ProfileModal = ({ isOpen, onClose }) => {
  const { profileFormData, updateProfileForm, submitProfileForm } =
    useContext(TokenContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateProfileForm(name, value);
  };

  const isValidPhoneNumber = (phone) => {
    const digits = phone.replace(/\D/g, "");

    if (digits.length < 10 || digits.length > 15) {
      return false;
    }

    if (
      digits.length === 10 &&
      (digits.startsWith("0") || digits.startsWith("1"))
    ) {
      return false;
    }

    const validCharRegex = /^[0-9\s()+.-]*$/;
    if (!validCharRegex.test(phone)) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    try {
      if (
        !profileFormData.first_name ||
        !profileFormData.last_name ||
        !profileFormData.company ||
        !profileFormData.phone_number
      ) {
        setError("All fields are required");
        setIsSubmitting(false);
        return;
      }

      // Validate phone number
      if (!isValidPhoneNumber(profileFormData.phone_number)) {
        setError("Please enter a valid phone number with at least 10 digits");
        setIsSubmitting(false);
        return;
      }

      const result = await submitProfileForm();
      if (result.success) {
        onClose();
      } else {
        setError(result.error);
      }
    } catch (error) {
      console.error("Error submitting profile:", error);
      setError("Failed to update profile. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center z-50 transition-opacity duration-300">
      <div className="bg-gradient-to-b from-[#1a1f2b] to-[#232936] rounded-lg p-8 w-full max-w-md shadow-xl border border-gray-700/50">
        <div className="flex items-center mb-6">
          <div className="bg-emerald-500/20 p-2 rounded-full mr-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-emerald-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </div>
          <h2 className="text-2xl font-semibold text-emerald-400">
            Complete Your Profile
          </h2>
        </div>

        <div className="bg-blue-900/20 border-l-4 border-blue-500 p-4 rounded mb-6">
          <p className="text-blue-100 text-left">
            We've noticed your profile is not yet complete. Taking a moment to
            fill in your details will help us personalize your experience and
            provide you with the best possible service.
          </p>
        </div>

        {error && (
          <div className="bg-red-900/40 border-l-4 border-red-500 text-red-200 p-4 rounded mb-6 flex items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-red-400 mr-2 mt-0.5 flex-shrink-0"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
            <span>{error}</span>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="space-y-5">
            <div>
              <label className="block text-gray-300 mb-1 font-medium text-left">
                First Name *
              </label>
              <input
                type="text"
                name="first_name"
                value={profileFormData.first_name}
                onChange={handleChange}
                className="w-full bg-[#2d3544] border border-gray-600 rounded-md p-3 text-white focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition"
                placeholder="Enter your first name"
                required
              />
            </div>

            <div>
              <label className="block text-gray-300 mb-1 font-medium text-left">
                Last Name *
              </label>
              <input
                type="text"
                name="last_name"
                value={profileFormData.last_name}
                onChange={handleChange}
                className="w-full bg-[#2d3544] border border-gray-600 rounded-md p-3 text-white focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition"
                placeholder="Enter your last name"
                required
              />
            </div>

            <div>
              <label className="block text-gray-300 mb-1 font-medium text-left">
                Company *
              </label>
              <input
                type="text"
                name="company"
                value={profileFormData.company}
                onChange={handleChange}
                className="w-full bg-[#2d3544] border border-gray-600 rounded-md p-3 text-white focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition"
                placeholder="Enter your company name"
                required
              />
            </div>

            <div>
              <label className="block text-gray-300 mb-1 font-medium text-left">
                Phone Number *
              </label>
              <input
                type="tel"
                name="phone_number"
                value={profileFormData.phone_number}
                onChange={handleChange}
                className="w-full bg-[#2d3544] border border-gray-600 rounded-md p-3 text-white focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition"
                placeholder="+1 (123) 456-7890"
                required
              />
              <p className="text-gray-400 text-xs mt-1 text-left">
                Please enter a 10-digit phone number. We'll format it as +1
                (XXX) XXX-XXXX.
              </p>
            </div>
          </div>

          <div className="mt-8 flex justify-start items-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-emerald-500 hover:bg-emerald-600 text-white py-2.5 px-5 rounded-md font-medium disabled:opacity-50 transition-colors duration-200 flex items-center"
            >
              {isSubmitting ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Saving...
                </>
              ) : (
                "Complete Profile"
              )}
            </button>
          </div>
        </form>

        <p className="text-gray-400 text-sm mt-6 text-left">
          Your information is securely stored and will only be used to enhance
          your experience.
        </p>
      </div>
    </div>
  );
};

export default ProfileModal;
