import React, { useState, useEffect, useContext } from "react";
import { metadataService } from "../../../../../../../services/api";
import { BaseContext } from "../../../../../../../contexts/BaseContext";
import { prepareVectorDBConfiguration } from "../../../../../../../services/utils";

export const TaxonomySelector = ({
  onSelect,
  onCancel,
  tagId,
  deasyApiKey,
}) => {
  const [taxonomies, setTaxonomies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [tagFilter, setTagFilter] = useState("");
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(null);
  const { deasyUserConfig } = useContext(BaseContext);
  const {
    vdbmConfig: { Configs: vdbmConfigs, LastActive: vdbmLastActive },
  } = deasyUserConfig;
  const vectorDBConfiguration = vdbmConfigs[vdbmLastActive];

  useEffect(() => {
    const fetchTaxonomies = async () => {
      try {
        setIsLoading(true);
        const response = await metadataService.listStandardizations({
          vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
          deasyApiKey,
        });

        if (response && response.data) {
          const taxonomiesData = response.data.taxonomies || [];
          setTaxonomies(taxonomiesData);
        }
      } catch (err) {
        console.error("Error fetching taxonomies:", err);
        setError("Failed to load taxonomies. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTaxonomies();
  }, [tagId, deasyApiKey, vectorDBConfiguration]);

  const filteredTaxonomies = taxonomies
    .filter((taxonomy) => {
      const taxonomyNameToSearch = taxonomy.taxonomy_name || "(unnamed)";
      const matchesTaxonomyName = taxonomyNameToSearch
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesTagName = taxonomy.tag_name
        ?.toLowerCase()
        .includes(tagFilter.toLowerCase());
      return matchesTaxonomyName && (tagFilter === "" || matchesTagName);
    })
    .sort((a, b) => {
      if (!a.taxonomy_name && b.taxonomy_name) return 1;
      if (a.taxonomy_name && !b.taxonomy_name) return -1;
      return 0;
    });

  const handleSelectTaxonomy = (taxonomy) => {
    console.log("Selecting taxonomy:", {
      rowId: taxonomy.rowId,
      taxonomyName: taxonomy.taxonomy_name,
    });
    setSelectedTaxonomy(taxonomy);
  };

  const handleConfirmSelection = () => {
    if (selectedTaxonomy) {
      onSelect(selectedTaxonomy);
    }
  };

  return (
    <div className="w-full h-full flex flex-col bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center p-5 border-b">
        <div className="flex items-center gap-3">
          <button
            onClick={onCancel}
            className="p-2 text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <h3 className="text-xl font-semibold text-gray-800">
            Taxonomy Library
          </h3>
        </div>
        <div className="flex gap-3">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirmSelection}
            disabled={!selectedTaxonomy}
            className={`px-4 py-2 text-sm font-medium text-white rounded-md transition-colors ${
              selectedTaxonomy
                ? "bg-primary hover:bg-green-800"
                : "bg-gray-300 cursor-not-allowed"
            }`}
          >
            Apply Selected Taxonomy
          </button>
        </div>
      </div>

      {/* Search inputs */}
      <div className="flex gap-4 p-5 bg-gray-50">
        <div className="relative flex-1">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
            placeholder="Search taxonomy names..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="relative flex-1">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
              />
            </svg>
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
            placeholder="Filter by tag name..."
            value={tagFilter}
            onChange={(e) => setTagFilter(e.target.value)}
          />
        </div>
      </div>

      {/* Taxonomies list */}
      <div className="flex-1 overflow-hidden">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-10 w-10 border-4 border-blue-500 border-t-transparent"></div>
          </div>
        ) : error ? (
          <div className="flex justify-center items-center h-64 text-red-500">
            <svg
              className="w-8 h-8 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p className="text-lg">{error}</p>
          </div>
        ) : filteredTaxonomies.length === 0 ? (
          <div className="flex flex-col justify-center items-center h-64 text-gray-500">
            <svg
              className="h-16 w-16 mb-3 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p className="text-lg">No taxonomies found</p>
            <p className="text-sm mt-2 text-gray-400">
              Try adjusting your search criteria
            </p>
          </div>
        ) : (
          <div className="h-full overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3.5 text-left font-medium text-gray-600 text-sm tracking-wider"
                  >
                    <div className="flex items-center space-x-2">
                      <svg
                        className="h-4 w-4 text-gray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
                        />
                      </svg>
                      <span>Taxonomy Name</span>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3.5 text-left font-medium text-gray-600 text-sm tracking-wider"
                  >
                    <div className="flex items-center space-x-2">
                      <svg
                        className="h-4 w-4 text-gray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                        />
                      </svg>
                      <span>Tag Name</span>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3.5 text-left font-medium text-gray-600 text-sm tracking-wider"
                  >
                    <div className="flex items-center space-x-2">
                      <svg
                        className="h-4 w-4 text-gray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                        />
                      </svg>
                      <span>Categories</span>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3.5 text-left font-medium text-gray-600 text-sm tracking-wider"
                  >
                    <div className="flex items-center space-x-2">
                      <svg
                        className="h-4 w-4 text-gray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                      <span>Created</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredTaxonomies.map((taxonomy, index) => {
                  const rowId = `row-${index}`;
                  const isSelected = selectedTaxonomy?.rowId === rowId;

                  return (
                    <React.Fragment key={rowId}>
                      <tr
                        onClick={() =>
                          handleSelectTaxonomy({ ...taxonomy, rowId })
                        }
                        className={`
                          cursor-pointer
                          transition-all
                          duration-200
                          ease-in-out
                          hover:bg-blue-50
                          border-l-4
                          ${
                            isSelected
                              ? "bg-blue-50/60 border-blue-500 shadow-sm"
                              : "border-transparent"
                          }
                        `}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-left">
                          <div className="text-sm font-medium text-gray-900">
                            {taxonomy.taxonomy_name
                              ? taxonomy.taxonomy_name
                              : "(unnamed)"}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-left">
                          <div className="text-sm font-medium text-gray-700">
                            {taxonomy.tag_name ? (
                              <span className="flex items-center gap-1.5">
                                <span className="w-2 h-2 rounded-full bg-primary"></span>
                                {taxonomy.tag_name}
                              </span>
                            ) : (
                              <span className="text-gray-400">N/A</span>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 text-left">
                          <div className="flex flex-wrap gap-1.5 max-w-md">
                            {taxonomy.standard_mapping ? (
                              <>
                                {Object.keys(taxonomy.standard_mapping)
                                  .slice(0, 3)
                                  .map((category) => (
                                    <span
                                      key={category}
                                      className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-green-50 text-green-700 border border-green-200"
                                    >
                                      {category}
                                    </span>
                                  ))}
                                {Object.keys(taxonomy.standard_mapping).length >
                                  3 && (
                                  <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-gray-50 text-gray-500 border border-gray-200">
                                    +
                                    {Object.keys(taxonomy.standard_mapping)
                                      .length - 3}
                                  </span>
                                )}
                              </>
                            ) : (
                              <span className="text-sm text-gray-400">
                                No categories
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500">
                          {taxonomy.created_at
                            ? new Date(taxonomy.created_at).toLocaleDateString()
                            : "N/A"}
                        </td>
                      </tr>
                      {isSelected && (
                        <tr>
                          <td colSpan="4" className="px-0 py-0">
                            <div className="px-6 py-4 bg-blue-50/30">
                              <div className="flex items-center justify-between mb-3">
                                <h4 className="text-sm font-semibold text-gray-800 flex items-center">
                                  <svg
                                    className="h-4 w-4 text-blue-500 mr-2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                                    />
                                  </svg>
                                  Taxonomy Preview
                                </h4>
                                <span className="px-2.5 py-1 text-xs font-medium bg-gray-100 text-gray-600 rounded-full">
                                  {
                                    Object.keys(taxonomy.standard_mapping || {})
                                      .length
                                  }{" "}
                                  Categories
                                </span>
                              </div>
                              <div className="grid grid-cols-3 gap-3 max-h-[250px] overflow-y-auto pr-2">
                                {Object.entries(
                                  taxonomy.standard_mapping || {},
                                ).map(([category, values]) => (
                                  <div
                                    key={category}
                                    className="bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow p-3"
                                  >
                                    <div className="font-medium text-sm text-gray-800 mb-2 flex items-center gap-2">
                                      <svg
                                        className="h-4 w-4 text-blue-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                                        />
                                      </svg>
                                      {category}
                                    </div>
                                    <div className="flex flex-wrap gap-1.5">
                                      {values.slice(0, 5).map((value) => (
                                        <span
                                          key={value}
                                          className="text-xs bg-gray-50 text-gray-600 px-2 py-1 rounded-md border border-gray-200"
                                        >
                                          {value}
                                        </span>
                                      ))}
                                      {values.length > 5 && (
                                        <span className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded-md border border-gray-200">
                                          +{values.length - 5} more
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
