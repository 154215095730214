import { useContext, useState, useEffect } from "react";
import { FiTag, FiLoader } from "react-icons/fi";
import TagsTable from "./TagTables/TagsTable";
import { BaseContext } from "../../../../contexts/BaseContext";
import { tagService, tagGroupService } from "../../../../services/api";

const TagSelector = ({
  tagInEditor,
  setTagInEditor,
  setIsTagSelectorExpanded = null,
}) => {
  const { deasyApiKey } = useContext(BaseContext);
  const [tags, setTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(false);
  const [tagGroups, setTagGroups] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      if (!deasyApiKey) return;

      setLoadingTags(true);

      try {
        // Make both API calls in parallel using Promise.all
        const [tagsResponse, groupsResponse] = await Promise.all([
          tagService.getSavedTags(deasyApiKey).catch((error) => {
            console.error("Error loading tags:", error);
            return { data: { tags: [] } };
          }),
          tagGroupService.getTagGroups(deasyApiKey).catch((error) => {
            console.error("Error loading tag groups:", error);
            return { data: { tag_groups: [] } };
          }),
        ]);

        // Process tags data
        const processedTags = (tagsResponse.data.tags || []).map((tag) => ({
          ...tag,
          tuned: typeof tag.tuned === "number" ? tag.tuned : 0,
        }));

        setTags(processedTags);
        setTagGroups(groupsResponse.data.tag_groups || []);
      } catch (error) {
        console.error("Error loading data:", error);
        setTags([]);
        setTagGroups([]);
      } finally {
        setLoadingTags(false);
      }
    };

    loadData();
  }, [deasyApiKey]);

  return (
    <div className="h-full flex flex-col bg-white rounded-lg shadow-md overflow-hidden">
      <div className="border-b border-gray-200 bg-gray-50">
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center space-x-2">
            <FiTag className="h-5 w-5 text-primary" />
            <div className="flex flex-col">
              <h2 className="font-semibold text-gray-800">Tag Selector</h2>
              {tagInEditor?.name && (
                <div className="flex items-center mt-1">
                  <span className="text-sm text-gray-600">Selected: </span>
                  <span className="text-sm font-medium text-primary-700 ml-1 bg-primary-50 px-2 py-0.5 rounded-full">
                    {tagInEditor.name}
                  </span>
                </div>
              )}
            </div>
          </div>

          {loadingTags && (
            <div className="flex items-center text-gray-500">
              <FiLoader className="animate-spin h-4 w-4 mr-2" />
              <span className="text-sm">Loading...</span>
            </div>
          )}
        </div>
      </div>

      <div className="flex-1 overflow-hidden">
        <TagsTable
          // Display options
          forceExpanded={true}
          compressedTagTable={true}
          isLoading={loadingTags}
          hideNewTagButton={true}
          // Tag data
          tags={tags}
          // Tag Editor state
          tagInEditor={tagInEditor}
          setTagInEditor={setTagInEditor}
          setIsTagSelectorExpanded={setIsTagSelectorExpanded}
          // Tag Groups
          tagGroups={tagGroups}
        />
      </div>
    </div>
  );
};

export default TagSelector;
