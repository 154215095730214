import { useState, useEffect, useContext } from "react";
import {
  FaSpinner,
  FaSearch,
  FaChevronDown,
  FaChevronUp,
  FaToggleOff,
  FaToggleOn,
  FaInfoCircle,
} from "react-icons/fa";
import { AiFillAlert } from "react-icons/ai";
import TSOutputCard from "./TSOutputCard";
import { TSContext } from "../TSContext";

const TaggingStudioOutput = () => {
  const {
    tsRunning,
    tagInEditor,
    setTagInEditor,
    setActiveTagEditorSection,
    taggingStudioOutput,
  } = useContext(TSContext);

  // State and variables
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedFiles, setExpandedFiles] = useState(new Set());
  // Add state for showing/hiding empty values
  const [showEmptyValues, setShowEmptyValues] = useState(false);

  // Reset search and expanded files when tag changes
  useEffect(() => {
    setSearchTerm("");
    setExpandedFiles(new Set());
  }, [tagInEditor?.name]);

  // Add this effect to clear results when test starts
  useEffect(() => {
    if (tsRunning) {
      // This would need to be handled in parent component's state
      // For demonstration, assuming parent passes empty output when running
      setSearchTerm("");
      setExpandedFiles(new Set());
    }
  }, [tsRunning]);

  const toggleFile = (filename) => {
    const newExpanded = new Set(expandedFiles);
    if (newExpanded.has(filename)) {
      newExpanded.delete(filename);
    } else {
      newExpanded.add(filename);
    }
    setExpandedFiles(newExpanded);
  };

  const toggleAll = () => {
    if (expandedFiles.size === Object.keys(taggingStudioOutput).length) {
      setExpandedFiles(new Set());
    } else {
      setExpandedFiles(new Set(Object.keys(taggingStudioOutput)));
    }
  };

  // Empty state component
  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center py-12 px-4 text-center">
      <AiFillAlert className="w-12 h-12 text-emerald-400 mb-2" />
      <h2 className="text-xl font-semibold text-gray-800">
        No Output Available
      </h2>
      <p className="text-sm text-gray-500 max-w-md">
        To get started, please:
        <ol className="mt-2 text-left list-decimal pl-4 space-y-1">
          <li>Select a tag from the list or create a new one</li>
          <li>Configure your tag settings</li>
          <li>Select the files you want to analyze</li>
          <li>Run the tag test</li>
        </ol>
      </p>
    </div>
  );

  // Loading state component
  const LoadingState = () => (
    <div className="flex flex-col items-center justify-center py-12 px-4">
      <FaSpinner className="w-8 h-8 text-emerald-500 animate-spin mb-4" />
      <h3 className="text-lg font-medium text-gray-900">Processing Files</h3>
      <p className="text-sm text-gray-500 mt-2">
        This may take a few moments...
      </p>
    </div>
  );

  // Filter function to check if a result should be displayed based on empty value toggle
  const shouldShowResult = (tagValue) => {
    if (showEmptyValues) {
      return true;
    } else {
      return (
        tagValue &&
        tagValue.length > 0 &&
        !(tagValue.length === 1 && tagValue[0] === "Not found")
      );
    }
  };

  return (
    <div className="w-full h-full bg-white border rounded-lg shadow">
      <div className="p-4 border-b border-gray-200 bg-gray-50 rounded-t-lg">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-xl font-semibold text-gray-800">Results</h2>
          <div className="flex items-center gap-3">
            {/* Toggle for showing/hiding empty values */}
            {taggingStudioOutput &&
              Object.keys(taggingStudioOutput).length > 0 && (
                <div className="flex items-center gap-1 relative group">
                  <button
                    onClick={() => setShowEmptyValues(!showEmptyValues)}
                    className="flex items-center gap-1 text-sm text-gray-600 hover:text-gray-800"
                  >
                    {showEmptyValues ? (
                      <FaToggleOn className="text-emerald-500 text-lg" />
                    ) : (
                      <FaToggleOff className="text-gray-400 text-lg" />
                    )}
                    <span>Show Unfound Results</span>
                  </button>
                  <FaInfoCircle className="text-gray-400 text-xs cursor-help" />
                  <div className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded p-2 w-48 mb-1 bottom-[-500%] left-0">
                    This toggle only affects chunk-level values with "Not
                    found".
                  </div>
                </div>
              )}

            {taggingStudioOutput &&
              Object.keys(taggingStudioOutput).length > 0 && (
                <button
                  onClick={toggleAll}
                  className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                >
                  {expandedFiles.size ===
                  Object.keys(taggingStudioOutput).length
                    ? "Collapse All"
                    : "Expand All"}
                </button>
              )}
          </div>
        </div>

        {!tsRunning && taggingStudioOutput?.length > 0 && (
          <div className="relative">
            <input
              type="text"
              placeholder="Search results..."
              className="w-full px-4 py-2 pl-10 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
          </div>
        )}
      </div>

      <div className="overflow-y-auto max-h-[80vh] ">
        {tsRunning ? (
          <LoadingState />
        ) : !taggingStudioOutput ||
          Object.keys(taggingStudioOutput).length === 0 ||
          !tagInEditor?.name ? (
          <EmptyState />
        ) : (
          <div className="divide-y divide-gray-200 border-b border-gray-200">
            {Object.entries(taggingStudioOutput).map(
              ([filename, resultBody]) => {
                const fileLevelValue = Object.values(resultBody.file_value)[0];
                return (
                  <div key={filename} className="bg-white">
                    <button
                      onClick={() => toggleFile(filename)}
                      className="w-full px-4 py-3 flex items-center justify-between hover:bg-gray-50 text-left"
                    >
                      <div className="flex flex-col items-start justify-between w-full">
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-sm italic text-gray-700">
                            {filename}
                          </span>
                          {expandedFiles.has(filename) ? (
                            <FaChevronUp className="text-gray-400" />
                          ) : (
                            <FaChevronDown className="text-gray-400" />
                          )}
                        </div>
                        <div className="flex items-start gap-2 overflow-x-auto mt-2 bg-gray-100 rounded-md p-2 flex-col w-full">
                          <div className="flex items-center gap-2 font-semibold text-gray-700 whitespace-nowrap">
                            File Level Values
                          </div>
                          <div className="flex flex-wrap gap-2">
                            {fileLevelValue && fileLevelValue.length > 0 ? (
                              fileLevelValue.map((value) => (
                                <div
                                  key={value}
                                  className="px-2 py-1 text-xs font-semibold rounded-sm bg-emerald-100 text-emerald-800"
                                >
                                  {value}
                                </div>
                              ))
                            ) : (
                              <div className="px-2 py-1 text-xs font-semibold rounded-sm bg-emerald-100 text-emerald-800">
                                Not found
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </button>

                    {expandedFiles.has(filename) && (
                      <div className="px-4 py-2 space-y-2">
                        {(() => {
                          const filteredResults = Object.entries(
                            resultBody.chunks_values,
                          ).filter(([_, result]) => {
                            const tagName = Object.keys(result)[0];
                            const tagValue = result[tagName]?.value;
                            return shouldShowResult(tagValue);
                          });

                          if (filteredResults.length === 0) {
                            return (
                              <div className="text-center py-4 text-gray-500">
                                No matching results found. Try adjusting the
                                "Show Unfound Results" toggle.
                              </div>
                            );
                          }

                          return filteredResults.map(
                            ([pointId, result], resultIndex) => {
                              const tagName = Object.keys(result)[0];
                              const tagValue = result[tagName]?.value;
                              const tagEvidence = result[tagName]?.evidence;
                              return (
                                <TSOutputCard
                                  key={resultIndex}
                                  value={tagValue}
                                  evidence={tagEvidence}
                                  filename={filename}
                                  pointId={pointId}
                                  setActiveTagEditorSection={
                                    setActiveTagEditorSection
                                  }
                                  setTagInEditor={setTagInEditor}
                                  tagInEditor={tagInEditor}
                                />
                              );
                            },
                          );
                        })()}
                      </div>
                    )}
                  </div>
                );
              },
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TaggingStudioOutput;
