import React from "react";
import { FiSearch } from "react-icons/fi";

// Utility functions
export const renderAvailableTagValues = (tag) => {
  if (
    !Array.isArray(tag.available_values) ||
    tag.available_values.length === 0
  ) {
    const typeMap = {
      number: "Any number",
      date: "Any date",
      text: "Any text",
    };
    return (
      <span className="text-xs text-gray-500 italic">
        {typeMap[tag.output_type] || "Any text"}
      </span>
    );
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-wrap gap-1">
        {tag.available_values.map((value, idx) => (
          <span
            key={idx}
            className="text-xs bg-emerald-50 text-emerald-700 px-2 py-1 rounded-md shadow-md"
          >
            {value}
          </span>
        ))}
      </div>
      {tag.maxValues && (
        <span className="text-xs text-gray-500 italic">
          Max values: {tag.maxValues}
        </span>
      )}
    </div>
  );
};

export const getTagType = (tag) => {
  const types = {
    yesNo: { label: "Yes/No", style: "bg-green-100 text-green-800" },
    custom: { label: "Defined values", style: "bg-gray-100 text-blue-800" },
    aiGenerated: { label: "Open ended", style: "bg-gray-100 text-blue-800" },
  };

  if (!Array.isArray(tag.available_values)) {
    console.log("Error: available_values is not an array for tag", tag.name);
    return types.aiGenerated;
  }

  // Determine type based on available_values first
  const available_values = tag.available_values.length;
  if (available_values === 0) {
    return types.aiGenerated;
  } else if (
    available_values === 2 &&
    tag.available_values.every(
      (val) => val.toLowerCase() === "yes" || val.toLowerCase() === "no",
    )
  ) {
    return types.yesNo;
  } else {
    return types.custom; // Any tag with values is "Defined values"
  }
};

export const CompactsTableHeader = ({
  searchTerm,
  setSearchTerm,
  selectedType,
  setSelectedType,
  localShowUnique,
  setLocalShowUnique,
  tagGroups,
  selectedTagGroup,
  setSelectedTagGroup,
}) => (
  <div className="p-5 flex flex-col gap-4 border-b border-gray-200 sticky top-0 bg-white z-10 shadow-sm">
    <div className="flex flex-wrap gap-3 items-center">
      <div className="relative flex-grow min-w-[200px] group">
        <div className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 transition-colors group-focus-within:text-primary">
          <FiSearch className="w-full h-full" />
        </div>
        <input
          type="text"
          placeholder="Search tags..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-primary outline-none transition-all duration-200 bg-gray-50 focus:bg-white"
        />
      </div>

      <div className="flex flex-wrap gap-3 w-full">
        <div className="relative min-w-[200px] flex-grow">
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          <select
            value={selectedTagGroup}
            onChange={(e) => setSelectedTagGroup(e.target.value)}
            className="w-full appearance-none px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-primary outline-none transition-all duration-200 bg-gray-50 focus:bg-white cursor-pointer"
          >
            <option value="">All Tag Groups</option>
            {tagGroups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
        </div>

        <div className="relative min-w-[150px] flex-grow">
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          <select
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            className="w-full appearance-none px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-primary outline-none transition-all duration-200 bg-gray-50 focus:bg-white cursor-pointer"
          >
            <option value="">All Types</option>
            <option value="Yes/No">Yes/No</option>
            <option value="Defined values">Defined values</option>
            <option value="Open ended">Open ended</option>
          </select>
        </div>

        <div className="relative group">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setLocalShowUnique(!localShowUnique);
            }}
            className={`px-4 py-3 rounded-lg transition-all duration-200 flex items-center gap-2 ${
              localShowUnique
                ? "bg-primary text-white border border-primary shadow-md"
                : "bg-gray-50 text-gray-700 border border-gray-300 hover:border-primary hover:text-primary"
            }`}
            aria-label={
              localShowUnique
                ? "Show all tags"
                : "Show tags extracted from the active data source"
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-5 w-5 ${localShowUnique ? "text-white" : "text-gray-500"}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
              />
            </svg>
            <span>Extracted</span>
          </button>
          <div className="absolute right-0 top-full mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none z-20">
            <div className="w-2 h-2 bg-gray-800 transform rotate-45 absolute -top-1 right-4"></div>
            <div className="bg-gray-800 text-white text-xs rounded py-2 px-3 whitespace-nowrap shadow-lg">
              Only include tags extracted from the active data source
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
