import axios from "axios";
import { auth } from "../config/firebase";
import { prepareVectorDBConfiguration } from "./utils";
const API_URL = process.env.REACT_APP_API_URL;

// Create single API instance
const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add authentication interceptor for console endpoints
const getAuthHeaders = async () => {
  const user = auth.currentUser;
  console.log(
    "Getting auth headers, current user:",
    user ? user.email : "No user",
  );

  if (user) {
    const token = await user.getIdToken();
    return {
      Authorization: `Bearer ${token}`,
      "x-user": user.email,
    };
  }
  return {};
};

// Metadata specific headers
export const tokenService = {
  listTokens: async () =>
    api.get("/console/token/list", { headers: await getAuthHeaders() }),
  createToken: async (username) =>
    api.post(
      "/console/token/create",
      { username },
      { headers: await getAuthHeaders() },
    ),
  deleteToken: async (tokenId, softDelete = true) =>
    api.delete(`/console/token/${tokenId}`, {
      data: { soft_delete: softDelete },
      headers: await getAuthHeaders(),
    }),
  updateUserProfile: async (profileData) =>
    api.post(
      "/console/user/profile",
      {
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        phone_number: profileData.phone_number,
        company: profileData.company,
      },
      { headers: await getAuthHeaders() },
    ),
  getSecret: async (secretName) => {
    const response = await api.post(
      `/console/secret/get`,
      { secret_name: secretName },
      { headers: await getAuthHeaders() },
    );

    // Add names to VDB configs inline when getting deasyUserConfig
    if (secretName === "deasyUserConfig" && response.data.secret) {
      const config = JSON.parse(response.data.secret);
      if (config.vdbmConfig?.Configs) {
        Object.entries(config.vdbmConfig.Configs).forEach(
          ([name, vdbConfig]) => {
            config.vdbmConfig.Configs[name] = {
              ...vdbConfig,
              name: name,
            };
          },
        );
      }
      response.data.secret = JSON.stringify(config);
    }

    return response;
  },
  storeSecret: async (secretName, secretValue) => {
    // Add names to VDB configs inline when storing deasyUserConfig
    if (secretName === "deasyUserConfig") {
      const config = JSON.parse(secretValue);
      if (config.vdbmConfig?.Configs) {
        Object.entries(config.vdbmConfig.Configs).forEach(
          ([name, vdbConfig]) => {
            // Remove temporary attributes from vdbConfigs ("syncStats")
            if (vdbConfig.syncStats) {
              delete vdbConfig.syncStats;
            }

            // Then set "name" property to config
            config.vdbmConfig.Configs[name] = {
              ...vdbConfig,
              name: name,
            };
          },
        );
      }
      secretValue = JSON.stringify(config);
    }

    return api.post(
      `/console/secret/store`,
      { secret_name: secretName, secret_value: secretValue },
      { headers: await getAuthHeaders() },
    );
  },
  getVDBDeleteStats: async (profileName, deasyApiKey) =>
    api.post(
      "/vdb_connector/delete_stats",
      { connector_name: profileName },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  validateVectorDB: async (config) =>
    api.post("/console/vector-db/validate", config, {
      headers: await getAuthHeaders(),
    }),
  // TODO: swap with the profile id?
  validateDestination: async (destinationConfig) =>
    api.post(
      "/console/destination/validate",
      { config: destinationConfig },
      {
        headers: await getAuthHeaders(),
      },
    ),
  getVectorDBIndexFieldInfo: async (vdbProfileName, dbType, vdbConfig = null) =>
    api.post(
      "/console/vector-db/check-index-fields",
      {
        vector_db_profile: vdbProfileName,
        db_type: dbType,
        vdb_config: vdbConfig,
      },
      {
        headers: await getAuthHeaders(),
      },
    ),
  // TODO: swap with the profile id?
  validateLLMProvider: async (providerConfig) => {
    try {
      const headers = await getAuthHeaders();

      return await api.post(
        "/console/llm-provider/validate",
        { endpoint_manager_config: providerConfig },
        { headers: headers },
      );
    } catch (error) {
      console.error(
        "LLM validation error:",
        error.response?.status,
        error.response?.data || error,
      );
      throw error;
    }
  },
  linkMarketplaceAccount: async (linkData) =>
    api.post("/console/marketplace/signup", linkData, {
      headers: await getAuthHeaders(),
    }),
  getSubscriptionInfo: async () =>
    api.get("/console/subscription", { headers: await getAuthHeaders() }),
  fetchTiers: async () =>
    api.get("/console/fetch_tiers", { headers: await getAuthHeaders() }),
  createSubscriptionSession: async (tierId) =>
    api.post(
      "/console/subscription/stripe/payment",
      { tier_id: tierId },
      { headers: await getAuthHeaders() },
    ),
  cancelSubscription: async () =>
    api.post(
      "/console/subscription/stripe/unsubscribe",
      {},
      { headers: await getAuthHeaders() },
    ),
  validateStripeSubscription: async () => {
    const headers = await getAuthHeaders();
    return api.post("/console/subscription/stripe/validate", {}, { headers });
  },
};

export const connectorService = {
  setActiveProfile: async (profileName, profileType, deasyApiKey) =>
    api.post(
      "/connector/set_active_profile",
      { connector_name: profileName, connector_type: profileType },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  createVDBConnector: async (profileName, vdbConfig, deasyApiKey) =>
    api.post(
      "/vdb_connector/create",
      { connector_name: profileName, connector_body: vdbConfig },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  createPostgresFromUnstructured: async (connectorData, deasyApiKey) =>
    api.post("/vdb_connector/create_postgres", connectorData, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  createLLMConnector: async (profileName, llmConfig, deasyApiKey) =>
    api.post(
      "/llm_connector/create",
      { connector_name: profileName, connector_body: llmConfig },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  updateVDBConnector: async (profileName, vdbConfig, deasyApiKey) =>
    api.post(
      "/vdb_connector/update",
      { connector_name: profileName, connector_body: vdbConfig },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  updateLLMConnector: async (profileName, llmConfig, deasyApiKey) =>
    api.post(
      "/llm_connector/update",
      { connector_name: profileName, connector_body: llmConfig },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  deleteVDBConnector: async (profileName, deasyApiKey) =>
    api.post(
      "/vdb_connector/delete",
      { connector_name: profileName },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  deleteLLMConnector: async (profileName, deasyApiKey) =>
    api.post(
      "/llm_connector/delete",
      { connector_name: profileName },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  getVDBProfiles: async (deasyApiKey) =>
    api.post(
      "/vdb_connector/list",
      {},
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  getLLMProfiles: async (deasyApiKey) =>
    api.post(
      "/llm_connector/list",
      {},
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
};

export const metadataService = {
  // TODO: swap with the profile id
  getHierarchyCountDistributions: async (data, deasyApiKey) =>
    api.post("/metadata/count_distributions", data, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  // TODO: swap with the profile id
  getUniqueTags: async (
    deasyApiKey,
    fileNames = null,
    vector_db_config = null,
    dataslice_id = null,
    node_condition = null,
  ) =>
    api.post(
      "/metadata/get_unique_tags",
      {
        file_names: fileNames,
        vector_db_config: vector_db_config,
        dataslice_id: dataslice_id,
        node_condition: node_condition,
      },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  // TODO: swap with the profile id
  getTagStatistics: async (
    vectorDBConfiguration,
    tagIds,
    deasyApiKey,
    conditions = [],
  ) =>
    api.post(
      "/metadata/tag_statistics",
      {
        vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
        tag_ids: tagIds,
        conditions: conditions,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  getOOBTaggedFileCount: async (
    vectorDBConfiguration,
    conditions,
    deasyApiKey,
  ) =>
    api.post(
      "/metadata/oob_tag_file_count",
      {
        vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
        conditions: conditions,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  filterByConditions: async (
    vectorDBConfiguration,
    conditions,
    analyzeRemainingTags,
    deasyApiKey,
  ) =>
    api.post(
      "/metadata/conditional_filter",
      {
        vector_db_config: vectorDBConfiguration,
        conditions: conditions,
        analyze_remaining_tags: analyzeRemainingTags,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  getFileTags: async (vectorDBConfiguration, fileNames, deasyApiKey) =>
    api.post(
      "/metadata/file/list",
      {
        file_names: fileNames,
        vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
      },
      {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      },
    ),
  // TODO: swap with the profile id
  getTagEvidence: async (
    vectorDBConfiguration,
    fileName,
    tagName,
    value,
    deasyApiKey,
  ) =>
    api.post(
      "/metadata/get_evidence",
      {
        vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
        filename: fileName,
        tag_id: tagName,
        tag_value: value,
      },
      {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      },
    ),
  // TODO: swap with the profile id
  getDistinctValuesPaginated: async (
    vectorDBConfiguration,
    deasyApiKey,
    tagId,
    offset = 0,
    limit = 50,
    searchText = null,
  ) =>
    api.post(
      "/metadata/distinct_values",
      {
        vector_db_config: vectorDBConfiguration,
        tag_id: tagId,
        page: offset,
        per_page: limit,
        search_query: searchText,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  getFilteredMetadata: async (
    vectorDBConfiguration,
    offset = 0,
    limit = 50,
    deasyApiKey,
    dataslice_id = null,
    node_condition = null,
    searchQuery = null,
    conditions = null,
  ) =>
    api.post(
      "/metadata/filtered",
      {
        vector_db_config: vectorDBConfiguration,
        offset: offset,
        limit: limit,
        dataslice_id: dataslice_id,
        node_condition: node_condition,
        search_query: searchQuery,
        conditions: conditions,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  getDistributions: async (
    vectorDBConfiguration,
    deasyApiKey,
    dataslice_id = null,
    node_condition = null,
    maxValPerTag = 500,
    conditionsNew = null,
    columns = null,
  ) =>
    api.post(
      "/metadata/distributions",
      {
        vector_db_config: vectorDBConfiguration,
        dataslice_id: dataslice_id,
        node_condition: node_condition,
        max_val_per_tag: maxValPerTag,
        conditions_new: conditionsNew,
        columns: columns,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  getTagTotals: async (
    vectorDBConfiguration,
    deasyApiKey,
    dataslice_id = null,
    node_condition = null,
    columns = null,
  ) =>
    api.post(
      "/metadata/tag_total_files",
      {
        vector_db_config: vectorDBConfiguration,
        dataslice_id: dataslice_id,
        node_condition: node_condition,
        columns: columns,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  listMetadataPostgres: async (
    vectorDBConfiguration,
    deasyApiKey,
    groupBy = "file",
    metadataKeys = null,
    fileNames = null,
    pointIds = null,
    limit = null,
  ) => {
    const response = await api.post(
      "/metadata/chunk/list",
      {
        vector_db_config: vectorDBConfiguration,
        group_by: groupBy,
        metadata_keys: metadataKeys,
        file_names: fileNames,
        point_ids: pointIds,
        limit: limit,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    );
    return response.data;
  },
  // TODO: swap with the profile id
  suggestStandardizedValues: async (
    tagName,
    description,
    outputType,
    valueDistribution,
    deasyApiKey,
    endpointManagerConfig,
    vectorDBConfiguration,
    existingCategories,
    context,
  ) =>
    api.post(
      "/metadata/standardization_suggest",
      {
        tag_name: tagName,
        description: description,
        output_type: outputType,
        value_distribution: valueDistribution,
        endpoint_manager_config: endpointManagerConfig,
        vector_db_config: vectorDBConfiguration,
        existing_categories: existingCategories,
        context: context,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  standardizeMetadataDB: async (
    tag_id,
    standardMaping,
    deasyApiKey,
    endpointManagerConfig,
    vectorDBConfiguration,
  ) =>
    api.post(
      "/metadata/standardization_db",
      {
        tag_name: tag_id,
        standard_mapping: standardMaping,
        endpoint_manager_config: endpointManagerConfig,
        vector_db_config: vectorDBConfiguration,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  getStandardizeTagDistribution: async (
    tagId,
    vectorDBConfiguration,
    deasyApiKey,
  ) =>
    api.post(
      "/metadata/standardize/tag_distribution",
      {
        tag_id: tagId,
        vector_db_config: vectorDBConfiguration,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  insertStandardization: async ({
    tag_id,
    tag_name,
    standard_mapping,
    taxonomy_name,
    vector_db_config,
    deasyApiKey,
  }) =>
    api.post(
      "/metadata/standardize/insert",
      {
        tag_id: tag_id,
        tag_name: tag_name,
        standard_mapping: standard_mapping,
        vector_db_config: vector_db_config,
        taxonomy_name: taxonomy_name,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  listStandardizations: async ({ vector_db_config, deasyApiKey }) =>
    api.post(
      "/metadata/standardize/list",
      {
        vector_db_config: vector_db_config,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  deleteMetadata: async (
    vdbProfileName,
    deasyApiKey,
    fileNames = null,
    tags = null,
    conditions = null,
  ) =>
    api.post(
      "/metadata/delete",
      {
        vdb_profile_name: vdbProfileName,
        file_names: fileNames,
        tags: tags,
        conditions: conditions,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
};

export const graphService = {
  createUpdateGraph: async (
    deasyApiKey,
    graph_name,
    graph_description = null,
    graph_data = null,
    new_graph_name = null,
  ) =>
    api
      .post(
        "/graph/upsert",
        {
          graph_name,
          new_graph_name,
          graph_description,
          graph_data,
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        return response;
      }),
  getGraphs: async (deasyApiKey, graphIds = null) =>
    api
      .post(
        `/graph/list`,
        { graph_ids: graphIds },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        return response;
      }),
  deleteGraph: async (graphName, deasyApiKey) =>
    api.delete(`/graph/delete?graph_name=${graphName}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
};

export const progressTrackingService = {
  getProgressTrackers: async (deasyApiKey) =>
    api
      .get("/progress_tracker/get_progress_trackers", {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      })
      .then((response) => {
        return response;
      }),
  abortProgressTracker: async (trackerId, deasyApiKey) =>
    api
      .put(
        `/progress_tracker/abort_progress_tracker/${trackerId}`,
        {},
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => response),
  deleteProgressTrackers: async (trackerIds, deasyApiKey) =>
    api
      .post(
        `/progress_tracker/delete_progress_trackers`,
        {
          tracker_ids: trackerIds,
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        return response;
      }),
  trackJob: async (uuid, deasyApiKey, maxRetries = 5) => {
    let retryCount = 0;
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    while (true) {
      try {
        const response = await api.get(
          `/progress_tracker/task_status/${uuid}`,
          {
            headers: {
              "x-token": deasyApiKey,
              "x-user": auth.currentUser.email,
            },
          },
        );
        return response;
      } catch (error) {
        // If we've exhausted all retries, throw the error
        if (retryCount >= maxRetries) {
          console.error(
            `Failed to fetch task status after ${maxRetries} retries:`,
            error,
          );
          throw error;
        }

        // Calculate delay with exponential backoff and jitter
        const baseDelay = Math.pow(2, retryCount) * 1000; // 1s, 2s, 4s, 8s...
        const jitter = Math.random() * 1000; // Random delay between 0-1000ms
        const delay = baseDelay + jitter;

        console.warn(
          `Retry attempt ${retryCount + 1} for task ${uuid}.`,
          `Waiting ${delay}ms before next attempt.`,
          `Error: ${error.message}`,
        );

        // Wait before retrying
        await sleep(delay);
        retryCount++;
      }
    }
  },
};

export const unstructuredService = {
  // TODO: swap with the profile ids
  ingestUnstructuredData: async (
    deasyApiKey,
    llmEndpointConfiguration,
    dataSourceDBConfiguration,
    jobId,
    fileNames = null,
    file_count_to_run = null,
    use_llm = false,
  ) =>
    api
      .post(
        "/ocr/ingest",
        {
          endpoint_manager_config: llmEndpointConfiguration,
          data_source_manager_config: dataSourceDBConfiguration,
          file_names: fileNames,
          job_id: jobId,
          file_count_to_run: file_count_to_run,
          use_llm: use_llm,
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => response),
  // TODO: swap with the profile ids
  getSyncStats: async (
    deasyApiKey,
    dataSourceDBConfiguration,
    useCache = true,
  ) =>
    api.post(
      "/ocr/sync_stats",
      {
        data_source_manager_configs: dataSourceDBConfiguration,
        use_cache: useCache,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
};

export const tagGroupService = {
  createTagGroup: async (deasyApiKey, groupName, groupDescription, tagIds) =>
    api.post(
      "/tags/groups/create",
      {
        group_name: groupName,
        group_description: groupDescription,
        tag_ids: tagIds,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  getTagGroups: async (deasyApiKey) =>
    api.get("/tags/groups/list", {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  updateTagGroup: async (
    deasyApiKey,
    groupId,
    groupName,
    groupDescription,
    tagIds,
  ) =>
    api.put(
      "/tags/groups/update",
      {
        group_id: groupId,
        group_name: groupName,
        group_description: groupDescription,
        tag_ids: tagIds,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  deleteTagGroup: async (deasyApiKey, groupId, deleteAssociatedTags = false) =>
    api.delete(
      `/tags/groups/delete?group_id=${groupId}&delete_associated_tags=${deleteAssociatedTags}`,
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
};

export const tagService = {
  createTag: async (tagData, deasyApiKey) =>
    api.post(
      "/tags/create",
      { tag_data: tagData },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  upsertTag: async (tagData, deasyApiKey) =>
    api.post(
      "/tags/upsert",
      { tag_data: tagData },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  getSavedTags: async (deasyApiKey) =>
    api.get("/tags/list", {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  getTagDeleteStats: async (deasyApiKey, tag_name) =>
    api.post(
      "/tags/delete_stats",
      { tag_name: tag_name },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  updateTag: async (tagData, deasyApiKey) =>
    api.put(
      "/tags/update",
      { tag_data: tagData },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  deleteTag: async (tagName, deasyApiKey) =>
    api.delete(`/tags/delete?tag_name=${tagName}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
};

export const dataSliceService = {
  createDataSlice: async (dataSliceData, deasyApiKey) => {
    return api
      .post(
        "/dataslice/create",
        {
          dataslice_name: dataSliceData.dataslice_name,
          condition: dataSliceData.conditions,
          condition_new: dataSliceData.conditionsNew,
          description: dataSliceData.description,
          status: dataSliceData.status,
          data_points: dataSliceData.dataPoints,
          latest_graph: dataSliceData.latestGraph,
          graph_id: dataSliceData.graphId,
          vdb_profile_name: dataSliceData.vdbProfileName,
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        return response;
      });
  },
  deleteDataslice: async (dataSliceId, deasyApiKey) =>
    api.delete(`/dataslice/delete?dataslice_id=${dataSliceId}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  // TODO: swap with the profile id
  getDatasliceFileCount: async (
    vectorDBConfiguration,
    condition = null,
    dataSliceId = null,
    deasyApiKey,
  ) =>
    api.post(
      "/dataslice/file_count",
      {
        vector_db_config: vectorDBConfiguration,
        condition: condition,
        dataslice_id: dataSliceId,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  getDatasliceMetrics: async (
    fileNames = null,
    nodeIds = null,
    tags = null,
    dataSliceId = null,
    vectorDBConfiguration = null,
    deasyApiKey,
  ) =>
    // TODO: swap with the profile id
    api.post(
      "/dataslice/metrics",
      {
        file_names: fileNames,
        node_ids: nodeIds,
        tags: tags,
        dataslice_id: dataSliceId,
        vector_db_config: vectorDBConfiguration,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  getSyncScore: async (dataslice_id, vector_db_config, deasyApiKey) =>
    api.post(
      "/dataslice/sync_score",
      {
        dataslice_id: dataslice_id,
        vector_db_config: vector_db_config,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  getTagDistribution: async (dataslice_id, vector_db_config, deasyApiKey) =>
    api.post(
      "/dataslice/tag_vdb_distribution",
      {
        dataslice_id: dataslice_id,
        vector_db_config: vector_db_config,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  exportMetadata: async (requestBody, deasyApiKey) =>
    api
      .post("/dataslice/export/metadata", requestBody, {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      })
      .then((response) => {
        return response;
      }),
  deleteDestinationMetadata: async (
    destinationName,
    tagsToDelete,
    deasyApiKey,
  ) =>
    api.post(
      "/dataslice/destination/delete",
      {
        destination_name: destinationName,
        delete_tags: tagsToDelete,
      },
      {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      },
    ),
  exportMetadataToDestination: async (
    dataslice_id,
    ori_vector_db_name,
    destination_name,
    export_tags,
    exportLevel,
    exportNodes,
    exportMetadata,
    deasyApiKey,
  ) =>
    api.post(
      "/dataslice/export/metadata/destination",
      {
        dataslice_id: dataslice_id,
        ori_vector_db_name: ori_vector_db_name,
        destination_name: destination_name,
        export_tags: export_tags,
        export_level: exportLevel,
        export_nodes: exportNodes,
        export_metadata: exportMetadata,
      },
      {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      },
    ),
  exportToVectorDB: async (requestBody, deasyApiKey) =>
    api.post(
      "/dataslice/export/vdb",
      {
        ...requestBody,
      },
      {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      },
    ),
  // TODO: swap with the profile id
  listDataslices: async (deasyApiKey) => {
    const response = await api.get(`/dataslice/list`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    });

    const transformedDataslices = response.data.dataslices.map((dataslice) => {
      const selectedParentNodes =
        dataslice.condition?.map((condition) => {
          const nodePath = Object.entries(condition).reduce(
            (path, [key, values]) => {
              return [...path, key, values[0]];
            },
            [],
          );

          return {
            data: { nodePath: nodePath },
            stats: {},
          };
        }) || [];

      return {
        id: dataslice.dataslice_id,
        name: dataslice.dataslice_name,
        description: dataslice.description,
        conditions_new: dataslice.condition_new,
        status: dataslice.status || "active",
        dataPoints: dataslice.data_points,
        graphId: dataslice.graph_id,
        latestGraph: dataslice.latest_graph,
        lastUpdated: dataslice.last_updated,
        selectedParentNodes,
        vdb_collection_name: dataslice.vdb_collection_name,
        vector_db_config: dataslice.vector_db_config,
      };
    });

    return transformedDataslices;
  },
};

export const miscService = {
  getFilePageText: async (
    vdbProfileName,
    filename,
    pageNumber,
    chunkId,
    deasyApiKey,
  ) =>
    // TODO: swap with the profile id
    api.post(
      "/get_ocr_page_text",
      {
        vdb_profile_name: vdbProfileName,
        file_name: filename,
        page_number: pageNumber,
        chunk_id: chunkId,
      },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
};

export const sourceMetadataService = {
  // TODO: swap with the profile id
  listMetadata: async (
    vectorDBConfiguration,
    deasyApiKey,
    groupBy = "file",
    metadataKeys = null,
    fileNames = null,
    pointIds = null,
    limit = null,
  ) => {
    const response = await api.post(
      "/data/metadata/list",
      {
        vector_db_config: vectorDBConfiguration,
        group_by: groupBy,
        metadata_keys: metadataKeys,
        file_names: fileNames,
        point_ids: pointIds,
        limit: limit,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    );
    return response.data;
  },
  // TODO: swap with the profile id
  listPaginated: async (
    deasyApiKey,
    vectorDBConfiguration,
    limit = 50,
    offset = 0,
    groupBy = "file",
    entitiesAlreadyScrolled = [],
    search_query = null,
  ) =>
    api
      .post(
        "/data/list_paginated",
        {
          vector_db_config: vectorDBConfiguration,
          limit,
          offset,
          group_by: groupBy,
          entities_already_scrolled: entitiesAlreadyScrolled,
          search_query: search_query,
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => ({
        entities: response.data.entities,
        next_offset: response.data.next_offset,
        total: response.data.total,
        error_str: response.data.error_str,
      })),
  // TODO: swap with the profile id
  getDocumentText: async (vectorDBConfiguration, fileNames, deasyApiKey) =>
    api.post(
      "/data/document_text",
      {
        file_names: fileNames,
        vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  // TODO: swap with the profile id
  getVdbTotalFiles: async (vectorDBConfiguration, deasyApiKey) =>
    api.post(
      "/data/file_count",
      { vector_db_config: vectorDBConfiguration },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
};

export const suggestionService = {
  suggestTags: async (
    vdbProfileName,
    llmProfileName,
    currentTree,
    node,
    userContext,
    scope,
    file_names,
    maxDepth,
    deasyApiKey,
    tagOptions = {},
    condition = null,
  ) =>
    api.post(
      "/suggest_schema",
      {
        vdb_profile_name: vdbProfileName,
        llm_profile_name: llmProfileName,
        current_tree: currentTree,
        node: node,
        user_context: userContext,
        context_level: scope,
        file_names: file_names,
        max_height: maxDepth,
        use_existing_tags: tagOptions.use_existing_tags ?? false,
        use_extracted_tags: tagOptions.use_extracted_tags ?? false,
        condition: condition,
        tag_type: tagOptions.output_type ?? "any",
        max_tags_per_level: tagOptions.max_number_of_tags ?? 10,
      },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  suggestDescription: async (
    vdbProfileName,
    endpointManagerConfig,
    tagName,
    context,
    deasyApiKey,
    currentDescription,
    availableValues,
  ) =>
    api.post(
      "/suggest_description",
      {
        vdb_profile_name: vdbProfileName,
        endpoint_manager_config: endpointManagerConfig,
        tag_name: tagName,
        context: context,
        current_description: currentDescription,
        available_values: availableValues,
      },
      {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      },
    ),
};

export const classificationService = {
  classify: async (
    vdbProfileName,
    llmProfileName,
    fileNames,
    tags,
    deasyApiKey,
    soft_run = false,
    overwrite = true,
    currentTree = {},
    jobId = null,
    dataSliceId = null,
    blockingCall = false,
  ) => {
    const requestBody = {
      vdb_profile_name: vdbProfileName,
      llm_profile_name: llmProfileName,
      file_names: fileNames,
      tag_datas: tags,
      soft_run: soft_run,
      job_id: jobId,
      overwrite: overwrite,
      hierarchy_data: currentTree,
      dataslice_id: dataSliceId,
    };

    const headers = {
      headers: {
        "x-token": deasyApiKey,
        "x-user": auth.currentUser.email,
      },
    };

    // The only difference was the 'await', so we can simplify this
    const request = api.post("/classify", requestBody, headers);

    if (blockingCall) {
      return await request;
    }
    return request;
  },
  classifyAll: async (
    vdbProfileName,
    llmProfileName,
    tags,
    deasyApiKey,
    jobId = null,
    dataSliceId = null,
    totalDataSets = null,
    hierarchy = null,
    conditions = null,
    overwrite = false,
  ) => {
    const requestBody = {
      vdb_profile_name: vdbProfileName,
      llm_profile_name: llmProfileName,
      tag_datas: tags,
      job_id: jobId,
      dataslice_id: dataSliceId,
      hierarchy_data: hierarchy,
      conditions: conditions,
      overwrite: overwrite,
      total_data_sets: totalDataSets,
    };

    // Only add total_data_sets if it's not null
    if (totalDataSets !== null) {
      requestBody.total_data_sets = totalDataSets;
    }

    api.post("/classify_bulk", requestBody, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    });
    return jobId;
  },
  retryFailedFiles: async (
    trackerId,
    deasyApiKey,
    llmProfileName = null,
    vdbProfileName = null,
  ) => {
    return api.post(
      "/retry_failed_files",
      {
        tracker_id: trackerId,
        llm_profile_name: llmProfileName,
        vdb_profile_name: vdbProfileName,
      },
      {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      },
    );
  },
};
