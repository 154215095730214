import React, { createContext, useState, useEffect } from "react";
import { tokenService } from "../services/api";

export const TokenContext = createContext();
export const TokenProvider = ({ children }) => {
  const [tokens, setTokens] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isProfileComplete, setIsProfileComplete] = useState(false);

  const [profileFormData, setProfileFormData] = useState({
    first_name: "",
    last_name: "",
    company: "",
    phone_number: "",
  });

  const [profileFormInitialized, setProfileFormInitialized] = useState(false);

  useEffect(() => {
    const loadSubscriptionData = async () => {
      try {
        const response = await tokenService.getSubscriptionInfo();
        setSubscriptionData(response.data);

        if (
          response.data &&
          response.data.first_name &&
          response.data.last_name &&
          response.data.company &&
          response.data.phone_number
        ) {
          setIsProfileComplete(true);

          if (!profileFormInitialized) {
            setProfileFormData({
              first_name: response.data.first_name || "",
              last_name: response.data.last_name || "",
              company: response.data.company || "",
              phone_number: response.data.phone_number || "",
            });
            setProfileFormInitialized(true);
          }
        }
      } catch (error) {
        console.error("Error loading subscription data:", error);
      }
    };

    loadSubscriptionData();
  }, [profileFormInitialized]);

  // Handle profile form updates
  const updateProfileForm = (field, value) => {
    setProfileFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle form submission from anywhere
  const submitProfileForm = async () => {
    try {
      const response = await tokenService.updateUserProfile(profileFormData);
      if (response && response.data && response.data.success === true) {
        setIsProfileComplete(true);
        return { success: true };
      } else {
        return {
          success: false,
          error: response?.data?.message || "Failed to update profile",
        };
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      return {
        success: false,
        error: "Failed to update profile. Please try again.",
      };
    }
  };

  return (
    <TokenContext.Provider
      value={{
        tokens,
        subscriptionData,
        isProfileComplete,
        profileFormData,
        setTokens,
        setSubscriptionData,
        setIsProfileComplete,
        updateProfileForm,
        submitProfileForm,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};
