import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import {
  metadataService,
  graphService,
  tagService,
} from "../../../../../../services/api";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { GraphContext } from "../../../../../../contexts/GraphContext";

import {
  prepareEndpointManagerConfig,
  prepareVectorDBConfiguration,
} from "../../../../../../services/utils";
import { TaxonomySelector } from "./components/TaxonomySelector";
import {
  ContextModalComponent,
  TaxonomyNameModal,
} from "./StandardizationUtils";
import * as Sentry from "@sentry/react";
import { ExpandValueListModal } from "./components/ExpandValueListModal";
import { Loader } from "./components/loader";
import { ExistingValuesSection } from "./components/ExistingValuesSection";
import { StandardizedValuesSection } from "./components/StandardizedValuesSection";

export const StandardizeValuesPanel = ({
  isOpen,
  onClose,
  onValueSelect,
  buttonRef,
  className = "",
  tagsDetails,
}) => {
  const dropdownRef = useRef(null);
  const hasLoadedRef = useRef(false);
  const { setSavedTags, deasyUserConfig } = useContext(BaseContext);
  const { selectedDiscoveryGraph, setSelectedDiscoveryGraph } =
    useContext(GraphContext);
  const {
    llmConfig: { Configs: llmConfigs, LastActive: llmLastActive },
    vdbmConfig: { Configs: vdbmConfigs, LastActive: vdbmLastActive },
    deasyApiKey,
  } = deasyUserConfig;
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [showContextModal, setShowContextModal] = useState(false);
  const [showTaxonomyLibrary, setShowTaxonomyLibrary] = useState(false);
  const llmEndpointConfiguration = llmConfigs[llmLastActive];
  const vectorDBConfiguration = vdbmConfigs[vdbmLastActive];
  const [valueMapping, setValueMapping] = useState({});
  const [standardizedValues, setStandardizedValues] = useState([
    "Category 1",
    "Category 2",
    "Category 3",
  ]);
  const [existingValues, setExistingValues] = useState([]);
  const [showTaxonomyNameModal, setShowTaxonomyNameModal] = useState(false);
  const [taxonomyName, setTaxonomyName] = useState("");
  const [draggedValue, setDraggedValue] = useState(null);
  const [dragOverCategory, setDragOverCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredValues, setFilteredValues] = useState([]);
  const [valueDistribution, setValueDistribution] = useState(null);
  const [uniqueTagCount, setUniqueTagCount] = useState(0);
  const [expandedCategory, setExpandedCategory] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        (!buttonRef?.current || !buttonRef.current.contains(event.target))
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose, buttonRef]);

  const processValueDistribution = useCallback(
    (distribution) => {
      if (distribution) {
        const values = Object.entries(distribution || {}).map(
          ([value, stats]) => ({
            value,
            count: stats.count,
            percentage: stats.percentage,
          }),
        );

        // Sort by percentage (descending)
        values.sort((a, b) => b.percentage - a.percentage);

        setExistingValues(values);

        // Also initialize filteredValues
        const valuesList = values.map((item) => item.value);
        setFilteredValues(valuesList);

        // Initialize standardized values only if they haven't been initialized yet
        if (standardizedValues.length === 0) {
          setStandardizedValues(["Category 1", "Category 2", "Category 3"]);
        }
      } else {
        // Handle case when valueDistribution is empty or undefined
        setExistingValues([]);
        setFilteredValues([]);
        if (standardizedValues.length === 0) {
          setStandardizedValues(["Category 1", "Category 2", "Category 3"]);
        }
      }
    },
    [standardizedValues],
  );

  const fetchTagDistribution = useCallback(async () => {
    try {
      setIsInitialLoading(true);
      const response = await metadataService.getStandardizeTagDistribution(
        tagsDetails.name,
        prepareVectorDBConfiguration(vectorDBConfiguration),
        deasyApiKey,
      );

      // Extract tag_distribution from the response
      const tagDistribution = response.data.tag_distribution || {};
      // Store unique tag count
      setUniqueTagCount(response.data.unique_tag_count || 0);

      setValueDistribution(tagDistribution);
      processValueDistribution(tagDistribution);
    } catch (error) {
      console.error("Error fetching tag distribution:", error);
    } finally {
      setIsInitialLoading(false);
      hasLoadedRef.current = true;
    }
  }, [
    tagsDetails?.name,
    vectorDBConfiguration,
    deasyApiKey,
    processValueDistribution,
  ]);

  useEffect(() => {
    if (isOpen && tagsDetails && !hasLoadedRef.current) {
      fetchTagDistribution();
    }
  }, [isOpen, tagsDetails, fetchTagDistribution]);

  useEffect(() => {
    if (!isOpen) {
      hasLoadedRef.current = false;
    }
  }, [isOpen]);

  // Filter values based on search query
  useEffect(() => {
    if (!existingValues || !existingValues.length) return;

    const values = existingValues.map((item) =>
      typeof item === "string" ? item : item.value,
    );

    if (!searchQuery) {
      setFilteredValues(values);
      return;
    }

    const filtered = values.filter((value) =>
      value.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    setFilteredValues(filtered);
  }, [existingValues, searchQuery]);

  const findTagKeyAndUpdate = (obj, tagName, newValues) => {
    if (Array.isArray(obj)) {
      return obj.map((item) => findTagKeyAndUpdate(item, tagName, newValues));
    }
    if (typeof obj === "object" && obj !== null) {
      const newObj = { ...obj };

      for (const [key, value] of Object.entries(newObj)) {
        if (key === tagName && typeof value === "object") {
          newObj[key] = {
            TagAvailableValues: Array.isArray(value.TagAvailableValues)
              ? newValues
              : { ...value.TagAvailableValues },
            ...Object.fromEntries(newValues.map((val) => [val, {}])),
          };
        } else {
          newObj[key] = findTagKeyAndUpdate(value, tagName, newValues);
        }
      }
      return newObj;
    }
    return obj;
  };

  const handleConfirmSelection = async (providedTaxonomyName = null) => {
    try {
      setIsLoading(true);
      const finalTaxonomyName =
        providedTaxonomyName ||
        taxonomyName ||
        `${tagsDetails.name} Categories`;

      await metadataService.standardizeMetadataDB(
        tagsDetails.name,
        valueMapping,
        deasyApiKey,
        prepareEndpointManagerConfig(llmEndpointConfiguration),
        prepareVectorDBConfiguration(vectorDBConfiguration),
      );

      await metadataService.insertStandardization({
        tag_id: tagsDetails.tag_id,
        tag_name: tagsDetails.name,
        standard_mapping: valueMapping,
        taxonomy_name: finalTaxonomyName,
        vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
        deasyApiKey,
      });

      const updatedTagData = {
        ...tagsDetails,
        available_values: Object.keys(valueMapping),
      };
      await tagService.updateTag(updatedTagData, deasyApiKey);

      setSavedTags((prevTags) =>
        prevTags.map((tag) =>
          tag.tag_id === tagsDetails.tag_id
            ? {
                ...tag,
                available_values: Object.keys(valueMapping),
              }
            : tag,
        ),
      );

      if (selectedDiscoveryGraph && tagsDetails) {
        const newValues = Array.from(Object.keys(valueMapping));
        const updatedGraphData = findTagKeyAndUpdate(
          selectedDiscoveryGraph.graph_data,
          tagsDetails.name,
          newValues,
        );

        await graphService.createUpdateGraph(
          deasyApiKey,
          selectedDiscoveryGraph.graph_name,
          selectedDiscoveryGraph.graph_description,
          updatedGraphData,
        );

        setSelectedDiscoveryGraph({
          ...selectedDiscoveryGraph,
          graph_data: updatedGraphData,
        });
      }

      onValueSelect(Object.keys(valueMapping));
      onClose();
    } catch (error) {
      console.error("Error updating values:", error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTaxonomyNameConfirm = (name) => {
    setTaxonomyName(name);
    setShowTaxonomyNameModal(false);
    handleConfirmSelection(name);
  };

  const handleAutoSuggest = async (context = null) => {
    try {
      setIsLoading(true);

      const formattedDistribution = {};
      Object.entries(valueDistribution).forEach(([tagName, stats]) => {
        formattedDistribution[tagName] = stats.percentage / 100;
      });

      let existingCategoriesPayload = undefined;

      const defaultCategories = ["Category 1", "Category 2", "Category 3"];
      const existingCategories = {};

      standardizedValues.forEach((category) => {
        if (!category.trim()) return;
        if (
          !defaultCategories.includes(category) ||
          (valueMapping[category] && valueMapping[category].length > 0)
        ) {
          existingCategories[category] = valueMapping[category] || [];
        }
      });

      if (Object.keys(existingCategories).length > 0) {
        existingCategoriesPayload = existingCategories;
      }

      const contextPayload = uniqueTagCount <= 1000 ? context : null;

      const response = await metadataService.suggestStandardizedValues(
        tagsDetails.name,
        tagsDetails.description,
        tagsDetails.output_type,
        formattedDistribution,
        deasyApiKey,
        prepareEndpointManagerConfig(llmEndpointConfiguration),
        prepareVectorDBConfiguration(vectorDBConfiguration),
        existingCategoriesPayload,
        contextPayload,
      );

      const standardCategories = Object.keys(response.data.standard_mapping);
      setStandardizedValues(standardCategories);

      const mapping = response.data.standard_mapping;

      const categorizedValues = new Set(Object.values(mapping).flat());
      const uncategorizedValues = filteredValues.filter(
        (value) => !categorizedValues.has(value),
      );

      let finalMapping = { ...mapping };
      let finalStandardValues = [...standardCategories];

      const normalizeValue = (value) => {
        return value.toLowerCase().replace(/_/g, " ").trim();
      };

      // Group similar uncategorized values
      const valueGroups = {};
      uncategorizedValues.forEach((value) => {
        const normalizedValue = normalizeValue(value);
        if (!valueGroups[normalizedValue]) {
          valueGroups[normalizedValue] = [];
        }
        valueGroups[normalizedValue].push(value);
      });

      Object.entries(valueGroups).forEach(([normalizedValue, values]) => {
        let categoryName =
          normalizedValue.charAt(0).toUpperCase() + normalizedValue.slice(1);

        let existingCategory = Object.keys(finalMapping).find(
          (category) => normalizeValue(category) === normalizedValue,
        );

        if (existingCategory) {
          finalMapping[existingCategory] = [
            ...finalMapping[existingCategory],
            ...values,
          ];
        } else {
          finalMapping[categoryName] = values;
          finalStandardValues.push(categoryName);
        }
      });

      setValueMapping(finalMapping);
      setStandardizedValues(finalStandardValues);

      const allMappedValuesArray = Object.values(finalMapping).flat();
      const allCategorizedValues = new Set(allMappedValuesArray);

      if (allMappedValuesArray.length !== allCategorizedValues.size) {
        console.log("Warning: Possible duplicate values in mapping");
      }

      const newExistingValues = existingValues.filter((item) => {
        const value = typeof item === "string" ? item : item.value;
        const isIncluded = !allCategorizedValues.has(value);
        return isIncluded;
      });

      setExistingValues(newExistingValues);
      setFilteredValues(
        newExistingValues.map((item) =>
          typeof item === "string" ? item : item.value,
        ),
      );
    } catch (error) {
      console.error("Error getting suggested values:", error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCategory = (indexToDelete) => {
    setStandardizedValues((prev) =>
      prev.filter((_, index) => index !== indexToDelete),
    );

    setValueMapping((prev) => {
      const categoryToDelete = prev[standardizedValues[indexToDelete]];
      const newMapping = { ...prev };
      delete newMapping[standardizedValues[indexToDelete]];

      if (categoryToDelete?.length) {
        setExistingValues((existing) => [
          ...existing,
          ...categoryToDelete.map((value) => ({
            value,
            percentage: valueDistribution[value]?.percentage || 0,
          })),
        ]);
      }

      return newMapping;
    });
  };

  const handleAddCategory = () => {
    setStandardizedValues((prev) => [...prev, ""]);
  };

  const handleOneToOneForRemainingValues = () => {
    const categorizedValues = new Set(Object.values(valueMapping).flat());
    const uncategorizedValues = filteredValues.filter(
      (value) => !categorizedValues.has(value),
    );

    if (uncategorizedValues.length === 0) return; // Nothing to do

    const newMapping = { ...valueMapping };
    const newStandardValues = [...standardizedValues];

    uncategorizedValues.forEach((value) => {
      newMapping[value] = [value];
      newStandardValues.push(value);
    });

    setValueMapping(newMapping);
    setStandardizedValues(newStandardValues);

    setExistingValues((prev) =>
      prev.filter(
        (item) =>
          !uncategorizedValues.includes(
            typeof item === "string" ? item : item.value,
          ),
      ),
    );
    setFilteredValues((prev) =>
      prev.filter((value) => !uncategorizedValues.includes(value)),
    );
  };

  const handleRemoveValueFromCategory = (category, value) => {
    setValueMapping((prev) => {
      const newMapping = { ...prev };
      if (newMapping[category]) {
        newMapping[category] = newMapping[category].filter((v) => v !== value);
        // Remove the category if it's empty
        if (newMapping[category].length === 0) {
          delete newMapping[category];
        }
      }
      return newMapping;
    });

    const distribution = valueDistribution[value]?.percentage || 0;
    if (distribution > 0) {
      const newValue = {
        value,
        percentage: distribution,
      };
      setExistingValues((prev) => [...prev, newValue]);
      setFilteredValues((prev) => [...prev, value]);
    }
  };

  const handleSkipContext = () => {
    setShowContextModal(false);
    handleAutoSuggest(null);
  };

  const handleApplyContext = (contextValue) => {
    setShowContextModal(false);
    handleAutoSuggest(contextValue);
  };

  const handleTaxonomySelected = (taxonomy) => {
    setShowTaxonomyLibrary(false);

    if (taxonomy && taxonomy.standard_mapping) {
      const standardCategories = Object.keys(taxonomy.standard_mapping);
      setStandardizedValues(standardCategories);

      const newMapping = {};
      const existingValueSet = new Set(
        existingValues.map((item) =>
          typeof item === "string" ? item : item.value,
        ),
      );

      Object.entries(taxonomy.standard_mapping).forEach(
        ([category, values]) => {
          // Filter values to only include those that exist in our current dataset
          const validValues = values.filter((value) =>
            existingValueSet.has(value),
          );

          if (validValues.length > 0) {
            newMapping[category] = validValues;
          }
        },
      );

      setValueMapping(newMapping);

      if (taxonomy.taxonomy_name) {
        setTaxonomyName(taxonomy.taxonomy_name);
      }

      const categorizedValues = new Set(Object.values(newMapping).flat());
      setExistingValues((prev) =>
        prev.filter((item) => !categorizedValues.has(item.value)),
      );
      setFilteredValues((prev) =>
        prev.filter((value) => !categorizedValues.has(value)),
      );
    }
  };

  const handleClearAllCategories = () => {
    // Move all mapped values back to existing values, but only if they have a distribution > 0%
    const allMappedValues = Object.values(valueMapping).flat();
    const newExistingValues = [
      ...existingValues,
      ...allMappedValues
        .filter((value) => (valueDistribution[value]?.percentage || 0) > 0)
        .map((value) => ({
          value,
          percentage: valueDistribution[value]?.percentage || 0,
        })),
    ];

    setExistingValues(newExistingValues);
    setFilteredValues(newExistingValues.map((item) => item.value));

    // Clear the mapping and reset standardized values
    setValueMapping({});
    setStandardizedValues(["Category 1", "Category 2", "Category 3"]);
  };

  const handleDragStart = (e, value) => {
    e.stopPropagation();
    e.dataTransfer.setData("text/plain", value);
    setDraggedValue(value);
  };

  const handleDragOver = (e, category) => {
    e.preventDefault();
    e.stopPropagation();
    if (draggedValue && !valueMapping[category]?.includes(draggedValue)) {
      setDragOverCategory(category);
    }
  };

  const handleDrop = (e, category) => {
    e.preventDefault();
    e.stopPropagation();
    const value = e.dataTransfer.getData("text/plain");

    if (value && !valueMapping[category]?.includes(value)) {
      handleAddValueToCategory(category, value);
    }

    setDraggedValue(null);
    setDragOverCategory(null);
  };

  const handleAddValueToCategory = (category, value) => {
    // Check if the value is already in any category
    const isValueMapped = Object.values(valueMapping).some((values) =>
      values.includes(value),
    );

    if (isValueMapped) {
      // Remove from existing category
      Object.keys(valueMapping).forEach((cat) => {
        if (valueMapping[cat].includes(value)) {
          handleRemoveValueFromCategory(cat, value);
        }
      });
    }

    // Add to new category
    setValueMapping((prev) => {
      const newMapping = { ...prev };
      if (!newMapping[category]) {
        newMapping[category] = [];
      }
      if (!newMapping[category].includes(value)) {
        newMapping[category] = [...newMapping[category], value];
      }
      return newMapping;
    });

    // Update existingValues if needed
    setExistingValues((prev) => {
      const newValues = prev.filter((v) =>
        typeof v === "string" ? v !== value : v.value !== value,
      );
      return newValues;
    });

    setFilteredValues((prev) => prev.filter((v) => v !== value));
  };

  const handleRemoveValueFromExpandedList = (value) => {
    if (expandedCategory) {
      handleRemoveValueFromCategory(expandedCategory, value);
    }
  };

  const performSimpleStandardization = () => {
    // Create a new mapping object where each value maps to itself
    const newMapping = {};
    const newStandardizedValues = [];

    filteredValues.forEach((value) => {
      // Use the value itself as the category name
      newMapping[value] = [value];
      newStandardizedValues.push(value);
    });

    // Update state
    setValueMapping(newMapping);
    setStandardizedValues(newStandardizedValues);

    // Clear existing values since they're all now mapped
    setExistingValues([]);
    setFilteredValues([]);
  };

  const renderInitialView = () => (
    <div className="p-4">
      <div className="flex gap-6 relative">
        {!showTaxonomyLibrary && (
          <ExistingValuesSection
            uniqueTagCount={uniqueTagCount}
            filteredValues={filteredValues}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleDragStart={handleDragStart}
            handleRemoveValueFromCategory={handleRemoveValueFromCategory}
            performSimpleStandardization={performSimpleStandardization}
          />
        )}

        {showTaxonomyLibrary ? (
          <div className="flex-1">
            <TaxonomySelector
              onSelect={handleTaxonomySelected}
              onCancel={() => setShowTaxonomyLibrary(false)}
              tagId={tagsDetails?.tag_id}
              deasyApiKey={deasyApiKey}
            />
          </div>
        ) : (
          <StandardizedValuesSection
            standardizedValues={standardizedValues}
            setStandardizedValues={setStandardizedValues}
            valueMapping={valueMapping}
            setValueMapping={setValueMapping}
            dragOverCategory={dragOverCategory}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            handleDeleteCategory={handleDeleteCategory}
            handleRemoveValueFromCategory={handleRemoveValueFromCategory}
            setExpandedCategory={setExpandedCategory}
            handleAddCategory={handleAddCategory}
            handleClearAllCategories={handleClearAllCategories}
            setShowTaxonomyLibrary={setShowTaxonomyLibrary}
            setShowContextModal={setShowContextModal}
            isLoading={isLoading}
            filteredValues={filteredValues}
            uniqueTagCount={uniqueTagCount}
            handleOneToOneForRemainingValues={handleOneToOneForRemainingValues}
          />
        )}
      </div>
    </div>
  );

  if (!isOpen) return null;

  if (isInitialLoading) {
    return (
      <Loader
        onClose={onClose}
        dropdownRef={dropdownRef}
        className={className}
        message="Loading tag values..."
        subMessage="Analyzing and preparing values for standardization"
      />
    );
  }

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-50"
        onClick={onClose}
      />
      <div
        ref={dropdownRef}
        className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-2xl border border-gray-200 overflow-hidden w-[90vw] max-w-[1400px] max-h-[90vh] z-50 ${className}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {/* Use the isolated modal component */}
        <ContextModalComponent
          isOpen={showContextModal}
          onClose={() => setShowContextModal(false)}
          onSkip={handleSkipContext}
          onApply={handleApplyContext}
          uniqueTagCount={uniqueTagCount}
        />

        {/* Panel Header */}
        <div className="sticky top-0 z-10 bg-white flex items-center justify-between p-4 border-b border-gray-200">
          <div className="flex flex-col items-start mx-auto justify-start text-left w-full px-3 py-1">
            <h3 className="text-lg font-medium text-gray-900">
              Standardize Output Values for{" "}
              {
                <span className="text-xl font-semibold text-primary">
                  {tagsDetails?.name}
                </span>
              }
            </h3>
            <p className="text-sm text-gray-600">
              Drag existing values into standardized categories or use AI
              suggestions to automate categorization.
            </p>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 p-1 rounded-md hover:bg-gray-100"
          >
            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {/* Main Content */}
        <div
          className="overflow-y-auto"
          style={{ maxHeight: "calc(90vh - 140px)" }}
        >
          <div className="p-4">{renderInitialView()}</div>
        </div>

        {/* Footer */}
        <div className="sticky bottom-0 z-10 bg-gray-50 border-t border-gray-200 p-4 rounded-b-lg flex justify-between items-center">
          {!showTaxonomyLibrary && (
            <>
              {/* Warning message on the left */}
              <div className="flex items-center gap-2 text-amber-600">
                <svg
                  className="w-5 h-5 flex-shrink-0"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
                <span className="text-sm">
                  Once confirmed, all existing values will be automatically
                  standardized to their new standardized value.
                </span>
              </div>

              {/* Action buttons on the right */}
              <div className="flex gap-2">
                <button
                  onClick={onClose}
                  className="px-3 py-1.5 text-sm font-medium text-gray-600 hover:text-gray-800 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors duration-200"
                  disabled={isLoading}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    if (Object.keys(valueMapping).length) {
                      setShowTaxonomyNameModal(true);
                    }
                  }}
                  disabled={!Object.keys(valueMapping).length || isLoading}
                  className={`px-3 py-1.5 text-sm font-medium text-white rounded-md transition-colors duration-200 flex items-center gap-2 ${
                    !Object.keys(valueMapping).length || isLoading
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-primary hover:bg-primary-dark"
                  }`}
                >
                  {isLoading ? (
                    <>
                      <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                          fill="none"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Processing...
                    </>
                  ) : (
                    "Confirm Changes"
                  )}
                </button>
              </div>
            </>
          )}
        </div>

        {/* Taxonomy Name Modal */}
        <TaxonomyNameModal
          isOpen={showTaxonomyNameModal}
          onClose={() => setShowTaxonomyNameModal(false)}
          onConfirm={handleTaxonomyNameConfirm}
          initialName={taxonomyName || `${tagsDetails?.name || ""} Categories`}
        />

        <ExpandValueListModal
          isOpen={!!expandedCategory}
          onClose={() => setExpandedCategory(null)}
          categoryName={expandedCategory || ""}
          values={valueMapping[expandedCategory] || []}
          onRemoveValue={handleRemoveValueFromExpandedList}
        />
      </div>
    </>
  );
};
