export const POSTGRESQL_TYPE = "PSQLVectorDBManager";
export const S3_TYPE = "S3DataSourceManager";
export const QDRANT_TYPE = "QdrantVectorDBManager";
export const SHAREPOINT_TYPE = "SharepointDataSourceManager";

export const SHAREPOINT_DEST = "SharepointNodeDestinationManager";

// Add PostgreSQL constant for destinations
export const POSTGRESQL_DEST = "PostgresNodeDestinationManager";

export const DESTINATION_OPTIONS = [
  {
    value: SHAREPOINT_DEST,
    label: "Sharepoint",
    fields: [
      "client_id",
      "client_secret",
      "tenant_id",
      "sharepoint_site_name",
      "documents_library_folder_name",
    ],
    schemaFields: [],
  },
  {
    value: POSTGRESQL_DEST,
    label: "PostgreSQL",
    fields: [
      "database_name",
      "collection_name",
      "url",
      "password",
      "db_user",
      "port",
      "create_new_collection",
    ],
    schemaFields: [
      "id_key",
      "filename_key",
      "tags_key",
      "text_key",
      "dimension_threshold",
    ],
  },
  // Add more destination types as needed
];

export const DB_OPTIONS = [
  {
    value: QDRANT_TYPE,
    label: "Qdrant",
    fields: ["api_key", "collection_name", "url"],
    schemaFields: ["filename_key", "text_key"],
  },
  {
    value: SHAREPOINT_TYPE,
    label: "Sharepoint",
    fields: ["client_id", "client_secret", "tenant_id", "sharepoint_site_name"],
    schemaFields: [],
  },
  {
    value: S3_TYPE,
    label: "S3",
    fields: ["bucket_name", "aws_access_key_id", "aws_secret_access_key"],
    schemaFields: [],
  },
  {
    value: POSTGRESQL_TYPE,
    label: "PostgreSQL",
    fields: [
      "database_name",
      "collection_name",
      "url",
      "password",
      "db_user",
      "port",
    ],
    schemaFields: ["filename_key", "text_key", "tags_key", "id_key"],
  },
];

export const getDisplayType = (type) => {
  switch (type) {
    // Vector DB types
    case S3_TYPE:
      return "S3";
    case POSTGRESQL_TYPE:
      return "PostgreSQL";
    case QDRANT_TYPE:
      return "Qdrant";
    case SHAREPOINT_TYPE:
      return "Sharepoint";

    // Destination types
    case SHAREPOINT_DEST:
      return "Sharepoint";
    case POSTGRESQL_DEST:
      return "PostgreSQL";

    default:
      return type?.replace("Manager", "") || "Unknown";
  }
};

export const isDeasyCompute = (config) => {
  return config?.llmType === "deasy_compute";
};

// Add this helper function to map VDB types
export const getVdbType = (type) => {
  switch (type?.toLowerCase()) {
    case "s3":
    case "s3datasourcemanager":
      return S3_TYPE;
    case "postgresql":
    case "psqlvectordbmanager":
      return POSTGRESQL_TYPE;
    case "pinecone":
      return "PineconeVectorDBManager";
    case "weaviate":
      return "WeaviateVectorDBManager";
    case "qdrant":
      return QDRANT_TYPE;
    case "sharepoint":
    case "sharepointdatasourcemanager":
      return SHAREPOINT_TYPE;
    case "postgresnodedestinationmanager":
      return POSTGRESQL_DEST;
    default:
      return type;
  }
};
