import React, { useContext } from "react";
import { FaVial } from "react-icons/fa";
import { BaseContext } from "../../contexts/BaseContext";
import { useNavigate } from "react-router-dom";
import { getPathFromMainItem } from "../Navigation/NavUtils";
import { MainItemType } from "../../models/WorkflowModel";

const TestTagButton = ({
  tagId,
  className = "",
  setIsTagSelectorExpanded = null,
  verticalAlignment = false,
}) => {
  const { setStartingTagInTS, savedTags, setActivePage, deasyUserConfig } =
    useContext(BaseContext);
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling

    // Find the tag in savedTags
    const tag = savedTags.find((t) => t.tag_id === tagId || t.name === tagId);
    if (tag) {
      // Set the tag in editor
      setStartingTagInTS(tag);
      // Switch to tagging studio screen
      setActivePage(MainItemType.METADATA_STUDIO);

      // Get the current data source name
      const dataSource =
        deasyUserConfig.vdbmConfig.Configs[
          deasyUserConfig.vdbmConfig.LastActive
        ];
      const dataSourceName = dataSource?.name || "none-selected";

      // Navigate to the tagging studio page
      const path = getPathFromMainItem(
        MainItemType.METADATA_STUDIO,
        dataSourceName,
      );
      navigate(path, {
        state: { page: MainItemType.METADATA_STUDIO },
      });

      if (setIsTagSelectorExpanded) {
        setIsTagSelectorExpanded(false);
      }
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`flex flex-col inline-flex items-center justify-start gap-1.5 px-2 py-1 text-sm font-medium
          text-gray-600 hover:text-gray-800 hover:bg-gray-100 bg-gray-100 bg-opacity-20 
          transition-colors rounded-md ${verticalAlignment ? "flex-col" : "flex-row"} ${className}`}
      title="Test this tag"
    >
      <FaVial className="w-4 h-4" />
      Test
    </button>
  );
};

export default TestTagButton;
