import React, { createContext, useContext, useState, useEffect } from "react";
import { BaseContext } from "./BaseContext";
import { tagService, tagGroupService } from "../services/api";

export const SmallDataContext = createContext();

export const useSmallDataContext = () => useContext(SmallDataContext);

export const SmallDataProvider = ({
  children,
  config = { enableTags: true, enableTagGroups: true },
}) => {
  const { deasyApiKey } = useContext(BaseContext);

  const [tags, setTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(config.enableTags);
  const [tagGroups, setTagGroups] = useState([]);
  const [loadingTagGroups, setLoadingTagGroups] = useState(
    config.enableTagGroups,
  );

  useEffect(() => {
    const loadTags = async () => {
      if (deasyApiKey && config.enableTags) {
        try {
          setLoadingTags(true);
          const response = await tagService.getSavedTags(deasyApiKey);
          // Ensure tuned property is always a number
          const processedTags = (response.data.tags || []).map((tag) => ({
            ...tag,
            tuned: typeof tag.tuned === "number" ? tag.tuned : 0,
          }));
          setTags(processedTags);
        } catch (error) {
          console.error("Error loading tags:", error);
          setTags([]);
        } finally {
          setLoadingTags(false);
        }
      } else if (!config.enableTags) {
        setLoadingTags(false);
        setTags([]);
      }
    };

    loadTags();
  }, [deasyApiKey, config.enableTags]);

  // Function to refresh tag groups
  const refreshTagGroups = async () => {
    if (deasyApiKey && config.enableTagGroups) {
      try {
        setLoadingTagGroups(true);
        const response = await tagGroupService.getTagGroups(deasyApiKey);
        setTagGroups(response.data.tag_groups);
      } catch (error) {
        console.error("Error refreshing tag groups:", error);
      } finally {
        setLoadingTagGroups(false);
      }
    }
  };

  useEffect(() => {
    // Load tag groups from API
    const loadTagGroups = async () => {
      if (deasyApiKey && config.enableTagGroups) {
        try {
          setLoadingTagGroups(true);
          const response = await tagGroupService.getTagGroups(deasyApiKey);
          setTagGroups(response.data.tag_groups);
        } catch (error) {
          console.error("Error loading tag groups:", error);
          setTagGroups([]);
        } finally {
          setLoadingTagGroups(false);
        }
      } else if (!config.enableTagGroups) {
        setLoadingTagGroups(false);
        setTagGroups([]);
      }
    };

    loadTagGroups();
  }, [deasyApiKey, config.enableTagGroups]);

  return (
    <SmallDataContext.Provider
      value={{
        // Getters
        tags,
        loadingTags,
        tagGroups,
        loadingTagGroups,
        // Setters
        setTags,
        setLoadingTags,
        setTagGroups,
        setLoadingTagGroups,
        // Config
        config,
        // Functions
        refreshTagGroups,
      }}
    >
      {children}
    </SmallDataContext.Provider>
  );
};
