import React, { useContext } from "react";
import DataSliceSummaryScreen from "./screens/DataSliceDashBoard";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { DataSliceSelect } from "./screens/DataSliceSelect";

const DataSliceManagementScreen = () => {
  const {
    selectedDataSlice,
    setSelectedDataSlice,
    dataSliceStep,
    setDataSliceStep,
  } = useContext(BaseContext);

  const handleDataSliceSelect = (dataSlice) => {
    setSelectedDataSlice(dataSlice);
    setDataSliceStep(3);
  };

  const renderTabContent = () => {
    switch (dataSliceStep) {
      case 0:
        return (
          <DataSliceSelect
            onCreateNew={() => setDataSliceStep(1)}
            onDataSliceSelect={handleDataSliceSelect}
          />
        );
      case 3:
        return (
          <DataSliceSummaryScreen
            setSelectedStep={setDataSliceStep}
            dataSlice={selectedDataSlice}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex-1 px-2 py-1 h-full overflow-y-auto">
      {renderTabContent()}
    </div>
  );
};

export default DataSliceManagementScreen;
