import React, { useState, useEffect, useContext } from "react";
import { TokenProvider } from "./contexts/TokenContext";
import { AuthProvider } from "./contexts/AuthContext";
import { Toaster } from "react-hot-toast";
import LoginLoadingScreen from "./components/utils/LoginLoadingScreen";
import { BaseContext } from "./contexts/BaseContext";
import {
  fetchUserSecrets,
  fetchVDBProfiles,
  fetchLlmProfiles,
} from "./services/utils";
import { AppLayout } from "./components/Layout/AppLayout";
import ErrorBoundary from "./components/Fallback/ErrorBoundary";
import * as Sentry from "@sentry/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  BrowserRouter as Router,
} from "react-router-dom";
function App() {
  const isDev = process.env.REACT_APP_ENVIRONMENT === "development";
  const isProd = process.env.REACT_APP_ENVIRONMENT === "production";
  if (isDev || isProd) {
    Sentry.init({
      dsn: "https://f35d1f1d49e8ce45d4ac9055046a9298@o4508877263405056.ingest.us.sentry.io/4508880460906496",
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        // Sentry.replayIntegration(),
      ],
      environment: process.env.REACT_APP_ENVIRONMENT,
      enabled: isDev || isProd,
      // Block local URLs from sending Sentry events
      denyUrls: [/localhost/, /127\.0\.0\.1/],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        /^https:\/\/dev-deasy-api-service-678999874120.us-east1.run.app/,
        /^https:\/\/metadata\.dev/,
        /^https:\/\/dev-deasy\.web\.app/,
        /^https:\/\/app\.deasylabs\.com/,
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState("Initializing...");
  const { setSavedTags, setDeasyUserConfig, setVdbProfiles, setLlmProfiles } =
    useContext(BaseContext);

  useEffect(() => {
    const preloadData = async () => {
      try {
        // Initialize
        setLoadingProgress(10);
        setLoadingMessage("Initializing application...");
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Auth check
        setLoadingProgress(30);
        setLoadingMessage("Checking authentication...");
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Load configuration
        setLoadingProgress(50);
        setLoadingMessage("Loading configuration...");
        const deasyUserConfig = await fetchUserSecrets(
          setSavedTags,
          setDeasyUserConfig,
        );
        if (deasyUserConfig.deasyApiKey) {
          await Promise.all([
            fetchVDBProfiles(setVdbProfiles, deasyUserConfig.deasyApiKey),
            fetchLlmProfiles(setLlmProfiles, deasyUserConfig.deasyApiKey),
          ]);
        }

        // Load files if we have configuration
        setLoadingProgress(70);
        setLoadingMessage("Loading available files...");

        // Finalize
        setLoadingProgress(90);
        setLoadingMessage("Finalizing...");
        await new Promise((resolve) => setTimeout(resolve, 500));

        setLoadingProgress(100);
        setLoadingMessage("Ready!");
        await new Promise((resolve) => setTimeout(resolve, 300));

        setIsLoading(false);
      } catch (error) {
        console.error("Error during preload:", error);
        setIsLoading(false);
      }
    };

    preloadData();
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <LoginLoadingScreen progress={loadingProgress} message={loadingMessage} />
    );
  }

  return (
    <>
      <Toaster />
      <Sentry.ErrorBoundary
        fallback={<ErrorBoundary />}
        showReportDialog={true}
      >
        <AuthProvider>
          <TokenProvider>
            <Router>
              <div className="App">
                <AppLayout />
              </div>
            </Router>
          </TokenProvider>
        </AuthProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}

export default App;
