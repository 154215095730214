import React, { useState, useEffect, useCallback } from "react";
import { tokenService } from "../../../services/api";
import { auth } from "../../../config/firebase";
import TokenTable from "./components/TokenTable";
import TokenCreationModal from "./components/TokenCreationModal";
import { useContext } from "react";
import { TokenContext } from "../../../contexts/TokenContext";
import { BaseContext } from "../../../contexts/BaseContext";
import { FormInput } from "../../MainContent/DeasyConfig/ConfigUtils";
import { LoadingScreen } from "../../utils/LoadingScreen";
import { toast } from "react-hot-toast";
import SubscriptionModal from "./components/SubscriptionModal";
import CancellationModal from "./components/CancellationModal";

export const handleSaveDeasyApiKey = async (
  deasyUserConfig,
  setDeasyUserConfig,
  deasyApiKey,
  setSavingApiKey = null,
) => {
  if (setSavingApiKey) setSavingApiKey(true);
  try {
    if (!deasyUserConfig) {
      toast.info(
        "Error saving Deasy API key: no user config found, please try again",
      );
      return;
    }
    const newConfig = {
      ...deasyUserConfig,
      deasyApiKey: deasyApiKey.trim(),
    };
    setDeasyUserConfig(newConfig);
    await tokenService.storeSecret(
      "deasyUserConfig",
      JSON.stringify(newConfig),
    );
  } catch (error) {
    console.error("Error saving Deasy API Key:", error);
  } finally {
    if (setSavingApiKey) setSavingApiKey(false);
  }
};

function TokenDashboard() {
  const { tokens, setTokens, subscriptionData, setSubscriptionData } =
    useContext(TokenContext);
  const { deasyUserConfig, setDeasyUserConfig } = useContext(BaseContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [newToken, setNewToken] = useState(null);
  const [deletingToken, setDeletingToken] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [deasyApiKey, setDeasyApiKey] = useState(
    deasyUserConfig?.deasyApiKey || "",
  );
  const [savingApiKey, setSavingApiKey] = useState(false);
  const [tiers, setTiers] = useState(null);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [loadingTiers, setLoadingTiers] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);

  const loadTokens = useCallback(async () => {
    try {
      const [tokensResponse, subscriptionResponse] = await Promise.all([
        tokenService.listTokens(),
        tokenService.getSubscriptionInfo(),
      ]);
      setTokens(
        Array.isArray(tokensResponse.data.tokens)
          ? tokensResponse.data.tokens
          : [],
      );
      setSubscriptionData(subscriptionResponse.data);
    } catch (err) {
      setError("Failed to load data");
      setTokens([]);
    } finally {
      setLoading(false);
      setInitialLoadDone(true);
    }
  }, [setTokens, setSubscriptionData]);

  useEffect(() => {
    if (!initialLoadDone) {
      loadTokens();
    }
  }, [loadTokens, initialLoadDone]);

  const handleCreateToken = async () => {
    try {
      const userEmail = auth.currentUser.email;
      const response = await tokenService.createToken(userEmail);
      setNewToken(response.data);
      await loadTokens();
    } catch (err) {
      setError("Failed to create an api key");
    }
  };

  const handleDeleteToken = async (tokenId) => {
    if (deletingToken) return;

    if (!window.confirm("Are you sure you want to delete this token?")) {
      return;
    }

    try {
      setDeletingToken(true);
      await tokenService.deleteToken(tokenId);
      await loadTokens(); // Refresh the token list
    } catch (err) {
      console.error("Delete api key error:", err);
      setError("Failed to delete api key");
    } finally {
      setDeletingToken(false);
    }
  };

  const handleSubscribeClick = async () => {
    setLoadingTiers(true);
    try {
      const response = await tokenService.fetchTiers();
      setTiers(response.data.tiers);
      setShowSubscribeModal(true);
    } catch (error) {
      console.error("Error fetching tiers:", error);
      toast.error("Failed to load subscription tiers");
    } finally {
      setLoadingTiers(false);
    }
  };

  const handleCancelSubscription = async () => {
    setCancellingSubscription(true);
    try {
      await tokenService.cancelSubscription();
      await loadTokens();
      setShowCancelModal(false);
      toast.success(
        "Your subscription has been cancelled. You will retain access until the end of your current billing period.",
        { duration: 5000 },
      );
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      toast.error(
        "Failed to cancel subscription. Please contact support if the problem persists.",
      );
    } finally {
      setCancellingSubscription(false);
    }
  };

  if (loading) {
    return (
      <LoadingScreen
        message="Loading your tokens"
        shrimpMessage="Shrimpy is scouring the ocean floor for your tokens"
      />
    );
  }

  return (
    <div className="p-8">
      {/* Error message display */}
      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      {/* Tier Section */}
      <h3 className="text-2xl font-semibold mb-6 text-left text-gray-800">
        Tier
      </h3>
      <div className="max-w-xl mb-8 bg-gray-50 p-6 shadow-md rounded-lg border border-gray-200">
        <div className="flex flex-col gap-4">
          <div className="flex items-center">
            <span className="inline-block font-medium text-left mr-1">
              User:
            </span>
            <span className="text-gray-700">{auth.currentUser?.email}</span>
          </div>

          {subscriptionData && (
            <>
              <div className="flex items-center justify-between mb-6">
                <div className="flex flex-col gap-3">
                  <div className="flex items-baseline gap-2">
                    <span className="text-sm text-gray-900">Current Plan:</span>
                    <span className="text-2xl font-bold text-teal-600">
                      {subscriptionData.tier_id
                        .replace("_", " ")
                        .charAt(0)
                        .toUpperCase() +
                        subscriptionData.tier_id.replace("_", " ").slice(1)}
                    </span>
                  </div>
                  {subscriptionData.cancel_at_period_end && (
                    <div className="flex items-center gap-2 text-amber-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <span className="text-sm font-medium">
                        Expires on{" "}
                        {new Date(
                          subscriptionData.period_end_date,
                        ).toLocaleDateString()}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-medium text-gray-600">
                      Monthly Usage
                    </span>
                    <span className="text-xs text-gray-500">
                      {Math.round(
                        (subscriptionData.monthly_usage /
                          subscriptionData.monthly_limit) *
                          100,
                      )}
                      % used
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-teal-600 h-2 rounded-full transition-all duration-300"
                      style={{
                        width: `${Math.min((subscriptionData.monthly_usage / subscriptionData.monthly_limit) * 100, 100)}%`,
                      }}
                    />
                  </div>
                  <div className="mt-2 flex justify-between text-sm">
                    <span className="text-gray-600 font-medium">
                      {subscriptionData.monthly_usage.toLocaleString()}
                    </span>
                    <span className="text-gray-500">
                      of {subscriptionData.monthly_limit.toLocaleString()} tags
                    </span>
                  </div>
                </div>

                <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-medium text-gray-600">
                      Storage Usage
                    </span>
                    <span className="text-xs text-gray-500">
                      {Math.round(
                        (subscriptionData.storage_usage /
                          subscriptionData.storage_limit) *
                          100,
                      )}
                      % used
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-teal-600 h-2 rounded-full transition-all duration-300"
                      style={{
                        width: `${Math.min((subscriptionData.storage_usage / subscriptionData.storage_limit) * 100, 100)}%`,
                      }}
                    />
                  </div>
                  <div className="mt-2 flex justify-between text-sm">
                    <span className="text-gray-600 font-medium">
                      {subscriptionData.storage_usage.toLocaleString()}
                    </span>
                    <span className="text-gray-500">
                      of {subscriptionData.storage_limit.toLocaleString()} tags
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex justify-start gap-4">
                <button
                  onClick={handleSubscribeClick}
                  disabled={
                    loadingTiers ||
                    subscriptionData?.subscription_source === "gcp_marketplace"
                  }
                  title={
                    subscriptionData?.subscription_source === "gcp_marketplace"
                      ? "Subscription managed via GCP marketplace"
                      : ""
                  }
                  className={`px-6 py-2.5 bg-white border border-teal-600 font-semibold rounded-lg 
                    shadow-sm transition-all duration-200 ${
                      subscriptionData?.subscription_source ===
                      "gcp_marketplace"
                        ? "opacity-50 cursor-not-allowed text-gray-500 border-gray-400"
                        : "text-teal-600 hover:bg-teal-50 hover:shadow-md"
                    }`}
                >
                  {loadingTiers ? "Loading..." : "Subscribe"}
                </button>
                <button
                  onClick={() => setShowCancelModal(true)}
                  disabled={
                    subscriptionData?.subscription_source ===
                      "gcp_marketplace" ||
                    subscriptionData?.tier_id
                      ?.toLowerCase()
                      .match(/(free|demo|expired)/) ||
                    subscriptionData?.cancel_at_period_end
                  }
                  title={
                    subscriptionData?.subscription_source === "gcp_marketplace"
                      ? "Subscription managed via GCP marketplace"
                      : subscriptionData?.cancel_at_period_end
                        ? "Subscription is already scheduled for cancellation"
                        : subscriptionData?.tier_id?.toLowerCase() === "expired"
                          ? "Your subscription has already expired"
                          : "Cancel your current subscription"
                  }
                  className="px-4 py-2 bg-white border border-gray-300 text-gray-500 text-sm rounded-lg
                    shadow-sm transition-all duration-200 hover:bg-gray-50 disabled:opacity-50
                    disabled:cursor-not-allowed disabled:hover:bg-white disabled:text-gray-400"
                >
                  Cancel Subscription
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Deasy API Key Section */}
      <h3 className="text-2xl font-semibold mb-2 text-left text-gray-800">
        Deasy API Key
      </h3>
      <p className="text-gray-600 mb-6 text-left">
        Your Deasy API key is required to access Deasy services. Keep this key
        secure and never share it publicly.
      </p>
      <div className="max-w-xl mb-8 bg-gray-50 p-6 shadow-md rounded-lg border border-gray-200">
        <FormInput
          label="API Key"
          type="password"
          value={deasyApiKey}
          onChange={(e) => setDeasyApiKey(e.target.value)}
          placeholder="Enter your Deasy API key"
          helperText="This key will be used to authenticate your requests to Deasy services"
        />
        <div className="flex justify-start">
          <button
            onClick={async (e) => {
              e.preventDefault();
              await handleSaveDeasyApiKey(
                deasyUserConfig,
                setDeasyUserConfig,
                deasyApiKey,
                setSavingApiKey,
              );
            }}
            disabled={savingApiKey}
            className="mt-4 px-6 py-2.5 bg-white border border-teal-600 text-teal-600 font-semibold rounded-lg 
                    shadow-sm hover:bg-teal-50 hover:shadow-md transition-all duration-200"
          >
            {savingApiKey ? "Saving..." : "Save API Key"}
          </button>
        </div>
      </div>

      {/* API Keys Section */}
      <div>
        <h3 className="text-2xl font-semibold mb-6 text-left text-gray-800">
          Deasy API keys
        </h3>
        <TokenTable tokens={tokens} onDeleteToken={handleDeleteToken} />
        <div className="text-left">
          <button
            onClick={handleCreateToken}
            className="mt-4 px-4 py-2 bg-white text-teal-600 rounded-lg shadow-md hover:shadow-lg transition-all duration-200 flex items-center space-x-2 font-medium"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            <span>New API key</span>
          </button>
        </div>
      </div>

      {/* Existing modals */}
      <TokenCreationModal token={newToken} onClose={() => setNewToken(null)} />

      <SubscriptionModal
        showModal={showSubscribeModal}
        tiers={tiers}
        onClose={() => setShowSubscribeModal(false)}
        loadTokens={loadTokens}
      />

      <CancellationModal
        showModal={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirm={handleCancelSubscription}
        isLoading={cancellingSubscription}
      />
    </div>
  );
}

export default TokenDashboard;
