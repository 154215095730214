import React, { useState, useContext } from "react";
import { GraphContext } from "../../../../../../../contexts/GraphContext";
import TagEditor from "../../../../../SavedTags/components/TagEditor/TagEditor";
import CompactTagsTable from "../../../../../SavedTags/components/TagTables/CompactTagsTable";
import { AutoSuggestedMetadata } from "./AutoSuggestedMetadata";

export const AddNodeSidePanel = ({
  handleAddNodeApplied,
  generatingSchema,
  setGeneratingSchema,
}) => {
  const {
    setSidePanelOpen,
    selectedNodeData,
    setSelectedNodeData,
    selectedMode,
    setSelectedMode,
    GRAPH_PANEL_MODES,
  } = useContext(GraphContext);

  const [tagInEditor, setTagInEditor] = useState({});
  const [isTagEditorCollapsed, setIsTagEditorCollapsed] = useState(false);
  const [activeTagEditorSection, setActiveTagEditorSection] =
    useState("general");
  const handleApply = async () => {
    if (!tagInEditor || !selectedNodeData) return;

    try {
      await handleAddNodeApplied(selectedNodeData, tagInEditor);
    } catch (error) {
      console.error("Error applying tag:", error);
    }
  };

  const ModeSelector = () => (
    <div className="p-6">
      <h2 className="text-lg font-semibold mb-4 text-left">
        How would you like to add metadata?
      </h2>
      <div className="space-y-3">
        <button
          onClick={() => setSelectedMode(GRAPH_PANEL_MODES.MANUAL)}
          className="w-full text-left px-4 py-3 hover:bg-gray-50 rounded-md border border-gray-200"
        >
          <div className="font-medium">Manual Definition</div>
          <div className="text-sm text-gray-500">
            Create a new metadata tag from scratch
          </div>
        </button>
        <button
          onClick={() => setSelectedMode(GRAPH_PANEL_MODES.LIBRARY)}
          className="w-full text-left px-4 py-3 hover:bg-gray-50 rounded-md border border-gray-200"
        >
          <div className="font-medium">Metadata Library</div>
          <div className="text-sm text-gray-500">
            Select existing metadata tag
          </div>
        </button>
        <button
          onClick={() => setSelectedMode(GRAPH_PANEL_MODES.AUTO)}
          className="w-full text-left px-4 py-3 hover:bg-gray-50 rounded-md border border-gray-200"
        >
          <div className="font-medium">Suggest Metadata Schema</div>
          <div className="text-sm text-gray-500">
            Deasy-powered metadata suggestions
          </div>
        </button>
      </div>
    </div>
  );

  const renderContent = () => {
    switch (selectedMode) {
      case GRAPH_PANEL_MODES.MANUAL:
        return (
          <TagEditor
            showHeader={false}
            includeNewTagButton={true}
            tagInEditor={tagInEditor}
            setTagInEditor={setTagInEditor}
            isTagEditorCollapsed={isTagEditorCollapsed}
            setIsTagEditorCollapsed={setIsTagEditorCollapsed}
            activeTagEditorSection={activeTagEditorSection}
            setActiveTagEditorSection={setActiveTagEditorSection}
            handleAddNodeApplied={handleAddNodeApplied}
            selectedNodeData={selectedNodeData}
            context="graph"
            editingExistingTag={false}
            isGraphContext={false}
          />
        );
      case GRAPH_PANEL_MODES.LIBRARY:
        return (
          <CompactTagsTable
            tagInEditor={tagInEditor}
            setTagInEditor={setTagInEditor}
          />
        );
      case GRAPH_PANEL_MODES.AUTO:
        return (
          <AutoSuggestedMetadata
            generatingSchema={generatingSchema}
            setGeneratingSchema={setGeneratingSchema}
          />
        );
      default:
        return <ModeSelector />;
    }
  };

  return (
    <div className="absolute right-0 top-0 h-screen bg-white border border-gray-200 flex flex-col rounded-l-md z-50 animate-slideIn w-[24vw]">
      {/* Close button */}
      <button
        onClick={() => {
          setSelectedNodeData({});
          setSidePanelOpen(false);
          setSelectedMode(null);
        }}
        className="absolute top-1 right-1 z-10 p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
      >
        ✕
      </button>

      {/* Main content container */}
      <div className="flex flex-col h-full overflow-hidden">
        {!selectedMode ? (
          <ModeSelector />
        ) : (
          <>
            {/* Header */}
            <div className="flex-shrink-0 p-4 border-b">
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setSelectedMode(null)}
                  className="p-1 hover:bg-gray-100 rounded-full"
                >
                  ←
                </button>
                <h2 className="text-lg font-semibold">
                  {selectedMode === GRAPH_PANEL_MODES.MANUAL &&
                    "Define New Tag"}
                  {selectedMode === GRAPH_PANEL_MODES.LIBRARY &&
                    "Select from Metadata Library"}
                  {selectedMode === GRAPH_PANEL_MODES.AUTO &&
                    "Suggest Metadata Schema"}
                </h2>
              </div>
            </div>

            {/* Content area */}
            <div className="flex-1 flex flex-col min-h-0">
              <div className="flex-1 overflow-y-auto border border-gray-200">
                {renderContent()}
              </div>
              {selectedMode === GRAPH_PANEL_MODES.LIBRARY && (
                <div className="flex-shrink-0 p-4 border-t bg-white">
                  <button
                    className={`w-full p-4 rounded-md text-sm font-medium transition-all duration-200 ${
                      !tagInEditor || Object.keys(tagInEditor).length === 0
                        ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                        : "bg-primary text-white hover:bg-emerald-700"
                    }`}
                    onClick={handleApply}
                    disabled={
                      !tagInEditor || Object.keys(tagInEditor).length === 0
                    }
                  >
                    {!tagInEditor || Object.keys(tagInEditor).length === 0
                      ? "Select a tag"
                      : "Include Tag"}
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
