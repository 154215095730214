import { FaChevronLeft, FaChevronDown, FaChevronUp } from "react-icons/fa";

export const TagEditorHeader = ({
  collapseDirection,
  labelStyles,
  labelRotation,
  headerClasses,
  disableCollapse,
  isTagEditorCollapsed,
  setIsTagEditorCollapsed,
  setIsTagSelectorExpanded,
  tagInEditor,
  context,
}) => {
  const isGraphContext = context === "graph";
  const isLibraryContext = context === "library";
  return (
    <>
      <div
        className={headerClasses}
        onClick={() =>
          !disableCollapse && setIsTagEditorCollapsed(!isTagEditorCollapsed)
        }
      >
        {(!isTagEditorCollapsed || collapseDirection === "vertical") && (
          <div className="flex w-full items-center justify-between p-3">
            <div className="flex items-center gap-2">
              <div className="flex flex-col">
                {isLibraryContext && (
                  <h2 className="text-gray-800 font-semibold text-left">
                    Metadata Editor
                  </h2>
                )}
                <p className="text-xs text-gray-500 mt-0.5 flex items-center gap-1.5 font-medium text-left max-w-[250px] text-wrap">
                  <span className="text-left max-w-[250px] text-wrap">
                    Clear names and descriptions help Deasy find the right
                    information. Check the quality of your inputs by testing
                    your metadata tag.
                  </span>
                </p>
              </div>
            </div>
            {!(setIsTagSelectorExpanded === null) && (
              <button
                onClick={() => setIsTagSelectorExpanded(true)}
                className={`p-2 rounded-full shadow-md hover:opacity-80 transition-colors duration-200 truncate px-4 ${
                  tagInEditor?.name
                    ? "w-1/3 bg-white text-gray-500 "
                    : "w-1/3 bg-emerald-50 border-primary text-emerald-600 border-2 font-medium"
                }`}
                title={tagInEditor?.name ? tagInEditor?.name : "Select a Tag"}
              >
                {tagInEditor?.name ? tagInEditor.name : "Select a Tag"}
              </button>
            )}
          </div>
        )}
        {!disableCollapse && !isGraphContext && (
          <button className="text-gray-500 hover:text-gray-700">
            {collapseDirection === "vertical" ? (
              isTagEditorCollapsed ? (
                <FaChevronDown />
              ) : (
                <FaChevronUp />
              )
            ) : isTagEditorCollapsed ? (
              <FaChevronLeft />
            ) : (
              <></>
            )}
          </button>
        )}
      </div>
      {isTagEditorCollapsed && collapseDirection === "horizontal" && (
        <div className="flex justify-center items-center p-6 text-gray-800 ">
          <h2
            className={`whitespace-nowrap font-semibold ${labelRotation}`}
            style={labelStyles}
          >
            Metadata Editor
          </h2>
        </div>
      )}
    </>
  );
};
