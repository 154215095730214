import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { metadataService } from "../services/api";
import { auth } from "../config/firebase";
import { BaseContext } from "./BaseContext";
import { graphService } from "../services/api";
import { prepareVectorDBConfiguration } from "../services/utils";
import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";

export const GraphContext = createContext();

export const GraphProvider = ({ children }) => {
  const [graphData, setGraphData] = useState(null);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [selectedNodeData, setSelectedNodeData] = useState(null);
  const [hierarchyStats, setHierarchyStats] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [activeDragNode, setActiveDragNode] = useState(null);
  const [activeOverlappingNode, setActiveOverlappingNode] = useState(null);
  const [graphs, setGraphs] = useState([]);
  const [shouldFitView, setShouldFitView] = useState(false);
  const { deasyUserConfig } = useContext(BaseContext);
  const [uniqueTags, setUniqueTags] = useState([]);
  const [loadingUniqueTags, setLoadingUniqueTags] = useState(false);

  // Keep track of initial load
  const [initialLoad, setInitialLoad] = useState(true);

  // When component mounts, trigger animation once
  useEffect(() => {
    if (initialLoad) {
      // Delay slightly to ensure graph is loaded
      setTimeout(() => {
        setShouldFitView(true);
        setInitialLoad(false);
      }, 500);
    }
  }, [initialLoad]);

  const [selectedDiscoveryGraph, setSelectedDiscoveryGraph] = useState(null);
  const discoveryGraphData = useMemo(
    () => selectedDiscoveryGraph?.graph_data || {},
    [selectedDiscoveryGraph?.graph_data],
  );

  // When graph changes, trigger animation
  const handleGraphChange = useCallback((graph) => {
    setSelectedDiscoveryGraph(graph);
    // Trigger animation after graph changes
    setTimeout(() => {
      setShouldFitView(true);
    }, 100);
  }, []);

  const setDiscoveryGraphData = (data) => {
    setSelectedDiscoveryGraph((prev) => ({
      ...prev,
      graph_data: data,
    }));
  };

  const GRAPH_PANEL_MODES = Object.freeze({
    HOME: "home",
    MANUAL: "manual",
    LIBRARY: "library",
    AUTO: "auto",
  });

  const [selectedMode, setSelectedMode] = useState(GRAPH_PANEL_MODES.HOME);

  // States for undo/redo functionality
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [isUndoRedoOperation, setIsUndoRedoOperation] = useState(false);
  const [isAtomicUpdateInProgress, setIsAtomicUpdateInProgress] =
    useState(false);

  const saveToHistory = useCallback(
    (graphData) => {
      const stateToSave = JSON.parse(
        JSON.stringify(graphData || selectedDiscoveryGraph?.graph_data || {}),
      );

      setUndoStack((prevStack) => {
        if (prevStack.length > 0) {
          // eslint-disable-next-line no-unused-vars
          const lastState = prevStack[prevStack.length - 1];
        }

        const newStack = [...prevStack];
        if (newStack.length >= 20) newStack.shift();
        newStack.push(stateToSave);
        return newStack;
      });

      setRedoStack([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDiscoveryGraph],
  );

  useEffect(() => {
    // Reset undo/redo stacks when switching graphs
    setUndoStack([]);
    setRedoStack([]);
  }, [selectedDiscoveryGraph?.graph_id]);

  const handleUndo = useCallback(() => {
    if (undoStack.length === 0) return;

    setIsUndoRedoOperation(true);

    setRedoStack((prevRedoStack) => {
      const currentState = JSON.parse(JSON.stringify(discoveryGraphData));
      return [...prevRedoStack, currentState];
    });

    setUndoStack((prevUndoStack) => {
      const newUndoStack = [...prevUndoStack];
      const previousState = newUndoStack.pop();
      setDiscoveryGraphData(previousState);
      return newUndoStack;
    });

    setTimeout(() => {
      setIsUndoRedoOperation(false);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [undoStack, discoveryGraphData]);

  const handleRedo = useCallback(() => {
    if (redoStack.length === 0) return;

    setIsUndoRedoOperation(true);

    setUndoStack((prevUndoStack) => {
      const currentState = JSON.parse(JSON.stringify(discoveryGraphData));
      const lastState = prevUndoStack[prevUndoStack.length - 1];

      if (
        lastState &&
        JSON.stringify(lastState) === JSON.stringify(currentState)
      ) {
        return prevUndoStack;
      }

      return [...prevUndoStack, currentState];
    });

    setRedoStack((prevRedoStack) => {
      const newRedoStack = [...prevRedoStack];
      const nextState = newRedoStack.pop();

      setDiscoveryGraphData(nextState);

      return newRedoStack;
    });

    setTimeout(() => {
      setIsUndoRedoOperation(false);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redoStack, discoveryGraphData, undoStack]);

  const handleSaveGraph = async (graphData) => {
    if (!graphData) {
      graphData = selectedDiscoveryGraph;
    }
    if (!graphData) return;

    try {
      await graphService.createUpdateGraph(
        deasyUserConfig.deasyApiKey,
        graphData.graph_name,
        graphData.graph_description,
        graphData.graph_data,
      );
      // Refresh graph list after save
      const updatedGraphs = await graphService.getGraphs(
        deasyUserConfig.deasyApiKey,
      );
      setGraphs(updatedGraphs.data.graphs);
    } catch (error) {
      console.error("Failed to save graph:", error);
      toast.error("Failed to save graph changes");
    }
  };

  const updateDiscoveryGraphData = useCallback(
    (newData) => {
      if (!isUndoRedoOperation && !isAtomicUpdateInProgress) {
        const currentState = JSON.parse(
          JSON.stringify(discoveryGraphData || {}),
        );
        saveToHistory(currentState);
      }
      setDiscoveryGraphData(newData);
    },
    [
      discoveryGraphData,
      saveToHistory,
      isUndoRedoOperation,
      isAtomicUpdateInProgress,
    ],
  );

  const refreshHierarchyStats = async (apiKey) => {
    try {
      if (!deasyUserConfig?.deasyApiKey) {
        return;
      }
      // Build conditions for all nodes in the tree
      const buildTreeConditions = (data) => {
        const conditions = [];

        const processNode = (node, tagId) => {
          if (!node || typeof node !== "object") return;

          // If node has TagAvailableValues, it's a tag node
          if (node.TagAvailableValues) {
            conditions.push({
              tag_id: tagId,
              values: node.TagAvailableValues,
            });
          }

          // Process child nodes
          Object.entries(node).forEach(([key, value]) => {
            if (key !== "TagAvailableValues" && typeof value === "object") {
              processNode(value, key);
            }
          });
        };

        Object.entries(data).forEach(([key, value]) => {
          processNode(value, key);
        });

        return conditions;
      };

      const conditions = buildTreeConditions(discoveryGraphData);

      const activeVdbConfig =
        deasyUserConfig.vdbmConfig?.Configs?.[
          deasyUserConfig.vdbmConfig?.LastActive
        ];

      const requestData = {
        vector_db_config: prepareVectorDBConfiguration({
          ...activeVdbConfig,
          user: auth.currentUser.email,
        }),
        endpoint_manager_config:
          deasyUserConfig.llmConfig?.Configs?.[
            deasyUserConfig.llmConfig?.LastActive
          ],
        conditions: conditions,
        current_tree: JSON.stringify(discoveryGraphData) || [],
      };

      const response = await metadataService.getHierarchyCountDistributions(
        requestData,
        apiKey,
      );

      // Store previous stats for comparison
      const previousStats = hierarchyStats;
      const newStats = response.data?.hierarchy;

      // Find updated nodes by comparing hierarchies
      const findUpdatedNodes = (oldNode, newNode, path = []) => {
        const updates = [];

        if (!oldNode || !newNode) return updates;

        // Check if current node was updated
        if (
          oldNode.file_count !== newNode.file_count ||
          oldNode.percentage !== newNode.percentage
        ) {
          updates.push({
            path: [...path],
            name: newNode.name,
            oldCount: oldNode.file_count,
            newCount: newNode.file_count,
            oldPercentage: oldNode.percentage,
            newPercentage: newNode.percentage,
          });
        }

        // Recursively check children
        if (newNode.children) {
          newNode.children.forEach((newChild) => {
            const oldChild = oldNode.children?.find(
              (c) => c.name === newChild.name,
            );
            updates.push(
              ...findUpdatedNodes(oldChild, newChild, [...path, newChild.name]),
            );
          });
        }

        return updates;
      };

      const updatedNodes = findUpdatedNodes(previousStats, newStats);

      setHierarchyStats(newStats);
      return updatedNodes; // Return the updates if needed elsewhere
    } catch (error) {
      console.error("Error refreshing hierarchy stats:", error);
      return [];
    }
  };

  const startAtomicUpdate = useCallback(() => {
    setIsAtomicUpdateInProgress(true);
    return () => setIsAtomicUpdateInProgress(false);
  }, []);

  const fetchGraphs = async () => {
    try {
      const graphs = await graphService.getGraphs(deasyUserConfig.deasyApiKey);
      const fetchedGraphs = graphs.data.graphs;

      if (fetchedGraphs.length === 0) {
        const defaultGraph = {
          graph_id: uuidv4(),
          graph_name: "Schema 1",
          graph_description: "Default schema",
          graph_data: {},
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        };

        setGraphs([defaultGraph]);
        setSelectedDiscoveryGraph(defaultGraph);
        setDiscoveryGraphData(defaultGraph.graph_data);
      } else {
        setGraphs(fetchedGraphs);
        // Always update with the latest data, even if we have a selected graph
        const graphToSelect = selectedDiscoveryGraph
          ? fetchedGraphs.find(
              (g) => g.graph_id === selectedDiscoveryGraph.graph_id,
            ) || fetchedGraphs[0]
          : fetchedGraphs[0];
        setSelectedDiscoveryGraph(graphToSelect);
        setDiscoveryGraphData(graphToSelect.graph_data);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        setGraphs([]);
      }
      console.error("Error fetching graphs:", error);
    }
  };

  const fetchUniqueTags = useCallback(async () => {
    if (loadingUniqueTags) {
      console.log("Already loading unique tags, skipping duplicate call");
      return;
    }

    if (!deasyUserConfig?.deasyApiKey) {
      console.log("Missing API key, skipping unique tags fetch");
      return;
    }

    const activeVdbConfig =
      deasyUserConfig.vdbmConfig?.Configs?.[
        deasyUserConfig.vdbmConfig?.LastActive
      ];

    if (!activeVdbConfig) {
      console.log("No active VDB config, skipping unique tags fetch");
      return;
    }

    try {
      setLoadingUniqueTags(true);

      const response = await metadataService.getUniqueTags(
        deasyUserConfig.deasyApiKey,
        null, // fileNames
        prepareVectorDBConfiguration({
          ...activeVdbConfig,
          user: auth.currentUser.email,
        }),
        null, // dataslice_id
        null, // node_condition
      );

      if (response.data?.tags) {
        setUniqueTags(response.data.tags);
      }
    } catch (error) {
      console.error("Error fetching unique tags:", error);
    } finally {
      setLoadingUniqueTags(false);
    }
  }, [
    loadingUniqueTags,
    deasyUserConfig.deasyApiKey,
    deasyUserConfig.vdbmConfig?.Configs,
    deasyUserConfig.vdbmConfig?.LastActive,
  ]);

  const contextValue = {
    graphData,
    setGraphData,
    sidePanelOpen,
    setSidePanelOpen,
    selectedNodeData,
    setSelectedNodeData,
    hierarchyStats,
    setHierarchyStats,
    selectedNodes,
    setSelectedNodes,
    activeDragNode,
    setActiveDragNode,
    activeOverlappingNode,
    setActiveOverlappingNode,
    discoveryGraphData,
    updateDiscoveryGraphData,
    graphs,
    setGraphs,
    fetchGraphs,
    selectedDiscoveryGraph,
    setSelectedDiscoveryGraph: handleGraphChange,
    handleSaveGraph,
    shouldFitView,
    setShouldFitView,
    selectedMode,
    setSelectedMode,
    refreshHierarchyStats,
    GRAPH_PANEL_MODES,
    handleUndo,
    handleRedo,
    canUndo: undoStack.length > 0,
    canRedo: redoStack.length > 0,
    isAtomicUpdateInProgress,
    startAtomicUpdate,
    uniqueTags,
    loadingUniqueTags,
    fetchUniqueTags,
  };

  return (
    <GraphContext.Provider value={contextValue}>
      {children}
    </GraphContext.Provider>
  );
};

export const useGraph = () => {
  const context = useContext(GraphContext);
  if (!context) {
    throw new Error("useGraph must be used within a GraphProvider");
  }
  return context;
};
