import { useState, useEffect, useContext } from "react";
import { AlertTriangle, X, Loader2 } from "lucide-react";
import { connectorService, tokenService } from "../../../../services/api";
import { BaseContext } from "../../../../contexts/BaseContext";

export const VDBDeleteModal = ({ profileName, isOpen, onClose, onConfirm }) => {
  const { deasyUserConfig } = useContext(BaseContext);
  const deasyApiKey = deasyUserConfig?.deasyApiKey;
  const [inputValue, setInputValue] = useState("");
  const [filesAffected, setFilesAffected] = useState(0);
  const [dataslicesAffected, setDataslicesAffected] = useState([]);
  const [loadingStats, setLoadingStats] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleDelete = async () => {
    try {
      setLoadingDelete(true);
      await connectorService.deleteVDBConnector(profileName, deasyApiKey);
      await onConfirm();
    } catch (error) {
      console.error("Failed to delete vectorDB:", error);
    } finally {
      setLoadingDelete(false);
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen && profileName) {
      const fetchDeleteVDBStats = async () => {
        try {
          setLoadingStats(true);
          const response = await tokenService.getVDBDeleteStats(
            profileName,
            deasyApiKey,
          );
          setFilesAffected(response.data.file_count_with_vdb);
          setDataslicesAffected(response.data.dataslices_for_vdb);
        } catch (error) {
          console.error("Failed to fetch delete vdb stats:", error);
        } finally {
          setLoadingStats(false);
        }
      };
      fetchDeleteVDBStats();
      // Reset input when opening
      setInputValue("");
    }
  }, [isOpen, profileName, deasyApiKey]);

  if (!isOpen) return null;

  if (loadingStats) {
    return (
      <div className="fixed inset-0 z-[10000] flex items-center justify-center bg-black/60 backdrop-blur-sm px-4 py-6 transition-opacity">
        <div className="relative w-full max-w-lg rounded-lg bg-white p-6 shadow-xl ring-1 ring-gray-200">
          <div className="flex items-center justify-center">
            <Loader2 className="w-8 h-8 animate-spin text-red-500" />
            <p className="text-sm text-gray-500">Loading...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-[10000] flex items-center justify-center bg-black/60 backdrop-blur-sm px-4 py-6 transition-opacity">
      <div className="relative w-full max-w-lg rounded-lg bg-white p-6 shadow-xl ring-1 ring-gray-200">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
          aria-label="Close"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="flex flex-col items-center justify-center space-y-1">
          <AlertTriangle className="w-8 h-8 text-red-500" />
          <h3 className="mt-1 text-2xl font-semibold text-gray-900">
            Delete Data Connection
          </h3>
          <p className="text-sm italic text-gray-500">
            This action cannot be undone
          </p>
        </div>

        <div className="my-6 rounded-lg bg-red-50 p-4 text-center">
          <p className="text-sm text-red-800">
            <span className="font-medium">Warning:</span> Deleting this data
            connection will remove metadata values from{" "}
            <span className="text-red-900 font-medium">
              {filesAffected?.toLocaleString()}
            </span>{" "}
            file
            {filesAffected > 1 || filesAffected === 0 ? "s " : " "}
            and the following dataslices:
          </p>
          <ul className="mt-2 text-sm text-red-800 flex flex-wrap gap-2">
            {dataslicesAffected.map((dataslice, index) => (
              <li
                key={index}
                className="font-medium bg-red-100 px-2 py-1 rounded-md"
              >
                {dataslice}
              </li>
            ))}
          </ul>
        </div>

        <div className="space-y-4 text-center">
          <p className="text-sm text-gray-700">
            Type{" "}
            <code className="rounded bg-gray-200 px-2 py-1 font-mono text-gray-800">
              {profileName}
            </code>{" "}
            to confirm
          </p>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="w-full rounded-lg border border-gray-300 px-4 py-2.5 outline-none transition-all"
            placeholder={profileName}
            autoComplete="off"
            style={{ fontStyle: "italic" }}
          />
        </div>

        <div className="mt-8 flex justify-end gap-3">
          {!loadingDelete && (
            <button
              onClick={onClose}
              className="rounded-lg px-5 py-2.5 font-medium text-gray-700 transition-colors hover:bg-gray-100 bg-gray-50"
            >
              Close
            </button>
          )}
          <button
            onClick={handleDelete}
            disabled={inputValue !== profileName || loadingDelete}
            className="rounded-lg bg-red-400 px-5 py-2.5 font-medium text-white transition-colors hover:bg-red-500 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {loadingDelete ? "Deleting..." : "Delete Permanently"}
          </button>
        </div>
      </div>
    </div>
  );
};
