import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import {
  FiFolder,
  FiFile,
  FiChevronLeft,
  FiFolderPlus,
  FiCheck,
  FiX,
  FiTrash2,
} from "react-icons/fi";
import { prepareVectorDBConfiguration } from "../../../services/utils";
import { sourceMetadataService } from "../../../services/api";

const FileBrowser = ({
  selectedFiles,
  setSelectedFiles,
  setError,
  showTitle = true,
  selectAllForEntireDB = false,
  selectAllVisualOverride = false,
}) => {
  const { deasyUserConfig } = useContext(BaseContext);
  const deasyApiKey = deasyUserConfig?.deasyApiKey;
  const vectorDBConfiguration =
    deasyUserConfig?.vdbmConfig?.Configs?.[
      deasyUserConfig?.vdbmConfig?.LastActive
    ];

  const [files, setFiles] = useState([]);
  const [fileStructure, setFileStructure] = useState({});
  const [currentPath, setCurrentPath] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [selectedFolders, setSelectedFolders] = useState(new Set());
  const [allFilesSelected, setAllFilesSelected] = useState(false);
  const folderFileCache = useRef(new WeakMap());
  const [excludedSubfolders, setExcludedSubfolders] = useState(new Set());

  const buildFileStructure = useCallback((fileList) => {
    const structure = {};

    fileList.forEach((filePath) => {
      const parts = filePath.split("/");
      let currentLevel = structure;

      parts.forEach((part, index) => {
        if (!part) return;

        if (index === parts.length - 1) {
          if (!currentLevel._files) currentLevel._files = [];
          currentLevel._files.push({
            name: part,
            path: filePath,
          });
        } else {
          if (!currentLevel[part]) {
            currentLevel[part] = {};
          }
          currentLevel = currentLevel[part];
        }
      });
    });

    return structure;
  }, []);

  const getFiles = useCallback(async () => {
    if (!deasyApiKey || !vectorDBConfiguration) {
      return { entities: [], next_offset: null };
    }
    try {
      setFetchingFiles(true);

      const response = await sourceMetadataService.listPaginated(
        deasyApiKey,
        prepareVectorDBConfiguration(vectorDBConfiguration),
        5000,
        0,
        "file",
        [],
        searchTerm,
      );

      if (response?.entities?.length) {
        setFiles(response.entities);
        const structure = buildFileStructure(response.entities);
        setFileStructure(structure);
      }

      return {
        entities: response?.entities || [],
        next_offset: response?.next_offset || null,
      };
    } catch (err) {
      console.error("Error fetching files", err);
      setError(err.message);
      return { entities: [], next_offset: null };
    } finally {
      setFetchingFiles(false);
    }
  }, [
    deasyApiKey,
    vectorDBConfiguration,
    setError,
    searchTerm,
    buildFileStructure,
  ]);

  useEffect(() => {
    if (!deasyApiKey || !vectorDBConfiguration) return;
    getFiles();
  }, [getFiles, deasyApiKey, vectorDBConfiguration]);

  const navigateToFolder = (folderName) => {
    const newPath = currentPath ? `${currentPath}/${folderName}` : folderName;
    setCurrentPath(newPath);

    const newBreadcrumbs = newPath.split("/").filter(Boolean);
    setBreadcrumbs(newBreadcrumbs);
  };

  const navigateToBreadcrumb = (index) => {
    const newPath = breadcrumbs.slice(0, index + 1).join("/");
    setCurrentPath(newPath);
    setBreadcrumbs(breadcrumbs.slice(0, index + 1));
  };

  const navigateUp = () => {
    if (!currentPath) return;

    const parts = currentPath.split("/");
    parts.pop();
    const newPath = parts.join("/");
    setCurrentPath(newPath);
    setBreadcrumbs(parts);
  };

  const getCurrentDirectory = useCallback(() => {
    if (!currentPath) return fileStructure;

    const parts = currentPath.split("/").filter(Boolean);
    let current = fileStructure;

    for (const part of parts) {
      if (current[part]) {
        current = current[part];
      } else {
        return {};
      }
    }

    return current;
  }, [currentPath, fileStructure]);

  const getAllFilesInFolder = useCallback((folder, folderPath) => {
    if (folderFileCache.current.has(folder)) {
      return folderFileCache.current.get(folder);
    }

    let allFiles = [];
    if (folder._files) {
      allFiles = folder._files.map((file) => file.path);
    }

    Object.keys(folder).forEach((key) => {
      if (key !== "_files") {
        const subfolder = folder[key];
        const subfolderPath = `${folderPath}/${key}`;
        allFiles = [
          ...allFiles,
          ...getAllFilesInFolder(subfolder, subfolderPath),
        ];
      }
    });

    folderFileCache.current.set(folder, allFiles);
    return allFiles;
  }, []);

  const handleFolderSelect = useCallback(
    (folderName) => {
      const folderPath = currentPath
        ? `${currentPath}/${folderName}`
        : folderName;
      const folder = getCurrentDirectory()[folderName];
      const filesInFolder = getAllFilesInFolder(folder, folderPath);

      const isIndirectlySelected =
        !selectedFolders.has(folderPath) &&
        [...selectedFolders].some((folder) =>
          folderPath.startsWith(`${folder}/`),
        );

      if (isIndirectlySelected) {
        setExcludedSubfolders((prev) => {
          const newExcluded = new Set(prev);
          newExcluded.add(folderPath);
          return newExcluded;
        });

        setSelectedFiles((prev) => {
          if (prev === null) {
            return files.filter((f) => !filesInFolder.includes(f));
          }

          const newFiles = new Set(prev);
          filesInFolder.forEach((f) => newFiles.delete(f));
          return Array.from(newFiles);
        });

        return;
      }

      if (excludedSubfolders.has(folderPath)) {
        setExcludedSubfolders((prev) => {
          const newExcluded = new Set(prev);
          newExcluded.delete(folderPath);

          [...newExcluded].forEach((path) => {
            if (path.startsWith(`${folderPath}/`)) {
              newExcluded.delete(path);
            }
          });

          return newExcluded;
        });
      }

      setSelectedFiles((prev) => {
        if (prev === null) {
          return files.filter((f) => !filesInFolder.includes(f));
        }

        const newFiles = new Set(prev);
        filesInFolder.forEach((f) =>
          newFiles.has(f) ? newFiles.delete(f) : newFiles.add(f),
        );
        return Array.from(newFiles);
      });

      setSelectedFolders((prev) => {
        const newFolders = new Set(prev);
        const isSelected = newFolders.has(folderPath);

        if (isSelected) {
          newFolders.delete(folderPath);
          [...newFolders].forEach((f) => {
            if (f.startsWith(`${folderPath}/`)) newFolders.delete(f);
          });
        } else {
          newFolders.add(folderPath);
        }
        return newFolders;
      });
    },
    [
      currentPath,
      files,
      getAllFilesInFolder,
      getCurrentDirectory,
      setSelectedFiles,
      selectedFolders,
      excludedSubfolders,
    ],
  );

  const handleFileSelect = useCallback(
    (filePath) => {
      if (selectedFiles === null) {
        const allFilesExceptCurrent = files.filter((f) => f !== filePath);
        setSelectedFiles(allFilesExceptCurrent);
      } else {
        setSelectedFiles((prev) => {
          const newFiles = new Set(prev);
          newFiles.has(filePath)
            ? newFiles.delete(filePath)
            : newFiles.add(filePath);
          return Array.from(newFiles);
        });
      }
    },
    [files, selectedFiles, setSelectedFiles],
  );

  const isInsideSelectedFolder = useCallback(
    (path) => {
      if (excludedSubfolders.has(path)) return false;

      return [...selectedFolders].some(
        (folder) => path === folder || path.startsWith(`${folder}/`),
      );
    },
    [selectedFolders, excludedSubfolders],
  );

  const getAllMatchingFolders = useCallback(
    (structure, searchTerm, parentPath = "") => {
      let matchingFolders = [];

      Object.keys(structure).forEach((key) => {
        if (key !== "_files") {
          const fullPath = parentPath ? `${parentPath}/${key}` : key;

          if (key.toLowerCase().includes(searchTerm.toLowerCase())) {
            matchingFolders.push(fullPath);
          }

          const subfoldersMatching = getAllMatchingFolders(
            structure[key],
            searchTerm,
            fullPath,
          );
          matchingFolders = [...matchingFolders, ...subfoldersMatching];
        }
      });

      return matchingFolders;
    },
    [],
  );

  const getAllMatchingFiles = useCallback(
    (structure, searchTerm, parentPath = "") => {
      let matchingFiles = [];

      if (structure._files) {
        const filesInFolder = structure._files.filter((file) =>
          file.name.toLowerCase().includes(searchTerm.toLowerCase()),
        );

        matchingFiles = filesInFolder.map((file) => ({
          ...file,
          parentPath: parentPath,
        }));
      }

      Object.keys(structure).forEach((key) => {
        if (key !== "_files") {
          const fullPath = parentPath ? `${parentPath}/${key}` : key;
          const subfolderMatches = getAllMatchingFiles(
            structure[key],
            searchTerm,
            fullPath,
          );
          matchingFiles = [...matchingFiles, ...subfolderMatches];
        }
      });

      return matchingFiles;
    },
    [],
  );

  const getSearchResults = useCallback(() => {
    if (!searchTerm) {
      return { matchingFolderPaths: [], matchingFileObjects: [] };
    }
    const matchingFolderPaths = getAllMatchingFolders(
      fileStructure,
      searchTerm,
    );
    const matchingFileObjects = getAllMatchingFiles(fileStructure, searchTerm);

    return {
      matchingFolderPaths,
      matchingFileObjects,
    };
  }, [searchTerm, fileStructure, getAllMatchingFolders, getAllMatchingFiles]);

  const { matchingFolderPaths, matchingFileObjects } = getSearchResults();

  const navigateToFileParent = (parentPath) => {
    if (parentPath) {
      setCurrentPath(parentPath);
      setBreadcrumbs(parentPath.split("/").filter(Boolean));
    } else {
      setCurrentPath("");
      setBreadcrumbs([]);
    }
  };

  const currentDirectory = getCurrentDirectory();

  const folders = Object.keys(currentDirectory)
    .filter((key) => key !== "_files")
    .filter((folder) =>
      searchTerm
        ? folder.toLowerCase().includes(searchTerm.toLowerCase())
        : true,
    );

  const directFiles = currentDirectory._files || [];
  const filteredFiles = searchTerm
    ? directFiles.filter((file) =>
        file.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : directFiles;

  const isAllSelected = allFilesSelected || selectAllVisualOverride;

  const handleSearch = () => {
    setCurrentPath("");
    setBreadcrumbs([]);
  };

  const handleDeselectAll = () => {
    setSelectedFiles([]);
    setSelectedFolders(new Set());
    setExcludedSubfolders(new Set());
    setAllFilesSelected(false);
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedFiles([]);
      setSelectedFolders(new Set());
      setAllFilesSelected(false);
    } else {
      setSelectedFiles([...files]);
      setAllFilesSelected(true);

      const rootFolders = Object.keys(fileStructure)
        .filter((key) => key !== "_files")
        .map((folderName) => folderName);

      setSelectedFolders(new Set(rootFolders));
    }
  };

  // Add this new function specifically for search results folder selection
  const handleSearchResultFolderSelect = useCallback(
    (folderPath) => {
      // Split the path to get folder parts
      const parts = folderPath.split("/").filter(Boolean);

      // Navigate through the folder structure to find the correct folder object
      let current = fileStructure;
      for (const part of parts) {
        if (current[part]) {
          current = current[part];
        } else {
          console.error("Cannot find folder:", folderPath);
          return; // Exit if folder not found
        }
      }

      // Get all files in this folder
      const filesInFolder = getAllFilesInFolder(current, folderPath);

      const isIndirectlySelected =
        !selectedFolders.has(folderPath) &&
        [...selectedFolders].some((folder) =>
          folderPath.startsWith(`${folder}/`),
        );

      if (isIndirectlySelected) {
        setExcludedSubfolders((prev) => {
          const newExcluded = new Set(prev);
          newExcluded.add(folderPath);
          return newExcluded;
        });

        setSelectedFiles((prev) => {
          if (prev === null) {
            return files.filter((f) => !filesInFolder.includes(f));
          }

          const newFiles = new Set(prev);
          filesInFolder.forEach((f) => newFiles.delete(f));
          return Array.from(newFiles);
        });

        return;
      }

      if (excludedSubfolders.has(folderPath)) {
        setExcludedSubfolders((prev) => {
          const newExcluded = new Set(prev);
          newExcluded.delete(folderPath);

          [...newExcluded].forEach((path) => {
            if (path.startsWith(`${folderPath}/`)) {
              newExcluded.delete(path);
            }
          });

          return newExcluded;
        });
      }

      setSelectedFiles((prev) => {
        if (prev === null) {
          return files.filter((f) => !filesInFolder.includes(f));
        }

        const newFiles = new Set(prev);
        filesInFolder.forEach((f) =>
          newFiles.has(f) ? newFiles.delete(f) : newFiles.add(f),
        );
        return Array.from(newFiles);
      });

      setSelectedFolders((prev) => {
        const newFolders = new Set(prev);
        const isSelected = newFolders.has(folderPath);

        if (isSelected) {
          newFolders.delete(folderPath);
          [...newFolders].forEach((f) => {
            if (f.startsWith(`${folderPath}/`)) newFolders.delete(f);
          });
        } else {
          newFolders.add(folderPath);
        }
        return newFolders;
      });
    },
    [
      files,
      getAllFilesInFolder,
      setSelectedFiles,
      selectedFolders,
      excludedSubfolders,
      fileStructure,
    ],
  );

  return (
    <div className="h-full flex flex-col">
      <div className="shrink-0">
        {showTitle && (
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium">1. Select Files</h3>
            <div className="flex items-center gap-3">
              <div className="text-sm text-gray-600 flex items-center gap-2">
                {isAllSelected ? (
                  <span className="font-medium text-primary">
                    {selectedFiles.length} files
                    {selectedFolders.size > 0 &&
                      `, ${selectedFolders.size} folders`}{" "}
                    selected
                  </span>
                ) : (
                  <>
                    <span>
                      <span className="font-medium">
                        {selectedFiles.length}
                      </span>{" "}
                      files
                    </span>
                    {selectedFolders.size > 0 && (
                      <span>
                        <span className="font-medium">
                          {selectedFolders.size}
                        </span>{" "}
                        folders
                      </span>
                    )}
                  </>
                )}
              </div>

              {(selectedFiles.length > 0 ||
                selectedFolders.size > 0 ||
                isAllSelected) && (
                <button
                  onClick={handleDeselectAll}
                  className="px-3 py-1.5 text-sm bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-md flex items-center gap-1"
                >
                  <FiX size={14} />
                  Deselect All
                </button>
              )}
            </div>
          </div>
        )}

        <div className="mb-4 flex gap-2 items-center">
          <input
            type="text"
            placeholder="Search files or folders..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <button
            onClick={handleSearch}
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-md disabled:opacity-50"
            disabled={fetchingFiles}
          >
            Search
          </button>
          {selectAllForEntireDB && (
            <button
              onClick={handleSelectAll}
              className={`px-4 whitespace-nowrap py-2 border border-gray-300 rounded-md text-sm transition-all ${
                isAllSelected
                  ? "bg-primary text-white"
                  : "bg-gray-100 text-gray-800 hover:bg-gray-200"
              }`}
              disabled={files.length === 0}
            >
              {isAllSelected
                ? `Deselect All${searchTerm ? " Filtered" : ""}`
                : `Select All${searchTerm ? " Filtered" : ""}`}
            </button>
          )}
        </div>

        <div className="flex items-center mb-4 overflow-x-auto whitespace-nowrap py-1 bg-gray-50 rounded-md px-2">
          <button
            onClick={() => {
              setCurrentPath("");
              setBreadcrumbs([]);
            }}
            className="mr-2 text-gray-600 hover:text-gray-800 hover:underline px-2 py-1"
          >
            Home
          </button>

          {breadcrumbs.length > 0 && (
            <span className="mx-1 text-gray-400">/</span>
          )}

          {breadcrumbs.map((crumb, index) => (
            <div key={index} className="flex items-center">
              <button
                onClick={() => navigateToBreadcrumb(index)}
                className="mx-1 text-gray-600 hover:text-gray-800 hover:underline px-2 py-1"
              >
                {crumb}
              </button>
              {index < breadcrumbs.length - 1 && (
                <span className="mx-1 text-gray-400">/</span>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="flex-1 min-h-0 border rounded-md bg-white overflow-hidden">
        {files.length === 0 ? (
          fetchingFiles ? (
            <div className="flex items-center justify-center h-32">
              <span className="loading loading-spinner loading-md"></span>
              <span className="ml-2 text-gray-600">Loading files...</span>
            </div>
          ) : (
            <div className="flex items-center justify-center h-32 text-gray-500">
              No files available
            </div>
          )
        ) : (
          <div className="h-full flex flex-col">
            <div className="mx-4 mt-2 mb-1 flex items-center justify-between text-sm border-b pb-2">
              <div className="flex items-center gap-2">
                {currentPath && (
                  <button
                    onClick={navigateUp}
                    className="px-2 py-1 text-left flex items-center gap-1 text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded"
                  >
                    <FiChevronLeft size={16} /> Back
                  </button>
                )}
              </div>

              <div className="flex items-center gap-3">
                {isAllSelected ? (
                  <span className="font-medium text-primary">
                    {selectedFiles.length} files
                    {selectedFolders.size > 0 &&
                      `, ${selectedFolders.size} folders`}{" "}
                    selected
                  </span>
                ) : (
                  <span className="text-gray-500">
                    {selectedFiles.length > 0 || selectedFolders.size > 0 ? (
                      <span>
                        Selected:{" "}
                        <span className="font-medium">
                          {selectedFiles.length}
                        </span>{" "}
                        files
                        {selectedFolders.size > 0 && (
                          <>
                            ,{" "}
                            <span className="font-medium">
                              {selectedFolders.size}
                            </span>{" "}
                            folders
                          </>
                        )}
                      </span>
                    ) : (
                      <span>No items selected</span>
                    )}
                  </span>
                )}

                {(selectedFiles.length > 0 ||
                  selectedFolders.size > 0 ||
                  isAllSelected) && (
                  <button
                    onClick={handleDeselectAll}
                    className="ml-2 p-1 text-gray-500 hover:text-red-500 hover:bg-gray-100 rounded-full"
                    title="Deselect all"
                  >
                    <FiTrash2 size={14} />
                  </button>
                )}
              </div>
            </div>

            <div className="flex-1 overflow-y-auto">
              <div className="divide-y divide-gray-100">
                {searchTerm &&
                matchingFolderPaths.length === 0 &&
                matchingFileObjects.length === 0 ? (
                  <div className="px-4 py-6 text-center text-gray-500">
                    No matching files or folders found
                  </div>
                ) : searchTerm ? (
                  // Global search results view
                  <>
                    {matchingFolderPaths.map((folderPath) => {
                      const parts = folderPath.split("/");
                      const folderName = parts[parts.length - 1];
                      const parentPath = parts.slice(0, -1).join("/");
                      const isDirectlySelected =
                        selectedFolders.has(folderPath);
                      const isIndirectlySelected =
                        isInsideSelectedFolder(folderPath);
                      const isAllFilesSelected = selectedFiles === null;
                      const isSelected =
                        isDirectlySelected ||
                        isIndirectlySelected ||
                        isAllFilesSelected;

                      return (
                        <div
                          key={folderPath}
                          className={`px-4 py-3 hover:bg-gray-50 flex justify-between items-center transition-colors ${
                            isSelected ? "bg-primary/5" : ""
                          }`}
                        >
                          <div className="flex items-center cursor-pointer flex-grow">
                            <div
                              className="flex items-center"
                              onClick={() => {
                                navigateToFileParent(parentPath);
                                navigateToFolder(folderName);
                              }}
                            >
                              <FiFolder
                                className={`mr-3 ${isSelected ? "text-primary" : "text-gray-400"}`}
                                size={18}
                              />
                              <div className="flex flex-col">
                                <span
                                  className={`text-sm font-medium ${isSelected ? "text-primary" : "text-gray-700"}`}
                                >
                                  {folderName}
                                </span>
                                {parentPath && (
                                  <span className="text-xs text-gray-500">
                                    in {parentPath}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center gap-2">
                            {isDirectlySelected || selectedFiles === null ? (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSearchResultFolderSelect(folderPath);
                                }}
                                className="ml-2 p-1.5 rounded-md text-sm flex items-center gap-1 transition-colors bg-primary/10 text-primary hover:bg-red-100 hover:text-red-600"
                                title={
                                  selectedFiles === null
                                    ? "Exclude this folder from selection"
                                    : "Deselect folder"
                                }
                              >
                                <FiX size={14} />{" "}
                                {selectedFiles === null
                                  ? "Exclude"
                                  : "Deselect"}
                              </button>
                            ) : isIndirectlySelected ? (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSearchResultFolderSelect(folderPath);
                                }}
                                className="ml-2 p-1.5 rounded-md text-sm flex items-center gap-1 transition-colors bg-primary/5 text-primary/80 hover:bg-red-100 hover:text-red-600"
                                title="Exclude this subfolder"
                              >
                                {excludedSubfolders.has(folderPath) ? (
                                  <>
                                    <FiFolderPlus size={14} /> Include
                                  </>
                                ) : (
                                  <>
                                    <FiX size={14} /> Exclude
                                  </>
                                )}
                              </button>
                            ) : (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSearchResultFolderSelect(folderPath);
                                }}
                                className="ml-2 p-1.5 rounded-md text-sm flex items-center gap-1 transition-colors bg-gray-100 text-gray-600 hover:bg-gray-200"
                                title="Select folder"
                              >
                                <FiFolderPlus size={14} /> Select
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}

                    {matchingFileObjects.map((file) => {
                      const isExplicitlySelected = selectedFiles?.includes(
                        file.path,
                      );
                      const isExplicitlyDeselected =
                        selectedFiles !== null &&
                        !selectedFiles.includes(file.path);
                      const isInSelectedFolder = isInsideSelectedFolder(
                        file.path,
                      );
                      let isSelected = false;

                      if (selectedFiles === null) {
                        isSelected = true;
                      } else if (isExplicitlySelected) {
                        isSelected = true;
                      } else if (
                        isInSelectedFolder &&
                        !isExplicitlyDeselected
                      ) {
                        isSelected = true;
                      }

                      if (selectAllVisualOverride) {
                        isSelected = true;
                      }

                      return (
                        <div
                          key={file.path}
                          className={`px-4 py-3 hover:bg-gray-50 transition-colors cursor-pointer ${
                            isSelected ? "bg-primary/5" : ""
                          }`}
                          onClick={() => handleFileSelect(file.path)}
                        >
                          <div
                            className="flex items-center justify-between"
                            title={file.path}
                          >
                            <div className="flex items-center flex-grow">
                              <FiFile
                                className={`mr-3 ${isSelected ? "text-primary" : "text-gray-400"}`}
                                size={18}
                              />
                              <div className="flex flex-col">
                                <span
                                  className={`text-sm font-medium ${isSelected ? "text-primary" : "text-gray-700"}`}
                                >
                                  {file.name}
                                </span>
                                {file.parentPath && (
                                  <span className="text-xs text-gray-500">
                                    in {file.parentPath}
                                  </span>
                                )}
                              </div>
                            </div>

                            {isSelected ? (
                              <div
                                className="flex-shrink-0 ml-2 p-1 bg-primary/10 rounded-full text-primary hover:bg-red-100 hover:text-red-600 transition-colors"
                                title="Click to deselect"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFileSelect(file.path);
                                }}
                              >
                                <FiX size={14} />
                              </div>
                            ) : (
                              <div
                                className="flex-shrink-0 ml-2 p-1 bg-gray-100 rounded-full text-gray-500 hover:bg-gray-200 transition-colors"
                                title="Click to select"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFileSelect(file.path);
                                }}
                              >
                                <FiCheck size={14} />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  // Regular directory view (no search term)
                  <>
                    {folders.map((folder) => {
                      const folderPath = currentPath
                        ? `${currentPath}/${folder}`
                        : folder;
                      const isDirectlySelected =
                        selectedFolders.has(folderPath);
                      const isIndirectlySelected =
                        isInsideSelectedFolder(folderPath);
                      const isAllFilesSelected = selectedFiles === null;
                      const isSelected =
                        isDirectlySelected ||
                        isIndirectlySelected ||
                        isAllFilesSelected;

                      return (
                        <div
                          key={folder}
                          className={`px-4 py-3 hover:bg-gray-50 flex justify-between items-center transition-colors ${
                            isSelected ? "bg-primary/5" : ""
                          }`}
                        >
                          <div
                            className="flex items-center cursor-pointer flex-grow"
                            onClick={() => navigateToFolder(folder)}
                          >
                            <FiFolder
                              className={`mr-3 ${isSelected ? "text-primary" : "text-gray-400"}`}
                              size={18}
                            />
                            <span
                              className={`text-sm font-medium truncate ${isSelected ? "text-primary" : "text-gray-700"}`}
                            >
                              {folder}
                            </span>
                          </div>

                          <div className="flex items-center gap-2">
                            {isDirectlySelected || selectedFiles === null ? (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFolderSelect(folder);
                                }}
                                className="ml-2 p-1.5 rounded-md text-sm flex items-center gap-1 transition-colors bg-primary/10 text-primary hover:bg-red-100 hover:text-red-600"
                                title={
                                  selectedFiles === null
                                    ? "Exclude this folder from selection"
                                    : "Deselect folder"
                                }
                              >
                                <FiX size={14} />{" "}
                                {selectedFiles === null
                                  ? "Exclude"
                                  : "Deselect"}
                              </button>
                            ) : isIndirectlySelected ? (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFolderSelect(folder);
                                }}
                                className="ml-2 p-1.5 rounded-md text-sm flex items-center gap-1 transition-colors bg-primary/5 text-primary/80 hover:bg-red-100 hover:text-red-600"
                                title="Exclude this subfolder"
                              >
                                {excludedSubfolders.has(folderPath) ? (
                                  <>
                                    <FiFolderPlus size={14} /> Include
                                  </>
                                ) : (
                                  <>
                                    <FiX size={14} /> Exclude
                                  </>
                                )}
                              </button>
                            ) : (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFolderSelect(folder);
                                }}
                                className="ml-2 p-1.5 rounded-md text-sm flex items-center gap-1 transition-colors bg-gray-100 text-gray-600 hover:bg-gray-200"
                                title="Select folder"
                              >
                                <FiFolderPlus size={14} /> Select
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}

                    {filteredFiles.map((file) => {
                      const isExplicitlySelected = selectedFiles?.includes(
                        file.path,
                      );
                      const isExplicitlyDeselected =
                        selectedFiles !== null &&
                        !selectedFiles.includes(file.path);

                      const isInSelectedFolder = isInsideSelectedFolder(
                        file.path,
                      );

                      let isSelected = false;

                      if (selectedFiles === null) {
                        isSelected = true;
                      } else if (isExplicitlySelected) {
                        isSelected = true;
                      } else if (
                        isInSelectedFolder &&
                        !isExplicitlyDeselected
                      ) {
                        isSelected = true;
                      }

                      if (selectAllVisualOverride) {
                        isSelected = true;
                      }

                      return (
                        <div
                          key={file.path}
                          className={`px-4 py-3 hover:bg-gray-50 transition-colors cursor-pointer ${
                            isSelected ? "bg-primary/5" : ""
                          }`}
                          onClick={() => handleFileSelect(file.path)}
                        >
                          <div
                            className="flex items-center justify-between"
                            title={file.path}
                          >
                            <div className="flex items-center flex-grow">
                              <FiFile
                                className={`mr-3 ${isSelected ? "text-primary" : "text-gray-400"}`}
                                size={18}
                              />
                              <div className="flex flex-col">
                                <span
                                  className={`text-sm font-medium truncate ${isSelected ? "text-primary" : "text-gray-700"}`}
                                >
                                  {file.name}
                                </span>
                                <span className="text-xs text-gray-500 truncate">
                                  {file.path}
                                </span>
                              </div>
                            </div>

                            {isSelected ? (
                              <div
                                className="flex-shrink-0 ml-2 p-1 bg-primary/10 rounded-full text-primary hover:bg-red-100 hover:text-red-600 transition-colors"
                                title="Click to deselect"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFileSelect(file.path);
                                }}
                              >
                                <FiX size={14} />
                              </div>
                            ) : (
                              <div
                                className="flex-shrink-0 ml-2 p-1 bg-gray-100 rounded-full text-gray-500 hover:bg-gray-200 transition-colors"
                                title="Click to select"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFileSelect(file.path);
                                }}
                              >
                                <FiCheck size={14} />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}

                    {folders.length === 0 && filteredFiles.length === 0 && (
                      <div className="px-4 py-6 text-center text-gray-500">
                        {searchTerm
                          ? "No matching files or folders"
                          : "This folder is empty"}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileBrowser;
