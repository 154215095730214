import React, { useContext, useState, memo, useRef, useEffect } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import TagsTable from "../SavedTags/components/TagTables/TagsTable";
import TagEditor from "../SavedTags/components/TagEditor/TagEditor";
import { FaTimes } from "react-icons/fa";
import TagGroups from "./TagGroups";
import toast from "react-hot-toast";
import { SmallDataContext } from "../../../contexts/SmallDataContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { tagGroupService } from "../../../services/api";

// Quick Group Selection Component
const QuickGroupSelector = ({
  isVisible,
  onClose,
  tagGroups,
  selectedTags,
  onSelectGroup,
  onClearSelection,
  position,
}) => {
  const selectorRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <div
      ref={selectorRef}
      className="absolute z-50 bg-white rounded-lg shadow-xl border border-gray-200 w-64 overflow-hidden"
      style={{
        top: position.y + "px",
        left: position.x + "px",
        animation: "dropdownFadeIn 0.15s ease-out forwards",
      }}
    >
      <div className="p-3 bg-emerald-50 border-b border-emerald-100">
        <h3 className="font-medium text-emerald-800">
          Add {selectedTags.length} {selectedTags.length === 1 ? "tag" : "tags"}{" "}
          to:
        </h3>
      </div>

      <div className="max-h-80 overflow-y-auto">
        {tagGroups.length === 0 ? (
          <div className="p-4 text-center text-gray-500">
            <p>No groups available</p>
          </div>
        ) : (
          <div className="py-1">
            {tagGroups.map((group) => (
              <button
                key={group.group_id}
                onClick={() => onSelectGroup(group)}
                className="w-full text-left px-3 py-2.5 hover:bg-emerald-50 flex items-center gap-2 border-b border-gray-100"
              >
                <span className="w-7 h-7 flex-shrink-0 flex items-center justify-center bg-emerald-50 text-emerald-600 rounded-full border border-emerald-100">
                  {group.name.charAt(0).toUpperCase()}
                </span>
                <div className="flex-grow min-w-0">
                  <div className="font-medium text-gray-800 truncate">
                    {group.name}
                  </div>
                  <div className="text-xs text-gray-500">
                    {group.tag_ids?.length || 0}{" "}
                    {(group.tag_ids?.length || 0) === 1 ? "tag" : "tags"}
                  </div>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="p-2 border-t border-gray-200 bg-gray-50">
        <button
          onClick={() => onSelectGroup({ isNew: true })}
          className="w-full px-3 py-2 text-left text-emerald-700 hover:bg-emerald-50 rounded flex items-center gap-2 mb-1"
        >
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          Create New Group
        </button>

        <button
          onClick={() => {
            onClearSelection();
            onClose();
          }}
          className="w-full px-3 py-2 text-left text-red-600 hover:bg-red-50 rounded flex items-center gap-2"
        >
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          Remove from All Groups
        </button>
      </div>
    </div>
  );
};

// New Group Creation Modal
const NewGroupModal = ({ isOpen, onClose, selectedTags, onCreateGroup }) => {
  const [groupName, setGroupName] = useState("");

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <FaTimes />
        </button>

        <div className="mb-4 text-center">
          <h2 className="text-xl font-semibold mb-2">Create New Group</h2>
          <p className="text-sm text-gray-600">
            Add {selectedTags.length}{" "}
            {selectedTags.length === 1 ? "tag" : "tags"} to a new group
          </p>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Group Name
          </label>
          <input
            type="text"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 outline-none"
            placeholder="Enter group name"
            autoFocus
          />
        </div>

        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (groupName.trim()) {
                onCreateGroup(groupName.trim());
              }
            }}
            disabled={!groupName.trim()}
            className="px-4 py-2 bg-emerald-600 text-white rounded-md disabled:opacity-50 hover:bg-emerald-700"
          >
            Create Group
          </button>
        </div>
      </div>
    </div>
  );
};

// Move table component outside and memoize it
const MetadataLibraryTagTable = memo(
  ({ tagInEditor, setTagInEditor, setIsTagEditorCollapsed }) => {
    const { tags, loadingTags, tagGroups, refreshTagGroups } =
      useContext(SmallDataContext);
    const { deasyApiKey } = useContext(BaseContext);
    const [selectedTags, setSelectedTags] = useState([]);
    const [quickSelectorVisible, setQuickSelectorVisible] = useState(false);
    const [quickSelectorPosition, setQuickSelectorPosition] = useState({
      x: 0,
      y: 0,
    });
    const [showNewGroupModal, setShowNewGroupModal] = useState(false);

    const handleRowSelectionChange = (selectedRows) => {
      setSelectedTags(selectedRows);
    };

    const handleUpdateTagGroup = async (
      groupId,
      groupName,
      groupDescription,
      tagIds,
    ) => {
      try {
        await tagGroupService.updateTagGroup(
          deasyApiKey,
          groupId,
          groupName,
          groupDescription,
          tagIds,
        );
        // Refresh tag groups after update
        refreshTagGroups();
      } catch (error) {
        console.error("Error updating tag group:", error);
        toast.error("Failed to update tag group");
      }
    };

    const handleAddToGroup = (e) => {
      if (selectedTags.length === 0) {
        toast.error("No tags selected");
        return;
      }

      // Calculate position for the quick selector
      const buttonRect = e.currentTarget.getBoundingClientRect();
      setQuickSelectorPosition({
        x: buttonRect.left,
        y: buttonRect.bottom + window.scrollY + 5,
      });

      setQuickSelectorVisible(true);
    };

    const handleSelectGroup = async (group) => {
      if (group.isNew) {
        // Show new group creation modal
        setQuickSelectorVisible(false);
        setShowNewGroupModal(true);
      } else {
        // Add to existing group
        const updatedTagIds = [
          ...group.tag_ids,
          ...selectedTags
            .filter((tag) => !group.tag_ids.includes(tag.tag_id))
            .map((tag) => tag.tag_id),
        ];

        try {
          await tagGroupService.updateTagGroup(
            deasyApiKey,
            group.group_id,
            group.name,
            group.description,
            updatedTagIds,
          );
          toast.success(`Tags added to group "${group.name}"`);
          refreshTagGroups();
        } catch (error) {
          console.error("Error updating group:", error);
          toast.error("Failed to update group");
        }
      }

      setQuickSelectorVisible(false);
    };

    const handleCreateNewGroup = async (groupName) => {
      try {
        await tagGroupService.createTagGroup(
          deasyApiKey,
          groupName,
          "",
          selectedTags.map((tag) => tag.tag_id),
        );
        toast.success(`Tags added to new group "${groupName}"`);
        refreshTagGroups();
        setShowNewGroupModal(false);
      } catch (error) {
        console.error("Error creating group:", error);
        toast.error("Failed to create group");
      }
    };

    const handleRemoveFromAllGroups = async () => {
      if (selectedTags.length === 0) return;

      const selectedTagIds = selectedTags.map((tag) => tag.tag_id);
      let updatedCount = 0;

      try {
        // For each group, remove the selected tags
        for (const group of tagGroups) {
          // Check if this group contains any of the selected tags
          const containsSelectedTags = group.tag_ids.some((id) =>
            selectedTagIds.includes(id),
          );

          if (containsSelectedTags) {
            // Filter out the selected tags
            const updatedTagIds = group.tag_ids.filter(
              (id) => !selectedTagIds.includes(id),
            );

            // Update the group
            await tagGroupService.updateTagGroup(
              deasyApiKey,
              group.group_id,
              group.name,
              group.description,
              updatedTagIds,
            );

            updatedCount++;
          }
        }

        if (updatedCount > 0) {
          toast.success(
            `Removed tags from ${updatedCount} ${updatedCount === 1 ? "group" : "groups"}`,
          );
          refreshTagGroups();
        } else {
          toast.info("Selected tags are not in any groups");
        }
      } catch (error) {
        console.error("Error removing tags from groups:", error);
        toast.error("Failed to remove tags from groups");
      }
    };

    return (
      <div className="w-full bg-white shadow-md rounded-lg border border-gray-200">
        <div className="p-6 border-b border-gray-200 flex flex-row justify-start">
          <h2 className="text-xl font-semibold text-gray-800 text-left">
            Metadata Tags
          </h2>
        </div>

        <div className="flex-1 overflow-hidden">
          <TagsTable
            // Display options
            isLoading={loadingTags}
            // Tag data
            tags={tags}
            // Tag Editor state
            tagInEditor={tagInEditor}
            setTagInEditor={setTagInEditor}
            setIsTagEditorCollapsed={setIsTagEditorCollapsed}
            // Row selection
            rowSelectionMode={true}
            onRowSelectionChange={handleRowSelectionChange}
            onAddSelectedToGroup={handleAddToGroup}
            selectedTags={selectedTags}
            // Tag Groups
            tagGroups={tagGroups}
            onUpdateTagGroup={handleUpdateTagGroup}
          />
        </div>

        {/* Quick Group Selector */}
        <QuickGroupSelector
          isVisible={quickSelectorVisible}
          onClose={() => setQuickSelectorVisible(false)}
          tagGroups={tagGroups}
          selectedTags={selectedTags}
          onSelectGroup={handleSelectGroup}
          onClearSelection={handleRemoveFromAllGroups}
          position={quickSelectorPosition}
        />

        {/* New Group Modal */}
        <NewGroupModal
          isOpen={showNewGroupModal}
          onClose={() => setShowNewGroupModal(false)}
          selectedTags={selectedTags}
          onCreateGroup={handleCreateNewGroup}
        />

        {/* CSS for dropdown animation */}
        <style>
          {`
          @keyframes dropdownFadeIn {
            from {
              opacity: 0;
              transform: translateY(-10px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          `}
        </style>
      </div>
    );
  },
);

const MetadataLibrary = () => {
  const { deasyApiKey, setDeasyUserConfig } = useContext(BaseContext);
  const { tags } = useContext(SmallDataContext);

  const [isTagEditorCollapsed, setIsTagEditorCollapsed] = useState(true);
  const [activeTagEditorSection, setActiveTagEditorSection] =
    useState("general");
  const [tagInEditor, setTagInEditor] = useState(null);
  const [apiKeyInput, setApiKeyInput] = useState("");
  const [appliedTagsNotification, setAppliedTagsNotification] = useState(null);

  const tagGroupsRef = useRef(null);

  // Handle navigating through applied tags
  const handleNextAppliedTag = () => {
    if (!appliedTagsNotification) return;

    const nextIndex = appliedTagsNotification.currentIndex + 1;
    if (nextIndex < appliedTagsNotification.tags.length) {
      setTagInEditor(appliedTagsNotification.tags[nextIndex]);
      setAppliedTagsNotification({
        ...appliedTagsNotification,
        currentIndex: nextIndex,
      });
      toast.success(
        `Applied tag: ${appliedTagsNotification.tags[nextIndex].name} (${nextIndex + 1} of ${appliedTagsNotification.count})`,
      );
    } else {
      // All tags have been applied
      setAppliedTagsNotification(null);
      toast.success(
        `All ${appliedTagsNotification.count} tags have been applied`,
      );
    }
  };

  const MetadataLibraryTagEditor = () => {
    return (
      <div
        onClick={() => setIsTagEditorCollapsed(true)}
        className={`fixed inset-0 bg-black bg-opacity-30 z-50 transition-opacity duration-500 ease-in-out ${
          isTagEditorCollapsed ? "opacity-0 pointer-events-none" : "opacity-100"
        }`}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={`absolute right-0 top-0 h-full w-[45%] bg-white shadow-xl transition-transform duration-500 ease-in-out ${
            isTagEditorCollapsed ? "translate-x-full" : "translate-x-0"
          }`}
        >
          <button
            onClick={() => setIsTagEditorCollapsed(true)}
            className="absolute left-0 top-6 z-10 p-1.5 -translate-x-full bg-white border border-gray-200 rounded-l-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition-all duration-200"
          >
            <FaTimes />
          </button>
          <div className="h-full">
            <TagEditor
              tagInEditor={tagInEditor}
              setTagInEditor={setTagInEditor}
              isTagEditorCollapsed={isTagEditorCollapsed}
              setIsTagEditorCollapsed={setIsTagEditorCollapsed}
              activeTagEditorSection={activeTagEditorSection}
              setActiveTagEditorSection={setActiveTagEditorSection}
              editingExistingTag={
                tagInEditor && Object.keys(tagInEditor).length > 0
              }
              context="library"
              onSaveSuccess={
                appliedTagsNotification ? handleNextAppliedTag : undefined
              }
            />

            {/* Applied Tags Navigation */}
            {appliedTagsNotification && (
              <div className="absolute bottom-0 left-0 right-0 bg-primary-50 p-4 border-t border-primary-100">
                <div className="flex items-center justify-between">
                  <div>
                    <span className="text-primary-800 font-medium">
                      Applying tag {appliedTagsNotification.currentIndex + 1} of{" "}
                      {appliedTagsNotification.count}
                    </span>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => setAppliedTagsNotification(null)}
                      className="px-3 py-1 text-sm border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleNextAppliedTag}
                      className="px-3 py-1 text-sm bg-primary text-white rounded-md hover:bg-primary-600"
                    >
                      Skip to Next
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (!deasyApiKey || tags === null) {
    return (
      <div className="flex items-center justify-center overflow-hidden">
        <div className="w-full max-w-md p-8 bg-white rounded-xl shadow-sm">
          <div className="text-center mb-8">
            <div className="w-16 h-16 bg-emerald-50 rounded-full flex items-center justify-center mx-auto mb-4">
              <svg
                className="w-8 h-8 text-emerald-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </div>
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              Welcome to Metadata Tags
            </h2>
            <p className="text-gray-600 mb-4">
              Metadata tags help you organize and classify your data
              consistently. Create and manage tags that define how your content
              should be categorized.
            </p>
            <p className="text-gray-600">
              Please configure your API key to start creating and managing
              metadata tags.
            </p>
          </div>

          <form
            onSubmit={() => {
              setDeasyUserConfig((prev) => ({
                ...prev,
                deasyApiKey: apiKeyInput,
              }));
            }}
            className="space-y-4"
          >
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Deasy API Key
              </label>
              <div className="relative rounded-lg shadow-sm">
                <input
                  type="password"
                  value={apiKeyInput}
                  onChange={(e) => setApiKeyInput(e.target.value)}
                  className="block w-full pr-10 border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 transition-all duration-200"
                  placeholder="Enter your API key"
                  required
                />
                {apiKeyInput && (
                  <button
                    type="button"
                    onClick={() => setApiKeyInput("")}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  >
                    <FaTimes className="h-4 w-4 text-gray-400 hover:text-gray-600" />
                  </button>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition-colors duration-200"
            >
              Configure API Key
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="mb-4">
        <div className="flex flex-col h-full bg-gray-50 rounded-lg overflow-hidden p-6 overflow-y-auto">
          {/* Side-by-side layout */}
          <div className="flex space-x-6">
            {/* Left side: Metadata Tags */}
            <div className="w-[73%]">
              <MetadataLibraryTagTable
                // Tag Editor state
                tagInEditor={tagInEditor}
                setTagInEditor={setTagInEditor}
                setIsTagEditorCollapsed={setIsTagEditorCollapsed}
              />
            </div>

            {/* Right side: Tag Groups */}
            <div className="w-[27%]">
              <TagGroups ref={tagGroupsRef} tags={tags} />
            </div>
          </div>

          <MetadataLibraryTagEditor />
        </div>
      </div>
    </DndProvider>
  );
};

export default MetadataLibrary;
