import { useState, useMemo, useEffect, useRef } from "react";
import { FaTrashAlt, FaEllipsisV } from "react-icons/fa";
import { FiSearch, FiPlus, FiEdit, FiTag } from "react-icons/fi";
import { useDrag } from "react-dnd";
import TestTagButton from "../../../../utils/TestTagButton";
import { renderAvailableTagValues } from "./TagTableUtils";
import { DeleteModal } from "./DeleteModal";
import { ImportTagsModal } from "./ImportTags";
import { toast } from "react-hot-toast";
import { createPortal } from "react-dom";

// Add CSS for animations
const fadeInKeyframes = `
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-5px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .animate-fadeIn {
    animation: fadeIn 0.2s ease-out forwards;
  }
  
  @keyframes dropdownFadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;

// Add this to the document if it doesn't exist
if (typeof document !== "undefined") {
  const style = document.createElement("style");
  style.innerHTML = fadeInKeyframes;
  document.head.appendChild(style);
}

// Add this new component for expandable tag groups
const GroupTagsList = ({ groupsForTag }) => {
  const [expanded, setExpanded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const hoverTimeoutRef = useRef(null);
  const maxVisibleTags = 2;

  // Handle hover enter with a small delay to prevent accidental triggers
  const handleMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    hoverTimeoutRef.current = setTimeout(() => {
      setIsHovering(true);
    }, 100);
  };

  // Handle hover leave with a small delay to prevent flickering
  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    hoverTimeoutRef.current = setTimeout(() => {
      setIsHovering(false);
    }, 300);
  };

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  // Show all groups when expanded or hovering
  const shouldShowAll = expanded || isHovering;
  const visibleGroups = shouldShowAll
    ? groupsForTag
    : groupsForTag.slice(0, maxVisibleTags);
  const hasMoreGroups = !shouldShowAll && groupsForTag.length > maxVisibleTags;

  if (!groupsForTag || groupsForTag.length === 0) {
    return (
      <span className="text-sm text-gray-500 italic">No groups assigned</span>
    );
  }

  return (
    <div
      className="flex flex-wrap gap-1.5 relative"
      role="group"
      aria-label="Tag groups"
    >
      {visibleGroups.map((groupName, index) => (
        <span
          key={index}
          className={`inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-emerald-50 text-emerald-700 border border-emerald-100 shadow-sm transition-all duration-200 hover:bg-emerald-100 hover:border-emerald-200 ${
            shouldShowAll && index >= maxVisibleTags ? "animate-fadeIn" : ""
          }`}
          title={groupName}
        >
          <span className="w-1.5 h-1.5 rounded-full bg-emerald-500 mr-1.5 flex-shrink-0"></span>
          {groupName.length > 15
            ? `${groupName.substring(0, 15)}...`
            : groupName}
        </span>
      ))}

      {hasMoreGroups && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(true);
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-gray-100 text-gray-700 hover:bg-gray-200 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary"
          aria-expanded={expanded}
          aria-label={`Show ${groupsForTag.length - maxVisibleTags} more groups`}
        >
          +{groupsForTag.length - maxVisibleTags} more
        </button>
      )}

      {expanded && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(false);
          }}
          className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-gray-100 text-gray-700 hover:bg-gray-200 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary ml-1"
          aria-label="Show fewer groups"
        >
          Show less
        </button>
      )}
    </div>
  );
};

// Add this new component for the Create Group Modal
const CreateGroupModal = ({ isOpen, onClose, onCreateGroup }) => {
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!groupName.trim()) {
      toast.error("Group name is required");
      return;
    }

    onCreateGroup(groupName, groupDescription);
    setGroupName("");
    setGroupDescription("");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <form onSubmit={handleSubmit}>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                  <FiTag className="h-6 w-6 text-primary" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    Create New Tag Group
                  </h3>
                  <div className="mt-4 space-y-4">
                    <div>
                      <label
                        htmlFor="group-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Group Name *
                      </label>
                      <input
                        type="text"
                        id="group-name"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                        placeholder="Enter group name"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="group-description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Description (Optional)
                      </label>
                      <textarea
                        id="group-description"
                        value={groupDescription}
                        onChange={(e) => setGroupDescription(e.target.value)}
                        rows="3"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                        placeholder="Enter group description"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm"
              >
                Create Group
              </button>
              <button
                type="button"
                onClick={onClose}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// Draggable tag component
const DraggableTag = ({
  tag,
  children,
  onClick,
  showCheckbox = false,
  isSelected = false,
  onCheckboxClick = null,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "TAG",
    item: { tag },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    // End drag callback to prevent state reset issues
    end: (item, monitor) => {
      // The drop was handled by the drop target, we don't need to do anything here
      // This ensures the component doesn't re-render unnecessarily
    },
  }));

  return (
    <tr
      ref={drag}
      key={tag.tag_id || tag.name}
      className={`cursor-grab transition-all duration-200 ${
        isDragging ? "opacity-50 bg-gray-100" : "hover:bg-gray-50"
      }`}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={onClick}
    >
      {showCheckbox && (
        <td className="px-3 py-3 whitespace-nowrap text-left w-10">
          <div className="flex items-center justify-center">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={(e) => {
                e.stopPropagation();
                if (onCheckboxClick) onCheckboxClick(e, tag);
              }}
              onClick={(e) => e.stopPropagation()} // Prevent row click when clicking checkbox
              className="form-checkbox h-5 w-5 text-primary-600 border-2 border-gray-300 rounded-sm transition duration-200 ease-in-out 
              hover:border-primary-400 
              checked:bg-primary-600 checked:border-transparent cursor-pointer"
            />
          </div>
        </td>
      )}
      {children}
    </tr>
  );
};

const TagsTable = ({
  // Display options
  forceExpanded = false,
  compressedTagTable = false,
  isLoading = false,
  hideNewTagButton = false,

  // Tag data
  tags = [],

  // Tag editor state
  tagInEditor,
  setTagInEditor,
  setIsTagEditorCollapsed = null,
  setIsTagSelectorExpanded = null,

  // Row selection
  rowSelectionMode = false,
  onRowSelectionChange = null,
  onAddSelectedToGroup = null,
  selectedTags = [],

  // Tag Groups
  tagGroups = [],
  onUpdateTagGroup = null,
}) => {
  // Local state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [showImportModal, setShowImportModal] = useState(false);
  const [activeGroupDropdown, setActiveGroupDropdown] = useState(null);
  const [newTagDropdownVisible, setNewTagDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const groupDropdownRef = useRef(null);
  const newTagDropdownRef = useRef(null);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [groupSearchTerm, setGroupSearchTerm] = useState("");
  const [dropdownRect, setDropdownRect] = useState({ top: 0, left: 0 });

  // Track whether all items are selected
  const [allSelected, setAllSelected] = useState(false);

  // Determine if table should be compressed
  const tagTableCompressed = !forceExpanded && compressedTagTable;

  // Determine if checkboxes should be shown
  const showCheckboxes =
    setIsTagSelectorExpanded === false ||
    (rowSelectionMode && setIsTagSelectorExpanded === null);

  // Set up columns based on display mode and checkbox visibility
  const columns = tagTableCompressed
    ? showCheckboxes
      ? ["Select"]
      : []
    : showCheckboxes
      ? ["Select", "Name", "Description", "Groups", "Output Values", "Actions"]
      : ["Name", "Description", "Groups", "Output Values", "Actions"];

  // Filter unique tags by name
  const uniqueTags = useMemo(() => {
    const uniqueTagMap = new Map();
    tags.forEach((tag) => {
      if (!uniqueTagMap.has(tag.name)) {
        uniqueTagMap.set(tag.name, tag);
      }
    });
    return Array.from(uniqueTagMap.values());
  }, [tags]);

  // Filter tags by search term
  const filteredTags = useMemo(() => {
    if (!uniqueTags || uniqueTags.length === 0) {
      return [];
    }

    return uniqueTags
      .filter(
        (tag) =>
          tag?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tag?.description?.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [uniqueTags, searchTerm]);

  // Handle selecting/deselecting all tags
  const handleSelectAll = (e) => {
    if (onRowSelectionChange) {
      if (e.target.checked) {
        // Select all filtered tags
        onRowSelectionChange(filteredTags);
        setAllSelected(true);
      } else {
        // Deselect all tags
        onRowSelectionChange([]);
        setAllSelected(false);
      }
    }
  };

  // Update allSelected state when selectedTags changes
  useEffect(() => {
    if (
      filteredTags.length > 0 &&
      selectedTags.length === filteredTags.length
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedTags, filteredTags]);

  const totalPages = Math.ceil(filteredTags.length / rowsPerPage);

  // Handle checkbox click for row selection
  const handleCheckboxClick = (e, tag) => {
    if (onRowSelectionChange) {
      const isTagSelected = selectedTags.some((t) => t.tag_id === tag.tag_id);
      const updatedTags = isTagSelected
        ? selectedTags.filter((t) => t.tag_id !== tag.tag_id)
        : [...selectedTags, tag];
      onRowSelectionChange(updatedTags);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    if (activeDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeDropdown]);

  // Calculate dropdown position when it's opened
  useEffect(() => {
    if (activeDropdown) {
      const buttonElement = document.querySelector(
        `[data-tag-id="${activeDropdown}"]`,
      );
      if (buttonElement) {
        const rect = buttonElement.getBoundingClientRect();

        // Position dropdown directly above the button, aligned to the right
        setDropdownRect({
          top: rect.top + window.scrollY, // Align with top of button
          left: rect.right - 120, // Right-align the dropdown with the button (dropdown width is 128px)
        });
      }
    }
  }, [activeDropdown]);

  // Add this function to handle creating a new group
  const handleCreateGroup = (name, description) => {
    // Generate a unique ID for the new group
    const newGroupId = `group_${Date.now()}`;

    if (onUpdateTagGroup) {
      onUpdateTagGroup(newGroupId, name, description, []);
      toast.success(`Group "${name}" created successfully`);
    }
  };

  // Handle edit tag
  const handleEditTag = (tag) => {
    setTagInEditor(tag);
    if (setIsTagEditorCollapsed) {
      setIsTagEditorCollapsed(false);
    }
    setActiveDropdown(null);
  };

  // Handle row click
  const handleRowClick = (tag) => {
    setTagInEditor(tag);
    if (setIsTagSelectorExpanded !== null) {
      setIsTagSelectorExpanded(false);
    }
  };

  // Handle changing a tag's group
  const handleChangeTagGroup = (tag, groupId, isNoGroup = false) => {
    if (!onUpdateTagGroup) return;

    if (isNoGroup) {
      // If "No Group" is selected, remove the tag from all groups and close the dropdown
      const currentGroups = tagGroups.filter((group) =>
        group.tag_ids.includes(tag.tag_id),
      );

      currentGroups.forEach((group) => {
        const updatedTagIds = group.tag_ids.filter((id) => id !== tag.tag_id);
        onUpdateTagGroup(
          group.group_id,
          group.name,
          group.description,
          updatedTagIds,
        );
      });

      // Close the dropdown when "No Group" is selected
      setActiveGroupDropdown(null);
      toast.success(`Tag "${tag.name}" removed from all groups`);
      return;
    }

    // For multi-select behavior with regular groups
    if (groupId) {
      const selectedGroup = tagGroups.find((g) => g.group_id === groupId);
      if (selectedGroup) {
        // Check if tag is already in this group
        const isTagInGroup = selectedGroup.tag_ids.includes(tag.tag_id);

        if (isTagInGroup) {
          // If tag is already in this group, remove it (toggle behavior)
          const updatedTagIds = selectedGroup.tag_ids.filter(
            (id) => id !== tag.tag_id,
          );
          onUpdateTagGroup(
            selectedGroup.group_id,
            selectedGroup.name,
            selectedGroup.description,
            updatedTagIds,
          );
          toast.success(
            `Tag "${tag.name}" removed from "${selectedGroup.name}"`,
          );
        } else {
          // If tag is not in this group, add it
          const updatedTagIds = [...selectedGroup.tag_ids, tag.tag_id];
          onUpdateTagGroup(
            selectedGroup.group_id,
            selectedGroup.name,
            selectedGroup.description,
            updatedTagIds,
          );
          toast.success(`Tag "${tag.name}" added to "${selectedGroup.name}"`);
        }

        // Don't close the dropdown to allow for more selections
      }
    }
  };

  // Filter groups based on search term
  const filteredGroups = useMemo(() => {
    if (!tagGroups || tagGroups.length === 0) {
      return [];
    }

    if (!groupSearchTerm) {
      return tagGroups;
    }

    return tagGroups.filter((group) =>
      group.name.toLowerCase().includes(groupSearchTerm.toLowerCase()),
    );
  }, [tagGroups, groupSearchTerm]);

  // Close new tag dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        newTagDropdownRef.current &&
        !newTagDropdownRef.current.contains(event.target)
      ) {
        setNewTagDropdownVisible(false);
      }
    };

    if (newTagDropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [newTagDropdownVisible]);

  // Function to render dropdown in portal
  const renderDropdownPortal = (tag) => {
    if (activeDropdown !== (tag.tag_id || tag.name)) {
      return null;
    }

    return createPortal(
      <>
        {/* Backdrop for closing dropdown */}
        <div
          className="fixed inset-0 z-[9998]"
          onClick={(e) => {
            e.stopPropagation();
            setActiveDropdown(null);
          }}
        />

        {/* Dropdown menu */}
        <div
          ref={dropdownRef}
          className="fixed shadow-lg rounded-md bg-white ring-1 ring-black ring-opacity-5 z-[9999] w-32 overflow-hidden"
          style={{
            top: `${dropdownRect.top}px`,
            left: `${dropdownRect.left}px`,
            opacity: 0,
            animation: "menu-appear 0.15s ease-out forwards",
          }}
          role="menu"
          aria-orientation="vertical"
        >
          {/* Edit option */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleEditTag(tag);
            }}
            className="w-full text-center justify-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-1.5"
            role="menuitem"
          >
            <FiEdit className="w-4 h-4" />
            <span>Edit</span>
          </button>

          {/* Test tag option */}
          <TestTagButton
            tagId={tag.tag_id || tag.name}
            className="flex flex-row w-full text-center justify-center px-3 py-2 text-sm hover:bg-primary-50 items-center gap-1.5 border-none rounded-none"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />

          {/* Delete option */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              setSelectedTag(tag);
              setShowDeleteModal(true);
              setActiveDropdown(null);
            }}
            className="w-full text-center justify-center px-3 py-2 text-sm text-red-600 hover:bg-red-50 flex items-center gap-1.5"
            role="menuitem"
          >
            <FaTrashAlt className="w-4 h-4" />
            <span>Delete</span>
          </button>
        </div>
      </>,
      document.body,
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-md flex flex-col h-full">
      {/* Modals */}
      {showDeleteModal && (
        <DeleteModal
          tagName={selectedTag?.name}
          tagInEditor={tagInEditor}
          setTagInEditor={setTagInEditor}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
      {showImportModal && (
        <ImportTagsModal
          isOpen={showImportModal}
          onClose={() => setShowImportModal(false)}
        />
      )}
      {showCreateGroupModal && (
        <CreateGroupModal
          isOpen={showCreateGroupModal}
          onClose={() => setShowCreateGroupModal(false)}
          onCreateGroup={handleCreateGroup}
        />
      )}

      {/* Header with search and actions */}
      <div className="flex-shrink-0">
        <div className="p-4 flex items-center border-b border-gray-200 sticky top-0 bg-white z-10">
          <div className="flex justify-between items-center w-full">
            {/* Search and selection info */}
            <div className="flex items-center gap-3">
              <div className="relative">
                <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search tags..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-[200px] focus:w-[400px] pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all duration-300"
                />
              </div>
            </div>

            {/* Action buttons */}
            <div className="flex items-center gap-3">
              {!hideNewTagButton && (
                <button
                  onClick={() => {
                    setTagInEditor({});
                    setIsTagEditorCollapsed(false);
                  }}
                  className="inline-flex items-center gap-1.5 px-3 py-2 bg-primary text-white text-sm font-medium rounded-md hover:bg-primary-600 transition-all duration-200 shadow-md border border-primary"
                >
                  <FiPlus className="w-4 h-4" />
                  New Tag
                </button>
              )}
              <button
                onClick={() => setShowImportModal(true)}
                className="inline-flex items-center gap-1.5 px-3 py-2 bg-gray-100 border border-gray-300 text-gray-700 text-sm font-medium rounded-md hover:bg-gray-200 transition-all duration-200 shadow-md"
              >
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
                  />
                </svg>
                Import Tags
              </button>
              {onAddSelectedToGroup && (
                <button
                  onClick={(e) => {
                    if (selectedTags.length > 0) {
                      onAddSelectedToGroup(e);
                    } else {
                      toast.error("No tags selected");
                    }
                  }}
                  disabled={selectedTags.length === 0}
                  className={`inline-flex items-center gap-1.5 px-4 py-2.5 text-sm font-medium rounded-md transition-all duration-200 shadow-md min-w-[160px] ${
                    selectedTags.length === 0
                      ? "bg-gray-100 text-gray-400 opacity-50 cursor-not-allowed"
                      : "bg-gray-400 text-white hover:bg-gray-900"
                  }`}
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  <span className="flex-1 flex items-center">
                    Manage Groups
                    {selectedTags.length > 0 && (
                      <span className="ml-1 bg-white bg-opacity-20 text-white px-1.5 py-0.5 rounded-full text-xs">
                        {selectedTags.length}
                      </span>
                    )}
                  </span>
                  <svg
                    className="w-3 h-3"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="flex-1 overflow-auto">
        <table className="min-w-full divide-y divide-gray-200">
          {/* Table header */}
          <thead className="bg-gray-50 sticky top-0 z-10">
            <tr>
              {columns.map((header) => (
                <th
                  key={header}
                  className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 ${
                    ["Name", "Output Values"].includes(header)
                      ? "max-w-[25%] min-w-[15%] w-fit"
                      : header === "Select"
                        ? "w-10"
                        : ""
                  }`}
                >
                  {header === "Actions" ? (
                    ""
                  ) : header === "Select" ? (
                    setIsTagSelectorExpanded === null ? (
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          checked={allSelected}
                          onChange={handleSelectAll}
                          className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                          aria-label="Select all tags"
                        />
                      </div>
                    ) : (
                      <span className="sr-only">Selection</span>
                    )
                  ) : (
                    header
                  )}
                </th>
              ))}
            </tr>
          </thead>

          {/* Table body */}
          <tbody className="bg-white divide-y divide-gray-200">
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="px-6 py-12">
                  <div className="flex flex-col items-center justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mb-2"></div>
                    <span className="text-gray-500">Loading tags...</span>
                  </div>
                </td>
              </tr>
            ) : !Array.isArray(tags) ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="px-6 py-12 text-center text-gray-500"
                >
                  Error loading tags
                </td>
              </tr>
            ) : filteredTags.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="px-6 py-12 text-center text-gray-500"
                >
                  No tags found
                </td>
              </tr>
            ) : (
              filteredTags
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((tag) => {
                  const groupsForTag = tagGroups
                    .filter((group) =>
                      group.tag_ids.some((t) => t === tag.tag_id),
                    )
                    .map((group) => group.name);

                  return (
                    <DraggableTag
                      key={tag.tag_id || tag.name}
                      tag={tag}
                      onClick={() => handleRowClick(tag)}
                      showCheckbox={showCheckboxes}
                      isSelected={selectedTags.some(
                        (t) => t.tag_id === tag.tag_id,
                      )}
                      onCheckboxClick={handleCheckboxClick}
                    >
                      {/* Name column */}
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="flex items-center group">
                          <span className="text-sm font-medium text-gray-900">
                            {tag.name}
                          </span>
                          <span className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity">
                            <button
                              className="text-gray-400 hover:text-primary-600 focus:outline-none focus:ring-2 focus:ring-primary rounded p-1"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditTag(tag);
                              }}
                              aria-label={`Edit ${tag.name}`}
                            >
                              <FiEdit className="w-3.5 h-3.5" />
                            </button>
                          </span>
                        </div>
                      </td>

                      {/* Additional columns for expanded view */}
                      {!tagTableCompressed && (
                        <>
                          <td className="px-3 py-1 text-left">
                            <span className="text-sm text-gray-700">
                              {tag.description || (
                                <span className="text-gray-400 italic">
                                  No description
                                </span>
                              )}
                            </span>
                          </td>
                          <td className="px-3 py-1 text-left">
                            <div
                              className="relative group-dropdown"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div
                                className="flex flex-col align-left flex-wrap gap-1.5 py-2 cursor-pointer hover:bg-gray-50 rounded-md px-3 border border-gray-100 hover:border-gray-300 hover:shadow-sm transition-all duration-200"
                                onClick={() =>
                                  setActiveGroupDropdown(
                                    activeGroupDropdown === tag.tag_id
                                      ? null
                                      : tag.tag_id,
                                  )
                                }
                                aria-expanded={
                                  activeGroupDropdown === tag.tag_id
                                }
                                aria-haspopup="true"
                                role="button"
                                tabIndex="0"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter" || e.key === " ") {
                                    e.preventDefault();
                                    setActiveGroupDropdown(
                                      activeGroupDropdown === tag.tag_id
                                        ? null
                                        : tag.tag_id,
                                    );
                                  }
                                }}
                              >
                                <GroupTagsList groupsForTag={groupsForTag} />
                                {onUpdateTagGroup && (
                                  <span className="text-xs w-full bg-gray-100 my-2 text-gray-600 px-2 py-1 justify-center rounded mt-2 self-start flex items-center hover:bg-gray-200 transition-colors">
                                    <svg
                                      className="w-3 h-3 mr-1.5"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      aria-hidden="true"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 9l-7 7-7-7"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>

                              {/* Group dropdown menu */}
                              {activeGroupDropdown === tag.tag_id &&
                                onUpdateTagGroup && (
                                  <div
                                    ref={groupDropdownRef}
                                    className="absolute left-0 mt-1 w-64 bg-white rounded-lg shadow-xl z-20 border border-gray-200 overflow-hidden"
                                    style={{
                                      animation:
                                        "dropdownFadeIn 0.15s ease-out forwards",
                                    }}
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby={`group-dropdown-${tag.tag_id}`}
                                  >
                                    <div className="divide-y divide-gray-100">
                                      <div className="px-4 py-3 text-sm font-medium text-gray-700 bg-gray-50 border-b border-gray-100 flex items-center justify-between">
                                        <span>
                                          Select Group for "{tag.name}"
                                        </span>
                                        <button
                                          onClick={() =>
                                            setActiveGroupDropdown(null)
                                          }
                                          className="text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary rounded-full p-1"
                                          aria-label="Close dropdown"
                                        >
                                          <svg
                                            className="w-4 h-4"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M6 18L18 6M6 6l12 12"
                                            />
                                          </svg>
                                        </button>
                                      </div>

                                      {/* Add search input for groups */}
                                      <div className="px-3 py-2 bg-white sticky top-0">
                                        <div className="relative">
                                          <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                                          <input
                                            type="text"
                                            placeholder="Search groups..."
                                            value={groupSearchTerm}
                                            onChange={(e) =>
                                              setGroupSearchTerm(e.target.value)
                                            }
                                            className="w-full pl-9 pr-3 py-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary transition-shadow"
                                            aria-label="Search groups"
                                          />
                                        </div>
                                      </div>

                                      <div className="max-h-60 overflow-y-auto py-1">
                                        {/* No group option */}
                                        <button
                                          onClick={() =>
                                            handleChangeTagGroup(
                                              tag,
                                              null,
                                              true,
                                            )
                                          }
                                          className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none transition-colors flex items-center"
                                          role="menuitem"
                                        >
                                          <span className="mr-3 w-4 flex justify-center">
                                            {!groupsForTag ||
                                            groupsForTag.length === 0
                                              ? "✓"
                                              : ""}
                                          </span>
                                          No Group
                                        </button>

                                        {/* Group options - now filtered by search */}
                                        {filteredGroups.map((group) => (
                                          <button
                                            key={group.group_id}
                                            onClick={() =>
                                              handleChangeTagGroup(
                                                tag,
                                                group.group_id,
                                                false,
                                              )
                                            }
                                            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none transition-colors flex items-center"
                                          >
                                            <span className="mr-3 w-4 flex justify-center">
                                              {groupsForTag.includes(group.name)
                                                ? "✓"
                                                : ""}
                                            </span>
                                            {group.name}
                                          </button>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </td>
                          <td className="px-6 pr-0 py-1 text-left">
                            {renderAvailableTagValues(tag)}
                          </td>
                          <td className="">
                            <div className="relative">
                              {/* Action dropdown toggle */}
                              <button
                                data-tag-id={tag.tag_id || tag.name}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent row click from triggering
                                  const tagId = tag.tag_id || tag.name;
                                  setActiveDropdown(
                                    activeDropdown === tagId ? null : tagId,
                                  );
                                }}
                                className="p-2 rounded-md hover:bg-gray-100 transition-colors"
                                title="More options"
                                aria-haspopup="true"
                                aria-expanded={
                                  activeDropdown === (tag.tag_id || tag.name)
                                }
                              >
                                <FaEllipsisV className="w-4 h-4 text-gray-500" />
                              </button>

                              {/* Render dropdown through portal */}
                              {renderDropdownPortal(tag)}
                            </div>
                          </td>
                        </>
                      )}
                    </DraggableTag>
                  );
                })
            )}
          </tbody>
        </table>
      </div>

      {/* CSS for dropdown animation */}
      <style>
        {`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(-5px); }
          to { opacity: 1; transform: translateY(0); }
        }
        
        .animate-fadeIn {
          animation: fadeIn 0.2s ease-out forwards;
        }
        
        @keyframes dropdownFadeIn {
          from { opacity: 0; transform: translateY(-10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        
        @keyframes menu-appear {
          from { opacity: 0; transform: translateY(-8px); }
          to { opacity: 1; transform: translateY(0); }
        }
        `}
      </style>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex-shrink-0 bg-white px-4 py-2 flex items-center justify-between border-t border-gray-200 rounded-b-lg">
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-700">Rows per page:</span>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setPage(0);
              }}
              className="border border-gray-300 rounded-md text-sm p-1"
            >
              {[50, 100, 200, 500].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setPage(Math.max(0, page - 1))}
              disabled={page === 0}
              className="px-3 py-2 border border-gray-300 rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Previous
            </button>
            <span className="text-sm text-gray-700">
              Page {page + 1} of {Math.max(1, totalPages)}
            </span>
            <button
              onClick={() => setPage(Math.min(totalPages - 1, page + 1))}
              disabled={page >= totalPages - 1}
              className="px-3 py-2 border border-gray-300 rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TagsTable;
