import React, { useState, useEffect, useContext } from "react";
import { TagDistributionChart } from "../DataSliceUtils";
import { Star, RefreshCw } from "lucide-react";
import GraphSelector from "../../DataDiscovery/Graph/GraphSelector";
import { GraphContext } from "../../../../../../contexts/GraphContext";
import { extractTagsFromSchema } from "../../MetadataTable/metadataTableUtils";

export default function DataSliceMetadata({
  metricsData,
  isLoadingMetrics,
  onTagsSelected,
  recommendedTags = [],
  setRecommendedTags,
  isSuggesting = false,
  onGenerateRecommendations,
}) {
  const [selectedTags, setSelectedTags] = useState([]);
  const [showRecommendedChips, setShowRecommendedChips] = useState(false);
  const { selectedDiscoveryGraph } = useContext(GraphContext);
  const [previousGraphId, setPreviousGraphId] = useState(null);
  const [tagsManuallyCleared, setTagsManuallyCleared] = useState(false);

  useEffect(() => {
    if (recommendedTags.length > 0) {
      setSelectedTags(recommendedTags);
      onTagsSelected?.(recommendedTags);
      setShowRecommendedChips(true);
      setTagsManuallyCleared(false);
    }
  }, [recommendedTags, onTagsSelected]);

  useEffect(() => {
    if (selectedDiscoveryGraph?.graph_id !== previousGraphId) {
      setTagsManuallyCleared(false);
    }
  }, [selectedDiscoveryGraph?.graph_id, previousGraphId]);

  useEffect(() => {
    if (
      selectedDiscoveryGraph &&
      selectedDiscoveryGraph.graph_id !== null &&
      metricsData?.metadata_tag_counts_distribution &&
      !tagsManuallyCleared
    ) {
      const graphChanged = previousGraphId !== selectedDiscoveryGraph.graph_id;
      setPreviousGraphId(selectedDiscoveryGraph.graph_id);

      const availableTags = Object.keys(
        metricsData.metadata_tag_counts_distribution,
      );

      const schemaTags = extractTagsFromSchema(
        selectedDiscoveryGraph.graph_data,
      );

      const validSchemaTags = schemaTags.filter((tag) =>
        availableTags.includes(tag),
      );

      if (validSchemaTags.length > 0) {
        if (graphChanged) {
          setSelectedTags(validSchemaTags);
          onTagsSelected?.(validSchemaTags);
        } else {
          const tagsToAdd = validSchemaTags.filter(
            (tag) => !selectedTags.includes(tag),
          );

          if (tagsToAdd.length > 0) {
            const newSelectedTags = [...selectedTags, ...tagsToAdd];
            setSelectedTags(newSelectedTags);
            onTagsSelected?.(newSelectedTags);
          }
        }
      } else {
        // If graph changed but has no valid tags, clear the selection
        if (graphChanged && selectedTags.length > 0) {
          setSelectedTags([]);
          onTagsSelected?.([]);
        }
      }
    }
  }, [
    selectedDiscoveryGraph,
    metricsData,
    selectedTags,
    onTagsSelected,
    previousGraphId,
    tagsManuallyCleared,
  ]);

  const filterDistributionByTags = (distribution) => {
    if (!distribution) return {};
    const tagsToShow = selectedTags.length > 0 ? selectedTags : recommendedTags;
    if (tagsToShow.length === 0) return distribution;

    return Object.fromEntries(
      Object.entries(distribution).filter(([key]) => tagsToShow.includes(key)),
    );
  };

  const TagSelector = React.memo(
    ({
      distribution,
      onTagsChange,
      recommendedTags,
      setRecommendedTags,
      isSuggesting,
      onGenerateRecommendations,
    }) => {
      const [localSearchQuery, setLocalSearchQuery] = useState("");

      const handleInputChange = (e) => {
        setLocalSearchQuery(e.target.value);
      };

      const handleTagSelect = (tag) => {
        const newTags = [...selectedTags, tag];
        onTagsChange(newTags);
      };

      if (!distribution) return null;

      const allTags = Object.keys(distribution).sort((a, b) => {
        const countA = distribution[a];
        const countB = distribution[b];
        return countB - countA;
      });

      const filteredTags = allTags
        .filter(
          (tag) =>
            tag.toLowerCase().includes(localSearchQuery.toLowerCase()) &&
            !selectedTags.includes(tag),
        )
        .slice(0, 5);

      const visibleTags = allTags.filter((tag) =>
        tag.toLowerCase().includes(localSearchQuery.toLowerCase()),
      );

      const handleSelectAll = () => {
        onTagsChange(allTags);
      };

      const handleDeselectAll = () => {
        onTagsChange([]);
        setLocalSearchQuery("");
        setRecommendedTags([]);
        setTagsManuallyCleared(true);
      };

      return (
        <div id="metadata-selector" className="z-50 bg-white w-full">
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            <div className="mb-4">
              <div className="flex flex-col space-y-3">
                <div>
                  <h3 className="text-base font-medium text-gray-800 mb-1 text-left">
                    Metadata Tag Selection
                  </h3>
                  <p className="text-sm text-gray-600 text-left">
                    Select the metadata tags you want to include in your data
                    source or structured dataset
                  </p>
                </div>

                <div className="flex items-center gap-3">
                  <div className="relative flex-1">
                    <input
                      type="text"
                      value={localSearchQuery}
                      onChange={handleInputChange}
                      placeholder="Type to search tags..."
                      className="w-full px-4 py-2.5 rounded-lg border border-gray-300 bg-white focus:border-primary focus:ring-2 focus:ring-primary/20 outline-none transition-all duration-200 pl-10"
                    />
                    <svg
                      className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>

                    {localSearchQuery && filteredTags.length > 0 && (
                      <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                        {filteredTags.map((tag) => (
                          <button
                            key={tag}
                            onClick={() => handleTagSelect(tag)}
                            className="w-full px-4 py-2 text-left hover:bg-gray-50 focus:bg-gray-50 focus:outline-none first:rounded-t-lg last:rounded-b-lg"
                          >
                            {tag}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>

                  <button
                    onClick={onGenerateRecommendations}
                    disabled={isSuggesting}
                    className={`px-4 py-2.5 rounded-lg flex items-center gap-2 whitespace-nowrap ${
                      isSuggesting
                        ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                        : "bg-primary text-white hover:bg-primary/90 shadow-sm"
                    }`}
                  >
                    {isSuggesting ? (
                      <>
                        <svg
                          className="animate-spin h-4 w-4"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                          />
                        </svg>
                        <span className="text-sm">Analyzing...</span>
                      </>
                    ) : (
                      <>
                        <RefreshCw size={16} />
                        <span className="text-sm">Recommend Tags</span>
                      </>
                    )}
                  </button>
                </div>

                <div className="flex items-center justify-between pt-1">
                  <GraphSelector viewOnly={true} verbose={false} />

                  <div className="flex items-center gap-3">
                    <button
                      onClick={handleSelectAll}
                      className="px-3 py-1.5 text-sm font-medium text-primary hover:text-primary/80 transition-colors"
                    >
                      Select All
                    </button>
                    <span className="text-gray-300">|</span>
                    <button
                      onClick={handleDeselectAll}
                      className="px-3 py-1.5 text-sm font-medium text-red-500 hover:text-red-600 transition-colors"
                    >
                      Deselect All
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {selectedTags.length > 0 && (
              <div className="flex flex-wrap gap-2 mb-4">
                {selectedTags.map((tag) => (
                  <span
                    key={tag}
                    className="px-3 py-1 rounded-full bg-primary text-white text-sm font-medium flex items-center gap-2 shadow-sm"
                  >
                    {tag}
                    <button
                      onClick={() => {
                        const newTags = selectedTags.filter((t) => t !== tag);
                        setTagsManuallyCleared(true);
                        onTagsChange(newTags);
                      }}
                      className="opacity-70 hover:opacity-100 transition-opacity duration-200"
                    >
                      <svg
                        className="w-3 h-3"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </span>
                ))}
              </div>
            )}

            <div className="mt-2">
              <div
                className="transition-all duration-300 relative max-h-[350px] overflow-y-auto"
                style={{
                  scrollbarWidth: "thin",
                  scrollbarColor: "rgb(203 213 225) transparent",
                }}
              >
                <div
                  className="absolute inset-0 pointer-events-none hidden"
                  style={{
                    background:
                      "linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,1) 90%)",
                    zIndex: 1,
                  }}
                />

                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 pb-8 pt-2 px-1 relative">
                  {visibleTags.map((tag) => (
                    <button
                      key={tag}
                      onClick={() =>
                        !selectedTags.includes(tag) && handleTagSelect(tag)
                      }
                      disabled={selectedTags.includes(tag)}
                      className={`px-3 py-1.5 text-sm rounded-md text-left truncate transition-all ${
                        selectedTags.includes(tag)
                          ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                          : "bg-white hover:bg-gray-50 border border-gray-200 hover:border-primary/50 text-gray-700 hover:shadow-sm"
                      }`}
                      title={tag}
                    >
                      <span className="truncate">{tag}</span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  );

  return (
    <div className="bg-white shadow-sm border border-gray-200 rounded-b-xl">
      <div className="p-6 border-t space-y-6 rounded-b-xl">
        {isLoadingMetrics ? (
          <div className="space-y-6 animate-pulse">
            <div className="w-[60%]">
              <div className="h-12 bg-gray-200 rounded-lg mb-4" />
              <div className="grid grid-cols-4 gap-2">
                {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                  <div key={i} className="h-8 bg-gray-200 rounded-md" />
                ))}
              </div>
            </div>

            <div className="grid grid-cols-3 gap-6">
              {[1, 2, 3].map((i) => (
                <div
                  key={i}
                  className="bg-white p-6 rounded-lg shadow-sm border border-gray-100"
                >
                  <div className="h-8 bg-gray-200 rounded mb-4 w-1/2" />
                  <div className="h-48 bg-gray-200 rounded" />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <>
            {metricsData?.metadata_tag_counts_distribution && (
              <div className="space-y-6">
                {showRecommendedChips && recommendedTags.length > 0 && (
                  <div className="flex items-center gap-2 p-3 bg-primary/5 rounded-lg border border-primary/20 animate-fade-in">
                    <Star className="w-4 h-4 text-primary" />
                    <span className="text-sm text-gray-700">
                      Recommended tags:
                    </span>
                    <div className="flex flex-wrap gap-2">
                      {recommendedTags.map((tag) => (
                        <span
                          key={tag}
                          className="px-2 py-1 text-xs font-medium bg-white text-primary rounded-full border border-primary/20"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                <div className="flex flex-row gap-4">
                  <TagSelector
                    distribution={metricsData.metadata_tag_counts_distribution}
                    onTagsChange={(tags) => {
                      setSelectedTags(tags);
                      onTagsSelected?.(tags);
                    }}
                    recommendedTags={recommendedTags}
                    setRecommendedTags={setRecommendedTags}
                    isSuggesting={isSuggesting}
                    onGenerateRecommendations={async () => {
                      setSelectedTags([]);
                      onTagsSelected?.([]);
                      await onGenerateRecommendations();
                    }}
                  />
                  <div className="bg-white p-2 rounded-lg shadow-sm border border-gray-100 hover:shadow-md transition-shadow duration-200">
                    <div className="flex items-center gap-2 mb-2">
                      <h3 className="text-lg font-semibold text-gray-800">
                        Deasy Metadata
                      </h3>
                      <div className="group relative">
                        <svg
                          className="w-5 h-5 text-gray-400 cursor-help"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
                          Metadata tags extracted and stored in Deasy about your
                          data source. This represents all available metadata.
                        </div>
                      </div>
                    </div>
                    <TagDistributionChart
                      distribution={filterDistributionByTags(
                        metricsData.metadata_tag_counts_distribution,
                      )}
                      isLoading={isLoadingMetrics}
                      selectedTags={
                        selectedTags.length > 0 ? selectedTags : recommendedTags
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
