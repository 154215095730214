import { useState, useEffect, useContext } from "react";
import { tokenService, connectorService } from "../../../../services/api";
import { LoadingSpinner, FormInput } from "../ConfigUtils";
import { toast } from "react-hot-toast";
import { ProfileCard } from "./ProfileCard";
import { getLlmLogo } from "../../../../config/config";
import { BaseContext } from "../../../../contexts/BaseContext";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Modal } from "@mui/material";

export const LLM_OPTIONS = [
  {
    value: "openai",
    label: "OpenAI",
    models: [
      { value: "gpt-4o-mini", label: "GPT-4o-mini" },
      { value: "gpt-4o", label: "GPT-4o" },
    ],
    embeddings: [
      { value: "text-embedding-3-small", label: "text-embedding-3-small" },
      { value: "text-embedding-3-large", label: "text-embedding-3-large" },
    ],
  },
  {
    value: "deasy_compute",
    label: "Deasy",
    models: [{ value: "deasy_compute", label: "OntoLLM" }],
    embeddings: [{ value: "deasy_compute", label: "Not Supported" }],
  },
  {
    value: "gemini",
    label: "Google Gemini",
    models: [
      { value: "gemini-2.0-flash", label: "Gemini 2.0 Flash" },
      { value: "gemini-1.5-pro", label: "Gemini 1.5 Pro" },
    ],
    embeddings: [
      {
        value: "gemini_embedding",
        label: "Coming Soon, Not Supported Yet",
      },
    ],
  },
];

// Add helper functions at the top of the file
const getModelLabel = (type, modelValue) => {
  const provider = LLM_OPTIONS.find((opt) => opt.value === type);
  return (
    provider?.models.find((m) => m.value === modelValue)?.label ||
    provider?.models[0]?.label ||
    "Unknown Model"
  );
};

const getEmbeddingLabel = (type, embeddingValue) => {
  const provider = LLM_OPTIONS.find((opt) => opt.value === type);
  return (
    provider?.embeddings.find((e) => e.value === embeddingValue)?.label ||
    provider?.embeddings[0]?.label ||
    "Unknown Embedding Model"
  );
};

export const LLMEndpointConfiguration = ({
  formState,
  handleInputChange,
  handleLoadConfig,
  handleSaveNewConfig,
}) => {
  const { llmProfiles, deasyUserConfig, setDeasyUserConfig } =
    useContext(BaseContext);
  const [showModal, setShowModal] = useState(false);
  const [editingProfile, setEditingProfile] = useState(null);
  const [profileName, setProfileName] = useState("");

  const activeProfile = deasyUserConfig?.llmConfig?.LastActive;

  const fieldsFilled =
    formState.llmType &&
    formState.api_key &&
    formState.requestsPerMinute &&
    formState.tokensPerMinute;
  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState(null);

  useEffect(() => {
    // Set default LLM type if not already set
    if (!formState.llmType) {
      const defaultProvider = LLM_OPTIONS.find(
        (opt) => opt.value !== "deasy_compute",
      );
      if (defaultProvider) {
        handleInputChange("llmType", defaultProvider.value);
        handleInputChange("model", defaultProvider.models[0].value);
      }
    }

    // Set default RPM and TPM values if not already set
    if (!formState.requestsPerMinute) {
      handleInputChange("requestsPerMinute", "5000");
    }
    if (!formState.tokensPerMinute) {
      handleInputChange("tokensPerMinute", "1000000");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddNew = () => {
    setEditingProfile(null);
    setProfileName("");
    setShowModal(true);
  };

  const handleEdit = (profileName) => {
    handleLoadConfig("llm", profileName);
    setEditingProfile(profileName);
    setProfileName(profileName);
    setShowModal(true);
  };

  const handleConfigLoad = async (name) => {
    handleLoadConfig("llm", name);
    if (name === "new") {
      setShowModal(true);
      return;
    }

    const newConfig = {
      ...deasyUserConfig,
      llmConfig: {
        ...deasyUserConfig.llmConfig,
        LastActive: name,
        Configs: deasyUserConfig.llmConfig.Configs,
      },
    };
    setDeasyUserConfig(newConfig);

    await connectorService.setActiveProfile(
      name,
      "llm",
      deasyUserConfig.deasyApiKey,
    );
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    // Validate required fields
    if (!formState.requestsPerMinute || !formState.tokensPerMinute) {
      setValidationError(
        "RPM (Requests Per Minute) and TPM (Tokens Per Minute) are required fields",
      );
      return;
    }

    setIsValidating(true);
    setValidationError(null);

    const configForValidation = {
      endpoint_manager_config: {
        model: formState.model || formState?.llmModel, // TODO: remove llmModel once we have the new config
        embedding_model:
          formState.embedding_model || formState?.llmEmbeddingModel, // TODO: remove llmEmbeddingModel once we have the new config
        api_key: formState.api_key || formState.llmApiKey, // TODO: remove llmApiKey once we have the new config
        vendor: formState.llmType.toLowerCase(),
        temperature: 0,
        rpm_completion: formState.requestsPerMinute
          ? parseInt(formState.requestsPerMinute)
          : null,
        tpm_completion: formState.tokensPerMinute
          ? parseInt(formState.tokensPerMinute)
          : null,
        rpm_embedding: formState.embeddingRequestsPerMinute
          ? parseInt(formState.embeddingRequestsPerMinute)
          : null,
        tpm_embedding: formState.embeddingTokensPerMinute
          ? parseInt(formState.embeddingTokensPerMinute)
          : null,
      },
    };

    try {
      // Important: Send the internal config directly - the API service will wrap it correctly
      const innerConfig = configForValidation.endpoint_manager_config;

      const validationResponse =
        await tokenService.validateLLMProvider(innerConfig);
      if (!validationResponse.data.success) {
        toast.error(`Validation failed: ${validationResponse.data.message}`);
        return;
      }

      await handleSaveNewConfig("llm", profileName.trim(), {
        llmType: formState.llmType,
        api_key: formState.api_key.trim() || formState?.llmApiKey.trim(), // TODO: remove llmApiKey once we have the new config
        model: formState.model || formState?.llmModel, // TODO: remove llmModel once we have the new config
        requestsPerMinute: formState.requestsPerMinute
          ? parseInt(formState.requestsPerMinute)
          : null,
        tokensPerMinute: formState.tokensPerMinute
          ? parseInt(formState.tokensPerMinute)
          : null,
        embedding_model:
          formState.embedding_model || formState?.llmEmbeddingModel, // TODO: remove llmEmbeddingModel once we have the new config
        embeddingRequestsPerMinute: formState.embeddingRequestsPerMinute
          ? parseInt(formState.embeddingRequestsPerMinute)
          : null,
        embeddingTokensPerMinute: formState.embeddingTokensPerMinute
          ? parseInt(formState.embeddingTokensPerMinute)
          : null,
      });
      setShowModal(false);
      toast.success("LLM configuration saved successfully");
    } catch (error) {
      toast.error("Failed to validate credentials: " + error.message);
    } finally {
      setIsValidating(false);
    }
  };

  const getButtonText = () => {
    if (isValidating)
      return (
        <div className="flex items-center justify-center">
          <LoadingSpinner />
          Verifying credentials...
        </div>
      );
    return editingProfile ? "Save Changes" : "Save New Profile";
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 h-full flex flex-col">
      <div className="pb-6">
        <div className="flex justify-between items-center mb-6 flex-1">
          <h2 className="text-xl font-semibold text-gray-700">LLM Profiles</h2>
          <button
            onClick={handleAddNew}
            className="p-2 bg-white text-primary rounded-full hover:bg-primary-dark shadow-md transition-all flex items-center justify-center"
          >
            <FaPlus size={12} />
          </button>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          {Object.entries(llmProfiles).map(([name, config]) => (
            <ProfileCard
              key={name}
              type={config.llmType}
              name={name}
              isActive={name === activeProfile}
              onSelect={(name) => handleConfigLoad(name)}
              onEdit={config.llmType === "deasy_compute" ? null : handleEdit}
              onDelete={async (name) =>
                await handleSaveNewConfig("llm", name, null)
              }
              details={{
                Type: config.llmType,
                Provider:
                  LLM_OPTIONS.find((opt) => opt.value === config.llmType)
                    ?.label || "Unknown Provider",
                Model: getModelLabel(
                  config.llmType,
                  config.model || config?.llmModel, // TODO: remove llmModel once we have the new config
                ),
                Embeddings: getEmbeddingLabel(
                  config.llmType,
                  config.embedding_model || config?.llmEmbeddingModel, // TODO: remove llmEmbeddingModel once we have the new config
                ),
              }}
            />
          ))}
        </div>
      </div>

      {/* LLM Configuration Modal */}
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        aria-labelledby="llm-form-modal"
        aria-describedby="add-or-edit-llm-form"
      >
        <div className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold text-gray-800">
                {editingProfile
                  ? `Edit ${editingProfile}`
                  : "Add New LLM Profile"}
              </h3>
              <button
                onClick={() => setShowModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <FaTimes size={18} />
              </button>
            </div>

            <div className="space-y-6">
              <div className="space-y-4 text-left">
                <FormInput
                  label="Profile Name"
                  type="text"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                  placeholder="Enter name for this LLM endpoint"
                  disabled={!!editingProfile}
                />

                {/* LLM Type Selection */}
                <div className="mb-4 text-left">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    LLM Provider
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    {LLM_OPTIONS.map((llm) => {
                      if (llm.value === "deasy_compute") return null;

                      return (
                        <div
                          key={llm.value}
                          onClick={() =>
                            handleInputChange("llmType", llm.value)
                          }
                          className={`flex items-center gap-3 p-4 rounded-lg cursor-pointer border-2 transition-all
                            ${
                              formState.llmType === llm.value
                                ? "border-primary bg-primary-50"
                                : "border-gray-200 hover:border-emerald-200"
                            }`}
                        >
                          <img
                            src={getLlmLogo(llm.value)}
                            alt={llm.label}
                            className="w-8 h-8 object-contain"
                          />
                          <span className="text-gray-700 font-medium">
                            {llm.label}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <FormInput
                  label="API Key"
                  type="password"
                  value={formState.api_key || formState?.llmApiKey} // TODO: remove llmApiKey once we have the new config
                  onChange={(e) => handleInputChange("api_key", e.target.value)}
                  placeholder="Enter your API key"
                />

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Model
                  </label>
                  <select
                    value={
                      formState.model ||
                      formState?.llmModel || // TODO: remove llmModel once we have the new config
                      LLM_OPTIONS.find((opt) => opt.value === formState.llmType)
                        ?.models[0]?.value
                    }
                    onChange={(e) => handleInputChange("model", e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 p-2"
                  >
                    {LLM_OPTIONS.find(
                      (opt) => opt.value === formState.llmType,
                    )?.models.map((model) => (
                      <option key={model.value} value={model.value}>
                        {model.label}
                      </option>
                    ))}
                  </select>
                </div>

                <FormInput
                  label={
                    <div className="flex items-center">
                      <span>Requests per Minute (required)</span>
                      <div className="ml-1 group relative">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-gray-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded p-2 w-48 z-10">
                          RPM limits how many API calls you can make per minute
                          to the LLM provider.
                        </div>
                      </div>
                    </div>
                  }
                  type="number"
                  value={formState.requestsPerMinute || ""}
                  onChange={(e) =>
                    handleInputChange("requestsPerMinute", e.target.value)
                  }
                  placeholder="Enter max requests per minute"
                  required
                />

                <FormInput
                  label={
                    <div className="flex items-center">
                      <span>Tokens per Minute (required)</span>
                      <div className="ml-1 group relative">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-gray-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded p-2 w-48 z-10">
                          TPM limits the total number of tokens (words and
                          characters) processed per minute.
                        </div>
                      </div>
                    </div>
                  }
                  type="number"
                  value={formState.tokensPerMinute || ""}
                  onChange={(e) =>
                    handleInputChange("tokensPerMinute", e.target.value)
                  }
                  placeholder="Enter max tokens per minute"
                  required
                />
                {validationError && (
                  <div className="text-red-600 text-sm font-medium">
                    {validationError}
                  </div>
                )}
              </div>

              <div className="flex justify-end gap-3 mt-6">
                <button
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={
                    isValidating || !fieldsFilled || !profileName.trim()
                  }
                  className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark flex items-center gap-2 disabled:bg-gray-400"
                >
                  {isValidating ? (
                    <>
                      <LoadingSpinner size={4} /> Validating...
                    </>
                  ) : (
                    getButtonText()
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
