import React, { useState, useEffect } from "react";
import {
  FaExpandAlt,
  FaCompressAlt,
  FaTrashAlt,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

const ExamplesSection = ({ formData, setFormData }) => {
  const [localExamples, setLocalExamples] = useState(formData?.examples || []);
  const [isGloballyCollapsed, setIsGloballyCollapsed] = useState(true);
  const [isInfoExpanded, setIsInfoExpanded] = useState(false);

  useEffect(() => {
    if (formData?.examples) {
      setLocalExamples(formData.examples);
      setFormData((prev) => {
        return {
          ...prev,
          examples: formData.examples,
        };
      });
    }
  }, [formData, setFormData]);

  const addExamplePair = ({ isPositive }) => {
    const newExample = {
      id: uuidv4(),
      value: "",
      evidence: "",
      name: "",
      isPositive,
    };

    const updatedExamples = [...localExamples, newExample];
    setLocalExamples(updatedExamples);
    updateFormDataExamples(updatedExamples);
  };

  const updateExamplePair = (id, field, value) => {
    const updatedExamples = localExamples.map((example) =>
      example.id === id ? { ...example, [field]: value } : example,
    );
    setLocalExamples(updatedExamples);
    updateFormDataExamples(updatedExamples);
  };

  const deleteExamplePair = (id) => {
    const updatedExamples = localExamples.filter(
      (example) => example.id !== id,
    );
    setLocalExamples(updatedExamples);

    updateFormDataExamples(updatedExamples);
  };

  const updateFormDataExamples = (examples) => {
    setFormData((prev) => ({
      ...prev,
      examples: examples,
    }));
  };

  const positiveExamples = localExamples.filter(
    (example) => example.isPositive,
  );
  const negativeExamples = localExamples.filter(
    (example) => !example.isPositive,
  );

  return (
    <div className="bg-white overflow-auto hide-scrollbar">
      <div className="mb-6">
        <button
          onClick={() => setIsInfoExpanded(!isInfoExpanded)}
          className="w-full flex items-center justify-between p-3 bg-gray-100 border-l-4 border-primary rounded-r-md text-left hover:bg-gray-50 transition-colors"
        >
          <span className="font-medium text-gray-700">
            What are Training Examples?
          </span>
          <span className="text-gray-500">
            {isInfoExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        </button>

        {isInfoExpanded && (
          <div className="text-sm text-gray-600 bg-gray-100 p-4 border-l-4 border-primary rounded-b-md text-left mt-[-1px]">
            <p className="mb-3">
              Training examples teach the AI how to analyze text and generate
              the right output for your tag. Each example shows the AI what
              output it should (or should not) produce for specific input text.
            </p>

            <div className="mb-3">
              <p className="font-medium text-primary">Correct Examples:</p>
              <p>
                For a "Customer Sentiment" tag that rates feedback from 1-5:
              </p>
              <div className="bg-white p-2 mt-1 rounded border border-gray-200">
                <p className="text-xs italic">
                  Input: "This product is absolutely fantastic! Best purchase
                  I've made all year. The quality is outstanding and customer
                  service was excellent."
                </p>
                <p className="text-xs italic text-primary">Output: 5</p>
              </div>
            </div>

            <div className="mb-3">
              <p className="font-medium text-gray-500">Incorrect Examples:</p>
              <p>Show the AI what NOT to output for given text:</p>
              <div className="bg-white p-2 mt-1 rounded border border-gray-200">
                <p className="text-xs italic">
                  Input: "The product is okay but shipping took forever and
                  packaging was damaged."
                </p>
                <p className="text-xs italic text-gray-500">Output: 5</p>
                <p className="text-xs mt-1">
                  (This teaches the AI not to give a perfect score for mixed
                  feedback)
                </p>
              </div>
            </div>

            <p className="font-medium mb-2">Tips for best results:</p>
            <ul className="list-disc ml-5 space-y-1">
              <li>
                Include examples with different writing styles and emotions
              </li>
              <li>Show examples of mixed or nuanced feedback</li>
              <li>Include examples with different lengths and detail levels</li>
              <li>Keep your rating scale consistent across all examples</li>
            </ul>
          </div>
        )}
      </div>

      <div className="flex justify-between items-center mb-8">
        <div className="flex space-x-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              addExamplePair({ isPositive: true });
            }}
            className="border-2 border-primary text-primary hover:bg-containerLight font-medium py-1 px-2 rounded-md transition duration-300 text-sm"
          >
            Add Correct Example
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              addExamplePair({ isPositive: false });
            }}
            className="border-2 border-gray-300 text-gray-600 hover:bg-gray-100 font-medium py-1 px-2 rounded-md transition duration-300 text-sm"
          >
            Add Incorrect Example
          </button>
          <button
            onClick={() => setIsGloballyCollapsed(!isGloballyCollapsed)}
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded-md transition duration-300"
          >
            {isGloballyCollapsed ? <FaExpandAlt /> : <FaCompressAlt />}
          </button>
        </div>
      </div>

      <div className="max-h-[600px] overflow-y-auto pr-2 hide-scrollbar">
        <ExampleList
          title="Correct Examples"
          examples={positiveExamples}
          updateExamplePair={updateExamplePair}
          deleteExamplePair={deleteExamplePair}
          type="examples"
          isGloballyCollapsed={isGloballyCollapsed}
          isPositive={true}
        />
        <ExampleList
          title="Incorrect Examples"
          examples={negativeExamples}
          updateExamplePair={updateExamplePair}
          deleteExamplePair={deleteExamplePair}
          type="neg_examples"
          isGloballyCollapsed={isGloballyCollapsed}
          isPositive={false}
        />
      </div>
    </div>
  );
};

const ExampleList = ({
  title,
  examples,
  updateExamplePair,
  deleteExamplePair,
  type,
  isGloballyCollapsed,
  isPositive,
}) => {
  if (!examples || examples.length === 0) return null;

  return (
    <div className="mb-8">
      <h3 className="text-lg font-medium text-gray-800 mb-2 flex justify-between items-center">
        <span>{title}</span>
      </h3>
      <p className="text-sm text-gray-600 mb-4 text-left">
        {type === "examples"
          ? "Correct examples help the AI understand what to look for and how to correctly apply the tag."
          : "Incorrect examples teach the AI what not to tag, improving accuracy and reducing false positives."}
      </p>
      {examples.map((example, index) => (
        <ExamplePair
          key={index}
          example={example}
          updateExamplePair={updateExamplePair}
          deleteExamplePair={deleteExamplePair}
          type={type}
          isGloballyCollapsed={isGloballyCollapsed}
          isPositive={isPositive}
        />
      ))}
    </div>
  );
};

const ExamplePair = ({
  example,
  updateExamplePair,
  deleteExamplePair,
  type,
  isGloballyCollapsed,
  isPositive,
}) => {
  const [isExampleCollapsed, setIsExampleCollapsed] = useState(true);
  const [copySuccess, setCopySuccess] = useState({
    evidence: false,
    value: false,
  });

  // when isGloballyCollapsed is False, the example is not collapsed
  // when isGloballyCollapsed is True, the example is collapsed if isExampleCollapsed is True
  const collapsed = isGloballyCollapsed && isExampleCollapsed;

  return (
    <div
      className={`mb-4 p-4 border-l-4 rounded-r-md relative ${
        isPositive ? "border-primary bg-gray-100" : "border-red-200 bg-red-50"
      }`}
    >
      <div className="flex justify-between items-center">
        <div>
          <input
            type="text"
            value={example?.name || ""}
            onChange={(e) => {
              e.preventDefault();
              updateExamplePair(example.id, "name", e.target.value);
            }}
            placeholder="Example name"
            className="text-sm font-medium p-1 border rounded"
          />
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsExampleCollapsed(!isExampleCollapsed);
            }}
            className="text-grey text-bold border-3 rounded-md hover:text-[#1a735e] p-2"
          >
            {isExampleCollapsed ? "Expand" : "Collapse"}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              deleteExamplePair(example.id);
            }}
            className="text-gray-500 hover:text-gray-700 transition duration-300 p-2"
            aria-label="Delete example"
          >
            <FaTrashAlt className="w-4 h-4" />
          </button>
        </div>
      </div>

      {!collapsed && (
        <div className="flex flex-col gap-4">
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-600">
              Input Text ({type === "examples" ? "Positive" : "Negative"}{" "}
              Example)
            </label>
            <div className="relative">
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary transition duration-300 resize-none"
                value={example?.evidence || ""}
                onChange={(e) => {
                  e.preventDefault();
                  updateExamplePair(example.id, "evidence", e.target.value);
                }}
                placeholder={
                  type === "examples"
                    ? "E.g., The product arrived on time and in perfect condition."
                    : "E.g., The delivery was late and the package was damaged."
                }
                rows={3}
              />
              <button
                onClick={() => {
                  navigator.clipboard.writeText(example?.evidence || "");
                  setCopySuccess({ ...copySuccess, evidence: true });
                  setTimeout(
                    () => setCopySuccess({ ...copySuccess, evidence: false }),
                    2000,
                  );
                }}
                className={`absolute top-2 right-2 p-1.5 rounded-md transition-all duration-200 
                  ${
                    copySuccess.evidence
                      ? "bg-green-100 text-green-600"
                      : "bg-gray-100/70 text-gray-500 hover:bg-gray-200/70 hover:text-gray-700"
                  }`}
                title={copySuccess.evidence ? "Copied!" : "Copy text"}
              >
                {copySuccess.evidence ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-600">
              Expected Output
            </label>
            <div className="relative">
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary transition duration-300 resize-none"
                value={example.value}
                onChange={(e) => {
                  e.preventDefault();
                  updateExamplePair(example.id, "value", e.target.value);
                }}
                placeholder={
                  type === "examples"
                    ? "E.g., Positive review"
                    : "E.g., Positive review"
                }
                rows={3}
              />
              <button
                onClick={() => {
                  navigator.clipboard.writeText(example.value || "");
                  setCopySuccess({ ...copySuccess, value: true });
                  setTimeout(
                    () => setCopySuccess({ ...copySuccess, value: false }),
                    2000,
                  );
                }}
                className={`absolute top-2 right-2 p-1.5 rounded-md transition-all duration-200 
                  ${
                    copySuccess.value
                      ? "bg-green-100 text-green-600"
                      : "bg-gray-100/70 text-gray-500 hover:bg-gray-200/70 hover:text-gray-700"
                  }`}
                title={copySuccess.value ? "Copied!" : "Copy text"}
              >
                {copySuccess.value ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamplesSection;
