import React, { useState, useCallback, useContext, useEffect } from "react";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import {
  metadataService,
  sourceMetadataService,
} from "../../../../../../services/api";
import { toast } from "react-hot-toast";
import { highlightText, getHighlightTerms } from "../metadataTableUtils";

export default function TagEvidence({ tagName, value, fileName, onClose }) {
  const { deasyUserConfig } = useContext(BaseContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [evidence, setEvidence] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedChunkId, setSelectedChunkId] = useState(null);
  const [documentChunks, setDocumentChunks] = useState({});
  const [isLoadingChunk, setIsLoadingChunk] = useState(false);

  const fetchDocumentChunk = useCallback(
    async (chunkId) => {
      if (!chunkId) return;

      try {
        setIsLoadingChunk(true);
        const activeVdbConfig =
          deasyUserConfig.vdbmConfig?.Configs?.[
            deasyUserConfig.vdbmConfig?.LastActive
          ] || {};

        const response = await sourceMetadataService.getDocumentText(
          activeVdbConfig,
          [fileName],
          deasyUserConfig.deasyApiKey,
        );

        if (response.data?.text_data) {
          setDocumentChunks(response.data.text_data);
        }
      } catch (error) {
        console.error("Error fetching document chunk:", error);
        toast.error("Failed to fetch document chunk");
      } finally {
        setIsLoadingChunk(false);
      }
    },
    [deasyUserConfig, fileName],
  );

  const fetchEvidence = useCallback(async () => {
    if (!isExpanded) return;

    try {
      setLoading(true);
      const activeVdbConfig =
        deasyUserConfig.vdbmConfig?.Configs?.[
          deasyUserConfig.vdbmConfig?.LastActive
        ] || {};

      const response = await metadataService.getTagEvidence(
        activeVdbConfig,
        fileName,
        tagName,
        value,
        deasyUserConfig.deasyApiKey,
      );

      if (response.data?.evidence) {
        setEvidence(response.data.evidence);
      }
    } catch (error) {
      console.error("Error fetching evidence:", error);
      toast.error("Failed to fetch evidence data");
    } finally {
      setLoading(false);
    }
  }, [deasyUserConfig, fileName, tagName, value, isExpanded]);

  useEffect(() => {
    if (isExpanded) {
      fetchEvidence();
    }
  }, [isExpanded, fetchEvidence]);

  useEffect(() => {
    setIsExpanded(true);
  }, []);

  return (
    <div className="w-full">
      <div className="bg-gradient-to-r from-primary/5 to-primary/10 border-b border-gray-200">
        <div className="px-8 py-6">
          <div className="flex items-start justify-between">
            <div className="flex-1 space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-xl font-semibold text-gray-900">
                  Evidence Details
                </h3>
                <button
                  onClick={onClose}
                  className="rounded-full p-2 hover:bg-white/50 transition-colors group -mt-2 -mr-2"
                >
                  <svg
                    className="w-5 h-5 text-gray-500 group-hover:text-gray-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex items-center">
                  <span className="text-sm font-medium text-gray-600 w-16 flex items-center gap-2">
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A2 2 0 013 12V7a4 4 0 014-4z"
                      />
                    </svg>
                    Tag:
                  </span>
                  <span className="text-sm text-gray-900 font-medium">
                    {tagName}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-sm font-medium text-gray-600 w-16 flex items-center gap-2">
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                    Value:
                  </span>
                  <span className="inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium bg-primary/15 text-primary border border-primary/20">
                    {value}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-sm font-medium text-gray-600 w-16 flex items-center gap-2">
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                    File:
                  </span>
                  <span
                    className="text-sm text-gray-900 break-all"
                    title={fileName}
                  >
                    {fileName}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className="bg-gray-50">
          {loading ? (
            <div className="p-8 flex justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
            </div>
          ) : Object.keys(evidence).length > 0 ? (
            <div className="p-6">
              <div className="max-w-6xl ml-8 space-y-4">
                {Object.entries(evidence).map(([pointId, text]) => (
                  <div
                    key={pointId}
                    className="bg-white rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow"
                  >
                    <div className="p-4">
                      <div
                        className="flex items-center justify-between mb-3 cursor-pointer group"
                        onClick={() => {
                          if (selectedChunkId === pointId) {
                            setSelectedChunkId(null);
                          } else {
                            setSelectedChunkId(pointId);
                            fetchDocumentChunk(pointId);
                          }
                        }}
                      >
                        <TextDisplay
                          text={text}
                          pointId={pointId}
                          value={value}
                          evidence={evidence}
                        />
                        <div className="relative ml-4 p-2 text-gray-400 group-hover:text-primary group-hover:bg-gray-50 rounded-md transition-colors">
                          <span
                            className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 
                            hidden group-hover:block whitespace-nowrap 
                            bg-gray-800 text-white text-xs py-1.5 px-3 rounded-md z-10"
                          >
                            Show original context
                            <span className="absolute -bottom-1 left-1/2 -translate-x-1/2 border-4 border-transparent border-t-gray-800"></span>
                          </span>
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                        </div>
                      </div>

                      {selectedChunkId === pointId && (
                        <div className="mt-4 bg-gray-50 rounded-lg border border-gray-200">
                          {isLoadingChunk ? (
                            <div className="flex justify-center p-6">
                              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-primary"></div>
                            </div>
                          ) : documentChunks[pointId] ? (
                            <div className="p-4">
                              <div className="flex items-center gap-2 mb-3">
                                <div className="text-sm font-medium text-gray-700 relative group">
                                  <span>Original Chunk ID: {pointId}</span>
                                  <span
                                    className="absolute bottom-full left-0 mb-2
                                    hidden group-hover:block whitespace-nowrap 
                                    bg-gray-800 text-white text-xs py-1.5 px-3 rounded-md z-10"
                                  >
                                    Node ID in vector database
                                    <span className="absolute -bottom-1 left-4 border-4 border-transparent border-t-gray-800"></span>
                                  </span>
                                </div>
                                <button
                                  onClick={() => {
                                    navigator.clipboard.writeText(pointId);
                                    toast.success("Context ID copied");
                                  }}
                                  className="p-1 text-gray-400 hover:text-primary transition-colors"
                                >
                                  <svg
                                    className="w-4 h-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                                    />
                                  </svg>
                                </button>
                              </div>
                              <div className="max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-primary/50 scrollbar-track-gray-200">
                                <TextDisplay
                                  text={documentChunks[pointId]}
                                  pointId={pointId}
                                  value={value}
                                  evidence={evidence}
                                  isExpandedView={true}
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="p-12 text-center">
              <div className="text-gray-500 flex flex-col items-center gap-3">
                <div className="rounded-full bg-gray-100 p-3">
                  <svg
                    className="w-8 h-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <span className="text-sm font-medium">
                  No evidence available
                </span>
                <span className="text-xs text-gray-400">
                  There is no evidence data for this tag value
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export const TextDisplay = ({
  text,
  pointId,
  value,
  evidence,
  isExpandedView = false,
}) => {
  const formatText = (text) => {
    if (!text) return "";

    return text
      .split(/\n\s*\n/)
      .map((paragraph) =>
        paragraph
          .split("\n")
          .map((line) => line.trim())
          .join(" ")
          .replace(/\[\d+\]/g, "")
          .replace(/\s+/g, " ")
          .trim(),
      )
      .filter((para) => para)
      .join("\n\n");
  };

  const highlightTerms =
    isExpandedView && pointId && evidence
      ? getHighlightTerms(pointId, value, evidence)
      : [];

  return (
    <div
      className={`bg-white rounded-lg w-full ${isExpandedView ? "p-4" : ""}`}
    >
      <div
        className={`
        prose 
        prose-headings:font-semibold 
        prose-headings:text-gray-900
        prose-p:text-gray-700
        prose-p:leading-relaxed
        prose-p:my-4
        prose-strong:text-gray-900
        prose-strong:font-semibold
        prose-em:text-gray-800
        prose-em:italic
        max-w-none
        ${isExpandedView ? "prose-lg" : "prose-base"}
      `}
      >
        <div
          className={`
          space-y-4
          ${isExpandedView ? "bg-gray-50 p-6 rounded-lg border border-gray-100" : ""}
        `}
        >
          {formatText(text)
            .split("\n\n")
            .map((paragraph, idx) => (
              <p
                key={idx}
                className={`
                  font-normal
                  text-left
                  ${isExpandedView ? "px-4 py-2" : "pl-3"}
                  ${isExpandedView ? "hover:bg-white transition-colors duration-200 rounded" : ""}
                `}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: isExpandedView
                      ? highlightText(paragraph, highlightTerms)
                      : paragraph,
                  }}
                  className={`
                    leading-relaxed
                    tracking-normal
                    ${isExpandedView ? "text-base" : "text-sm"}
                  `}
                />
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};
