import React, { useState } from "react";
import { MetricCard } from "../DataSliceUtils";
import MetadataTable from "../../MetadataTable/MetadataTable";

export default function DataSliceOverview({
  isOverviewOpen,
  setIsOverviewOpen,
  dataslice_id,
  dataslice_name,
  dataslice_conditions_new,
  metricsData,
  isLoadingMetrics,
  compact = false,
  isExportScreen = false,
  vector_db_config = null,
}) {
  const [showFiles, setShowFiles] = useState(false);
  const [tableFilters, setTableFilters] = useState({});
  const [tableConditionsNew, setTableConditionsNew] = useState(null);
  let curConditionTree = dataslice_conditions_new;

  const renderConditionTree = (node, depth = 0) => {
    if (!node) return null;
    // Handle tag condition
    if (node.tag) {
      return (
        <div className="ml-2 border-l-2 border-gray-200 pl-3 py-1">
          <div className="font-medium text-gray-700 mb-1">{node.tag.name}</div>
          <div className="flex flex-wrap items-center gap-2">
            {node.tag.values.map((value, i, arr) => (
              <React.Fragment key={i}>
                <span className="bg-gray-100 px-2 py-1 rounded text-sm">
                  {value}
                </span>
                {i < arr.length - 1 && (
                  <span className="text-xs text-green-800 font-medium">OR</span>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    }

    // Handle condition group
    if (node.condition && node.children) {
      const isOr = node.condition === "OR";
      return (
        <div
          className={`${depth > 0 ? "ml-4 border-l-2 border-gray-200 pl-3" : ""}`}
        >
          {depth > 1 && (
            <div
              className={`text-xs ${isOr ? "text-green-800" : "text-blue-800"} font-medium mb-1`}
            >
              {node.condition}
            </div>
          )}
          {node.children.map((child, index) => (
            <React.Fragment key={index}>
              {renderConditionTree(child, depth + 1)}
              {index < node.children.length - 1 && (
                <div
                  className={`my-1 text-xs ${isOr ? "text-green-800" : "text-blue-800"} font-medium`}
                >
                  {node.condition}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 mb-6">
      <button
        onClick={() => setIsOverviewOpen(!isOverviewOpen)}
        className="w-full px-6 py-4 text-left font-medium flex items-center justify-between hover:bg-gray-50 rounded-xl transition-colors duration-200"
      >
        <div className="flex items-center gap-3">
          <h2 className="text-md font-semibold text-gray-800">
            {!isExportScreen ? "Overview " : "2. View "}{" "}
            <span className="text-primary">
              {dataslice_name?.startsWith("Deasy_Data_")
                ? `All data from ${dataslice_name.replace("Deasy_Data_", "")}`
                : dataslice_name || "Data"}
            </span>
          </h2>
        </div>
        <span
          className="transform transition-transform duration-200 text-gray-400"
          style={{ transform: isOverviewOpen ? "rotate(180deg)" : "" }}
        >
          ▼
        </span>
      </button>
      {isOverviewOpen && (
        <div className="p-6 border-t space-y-6 rounded-b-xl">
          {isLoadingMetrics ? (
            <div className="space-y-6 animate-pulse">
              <div className="grid grid-cols-3 gap-6">
                {[1, 2, 3].map((i) => (
                  <div key={i} className="h-32 bg-gray-200 rounded-lg" />
                ))}
              </div>
              <div className="h-64 bg-gray-200 rounded-lg" />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4 h-full items-center">
              {curConditionTree && (
                <div className="col-span-2">
                  <div className="text-sm font-medium text-gray-700 mb-2">
                    Conditions:
                  </div>
                  {renderConditionTree(curConditionTree)}
                </div>
              )}
              <div className="grid grid-cols-2 gap-4 h-full">
                <MetricCard
                  title="Total Files"
                  value={metricsData?.total_file_count.toLocaleString() || null}
                  onClick={() => setShowFiles(!showFiles)}
                  isClickable={true}
                  isActive={showFiles}
                  isLoading={isLoadingMetrics}
                />
                <MetricCard
                  title="Total Chunks"
                  value={
                    metricsData?.total_node_count?.toLocaleString() || null
                  }
                  info="The total number of text segments (nodes) across all files in this use case. Each node represents a distinct section of content."
                  isLoading={isLoadingMetrics}
                />
              </div>
              <div className="grid grid-cols-2 gap-4 h-full">
                <MetricCard
                  title="Unique Metadata Tags"
                  value={metricsData?.unique_metadata_tags || null}
                  info="The total number of distinct metadata tags used across all files in this use case."
                  isLoading={isLoadingMetrics}
                />
                <MetricCard
                  title="Total Tags Extracted"
                  value={metricsData?.total_metadata_tags || null}
                  info="The total count of all metadata tags extracted, including duplicates across different files."
                  isLoading={isLoadingMetrics}
                />
              </div>
            </div>
          )}
          {/* MetadataTable Section */}
          {showFiles && (
            <div className="mb-6 h-full">
              <MetadataTable
                backendFilters={tableFilters}
                onFiltersChange={setTableFilters}
                dataslice_id={dataslice_id}
                compact={compact}
                vector_db_config={dataslice_id ? null : vector_db_config}
                conditionsNew={tableConditionsNew}
                setConditionsNew={setTableConditionsNew}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
