export const WorkflowStatus = {
  DRAFT: "draft",
  SYNCED: "synced",
  UNSYNCED: "unsynced",
  RUNNING: "running",
};

export const WorkflowType = {
  CONNECT_DATA: "connect-data",
  VIEW_METADATA: "view-metadata",
  EXTRACT_METADATA: "extract-metadata",
  DATA_SLICE_MANAGEMENT: "data-slices",
  EXPORT_METADATA: "export-metadata",
};

export const MainItemType = {
  WORKFLOWS: "workflows",
  METADATA_LIBRARY: "metadata-library",
  METADATA_STUDIO: "metadata-studio",
  DATA_CONNECTIONS: "connections",
  TOKEN_DASHBOARD: "tokens",
  PROGRESS_TRACKING: "progress-tracking",
};

export const WorkFlowTypeMapping = {
  [WorkflowType.CONNECT_DATA]: "connect-data",
  [WorkflowType.VIEW_METADATA]: "view-metadata",
  [WorkflowType.EXPORT_METADATA]: "export-metadata",
  [WorkflowType.DATA_SLICE_MANAGEMENT]: "data-slices",
  [WorkflowType.EXTRACT_METADATA]: "extract-metadata",
};

export class WorkflowModel {
  constructor({
    id,
    name,
    type,
    status = WorkflowStatus.DRAFT,
    created_at = new Date().toISOString(),
    updated_at = new Date().toISOString(),
    last_run = null,
    clusters_count = 0,
    tags_extracted = 0,
    auto_sync = false,
    // Add new fields for endpoint and VDB configuration
    llmEndpoint = null,
    llmType = null,
    vdbProfile = null,
    vdbType = null,
    config = {},
    metadata = {},
  }) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.status = status;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.last_run = last_run;
    this.clusters_count = clusters_count;
    this.tags_extracted = tags_extracted;
    this.auto_sync = auto_sync;
    // Add new fields
    this.llmEndpoint = llmEndpoint;
    this.llmType = llmType;
    this.vdbProfile = vdbProfile;
    this.vdbType = vdbType;
    this.config = config;
    this.metadata = metadata;
  }

  getTableDisplayData() {
    return {
      name: this.name,
      clusters_count: this.clusters_count.toLocaleString(),
      tags_extracted: this.tags_extracted.toLocaleString(),
      vdb_profile: this.vdbProfile || "Not configured",
      auto_sync: this.auto_sync ? "Yes" : "No",
    };
  }

  // Add helper method to update VDB configuration
  updateVdbConfig(profile, type) {
    this.vdbProfile = profile;
    this.vdbType = type;
    this.updated_at = new Date().toISOString();
  }

  // Add helper method to update LLM configuration
  updateLlmConfig(endpoint, type) {
    this.llmEndpoint = endpoint;
    this.llmType = type;
    this.updated_at = new Date().toISOString();
  }

  static fromJSON(data) {
    return new WorkflowModel(data);
  }
}
