import React, { useState, useContext, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { FaTimes, FaSearch } from "react-icons/fa";
import { GraphContext } from "../../../../../../contexts/GraphContext";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { fetchTagTotals } from "../metadataTableUtils";

const MetadataColumnSelector = ({
  availableColumns,
  selectedColumns,
  setSelectedColumns,
  showAllColumns,
  setShowAllColumns,
  onDeleteTags,
  additionalButtons,
  totalRows = 0,
  dataslice_id = null,
  node_condition = null,
}) => {
  const { discoveryGraphData } = useContext(GraphContext);
  const { deasyUserConfig } = useContext(BaseContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [tagStats, setTagStats] = useState({});
  const [isLoadingStats, setIsLoadingStats] = useState(false);

  useEffect(() => {
    const fetchStats = async () => {
      if (!deasyUserConfig?.deasyApiKey) return;

      setIsLoadingStats(true);
      await fetchTagTotals({
        deasyUserConfig,
        dataslice_id,
        node_condition,
        setTagStats,
      });
      setIsLoadingStats(false);
    };

    fetchStats();
  }, [deasyUserConfig, availableColumns, dataslice_id, node_condition]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(selectedColumns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedColumns(items);
  };

  const addColumn = (column) => {
    if (!selectedColumns.includes(column)) {
      setSelectedColumns([...selectedColumns, column]);
    }
  };

  const removeColumn = (column) => {
    setSelectedColumns(selectedColumns.filter((c) => c !== column));
  };

  const getAllTagsFromGraph = (data) => {
    if (!data) return new Set();
    const tags = new Set();
    const traverse = (obj) => {
      if (!obj || typeof obj !== "object") return;
      Object.entries(obj).forEach(([key, value]) => {
        // Count any tag that has TagAvailableValues, regardless of whether it has child values
        if (key !== "TagAvailableValues" && value?.TagAvailableValues) {
          tags.add(key);
        }
        if (typeof value === "object" && value !== null) {
          traverse(value);
        }
      });
    };
    traverse(data);
    return tags;
  };
  const tagsFromGraph = getAllTagsFromGraph(discoveryGraphData);

  const filteredAvailableColumns = availableColumns.filter(
    (column) =>
      (column.toLowerCase().includes(searchTerm.toLowerCase()) &&
        Object.keys(discoveryGraphData).length === 0) ||
      (Object.keys(discoveryGraphData).length > 0 && tagsFromGraph.has(column)),
  );

  const handleDeleteClick = async () => {
    setIsDeleting(true);
    const tagsToDelete = selectedColumns.slice(1);
    await onDeleteTags(tagsToDelete);
    setIsDeleting(false);
  };

  const handleSelectAll = () => {
    const newColumns = [
      ...selectedColumns,
      ...filteredAvailableColumns.filter(
        (col) => !selectedColumns.includes(col),
      ),
    ];
    setSelectedColumns(newColumns);
  };

  const handleDeselectAll = () => {
    // Keep only the first column (deasyFileKey)
    setSelectedColumns([selectedColumns[0]]);
  };

  const renderTagWithStats = (column) => {
    const fileCount = tagStats.tag_totals?.[column];

    if (isLoadingStats) {
      return (
        <div className="flex items-center gap-1">
          <span>{column}</span>
          <CircularProgress size={8} className="text-gray-400 ml-1" />
        </div>
      );
    }

    if (fileCount !== undefined && totalRows > 0) {
      const percentage = Math.round((fileCount / totalRows) * 100);
      return (
        <div className="flex items-center gap-1">
          <span>{column}</span>
          <Tooltip
            title={`Found in ${fileCount} files (${percentage}% of total)`}
            arrow
            placement="top"
          >
            <span className="text-xs text-gray-500 ml-1">({fileCount})</span>
          </Tooltip>
        </div>
      );
    }

    return column;
  };

  return (
    <div className="flex flex-col gap-4">
      {/* Search Bar */}
      <div className="relative">
        <FaSearch
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          size={12}
        />
        <input
          type="text"
          placeholder="Search metadata tags..."
          className="w-full pl-9 pr-4 py-2 text-sm border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Main Content - Side by Side Layout */}
      <div className="flex gap-4 h-[350px]">
        {/* Available Tags Panel */}
        <div className="flex-1 bg-white rounded-lg border border-gray-200 overflow-hidden flex flex-col">
          <div className="px-4 py-3 bg-gray-50 border-b border-gray-200 flex-shrink-0">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium text-gray-700">
                Available Tags
              </h3>
              <div className="flex items-center gap-2 text-xs">
                <button
                  onClick={handleSelectAll}
                  className="text-primary hover:text-primary-dark transition-colors whitespace-nowrap px-2 py-1"
                  disabled={
                    filteredAvailableColumns.filter(
                      (column) => !selectedColumns.includes(column),
                    ).length === 0
                  }
                >
                  Select All
                </button>
                <span className="text-gray-300">|</span>
                <button
                  onClick={handleDeselectAll}
                  className="text-gray-600 hover:text-gray-900 transition-colors whitespace-nowrap px-2 py-1"
                  disabled={selectedColumns.length <= 1}
                >
                  Deselect All
                </button>
              </div>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto p-3">
            <div className="flex flex-wrap gap-1.5">
              {(() => {
                const filteredBySearch = availableColumns.filter((tag) =>
                  tag.toLowerCase().includes(searchTerm.toLowerCase()),
                );

                const filteredByGraph = filteredBySearch.filter(
                  (tag) =>
                    Object.keys(discoveryGraphData).length === 0 ||
                    tagsFromGraph.has(tag),
                );

                const finalTags = filteredByGraph.filter(
                  (tag) => !selectedColumns.includes(tag),
                );

                return finalTags.map((tag) => (
                  <Chip
                    key={tag}
                    label={renderTagWithStats(tag)}
                    onClick={() => addColumn(tag)}
                    size="small"
                    sx={{
                      background:
                        tagStats.tag_totals?.[tag] !== undefined &&
                        totalRows > 0
                          ? `linear-gradient(90deg, rgba(220, 240, 230, 0.5) ${Math.round((tagStats.tag_totals?.[tag] / totalRows) * 100)}%, white ${Math.round((tagStats.tag_totals?.[tag] / totalRows) * 100)}%)`
                          : "white",
                      border: "1px solid #e5e7eb",
                      color: "#374151",
                      cursor: "pointer",
                      height: "26px",
                      fontSize: "0.75rem",
                      margin: "1px",
                      "& .MuiChip-label": {
                        padding: "0 8px",
                      },
                      "&:hover": {
                        background:
                          tagStats.tag_totals?.[tag] !== undefined &&
                          totalRows > 0
                            ? `linear-gradient(90deg, rgba(210, 235, 225, 0.7) ${Math.round((tagStats.tag_totals?.[tag] / totalRows) * 100)}%, #f3f4f6 ${Math.round((tagStats.tag_totals?.[tag] / totalRows) * 100)}%)`
                            : "#f3f4f6",
                        borderColor: "#d1d5db",
                        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                      },
                      transition: "all 0.2s ease",
                    }}
                  />
                ));
              })()}
              {(() => {
                const filteredBySearch = availableColumns.filter((tag) =>
                  tag.toLowerCase().includes(searchTerm.toLowerCase()),
                );

                const filteredByGraph = filteredBySearch.filter(
                  (tag) =>
                    Object.keys(discoveryGraphData).length === 0 ||
                    tagsFromGraph.has(tag),
                );

                const finalTags = filteredByGraph.filter(
                  (tag) => !selectedColumns.includes(tag),
                );

                return (
                  finalTags.length === 0 && (
                    <div className="w-full text-center text-sm text-gray-500 py-4">
                      No available tags to select
                    </div>
                  )
                );
              })()}
            </div>
          </div>
        </div>

        {/* Selected Tags Panel */}
        <div className="flex-1 bg-white rounded-lg border border-gray-200 overflow-hidden flex flex-col">
          <div className="px-4 py-3 bg-gray-50 border-b border-gray-200 flex-shrink-0">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <h3 className="text-sm font-medium text-gray-700">
                  Selected Tags
                </h3>
              </div>
              <div className="flex items-center gap-2">
                {selectedColumns.length > 1 && (
                  <button
                    onClick={handleDeselectAll}
                    className="flex items-center gap-2 px-3 py-1.5 rounded-md text-xs bg-gradient-to-b from-white to-gray-50 border border-gray-200 text-gray-700 hover:border-gray-300 hover:shadow-sm transition-all"
                  >
                    <span>Clear</span>
                  </button>
                )}
                {selectedColumns.length > 1 && (
                  <button
                    onClick={handleDeleteClick}
                    disabled={isDeleting}
                    className={`flex items-center gap-2 px-2 py-1.5 rounded-md text-xs ${
                      isDeleting
                        ? "bg-gray-100 text-gray-400 cursor-not-allowed border border-gray-200"
                        : "bg-gradient-to-b from-white to-gray-50 text-gray-700 border border-red-200 hover:border-gray-300 hover:shadow-sm"
                    } transition-all`}
                  >
                    {isDeleting ? (
                      <>
                        <CircularProgress size={12} className="text-gray-400" />
                        <span>Processing...</span>
                      </>
                    ) : (
                      <>
                        <span className="text-red-600/70">Delete</span>
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="selected-columns">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex-1 overflow-y-auto"
                >
                  <div className="divide-y divide-gray-100">
                    {selectedColumns.map((column, index) => (
                      <Draggable
                        key={column}
                        draggableId={column}
                        index={index}
                        isDragDisabled={showAllColumns}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`flex items-center justify-between px-4 py-2 ${
                              snapshot.isDragging ? "bg-gray-50" : "bg-white"
                            } hover:bg-gray-50 transition-colors group`}
                          >
                            <div className="flex items-center">
                              {!showAllColumns && (
                                <div
                                  {...provided.dragHandleProps}
                                  className="cursor-grab text-gray-400 hover:text-gray-600"
                                ></div>
                              )}
                              <span className="text-sm text-gray-700">
                                {renderTagWithStats(column)}
                              </span>
                            </div>
                            {!showAllColumns && index !== 0 && (
                              <button
                                onClick={() => removeColumn(column)}
                                className="opacity-0 group-hover:opacity-100 text-gray-400 hover:text-red-500 transition-all"
                              >
                                <FaTimes size={12} />
                              </button>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default MetadataColumnSelector;
