import { useEffect, useState, useContext, useRef } from "react";
import { miscService } from "../../../../../../services/api";
import { BaseContext } from "../../../../../../contexts/BaseContext";

export const FilePreviewModal = ({
  filename,
  closePreviewModal,
  startingPage = 0,
  startingChunk = null,
}) => {
  const [pageToText, setPageToText] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(startingPage);
  const [searchQuery, setSearchQuery] = useState("");
  const [highlightedContent, setHighlightedContent] = useState("");
  const [matchCount, setMatchCount] = useState(0);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);

  const searchInputRef = useRef(null);
  const contentRef = useRef(null);

  const { deasyUserConfig, deasyApiKey } = useContext(BaseContext);
  const vdbProfileName = deasyUserConfig?.vdbmConfig?.LastActive;

  const handleBackdropClick = (e) => {
    // Only close if clicking directly on the backdrop, not its children
    if (e.target === e.currentTarget) {
      closePreviewModal();
    }
  };

  const getPageContent = async (pageNumber) => {
    try {
      setIsLoading(true);
      const response = await miscService.getFilePageText(
        vdbProfileName, // vdbProfileName
        filename, // filename
        pageNumber, // pageNumber
        null, // chunkId
        deasyApiKey, // deasyApiKey
      );
      const pageText = response.data.page_text;

      setPageToText((prev) => {
        const newPageTexts = [...prev];
        newPageTexts[pageNumber] = pageText;
        return newPageTexts;
      });
      return pageText;
    } catch (error) {
      console.error("Error fetching file content:", error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = async (newPage) => {
    if (newPage >= totalPageCount || newPage < 0) {
      return;
    } else if (pageToText[newPage]) {
      setCurrentPage(newPage);
    } else {
      const newPageContent = await getPageContent(newPage);
      if (newPageContent) {
        setCurrentPage(newPage);
      }
    }
  };

  useEffect(
    () => {
      const fetchFileContent = async () => {
        try {
          setIsLoading(true);
          const response = await miscService.getFilePageText(
            vdbProfileName, // vdbProfileName
            filename, // filename
            startingChunk != null ? null : startingPage, // startingPage
            startingChunk, // startingChunk
            deasyApiKey, // deasyApiKey
          );

          // Set the current page
          const currentPage = response.data.page_number;
          setCurrentPage(currentPage);

          // Set the total page count
          const totalPageCount = response.data.total_page_count;
          setTotalPageCount(totalPageCount);

          // Set the page text
          const pageText = response.data.page_text;
          const pageTexts = Array(totalPageCount).fill(null);
          pageTexts[currentPage] = pageText;
          setPageToText(pageTexts);
        } catch (error) {
          console.error("Error fetching file content:", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchFileContent();
    },
    // eslint-disable-next-line
    [],
  );

  // Get current text from pageToText using currentPage
  const currentText =
    currentPage < pageToText.length ? pageToText[currentPage] : null;

  // Highlight search matches when search query or current page changes
  useEffect(() => {
    if (!searchQuery.trim() || !currentText) {
      setHighlightedContent(currentText || "");
      setMatchCount(0);
      setCurrentMatchIndex(0);
      return;
    }

    try {
      const regex = new RegExp(`(${searchQuery})`, "gi");
      const matches = currentText.match(regex);
      const matchesCount = matches ? matches.length : 0;
      setMatchCount(matchesCount);

      if (matchesCount > 0) {
        // Reset current match index if it's beyond the available matches
        if (currentMatchIndex >= matchesCount) {
          setCurrentMatchIndex(0);
        }

        // Add highlight markup
        const highlighted = currentText.replace(
          regex,
          (match, group, index) => {
            const isCurrentMatch =
              matches.findIndex((m, i) => {
                const matchIndex = currentText.indexOf(
                  m,
                  i === 0
                    ? 0
                    : currentText.indexOf(matches[i - 1]) +
                        matches[i - 1].length,
                );
                return matchIndex === index;
              }) === currentMatchIndex;

            return isCurrentMatch
              ? `<mark class="bg-yellow-300 dark:bg-yellow-600 current-match">${match}</mark>`
              : `<mark class="bg-yellow-100 dark:bg-yellow-800">${match}</mark>`;
          },
        );
        setHighlightedContent(highlighted);
      } else {
        setHighlightedContent(currentText);
      }
    } catch (e) {
      // Handle regex errors gracefully
      console.error("Search regex error:", e);
      setHighlightedContent(currentText);
    }
  }, [searchQuery, currentText, currentMatchIndex]);

  // Scroll to the current matched term
  useEffect(() => {
    if (contentRef.current && matchCount > 0) {
      const currentMatchElement =
        contentRef.current.querySelector(".current-match");
      if (currentMatchElement) {
        currentMatchElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [currentMatchIndex, highlightedContent, matchCount]);

  const navigateMatches = (direction) => {
    if (matchCount === 0) return;

    if (direction === "next") {
      setCurrentMatchIndex((prevIndex) => (prevIndex + 1) % matchCount);
    } else {
      setCurrentMatchIndex(
        (prevIndex) => (prevIndex - 1 + matchCount) % matchCount,
      );
    }
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      navigateMatches("next");
    } else if (e.key === "Escape") {
      e.preventDefault();
      setSearchQuery("");
      searchInputRef.current?.blur();
    }
  };

  // Focus search input when pressing Ctrl+F
  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "f") {
        e.preventDefault();
        searchInputRef.current?.focus();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div
      className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50 transition-opacity duration-300"
      onClick={handleBackdropClick}
      style={{ isolation: "isolate" }}
    >
      <div
        className="bg-white dark:bg-gray-800 rounded-xl shadow-2xl w-11/12 md:w-4/5 h-[85vh] flex flex-col overflow-hidden transform transition-all duration-300 border border-gray-200 dark:border-gray-700"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header with filename and close button */}
        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900">
          <div className="flex items-center">
            <h2 className="text-xl font-bold text-gray-800 dark:text-white truncate">
              {filename}
            </h2>
          </div>

          {/* Search box */}
          <div className="flex-1 max-w-md mx-4 relative">
            <div className="relative">
              <input
                ref={searchInputRef}
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleSearchKeyDown}
                className="w-full pl-10 pr-16 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search in document (Ctrl+F)"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  className="h-5 w-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>

              {searchQuery && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  {matchCount > 0 && (
                    <span className="text-xs text-gray-500 dark:text-gray-400 mr-2">
                      {currentMatchIndex + 1}/{matchCount}
                    </span>
                  )}

                  <button
                    onClick={() => navigateMatches("prev")}
                    disabled={matchCount === 0}
                    className="p-1 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 disabled:opacity-50"
                    title="Previous match"
                  >
                    <svg
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                  </button>

                  <button
                    onClick={() => navigateMatches("next")}
                    disabled={matchCount === 0}
                    className="p-1 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 disabled:opacity-50"
                    title="Next match"
                  >
                    <svg
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>

                  <button
                    onClick={() => setSearchQuery("")}
                    className="p-1 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                    title="Clear search"
                  >
                    <svg
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </div>

          <button
            className="p-2 rounded-full text-gray-500 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={closePreviewModal}
            aria-label="Close preview"
          >
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {/* Content area */}
        <div className="flex-grow overflow-auto px-6 py-4 bg-white dark:bg-gray-800">
          {isLoading ? (
            <div className="flex flex-col items-center justify-center h-full text-gray-500 dark:text-gray-400">
              <svg
                className="animate-spin h-8 w-8 mb-4 text-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading page content...</p>
            </div>
          ) : currentText ? (
            <pre
              ref={contentRef}
              className="whitespace-pre-wrap font-mono text-sm text-left p-4 bg-gray-50 dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-700 h-full overflow-auto shadow-inner"
              dangerouslySetInnerHTML={{ __html: highlightedContent }}
            ></pre>
          ) : (
            <div className="flex flex-col items-center justify-center h-full text-gray-500 dark:text-gray-400">
              <svg
                className="w-16 h-16 mb-4"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="text-lg">No content available for this page</p>
            </div>
          )}
        </div>

        {/* Footer with pagination */}
        {totalPageCount > 0 && (
          <div className="border-t border-gray-200 dark:border-gray-700 px-6 py-3 bg-gray-50 dark:bg-gray-900 flex items-center justify-between">
            <div className="text-sm text-gray-500 dark:text-gray-400">
              Viewing page {currentPage + 1} of {totalPageCount}
            </div>

            <div className="flex items-center space-x-2">
              <button
                className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                onClick={() => handlePageChange(0)}
                disabled={currentPage === 0 || isLoading}
              >
                <svg
                  className="w-5 h-5 mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M7.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L3.414 10l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                First
              </button>

              <button
                className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage <= 0 || isLoading}
              >
                <svg
                  className="w-5 h-5 mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Previous
              </button>

              <div className="px-4 py-2 text-sm font-medium text-center min-w-[80px]">
                {currentPage + 1} / {totalPageCount}
              </div>

              <button
                className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= totalPageCount - 1 || isLoading}
              >
                Next
                <svg
                  className="w-5 h-5 ml-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              <button
                className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                onClick={() => handlePageChange(totalPageCount - 1)}
                disabled={currentPage >= totalPageCount - 1 || isLoading}
              >
                Last
                <svg
                  className="w-5 h-5 ml-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 15.707a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L8.586 10l-4.293 4.293a1 1 0 000 1.414z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M12.293 15.707a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L16.586 10l-4.293 4.293a1 1 0 000 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
