import { RotateCcw } from "lucide-react";
import FileList from "../../Utils/FileList";
import { TSContext } from "../TSContext";
import { useContext } from "react";

const FileSelectionDropdown = () => {
  const { selectedFiles, setSelectedFiles } = useContext(TSContext);
  return (
    <div className="h-full flex flex-col">
      <div className="p-4 border-b border-gray-200 bg-gray-50">
        <div className="flex items-center justify-between">
          <h2 className="font-semibold">File Selector</h2>
          <div className="flex items-center gap-2">
            <span className="px-2.5 py-1 bg-white text-sm text-gray-600 rounded-full border border-gray-200">
              {selectedFiles.length} selected
            </span>
            <button
              onClick={() => setSelectedFiles([])}
              className="flex items-center gap-2 p-2"
              disabled={selectedFiles?.length === 0}
            >
              <RotateCcw size={15} />
            </button>
          </div>
        </div>
      </div>
      <div className="flex-1 p-2 overflow-y-auto max-h-[80vh]">
        <FileList
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          setError={() => {}}
          showTitle={false}
        />
      </div>
    </div>
  );
};

export default FileSelectionDropdown;
