import React, { useState, useContext, useEffect, useRef } from "react";
import { BaseContext } from "../../contexts/BaseContext";
import { dataSliceService } from "../../services/api";
import { toast } from "react-hot-toast";

export default function CreateDataSlice({
  isOpen,
  onClose,
  conditions,
  fileCount,
  onSuccess,
  isGraphMode,
  discoveryGraphData,
  selectedDiscoveryGraph,
  isAllFiles,
}) {
  const { deasyUserConfig, refreshDataSlicesHook } = useContext(BaseContext);
  const [currentFileCount, setCurrentFileCount] = useState(fileCount);
  const [processedConditions, setProcessedConditions] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);

  // selected nodes get reset, so we just want to set the conditions with the initial pass on render
  useEffect(() => {
    if (conditions) {
      setProcessedConditions(conditions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isInitialized = useRef(false);
  useEffect(() => {
    if (!isInitialized.current && fileCount > 0) {
      setCurrentFileCount(fileCount);
      isInitialized.current = true;
    }
  }, [fileCount]);

  const handleCreateDataSlice = async () => {
    if (!formData.name) {
      toast.error("Please enter a use case name");
      return;
    }
    setIsCreating(true);
    try {
      const dataSliceData = {
        dataslice_name: formData.name,
        conditions: processedConditions,
        description: formData.description,
        status: "active",
        dataPoints: currentFileCount,
        latestGraph: isGraphMode ? discoveryGraphData : {},
        graphId: isGraphMode
          ? selectedDiscoveryGraph?.graph_id
          : "table_filters",
        vdbProfileName: deasyUserConfig?.vdbmConfig?.LastActive,
      };

      await dataSliceService.createDataSlice(
        dataSliceData,
        deasyUserConfig.deasyApiKey,
      );
      toast.success("Use case created successfully");
      refreshDataSlicesHook();
      onSuccess?.(dataSliceData);
      onClose();
    } catch (error) {
      console.error("Failed to create data slice:", error);
      toast.error("Failed to create data slice");
    } finally {
      setIsCreating(false);
    }
  };

  // Add this new function to render condition tree
  const renderConditionTree = (node, depth = 0) => {
    if (!node) return null;

    // Handle tag condition
    if (node.tag) {
      return (
        <div className="ml-2 border-l-2 border-gray-200 pl-3 py-1">
          <div className="font-medium text-gray-700 mb-1">{node.tag.name}</div>
          <div className="flex flex-wrap items-center gap-2">
            {node.tag.values.map((value, i, arr) => (
              <React.Fragment key={i}>
                <span className="bg-gray-100 px-2 py-1 rounded text-sm">
                  {value}
                </span>
                {i < arr.length - 1 && (
                  <span className="text-xs text-green-800 font-medium">OR</span>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    }

    // Handle condition group
    if (node.condition && node.children) {
      const isOr = node.condition === "OR";
      return (
        <div
          className={`${depth > 0 ? "ml-4 border-l-2 border-gray-200 pl-3" : ""}`}
        >
          {depth > 1 && (
            <div
              className={`text-xs ${isOr ? "text-green-800" : "text-blue-800"} font-medium mb-1`}
            >
              {node.condition}
            </div>
          )}
          {node.children.map((child, index) => (
            <React.Fragment key={index}>
              {renderConditionTree(child, depth + 1)}
              {index < node.children.length - 1 && (
                <div
                  className={`my-1 text-xs ${isOr ? "text-green-800" : "text-blue-800"} font-medium`}
                >
                  {node.condition}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      );
    }

    return null;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-3xl w-full mx-4 text-left">
        <h2 className="text-xl font-semibold mb-4">Create Data Slice</h2>

        <div className="text-sm text-gray-600 mb-6">
          This will create a new data slice that automatically filters files
          based on your selected conditions. You can modify these conditions
          later through the data slice settings.
        </div>

        <div className="space-y-4 mb-6 h-full flex overflow-y-auto flex-col max-h-[60vh]">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Data Slice Name
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
              value={formData.name}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, name: e.target.value }))
              }
              placeholder="Enter data slice name"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
              rows={3}
              value={formData.description}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              placeholder="Describe the data slice"
            />
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">
              Selected Conditions
            </h3>
            <div className="flex flex-col gap-1 mb-2">
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <span className="bg-green-100 text-green-800 px-2 py-0.5 rounded text-xs font-medium">
                  OR
                </span>
                <span>
                  Multiple condition groups will match if any group is satisfied
                </span>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <span className="bg-blue-100 text-blue-800 px-2 py-0.5 rounded text-xs font-medium">
                  AND
                </span>
                <span>Within each group, all conditions must be satisfied</span>
              </div>
            </div>

            {/* Display new condition format if available */}
            {Object.keys(processedConditions).length > 0 && (
              <div className="border border-gray-200 rounded-md p-4">
                {renderConditionTree(processedConditions)}
              </div>
            )}
          </div>

          <div className="bg-gray-50 p-4 rounded-md">
            <div className="text-sm text-gray-700">
              <span className="font-medium">Matching Files:</span>{" "}
              <span className="text-primary font-medium">
                {currentFileCount}
              </span>
            </div>
            <div className="text-xs text-gray-500 mt-1">
              Only these files will be included in the use case
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleCreateDataSlice}
            disabled={isCreating}
            className={`px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90 
              ${isCreating ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            {isCreating ? "Creating..." : "Create Data Slice"}
          </button>
        </div>
      </div>
    </div>
  );
}
