import { tokenService, connectorService, tagService } from "../services/api";
import { config } from "../config/config";
import { DB_OPTIONS } from "../components/MainContent/DeasyConfig/ConfigElements/utils";
import { unstructuredService } from "../services/api";
import { toast } from "react-hot-toast";
import {
  POSTGRESQL_TYPE,
  QDRANT_TYPE,
  S3_TYPE,
  SHAREPOINT_TYPE,
} from "../components/MainContent/DeasyConfig/ConfigElements/utils";
import { DESTINATION_OPTIONS } from "../components/MainContent/DeasyConfig/ConfigElements/utils";

export const prepareVectorDBConfiguration = (vectorDBConfiguration) => {
  if (!vectorDBConfiguration) {
    console.error("No vectorDBConfiguration provided");
    return null;
  }
  // Base configuration that's common for all DB types
  const baseConfig = {
    classname: vectorDBConfiguration.type,
    type: vectorDBConfiguration.type,
    // Only add keys if they exist in the configuration
    ...(vectorDBConfiguration.filename_key && {
      filename_key: vectorDBConfiguration.filename_key,
    }),
    ...(vectorDBConfiguration.text_key && {
      text_key: vectorDBConfiguration.text_key,
    }),
    ...(vectorDBConfiguration.name && {
      name: vectorDBConfiguration.name,
    }),
  };

  // Add tags_key only for PostgreSQL and only if it exists
  if (vectorDBConfiguration.type === POSTGRESQL_TYPE) {
    if (vectorDBConfiguration.tags_key) {
      baseConfig.tags_key = vectorDBConfiguration.tags_key;
    }
    if (vectorDBConfiguration.id_key) {
      baseConfig.id_key = vectorDBConfiguration.id_key;
    }
  }
  if (
    vectorDBConfiguration.type === QDRANT_TYPE ||
    vectorDBConfiguration.type === POSTGRESQL_TYPE
  ) {
    if (vectorDBConfiguration.index_info) {
      baseConfig.index_info = vectorDBConfiguration.index_info;
    }
  }

  // Add fields based on DB type
  const dbOption = DB_OPTIONS.find(
    (opt) => opt.value === vectorDBConfiguration.type,
  );

  if (dbOption) {
    dbOption.fields.forEach((field) => {
      switch (field) {
        case "apiKey":
          baseConfig.api_key = vectorDBConfiguration.apiKey;
          break;
        case "collection":
          baseConfig.collection_name = vectorDBConfiguration.collection;
          break;
        case "url":
          baseConfig.url = vectorDBConfiguration.url;
          break;
        case "bucketName":
          baseConfig.bucket_name = vectorDBConfiguration.bucketName;
          break;
        case "awsAccessKeyId":
          baseConfig.aws_access_key_id = vectorDBConfiguration.awsAccessKeyId;
          break;
        case "awsSecretAccessKey":
          baseConfig.aws_secret_access_key =
            vectorDBConfiguration.awsSecretAccessKey;
          break;
        // Handle PostgreSQL specific fields
        case "database_name":
          baseConfig.database_name = vectorDBConfiguration.database_name;
          break;
        case "password":
          baseConfig.password = vectorDBConfiguration.password;
          break;
        case "db_user":
          baseConfig.db_user = vectorDBConfiguration.db_user;
          break;
        case "port":
          baseConfig.port = vectorDBConfiguration.port || "5432";
          break;
        default:
          // For any other fields, pass them through as-is
          baseConfig[field] = vectorDBConfiguration[field];
          break;
      }
    });
  }

  // Add dimension threshold if it exists
  if (vectorDBConfiguration.dimensionThreshold) {
    baseConfig.dimension_threshold = vectorDBConfiguration.dimensionThreshold;
  }

  return baseConfig;
};

export const prepareEndpointManagerConfig = (endpointManagerConfig) => {
  return {
    model: endpointManagerConfig.model || endpointManagerConfig.llmModel, // TODO: remove llmModel once we have the new config
    embedding_model:
      endpointManagerConfig.embedding_model ||
      endpointManagerConfig?.llmEmbeddingModel, // TODO: remove llmEmbeddingModel once we have the new config
    api_key: endpointManagerConfig.api_key || endpointManagerConfig?.llmApiKey, // TODO: remove llmApiKey once we have the new config
    vendor: endpointManagerConfig.llmType.toLowerCase(),
    // seed: 42,
    // max_tokens: 1000,
    temperature: 0,
    rpm_completion: endpointManagerConfig?.requestsPerMinute ?? null,
    tpm_completion: endpointManagerConfig?.tokensPerMinute ?? null,
    rpm_embedding: endpointManagerConfig?.embeddingRequestsPerMinute ?? null,
    tpm_embedding: endpointManagerConfig?.embeddingTokensPerMinute ?? null,
  };
};

export const loadIndexFields = async (profiles, deasyApiKey) => {
  // Create an array of promises for each relevant profile
  const profilePromises = Object.keys(profiles)
    .filter((profileKey) => {
      const profile = profiles[profileKey];
      return profile.type === POSTGRESQL_TYPE || profile.type === QDRANT_TYPE;
    })
    .map(async (profileKey) => {
      const profile = profiles[profileKey];

      try {
        const indexFieldsResponse =
          await tokenService.getVectorDBIndexFieldInfo(
            profile.name,
            profile.type,
          );

        // Return an object containing the profile key and processed data
        return {
          profileKey,
          data: indexFieldsResponse.data,
          type: profile.type,
        };
      } catch (error) {
        console.error(
          `Error fetching index fields for ${profile.name}:`,
          error,
        );
        return { profileKey, error };
      }
    });

  // Execute all promises in parallel and process results
  const results = await Promise.all(profilePromises);

  // Update the profiles object with the results
  results.forEach((result) => {
    if (result.error) return; // Skip profiles with errors

    const { profileKey, data, type } = result;

    if (type === POSTGRESQL_TYPE) {
      profiles[profileKey].index_info = {
        found_indexes: data.found_indexes,
        total_indexes_found: data.total_indexes_found,
      };
    } else if (type === QDRANT_TYPE) {
      profiles[profileKey].index_info = {
        total_indexes_found: data.total_indexes_found,
      };
    }
  });

  return profiles; // Return updated profiles for potential chaining
};

export const fetchUserSecrets = async (setSavedTags, setDeasyUserConfig) => {
  // Initialize default values
  const defaults = {
    deasyApiKey: "",
    vectorDBConfiguration: config.vectorDB.default,
    deasyUserConfig: config.deasyUserConfig,
  };
  let deasyApiKey = defaults.deasyApiKey;

  try {
    // Load and parse user config
    const userConfigResult = await tokenService.getSecret("deasyUserConfig");
    const deasyUserConfig =
      JSON.parse(userConfigResult.data.secret) || defaults.deasyUserConfig;
    const updatedConfig = { ...deasyUserConfig };

    try {
      // Get subscription data and add it to the config
      const subscriptionResponse = await tokenService.getSubscriptionInfo();
      updatedConfig.subscription = subscriptionResponse.data;
    } catch (error) {
      console.error("Failed to load subscription data:", error);
      updatedConfig.subscription = null;
    }

    try {
      await tokenService.validateStripeSubscription();
    } catch (error) {
      console.error("Failed to validate subscription:", error);
    }

    setDeasyUserConfig(updatedConfig);
    const profiles = deasyUserConfig?.vdbmConfig?.Configs || {};

    try {
      await loadIndexFields(profiles, deasyApiKey);
    } catch (error) {
      console.error("Error loading index fields:", error);
    }
    // Load API key and tags
    try {
      deasyApiKey = deasyUserConfig.deasyApiKey;
      const savedTags = deasyApiKey
        ? (await tagService.getSavedTags(deasyApiKey)).data.tags || []
        : [];
      setSavedTags(savedTags);

      if (deasyApiKey && deasyUserConfig.vdbmConfig?.Configs) {
        const vdbConfigs = Object.values(
          deasyUserConfig.vdbmConfig.Configs,
        ).map((cfg) => prepareVectorDBConfiguration(cfg));

        if (vdbConfigs.length > 0) {
          unstructuredService
            .getSyncStats(deasyApiKey, vdbConfigs, false)
            .then((result) => {
              result.data.sources.forEach((source) => {
                if (updatedConfig.vdbmConfig.Configs[source.source_name]) {
                  if (source.added > 0) {
                    setTimeout(() => {
                      toast(
                        `Data source ${source.source_name} has added ${source.added} files since last sync`,
                        { duration: 5000 },
                      );
                    }, 1000);
                  }
                  updatedConfig.vdbmConfig.Configs[
                    source.source_name
                  ].syncStats = {
                    added: source.added,
                  };
                }
              });
              setDeasyUserConfig(updatedConfig);
            })
            .catch((error) =>
              console.error("Error fetching sync stats:", error),
            );
        }
      }
    } catch (error) {
      setSavedTags([]);
    }

    // Return the user config for additional checks
    return updatedConfig;
  } catch (error) {
    console.error("Error fetching user secrets:", error);
    return defaults.deasyUserConfig;
  }
};

export const fetchVDBProfiles = async (setVdbProfiles, deasyApiKey) => {
  const response = await connectorService.getVDBProfiles(deasyApiKey);
  setVdbProfiles(response.data.connectors);
};

export const fetchLlmProfiles = async (setLlmProfiles, deasyApiKey) => {
  const response = await connectorService.getLLMProfiles(deasyApiKey);
  setLlmProfiles(response.data.connectors);
};

export const prepareDestinationConfiguration = (destinationConfiguration) => {
  if (!destinationConfiguration) {
    console.error("No destinationConfiguration provided");
    return null;
  }

  // Base configuration that's common for all destination types
  const baseConfig = {
    classname: destinationConfiguration.type,
    type: destinationConfiguration.type,
  };

  // Add fields based on destination type
  const destOption = DESTINATION_OPTIONS.find(
    (opt) => opt.value === destinationConfiguration.type,
  );

  if (destOption) {
    destOption.fields.forEach((field) => {
      // Map the field to the appropriate API format
      baseConfig[field] = destinationConfiguration[field];
    });
  }

  return baseConfig;
};

// Function to create a PostgreSQL connector from an unstructured data connector
export const createPostgresFromUnstructuredUtils = (
  unstructuredConnector,
  user,
) => {
  // Determine source type and name components based on connector type
  let sourceType, bucketName;

  if (unstructuredConnector.type === SHAREPOINT_TYPE) {
    sourceType = "Sharepoint";
    bucketName = unstructuredConnector.sharepoint_site_name;
  } else if (unstructuredConnector.type === S3_TYPE) {
    sourceType = "S3";
    bucketName = unstructuredConnector.bucket_name;
  } else {
    toast.error("Unsupported connector type for conversion");
    return null;
  }

  // Format collection name as specified
  const collectionName = `${sourceType}_${bucketName}_${user}`;

  // Generate a name for the new connector
  const connectorName = `${unstructuredConnector.name} Vector DB`;

  // Return minimal info that will be passed to backend
  return {
    connector_name: connectorName,
    source_type: sourceType,
    bucket_name: bucketName,
    collection_name: collectionName,
  };
};
