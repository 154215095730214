import { createPortal } from "react-dom";

export const findNodeStats = (stats, nodePath) => {
  if (!stats || !nodePath) return null;

  let current = stats;

  for (const pathPart of nodePath) {
    if (!current) return null;

    // For root node
    if (pathPart === "root") {
      return current;
    }

    // Look for matching node in children
    if (current.children) {
      current = current.children.find((child) => child.name === pathPart);
    } else {
      return null;
    }
  }

  return current;
};

export const DeleteConfirmationModal = ({ onConfirm, onCancel, nodeName }) => {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-96 shadow-xl">
        <h3 className="text-lg font-semibold mb-2 text-left">{nodeName}</h3>
        <p className="mb-6 text-gray-600 text-left">
          Are you sure you want to delete this node and all its children? This
          action cannot be undone.
        </p>
        <div className="flex justify-end gap-3">
          <button
            className="px-4 py-2 rounded-md text-gray-600 hover:bg-gray-50 border border-gray-200"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 rounded-md bg-danger text-white hover:bg-danger/90"
            onClick={onConfirm}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export const handleConfirmNodeDelete = (setShowDeleteModal, data) => {
  if (data.onDeleteNode) {
    data.onDeleteNode(data.id);
  }
  setShowDeleteModal(false);
};

export const RerunConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  data,
  tagBody,
  nodeStats,
  vdbFilesCount,
  confirmationDetails,
}) => {
  if (!isOpen) return null;

  return createPortal(
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-[1000]"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-white rounded-xl shadow-xl p-8 w-[800px] max-h-[90vh] overflow-y-auto mx-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold">Confirm Run</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 p-2 rounded-full hover:bg-gray-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div className="space-y-6">
          <div className="flex items-center justify-between border-b pb-4">
            <p className="text-gray-600 text-lg">
              Processing{" "}
              {(confirmationDetails?.option === "node"
                ? nodeStats?.file_count || 0
                : vdbFilesCount?.total_files || 0
              ).toLocaleString()}{" "}
              file
              {confirmationDetails?.option === "node"
                ? (nodeStats?.file_count || 0) === 1
                  ? ""
                  : "s"
                : (vdbFilesCount?.total_files || 0) === 1
                  ? ""
                  : "s"}
              {confirmationDetails?.isRootSelection
                ? " from the entire dataset. "
                : ""}
              {confirmationDetails?.isRootSelection && (
                <span className="text-amber-600 font-medium ml-1">
                  Note: This will overwrite any existing values for this tag.
                </span>
              )}
            </p>
          </div>

          <div className="space-y-3">
            <div className="text-base font-medium text-gray-700">
              Selected Tag for Run
            </div>
            <div className="bg-gray-50 rounded-lg p-6">
              <div className="flex items-center justify-between px-6 py-4 bg-white rounded-lg border border-gray-100">
                <div className="flex items-center gap-3">
                  <span className="text-base font-medium text-gray-700">
                    {data.label}
                  </span>
                  {tagBody?.available_values?.length > 0 && (
                    <span className="text-sm text-gray-500 bg-gray-100 px-3 py-1 rounded-full">
                      {tagBody.available_values.length} values
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <p className="text-sm text-gray-500 bg-primary/5 p-4 rounded-lg border border-primary/10">
            This process may take some time depending on the size of your
            dataset.
          </p>
        </div>

        <div className="flex justify-end gap-3 mt-8 pt-6 border-t">
          <button
            onClick={onClose}
            className="px-6 py-2.5 rounded-lg border border-gray-300 text-gray-700 font-semibold hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-6 py-2.5 rounded-lg bg-primary text-white font-semibold hover:bg-primary-dark"
          >
            Run Tag
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export const DeleteValueNodeModal = ({
  isOpen,
  onClose,
  onConfirm,
  data,
  childNodes,
}) => {
  if (!isOpen) return null;

  return createPortal(
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-white rounded-lg p-6 max-w-sm w-96 shadow-xl">
        <h3 className="text-lg font-semibold mb-2 text-left">
          Delete "{data.label}"
        </h3>
        <div className="mb-6 space-y-4">
          <div className="text-gray-600 text-left">
            Are you sure you want to delete this value node?
          </div>

          {childNodes.length > 0 && (
            <div className="bg-red-50 p-4 rounded-lg">
              <p className="text-sm text-red-800 font-medium mb-2">
                The following tags will also be deleted from this schema:
              </p>
              <ul className="list-disc list-inside text-sm text-red-700 space-y-1">
                {childNodes.map((node, index) => (
                  <li key={index}>{node.label}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="flex justify-end gap-3">
          <button
            className="px-4 py-2 rounded-md text-gray-600 hover:bg-gray-50 border border-gray-200"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 rounded-md bg-danger text-white hover:bg-danger/90"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};
