import React, { useState, useEffect, useContext, useRef } from "react";
import ReactDOM from "react-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { metadataService } from "../../../../../../services/api";
import MetadataTable from "../../MetadataTable/MetadataTable";
import { nodePathToConditions } from "../GraphUtils";
import { RotateCw } from "lucide-react";

export const NodeInfoModal = ({
  nodeData,
  onClose,
  setUpdatedTags,
  portalTarget = document.body,
  vectorDBConfiguration,
  deasyApiKey,
}) => {
  const { savedTags, updatedTags } = useContext(BaseContext);

  const questionTagUpdated = updatedTags[nodeData.label];

  const [tagStats, setTagStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [valueSearchQuery, setValueSearchQuery] = useState("");
  const [valueLimit, setValueLimit] = useState(10);
  const [selectedValue, setSelectedValue] = useState(null);
  const [tableFilters, setTableFilters] = useState({});
  const [tableConditionsNew, setTableConditionsNew] = useState(null);
  const [manualRefresh, setManualRefresh] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const metadataTableRef = useRef(null);

  useEffect(() => {
    if (selectedValue && metadataTableRef.current) {
      metadataTableRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedValue]);

  const tagDetails = savedTags.find((tag) => tag.name === nodeData.label);

  const fetchTagStatistics = async () => {
    try {
      setIsRefreshing(true);
      if (loading) setLoading(true);

      const response = await metadataService.getTagStatistics(
        vectorDBConfiguration,
        [nodeData.label],
        deasyApiKey,
        nodePathToConditions(nodeData.nodePath),
      );

      setTagStats(response.data.tag_statistics[nodeData.label]);
    } catch (error) {
      console.error("Error fetching tag statistics:", error);
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  const handleRefresh = () => {
    setManualRefresh((prev) => prev + 1);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (nodeData.label) {
      setLoading(true);
      fetchTagStatistics();
    }
  }, [manualRefresh]);

  const prepareExtractionData = () => {
    if (!tagStats?.extraction_stats) return [];

    return [
      {
        name: "Extracted",
        value: tagStats.extraction_stats.extracted.count,
        color: "#4FA892",
        percentage: tagStats.extraction_stats.extracted.percentage,
      },
      {
        name: "Not Extracted",
        value: tagStats.extraction_stats.not_extracted.count,
        color: "#6c757d",
        percentage: tagStats.extraction_stats.not_extracted.percentage,
      },
    ];
  };

  const getFilteredChartData = () => {
    if (!tagStats?.value_distribution) return [];

    let data = Object.entries(tagStats.value_distribution)
      .map(([value, stats]) => ({
        value,
        count: stats.count,
        percentage: stats.percentage,
      }))
      // Filter based on search query and limit
      .filter((item) =>
        item.value.toLowerCase().includes(valueSearchQuery.toLowerCase()),
      )
      // Sort by count in descending order
      .sort((a, b) => b.count - a.count);

    // Apply limit if no search query
    if (!valueSearchQuery && valueLimit > 0) {
      data = data.slice(0, valueLimit);
    }

    return data;
  };

  const truncateValue = (value, maxLength = 15) => {
    if (value.length <= maxLength) return value;
    return value.slice(0, maxLength) + "...";
  };

  const handleBarClick = (data) => {
    const newValue = {
      tagName: nodeData.label,
      value: data.value,
    };
    setSelectedValue(newValue);
    // Initialize filters when bar is clicked
    setTableFilters({
      [nodeData.label]: [String(data.value)],
    });
    setTableConditionsNew({
      condition: "OR",
      children: [
        {
          condition: "OR",
          children: [
            { tag: { name: nodeData.label, values: [String(data.value)] } },
          ],
        },
      ],
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (questionTagUpdated) {
        // remove the tag from the updatedTags object
        setUpdatedTags((prev) => {
          const newUpdatedTags = { ...prev };
          delete newUpdatedTags[nodeData.label];
          return newUpdatedTags;
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (questionTagUpdated) {
        // remove the tag from the updatedTags object
        setUpdatedTags((prev) => {
          const newUpdatedTags = { ...prev };
          delete newUpdatedTags[nodeData.label];
          return newUpdatedTags;
        });
      }
    };
  }, [nodeData.label, questionTagUpdated, setUpdatedTags]);

  const modalContent = (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000] animate-fadeIn"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div
        className="bg-white relative rounded-lg p-6 w-[78vw] h-[90vh] overflow-y-auto mx-4 animate-slideIn"
        onClick={(e) => e.stopPropagation()}
      >
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold text-gray-900">
                Tag: {nodeData.label}
              </h2>
              <div className="flex items-center gap-2">
                <button
                  onClick={handleRefresh}
                  disabled={isRefreshing}
                  className="text-gray-600 hover:text-primary flex items-center gap-1 px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
                  title="Refresh statistics"
                >
                  <RotateCw
                    size={16}
                    className={isRefreshing ? "animate-spin" : ""}
                  />
                  <span className="text-sm">Refresh</span>
                </button>
                <button
                  onClick={onClose}
                  className="text-gray-400 hover:text-gray-600 p-1"
                >
                  ✕
                </button>
              </div>
            </div>
            {tagDetails && (
              <div className="mb-6 space-y-4">
                {tagDetails.description && (
                  <div>
                    <p className="text-lg font-medium mb-1">Description</p>
                    <p className="text-base text-gray-700">
                      {tagDetails.description}
                    </p>
                  </div>
                )}
                <div>
                  <p className="text-lg font-medium mb-1">Ouput Values</p>
                  {tagDetails.available_values?.length > 0 ? (
                    <div className="flex flex-wrap gap-2">
                      {tagDetails.available_values.map((value, index) => {
                        const isUpdatedValue =
                          questionTagUpdated &&
                          questionTagUpdated.includes(value);
                        return (
                          <span
                            key={index}
                            className={`relative inline-flex items-center px-2.5 py-0.5 rounded-full text-base font-medium ${
                              isUpdatedValue
                                ? "bg-orange-100 text-orange-500"
                                : "bg-primary/10 text-primary"
                            }`}
                            title={
                              isUpdatedValue
                                ? "This is a new available value that autosuggested generated. Re-run files or standardize the tag values to incorporate the new value."
                                : ""
                            }
                          >
                            {value}
                            {isUpdatedValue && (
                              <div className="absolute -top-0.5 -right-0.5">
                                <span className="flex h-2 w-2">
                                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-300 opacity-75"></span>
                                  <span className="relative inline-flex rounded-full h-2 w-2 bg-orange-500"></span>
                                </span>
                              </div>
                            )}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="space-y-4">
                      <div className="flex items-center gap-3">
                        <p className="text-base text-gray-700">Open-Ended</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="mb-4">
              <h2 className="text-lg font-medium mb-2">Node Information</h2>
              <p className="text-gray-600 text-base">
                View and manage metadata associated with this node. Track
                coverage and tagging progress.
              </p>
            </div>

            <div className="space-y-8">
              {/* Overview Section */}
              <div className="bg-gray-50 p-6 rounded-lg">
                <div className="grid grid-cols-3 gap-8">
                  <div>
                    <p className="text-sm text-gray-500 mb-1">Files Tagged</p>
                    <p className="text-2xl font-semibold text-gray-900">
                      {(tagStats?.total_files_with_tag || 0).toLocaleString()}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 mb-1">Total Files</p>
                    <p className="text-2xl font-semibold text-gray-900">
                      {(
                        (tagStats?.extraction_stats?.extracted?.count || 0) +
                        (tagStats?.extraction_stats?.not_extracted?.count || 0)
                      ).toLocaleString()}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 mb-1">Coverage</p>
                    <p className="text-2xl font-semibold text-gray-900">
                      {(tagStats?.percentage_of_total || 0).toFixed(1)}%
                    </p>
                  </div>
                </div>

                {/* Extraction Status Chart */}
                <div className="mt-6">
                  <p className="text-sm text-gray-500 mb-4">
                    Extraction Status
                  </p>
                  {tagStats?.extraction_stats ? (
                    <div className="h-60">
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={prepareExtractionData()}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            label={({ name, percent }) =>
                              `${name} (${(percent * 100).toFixed(0)}%)`
                            }
                          >
                            {prepareExtractionData().map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  ) : (
                    <div className="h-60 flex items-center justify-center bg-gray-100 rounded-lg">
                      <p className="text-gray-500">
                        No extraction data available
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* Value Distribution Chart */}
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider">
                    Value Distribution
                  </h3>
                  <div className="flex gap-4 items-center">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Search values..."
                        value={valueSearchQuery}
                        onChange={(e) => setValueSearchQuery(e.target.value)}
                        className="px-3 py-1 border rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-primary"
                      />
                      {valueSearchQuery && (
                        <button
                          onClick={() => setValueSearchQuery("")}
                          className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                        >
                          ✕
                        </button>
                      )}
                    </div>
                    <div className="flex items-center gap-2">
                      <label className="text-sm text-gray-600">Show top:</label>
                      <select
                        value={valueLimit}
                        onChange={(e) => setValueLimit(Number(e.target.value))}
                        className="px-2 py-1 border rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-primary"
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={0}>All</option>
                      </select>
                    </div>
                  </div>
                </div>
                {tagStats?.value_distribution &&
                Object.keys(tagStats.value_distribution).length > 0 ? (
                  <div className="h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={getFilteredChartData()}
                        margin={{ bottom: 70, right: 20 }}
                        className="group cursor-pointer"
                        onClick={(data) => {
                          if (data.activePayload && data.activePayload[0]) {
                            handleBarClick(data.activePayload[0].payload);
                          }
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                        <XAxis
                          dataKey="value"
                          tick={(props) => {
                            const { x, y, payload } = props;
                            return (
                              <g transform={`translate(${x},${y})`}>
                                <text
                                  x={0}
                                  y={0}
                                  dy={16}
                                  textAnchor="end"
                                  fill="#343a40"
                                  transform="rotate(-45)"
                                  className="cursor-pointer hover:fill-primary"
                                >
                                  {truncateValue(payload.value)}
                                </text>
                              </g>
                            );
                          }}
                          interval={0}
                          height={90}
                        />
                        <YAxis tick={{ fill: "#343a40" }} />
                        <Tooltip
                          content={({ active, payload }) => {
                            if (active && payload && payload.length) {
                              return (
                                <div className="bg-white border border-gray-200 p-2 rounded shadow-lg">
                                  <p className="text-sm font-medium break-all">
                                    {payload[0].payload.value}
                                  </p>
                                  <p className="text-sm text-gray-600">
                                    Count: {payload[0].value}
                                  </p>
                                  <p className="text-xs text-primary italic mt-1">
                                    Click to view files
                                  </p>
                                </div>
                              );
                            }
                            return null;
                          }}
                        />
                        <Bar
                          dataKey="count"
                          fill="#4FA892"
                          radius={[4, 4, 0, 0]}
                          className="hover:opacity-80 transition-opacity duration-200 cursor-pointer"
                          onClick={(data) => handleBarClick(data.payload)}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <div className="h-80 flex items-center justify-center bg-gray-100 rounded-lg">
                    <p className="text-gray-500">
                      No value distribution data available
                    </p>
                  </div>
                )}
              </div>
            </div>

            {selectedValue && (
              <div ref={metadataTableRef} className="mt-8">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-medium">
                    Files tagged with {selectedValue.tagName}:{" "}
                    {selectedValue.value}
                  </h3>
                  <button
                    onClick={() => {
                      setSelectedValue(null);
                      setTableFilters({});
                      setTableConditionsNew(null);
                    }}
                    className="text-gray-400 hover:text-gray-600 p-1"
                  >
                    ✕
                  </button>
                </div>
                <MetadataTable
                  backendFilters={tableFilters}
                  onFiltersChange={setTableFilters}
                  nodeData={nodeData}
                  conditionsNew={tableConditionsNew}
                  setConditionsNew={setTableConditionsNew}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, portalTarget);
};
