import React, { useState, useContext, useCallback, useEffect } from "react";
import {
  FiChevronDown,
  FiChevronRight,
  FiChevronLeft,
  FiCircle,
  FiInfo,
  FiFilter,
} from "react-icons/fi";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { metadataService } from "../../../../../services/api";
import { useDrag } from "react-dnd";
import { GraphContext } from "../../../../../contexts/GraphContext";
import { prepareVectorDBConfiguration } from "../../../../../services/utils";
import { getTagType } from "./TagTableUtils";
import { CompactsTableHeader } from "./TagTableUtils";
import { tagGroupService } from "../../../../../services/api";

const CompactTagRow = ({
  tag,
  isSelected,
  onSelect,
  isExpanded,
  onToggleExpand,
}) => {
  const { discoveryGraphData } = useContext(GraphContext);
  const [{ isDragging }, dragRef] = useDrag({
    type: "TAG",
    item: { tag },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    options: {
      dropEffect: "move",
    },
  });

  const handleDragStart = useCallback(
    (e) => {
      e.dataTransfer.setData("draggedItem", JSON.stringify(tag));
      e.dataTransfer.effectAllowed = "move";
    },
    [tag],
  );

  const renderAvailableValues = () => {
    if (
      !Array.isArray(tag.available_values) ||
      tag.available_values.length === 0
    ) {
      const typeMap = {
        number: "Any number",
        date: "Any date",
        text: "Any text",
      };
      return (
        <span className="text-xs text-gray-500 italic">
          {typeMap[tag.output_type] || "Any text"}
        </span>
      );
    }

    return (
      <div className="flex flex-col gap-1">
        <div className="flex flex-wrap gap-1">
          {tag.available_values.slice(0, 3).map((value, idx) => (
            <span
              key={idx}
              className="text-xs bg-gray-100 text-gray-700 px-2 py-1 rounded-full"
            >
              {value}
            </span>
          ))}
          {tag.available_values.length > 3 && (
            <span className="text-xs text-gray-500">
              +{tag.available_values.length - 3} more
            </span>
          )}
        </div>
        {tag.maxValues && (
          <span className="text-xs text-gray-500 italic">
            Max values: {tag.maxValues}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      ref={dragRef}
      onDragStart={handleDragStart}
      draggable
      className={`border-b border-gray-200 ${
        isSelected ? "bg-green-50" : "hover:bg-gray-50"
      } cursor-pointer transition-colors ${isDragging ? "opacity-50" : ""}`}
    >
      <div
        className="flex items-center px-4 py-3 text-left"
        onClick={() => {
          onSelect(tag);
          onToggleExpand();
        }}
      >
        <button
          className="mr-2 p-1 hover:bg-gray-200 rounded"
          onClick={(e) => {
            e.stopPropagation();
            onToggleExpand();
          }}
        >
          {isExpanded ? <FiChevronDown /> : <FiChevronRight />}
        </button>

        <div className="flex-1">
          <div className="font-medium">
            {tag.name}
            {discoveryGraphData[tag.name] && (
              <div className="relative inline-block group">
                <FiCircle className="inline-block ml-2 text-green-600" />
                <span className="absolute left-1/2 -translate-x-1/2 top-full mt-1 px-2 py-1 bg-gray-800 text-white text-xs rounded whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
                  This tag is a node in the selected schema
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center gap-2">
          <span
            className={`px-2 py-1 text-xs font-semibold rounded-full ${getTagType(tag).style}`}
          >
            {getTagType(tag).label}
          </span>
        </div>
      </div>

      {isExpanded && (
        <div className="px-11 pb-3">
          <p className="text-sm text-gray-600 mb-2">{tag.description}</p>
          <div className="mt-2">{renderAvailableValues()}</div>
        </div>
      )}
    </div>
  );
};

const CompactTagsTable = ({ tagInEditor, setTagInEditor }) => {
  const { savedTags, deasyUserConfig, uniqueTags, setUniqueTags, deasyApiKey } =
    useContext(BaseContext);
  const { selectedFiles } = useContext(GraphContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 40; // You can adjust this number
  const [localShowUnique, setLocalShowUnique] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Tag Groups
  const [tagGroups, setTagGroups] = useState([]);
  const [selectedTagGroup, setSelectedTagGroup] = useState("");

  useEffect(() => {
    // Load tag groups from API
    const loadTagGroups = async () => {
      if (deasyApiKey) {
        try {
          const response = await tagGroupService.getTagGroups(deasyApiKey);
          setTagGroups(response.data.tag_groups);
        } catch (error) {
          console.error("Error loading tag groups:", error);
          setTagGroups([]);
        }
      }
    };

    loadTagGroups();
  }, [deasyApiKey]);

  useEffect(() => {
    const fetchUniqueTags = async () => {
      if (localShowUnique && deasyApiKey && uniqueTags.length === 0) {
        try {
          setIsLoading(true);
          const vectorDBConfig = prepareVectorDBConfiguration(
            deasyUserConfig.vdbmConfig.Configs[
              deasyUserConfig.vdbmConfig.LastActive
            ],
          );

          const response = await metadataService.getUniqueTags(
            deasyApiKey,
            selectedFiles,
            vectorDBConfig,
          );

          // Create a map of saved tags for quick lookup
          const savedMap = new Map(savedTags.map((tag) => [tag.name, tag]));

          // Convert unique tags to the right format
          const tags = response.data.tags.map((tagName) => ({
            name: tagName,
            isUnique: true,
            ...(savedMap.get(tagName) || {}),
          }));

          setUniqueTags(tags);
        } catch (error) {
          console.error("Error fetching unique tags:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchUniqueTags();
  }, [
    deasyApiKey,
    localShowUnique,
    deasyUserConfig,
    selectedFiles,
    savedTags,
    setUniqueTags,
    uniqueTags.length,
  ]);

  const filteredTags = useCallback(() => {
    const tagsToFilter = localShowUnique ? uniqueTags : savedTags || [];
    return tagsToFilter.filter((tag) => {
      const matchesSearch =
        tag?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tag?.description?.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesType =
        !selectedType || getTagType(tag).label === selectedType;

      // Filter by tag group
      const matchesGroup =
        !selectedTagGroup ||
        tagGroups.some(
          (group) =>
            group.name === selectedTagGroup &&
            group.tag_ids &&
            group.tag_ids.includes(tag.tag_id),
        );

      return matchesSearch && matchesType && matchesGroup;
    });
  }, [
    savedTags,
    uniqueTags,
    searchTerm,
    selectedType,
    localShowUnique,
    selectedTagGroup,
    tagGroups,
  ]);

  const toggleExpand = useCallback((tagId) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(tagId)) {
        newSet.delete(tagId);
      } else {
        newSet.add(tagId);
      }
      return newSet;
    });
  }, []);

  const Pagination = ({ total, current, onChange }) => (
    <div className="flex items-center justify-between px-4 py-3 border-t border-gray-200">
      <div className="flex items-center gap-2">
        <span className="text-sm text-gray-700">
          Showing {Math.min((current - 1) * itemsPerPage + 1, total)} to{" "}
          {Math.min(current * itemsPerPage, total)} of {total} tags
        </span>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={() => onChange(current - 1)}
          disabled={current === 1}
          className="p-2 rounded hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <FiChevronLeft />
        </button>
        <span className="text-sm text-gray-700">
          Page {current} of {Math.ceil(total / itemsPerPage)}
        </span>
        <button
          onClick={() => onChange(current + 1)}
          disabled={current >= Math.ceil(total / itemsPerPage)}
          className="p-2 rounded hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <FiChevronRight />
        </button>
      </div>
    </div>
  );

  return (
    <div
      className="bg-white flex flex-col h-full"
      onClick={(e) => e.stopPropagation()}
    >
      <CompactsTableHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        localShowUnique={localShowUnique}
        setLocalShowUnique={setLocalShowUnique}
        selectedTagGroup={selectedTagGroup}
        setSelectedTagGroup={setSelectedTagGroup}
        tagGroups={tagGroups}
        onNewTag={() => setTagInEditor({})}
      />

      {(searchTerm || selectedType || localShowUnique || selectedTagGroup) && (
        <div className="px-4 py-2 bg-gray-50 border-b border-gray-200 flex items-center gap-2">
          <FiFilter className="text-gray-500" />
          <span className="text-sm text-gray-600">Filters:</span>
          {searchTerm && (
            <span className="text-xs bg-blue-50 text-blue-700 px-2 py-1 rounded-full">
              Search: "{searchTerm}"
            </span>
          )}
          {selectedType && (
            <span className="text-xs bg-purple-50 text-purple-700 px-2 py-1 rounded-full">
              Type: {selectedType}
            </span>
          )}
          {selectedTagGroup && (
            <span className="text-xs bg-emerald-50 text-emerald-700 px-2 py-1 rounded-full">
              Group:{" "}
              {tagGroups.find((g) => g.id === selectedTagGroup)?.name ||
                selectedTagGroup}
            </span>
          )}
          {localShowUnique && (
            <div className="relative group">
              <span className="text-xs bg-green-50 text-green-700 px-2 py-1 rounded-full">
                Extracted Tags Only
              </span>
            </div>
          )}
        </div>
      )}

      <div className="flex-1 overflow-auto">
        {isLoading ? (
          <div className="flex flex-col items-center justify-center h-full p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mb-4"></div>
            <p className="text-gray-600">Loading extracted tags...</p>
          </div>
        ) : filteredTags().length > 0 ? (
          filteredTags()
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((tag) => (
              <CompactTagRow
                key={tag.tag_id || tag.name}
                tag={tag}
                isSelected={
                  tagInEditor?.tag_id === tag.tag_id ||
                  tagInEditor?.name === tag.name
                }
                onSelect={() => setTagInEditor(tag)}
                isExpanded={expandedRows.has(tag.tag_id || tag.name)}
                onToggleExpand={() => toggleExpand(tag.tag_id || tag.name)}
              />
            ))
        ) : (
          <div className="flex flex-col items-center justify-center h-full p-8">
            <div className="bg-gray-100 rounded-full p-3 mb-4">
              <FiInfo className="text-gray-500 w-6 h-6" />
            </div>
            <h3 className="text-lg font-medium text-gray-700 mb-2">
              No tags found
            </h3>
            <p className="text-gray-500 text-center max-w-md">
              {localShowUnique
                ? "No extracted tags found in your documents. Try selecting different files or creating tags manually."
                : "No tags match your current filters. Try adjusting your search or filter criteria."}
            </p>
            {(searchTerm ||
              selectedType ||
              selectedTagGroup ||
              localShowUnique) && (
              <button
                onClick={() => {
                  setSearchTerm("");
                  setSelectedType("");
                  setSelectedTagGroup("");
                  setLocalShowUnique(false);
                }}
                className="mt-4 px-3 py-2 bg-white border border-gray-300 rounded-lg text-sm hover:bg-gray-50"
              >
                Clear filters
              </button>
            )}
          </div>
        )}
      </div>

      {filteredTags().length > 0 && (
        <Pagination
          total={filteredTags().length}
          current={currentPage}
          onChange={setCurrentPage}
        />
      )}
    </div>
  );
};

export default React.memo(CompactTagsTable);

export { CompactTagRow };
