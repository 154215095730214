import React, { useState, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { tokenService } from "../../../../services/api";
import { toast } from "react-hot-toast";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionModal = ({ showModal, tiers, onClose, loadTokens }) => {
  const [selectedTier, setSelectedTier] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const scrollContainerRef = useRef(null);

  const sortedTiers =
    tiers?.sort((a, b) => {
      const aNum = parseInt(a.tier_id.replace(/\D/g, ""));
      const bNum = parseInt(b.tier_id.replace(/\D/g, ""));
      return aNum - bNum;
    }) || [];

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      const position = (scrollLeft / (scrollWidth - clientWidth)) * 100;
      const tierIndex = Math.floor((position / 100) * (sortedTiers.length - 1));
      setSelectedTier(sortedTiers[tierIndex]?.tier_id);
    }
  };

  const handleSubscribe = async () => {
    console.log("selectedTier", selectedTier);
    if (!selectedTier) return;

    setIsLoading(true);
    try {
      const response =
        await tokenService.createSubscriptionSession(selectedTier);
      if (response.data.operation !== "create") {
        await loadTokens();
        toast.success(
          "Your subscription has been updated successfully. The new tier is active now. Please check your Stripe account for more details.",
          { duration: 3000 },
        );
        onClose();
        return;
      }

      // For new subscriptions, proceed with Stripe checkout
      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: response.data.session_id,
      });

      if (result.error) {
        console.error(result.error.message);
        toast.error(result.error.message);
      }
    } catch (error) {
      console.error("Error creating subscription:", error);
      toast.error(
        "Failed to subscribe. Please contact the Deasy team for assistance.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (!showModal || !tiers) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl p-8 max-w-5xl w-full mx-4 shadow-2xl relative">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
          Choose Your Plan
        </h2>

        {/* Triangular Slider Section */}
        <div
          ref={scrollContainerRef}
          className="overflow-x-auto pb-4 mb-8 scrollbar-hide"
          onScroll={handleScroll}
        >
          <div className="flex items-end space-x-4" style={{ width: "120%" }}>
            {sortedTiers.map((tier, index) => {
              const height = 80 + index * 25;
              const tierName = tier.tier_id
                .replace(/['"]/g, "")
                .replace("_", " ")
                .replace(/([a-z])([A-Z])/g, "$1 $2");
              return (
                <div
                  key={tier.tier_id}
                  className={`flex-shrink-0 transition-all duration-300 cursor-pointer
                    ${selectedTier === tier.tier_id ? "scale-105" : ""}`}
                  style={{ height: `${height}px`, width: "130px" }}
                  onClick={() => setSelectedTier(tier.tier_id)}
                >
                  <div
                    className={`h-full w-full rounded-lg flex items-end justify-center p-2
                      ${
                        selectedTier === tier.tier_id
                          ? "bg-teal-600 text-white shadow-lg"
                          : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                      }`}
                  >
                    <div className="text-center">
                      <p className="text-base font-bold">{tierName}</p>
                      <p className="text-xs opacity-75">
                        {(tier.max_tags_stored_limit / 1000).toFixed(0)}k
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Metrics Display */}
        {selectedTier && (
          <div className="bg-gray-50 rounded-xl p-6 mb-8 transform transition-all duration-300">
            <div className="grid grid-cols-3 gap-8">
              <div className="text-center">
                <p className="text-gray-600 mb-2">Monthly Fee</p>
                <div className="flex items-end justify-center gap-1">
                  <p className="text-4xl font-bold text-gray-600">
                    $
                    {
                      sortedTiers.find((t) => t.tier_id === selectedTier)
                        ?.monthly_fee
                    }
                  </p>
                </div>
              </div>
              <div className="text-center">
                <div className="flex items-center justify-center gap-1.5 mb-2">
                  <p className="text-gray-600">Max Tags Stored</p>
                  <div className="group relative inline-flex">
                    <div className="w-4 h-4 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium text-gray-500 cursor-help select-none pointer-events-none">
                      ?
                    </div>
                    <div className="hidden group-hover:block absolute z-50 w-64 p-2 text-sm bg-gray-900 text-white rounded-md -top-1 left-5 shadow-lg">
                      Number of tags is calculated as: number of tags × number
                      of chunks they classify (e.g. classifying 2 tags on a
                      document with 10 chunks, the total tags created would be
                      20.)
                    </div>
                  </div>
                </div>
                <div className="flex items-end justify-center gap-1">
                  <p className="text-4xl font-bold text-teal-600">
                    {(
                      sortedTiers.find((t) => t.tier_id === selectedTier)
                        ?.max_tags_stored_limit / 1000000
                    ).toFixed(1)}
                  </p>
                  <p className="text-xl font-semibold text-teal-600 mb-1">M</p>
                </div>
              </div>
              <div className="text-center">
                <div className="flex items-center justify-center gap-1.5 mb-2">
                  <p className="text-gray-600">Max Tags Created Monthly</p>
                  <div className="group relative inline-flex">
                    <div className="w-4 h-4 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium text-gray-500 cursor-help select-none pointer-events-none">
                      ?
                    </div>
                    <div className="hidden group-hover:block absolute z-50 w-64 p-2 text-sm bg-gray-900 text-white rounded-md -top-1 left-5 shadow-lg">
                      Monthly limit is calculated as: number of tags × number of
                      chunks they classify (e.g. classify 2 tag on a document
                      with 10 chunks, the total tags created would be 20)
                    </div>
                  </div>
                </div>
                <div className="flex items-end justify-center gap-1">
                  <p className="text-4xl font-bold text-teal-600">
                    {(
                      sortedTiers.find((t) => t.tier_id === selectedTier)
                        ?.max_tags_created_limit / 1000
                    ).toFixed(0)}
                  </p>
                  <p className="text-xl font-semibold text-teal-600 mb-1">K</p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-center space-x-4">
          <button
            onClick={handleSubscribe}
            disabled={!selectedTier || isLoading}
            className={`px-10 py-3 rounded-full text-lg font-semibold transition-all duration-300
              ${
                selectedTier && !isLoading
                  ? "bg-teal-600 text-white hover:bg-teal-700 hover:shadow-lg transform hover:-translate-y-0.5"
                  : "bg-gray-200 text-gray-400 cursor-not-allowed"
              }`}
          >
            {isLoading ? "Processing payment..." : "Continue to checkout"}
          </button>
        </div>

        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SubscriptionModal;
