import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { BaseContext } from "../../../../../../../contexts/BaseContext";
import { GraphContext } from "../../../../../../../contexts/GraphContext";
import {
  Settings,
  Database,
  Zap,
  Info,
  ChevronDown,
  Layers,
  List,
} from "lucide-react";
import { cleanSchema } from "./AutoSuggestUtils";
import {
  metadataService,
  tagService,
  suggestionService,
} from "../../../../../../../services/api";
import toast from "react-hot-toast";
import { LinearProgress, Box, Typography, Modal } from "@mui/material";
import {
  useJobProgress,
  classifyAll,
} from "../../../../../DeasyConfig/ConfigElements/useJobProgress";
import { nodePathToConditions } from "../../GraphUtils";
import { PathNavigator } from "./AutoSuggestUtils";
import { getNewConditionsFromNodes } from "../../ExtractMetadata/components/Utils";
import * as Sentry from "@sentry/react";
import { TAG_TYPES } from "../../GraphUtils";

const DEFAULT_DEPTH_FLAT = 1;
const DEFAULT_DEPTH_HIERARCHY = 4;
const FLAT_VAR = "flat";
const HIERARCHY_VAR = "hierarchy";
export const AutoSuggestedMetadata = ({
  generatingSchema,
  setGeneratingSchema,
}) => {
  const {
    deasyUserConfig,
    vdbFilesCount,
    unsyncedVDBFilesCount,
    metadataReadinessStatus,
    setSavedTags,
    savedTags,
    uniqueTags,
    setUpdatedTags,
  } = useContext(BaseContext);

  const {
    discoveryGraphData,
    updateDiscoveryGraphData,
    selectedDiscoveryGraph,
    handleSaveGraph,
    selectedNodeData,
    startAtomicUpdate,
    hierarchyStats,
  } = useContext(GraphContext);

  const {
    vdbmConfig: { Configs: vdbmConfigs, LastActive: vdbmLastActive },
    llmConfig: { LastActive: llmLastActive },
    deasyApiKey,
  } = deasyUserConfig;

  const vectorDBConfiguration = vdbmConfigs[vdbmLastActive];

  const [scope, setScope] = useState("file");
  const [useExtracted, setUseExtracted] = useState(false);
  const [useExisting, setUseExisting] = useState(false);
  const [tagType, setTagType] = useState(TAG_TYPES.ANY);
  const [useNetNew, setUseNetNew] = useState(true);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [inputTextContext, setInputTextContext] = useState("");
  const [maxDepth, setMaxDepth] = useState(1);
  const [maxNumberOfTags, setMaxNumberOfTags] = useState(10);
  const [disableAutoSuggest, setDisableAutoSuggest] = useState(false);
  const [isClassifying, setIsClassifying] = useState(false);
  const [selectedSource, setSelectedSource] = useState("netNew");
  const [jobId, setJobId] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showMiniWarning, setShowMiniWarning] = useState(false);
  const [showPrepareDataDialog, setShowPrepareDataDialog] = useState(false);
  const [oobTaggedFileCount, setOobTaggedFileCount] = useState(0);
  const [loadingOOBTaggedFileCount, setLoadingOOBTaggedFileCount] =
    useState(false);
  const [structureType, setStructureType] = useState(FLAT_VAR);

  const handleStructureTypeChange = (type) => {
    setStructureType(type);
    setMaxDepth(
      type === FLAT_VAR ? DEFAULT_DEPTH_FLAT : DEFAULT_DEPTH_HIERARCHY,
    );
  };

  const getFileCountForNode = (hierarchyStats, nodePath) => {
    if (!nodePath?.length) {
      return hierarchyStats?.file_count || 0;
    }

    let current = hierarchyStats;
    if (!hierarchyStats) return 0;

    for (let i = 0; i < nodePath.length; i++) {
      const pathSegment = nodePath[i];
      const matchingNode = current.children?.find(
        (child) => child.name?.toLowerCase() === pathSegment?.toLowerCase(),
      );
      if (!matchingNode) return 0;
      current = matchingNode;
    }
    return current.file_count || 0;
  };

  const getTotalDatasetsCount = useCallback(
    (hierarchyStats, nodePath) => {
      if (!nodePath?.length) {
        return vdbFilesCount?.total_files || hierarchyStats?.file_count || 0;
      }
      return getFileCountForNode(hierarchyStats, nodePath);
    },
    [vdbFilesCount],
  );

  const allFilesSynthesized = useMemo(() => {
    const totalCount = getTotalDatasetsCount(
      hierarchyStats,
      selectedNodeData?.nodePath,
    );
    return totalCount > 0 && oobTaggedFileCount === totalCount;
  }, [
    hierarchyStats,
    selectedNodeData,
    getTotalDatasetsCount,
    oobTaggedFileCount,
  ]);

  const handleSourceSelect = (source) => {
    setSelectedSource(source);
    setUseExisting(source === "existing");
    setUseExtracted(source === "extracted");
    setUseNetNew(source === "netNew");
  };

  const updateGraphData = (suggestion, nodePath) => {
    // Create a deep copy of the discovery graph data
    const newGraphData = JSON.parse(JSON.stringify(discoveryGraphData || {}));

    let currentNode = newGraphData;
    for (let i = 0; i < nodePath?.length - 1; i++) {
      if (!currentNode[nodePath[i]]) {
        currentNode[nodePath[i]] = {};
      }
      currentNode = currentNode[nodePath[i]];
    }

    const cleanedSuggestion = cleanSchema(suggestion);

    // Helper function to ensure value nodes have correct configuration
    const ensureValueNodes = (node, autoSuggestion) => {
      if (!node || typeof node !== "object") return node;

      Object.entries(node).forEach(([key, value]) => {
        // Only process if the key exists in the suggestion
        if (key in autoSuggestion) {
          if (value?.TagAvailableValues) {
            // For flat structure, always set to ['<ANY>']
            // For hierarchical, only set to ['<ANY>'] if no values specified
            if (
              structureType === FLAT_VAR ||
              !Array.isArray(value.TagAvailableValues) ||
              value.TagAvailableValues.length === 0
            ) {
              node[key] = {
                ...value,
                TagAvailableValues:
                  tagType === TAG_TYPES.BINARY ? ["Yes", "No"] : ["<ANY>"],
              };
            }
          } else if (typeof value === "object") {
            ensureValueNodes(value, autoSuggestion[key] || {});
          }
        }
      });
      return node;
    };

    if (!nodePath?.length) {
      Object.keys(cleanedSuggestion).forEach((key) => {
        currentNode[key] = cleanedSuggestion[key];
      });
    } else {
      currentNode[nodePath.at(-1)] = cleanedSuggestion;
    }

    // Process the entire graph to ensure value nodes
    ensureValueNodes(newGraphData, cleanedSuggestion);
    return newGraphData;
  };

  const updateTagsFromSuggestion = async (createdTags) => {
    // get tags to their updated available values
    const updatedTags = {};
    const newTags = [];
    // Check if createdTags is an array and not null/undefined before iterating
    if (Array.isArray(createdTags)) {
      for (const tag of createdTags) {
        updatedTags[tag.deasy_tag.name] = tag.new_available_values;
        newTags.push(tag.deasy_tag);
      }
    } else {
      console.warn("createdTags is not an array:", createdTags);
    }

    setUpdatedTags(updatedTags);

    if (Object.keys(updatedTags).length > 0) {
      toast.success(
        <div className="space-y-2">
          <p>The following tags have new available values:</p>
          <ul className="list-disc list-inside">
            {Object.keys(updatedTags).map((tagName) => (
              <li key={tagName} className="font-medium">
                {tagName}
              </li>
            ))}
          </ul>
          <p>
            Please review these tags via their tag modals to standardize values
            and re-run existing files.
          </p>
        </div>,
        {
          duration: 15000, // 15 seconds
        },
      );
    }

    const tags = await tagService.getSavedTags(deasyApiKey);
    setSavedTags(tags.data.tags || []);
  };

  const handleAutoSuggest = async () => {
    if (generatingSchema) return;

    const endAtomicUpdate = startAtomicUpdate();

    try {
      setGeneratingSchema(true);

      const nodeLabel =
        selectedNodeData?.nodeType === "value"
          ? selectedNodeData.nodePath.at(-2)
          : selectedNodeData?.label;

      const nodeObject = selectedNodeData
        ? {
            data: {
              nodePath: selectedNodeData.nodePath || [],
            },
          }
        : null;

      const conditions = getNewConditionsFromNodes(
        nodeObject ? [nodeObject] : [],
      );

      const response = await suggestionService.suggestTags(
        vdbmLastActive,
        llmLastActive,
        discoveryGraphData,
        {
          label: nodeLabel,
          path: selectedNodeData.nodePath,
        },
        inputTextContext,
        scope,
        [],
        structureType === FLAT_VAR ? 1 : maxDepth,
        deasyApiKey,
        {
          use_existing_tags: useExisting,
          use_extracted_tags: useExtracted,
          use_net_new_tags: useNetNew,
          structure_type: structureType,
          output_type: tagType,
          max_number_of_tags: maxNumberOfTags,
        },
        conditions,
      );

      console.log(
        `Auto-suggesting schema with ${maxNumberOfTags} tags requested`,
      );

      if (!response?.data?.suggestion) {
        throw new Error("Invalid response format from suggestion service");
      }

      const suggestion = response.data.suggestion;
      const updatedGraphData = updateGraphData(
        suggestion,
        selectedNodeData.nodePath,
      );
      updateDiscoveryGraphData(updatedGraphData);
      await handleSaveGraph({
        ...selectedDiscoveryGraph,
        graph_data: updatedGraphData,
      });
      // Ensure created_tags is an array before passing it to updateTagsFromSuggestion
      const createdTags = Array.isArray(response.data.created_tags)
        ? response.data.created_tags
        : [];
      await updateTagsFromSuggestion(createdTags);
      toast.success("Schema updated successfully");
    } catch (error) {
      const errorMessage =
        error.response?.data?.detail ||
        error.message ||
        "Unknown error occurred";
      toast.error(errorMessage);
      Sentry.captureException(error);
      console.error("Auto-suggest failed:", error);
    } finally {
      endAtomicUpdate();
      setGeneratingSchema(false);
    }
  };

  const { progress: classifyProgress, additionalStats } = useJobProgress({
    jobId,
    isRunning: isClassifying,
    onComplete: () => {
      setIsClassifying(false);
      setJobId(null);
    },
    onError: (error) => {
      toast.error(error.response?.data?.detail || "Synthesis failed");
      setIsClassifying(false);
      setJobId(null);
    },
    apiKey: deasyApiKey,
    successMessage: null,
    onProgress: (progress) => {
      setOobTaggedFileCount(
        Math.max(progress.completed_progress_increments, oobTaggedFileCount),
      );
    },
  });

  useEffect(() => {
    const readyFilesCount = vdbFilesCount - unsyncedVDBFilesCount;
    setDisableAutoSuggest(readyFilesCount === 0);
  }, [vdbFilesCount, unsyncedVDBFilesCount, metadataReadinessStatus]);

  useEffect(() => {
    const fetchTagStatistics = async () => {
      try {
        setLoadingOOBTaggedFileCount(true);
        const conditions = nodePathToConditions(
          selectedNodeData?.nodePath || [],
        );
        const response = await metadataService.getOOBTaggedFileCount(
          vectorDBConfiguration,
          conditions,
          deasyApiKey,
        );
        setOobTaggedFileCount(response.data.fully_tagged_files || 0);
      } catch (error) {
        console.error("Failed to fetch OOB tagged files count:", error);
        toast.error("Failed to fetch OOB tagged files count.");
      } finally {
        setLoadingOOBTaggedFileCount(false);
      }
    };

    if (vectorDBConfiguration && deasyApiKey) {
      fetchTagStatistics();
    }
  }, [selectedNodeData?.nodePath, vectorDBConfiguration, deasyApiKey]);

  const handleAutoSuggestClick = () => {
    if (generatingSchema || disableAutoSuggest) return;

    // Check if using GPT-4o-mini
    const currentLLMConfig =
      deasyUserConfig?.llmConfig?.Configs[
        deasyUserConfig?.llmConfig?.LastActive
      ];
    if (
      currentLLMConfig?.model ||
      currentLLMConfig?.llmModel === "gpt-4o-mini"
    ) {
      setShowMiniWarning(true);
      return;
    }

    setShowConfirmDialog(true);
  };

  return (
    <div className="flex flex-col p-4 pt-0 space-y-6">
      {/* Header */}
      <div className="flex flex-col">
        <div className="flex items-center space-x-3 mt-4">
          <h2 className="text-xl font-semibold text-left">
            <span className="text-primary">
              <PathNavigator nodePath={selectedNodeData?.nodePath} />
            </span>
          </h2>
        </div>
        <div className="text-gray-600 text-base text-left italic">
          Let Deasy suggest the best representation of your data in an organized
          structure
        </div>
      </div>

      {/* Dataset Stats Section */}
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <Database size={24} className="text-primary" />
          <h3 className="text-lg font-medium text-left">Data Overview</h3>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-emerald-50 border border-emerald-100 rounded-lg p-4">
            <div className="text-sm text-emerald-600 font-medium mb-1 text-left flex items-center justify-start gap-1">
              Ready to Use
              <div className="group relative">
                <Info size={16} className="text-emerald-400 cursor-help" />
                <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">
                  Files that have been analyzed and are ready for schema
                  generation
                </div>
              </div>
            </div>
            <div className="flex items-baseline justify-start text-left">
              <span className="text-2xl font-bold text-emerald-700">
                {loadingOOBTaggedFileCount ? (
                  <span className="inline-block animate-[pulse_1s_ease-in-out_infinite] text-2xl font-bold tracking-wider opacity-20 hover:opacity-90">
                    ...
                  </span>
                ) : (
                  oobTaggedFileCount
                )}
              </span>
            </div>
          </div>

          <div className="bg-gray-50 border border-gray-100 rounded-lg p-4">
            <div className="text-sm text-gray-600 font-medium mb-1 text-left flex items-center justify-start gap-1">
              Preparation Progress
              <div className="group relative">
                <Info size={16} className="text-gray-400 cursor-help" />
                <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">
                  How much of your data has been analyzed
                </div>
              </div>
            </div>
            {!selectedNodeData?.nodePath?.length ? (
              !vdbFilesCount?.total_files || loadingOOBTaggedFileCount ? (
                <div className="flex items-left justify-left gap-2 text-gray-600">
                  <span className="inline-block animate-[pulse_1s_ease-in-out_infinite] text-2xl font-bold tracking-wider opacity-20 hover:opacity-90">
                    ...
                  </span>
                </div>
              ) : vdbFilesCount.total_files === 0 ? (
                <div className="flex items-baseline justify-start text-gray-500">
                  <span className="text-sm">No datasets available</span>
                </div>
              ) : (
                <div className="flex items-baseline justify-start gap-1">
                  <span className="text-2xl font-bold text-gray-700">
                    {Math.round(
                      (oobTaggedFileCount / vdbFilesCount?.total_files) * 100,
                    )}
                    %
                  </span>
                  <span className="text-sm text-gray-500">
                    ({oobTaggedFileCount}/{vdbFilesCount?.total_files})
                  </span>
                </div>
              )
            ) : getFileCountForNode(
                hierarchyStats,
                selectedNodeData?.nodePath,
              ) === 0 ? (
              <div className="flex items-baseline justify-start text-gray-500">
                <span className="text-sm">No datasets in this node</span>
              </div>
            ) : (
              <div className="flex items-baseline justify-start gap-1">
                <span className="text-2xl font-bold text-gray-700">
                  {Math.round(
                    (oobTaggedFileCount /
                      getFileCountForNode(
                        hierarchyStats,
                        selectedNodeData?.nodePath,
                      )) *
                      100,
                  )}
                  %
                </span>
                <span className="text-sm text-gray-500">
                  ({oobTaggedFileCount}/
                  {getFileCountForNode(
                    hierarchyStats,
                    selectedNodeData?.nodePath,
                  )}
                  )
                </span>
              </div>
            )}
          </div>
        </div>
        <p className="text-base text-gray-600 italic text-left">
          Tip: Preparing more files helps create a more accurate schema
        </p>
        {isClassifying && (
          <Box sx={{ width: "100%", mt: 2 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Typography variant="body2" color="text.secondary">
                Preparing datasets...
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {Math.round(classifyProgress * 100)}%{" "}
                {additionalStats?.tags_created > 0
                  ? `(${additionalStats.tags_created.toLocaleString()} tag value pairs created)`
                  : ""}
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={classifyProgress * 100}
              sx={{
                height: 8,
                borderRadius: 4,
                backgroundColor: "#E0E0E0",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#4FA892",
                  borderRadius: 4,
                },
              }}
            />
          </Box>
        )}
        <button
          onClick={() =>
            !isClassifying &&
            !allFilesSynthesized &&
            setShowPrepareDataDialog(true)
          }
          disabled={isClassifying || allFilesSynthesized}
          className={`w-full mt-2 py-2 px-4 border rounded-md flex items-center justify-center gap-2 ${
            isClassifying || allFilesSynthesized
              ? "bg-gray-50 border-gray-200 text-gray-400 cursor-not-allowed"
              : "bg-gray-50 border-gray-300 text-gray-700 hover:bg-gray-100"
          }`}
        >
          <Zap
            className={`w-4 h-4 ${allFilesSynthesized ? "text-gray-400" : "text-amber-500"}`}
          />
          <span className="font-bold text-left">
            {isClassifying
              ? "Preparation..."
              : allFilesSynthesized
                ? "Preparation complete"
                : "Prepare data"}
          </span>
        </button>
        {allFilesSynthesized && (
          <p className="text-sm text-gray-500 italic text-left">
            All files have been analyzed and are ready for schema generation
          </p>
        )}
      </div>

      {/* Schema Structure Selection */}
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <Layers size={24} className="text-primary" />
          <h3 className="text-lg font-medium text-left">Schema Structure</h3>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <button
            onClick={() => handleStructureTypeChange(FLAT_VAR)}
            className={`p-4 rounded-lg border-2 transition-all duration-200 flex flex-col items-center space-y-2
              ${
                structureType === FLAT_VAR
                  ? "border-primary bg-primary/5"
                  : "border-gray-200 hover:border-gray-300"
              }`}
          >
            <List
              size={32}
              className={
                structureType === FLAT_VAR ? "text-primary" : "text-gray-400"
              }
            />
            <div className="text-center">
              <div className="font-medium">Flat Structure</div>
              <div className="text-sm text-gray-500">Single level of tags</div>
            </div>
            {structureType === FLAT_VAR && (
              <div className="text-xs text-primary">Selected</div>
            )}
          </button>

          <button
            onClick={() => handleStructureTypeChange(HIERARCHY_VAR)}
            className={`p-4 rounded-lg border-2 transition-all duration-200 flex flex-col items-center space-y-2
              ${
                structureType === HIERARCHY_VAR
                  ? "border-primary bg-primary/5"
                  : "border-gray-200 hover:border-gray-300"
              }`}
          >
            <Layers
              size={32}
              className={
                structureType === HIERARCHY_VAR
                  ? "text-primary"
                  : "text-gray-400"
              }
            />
            <div className="text-center">
              <div className="font-medium">
                Hierarchical Structure (Preview)
              </div>
              <div className="text-sm text-gray-500">Nested organization</div>
            </div>
            {structureType === HIERARCHY_VAR && (
              <div className="text-xs text-primary">Selected</div>
            )}
          </button>
        </div>
      </div>

      {/* Domain Context */}
      <div className="flex flex-col mb-5 bg-white rounded-lg shadow-sm p-4">
        <div className="flex items-center space-x-2 mb-3">
          <Database size={18} className="text-primary" />
          <h3 className="text-base font-medium text-left">Domain Context</h3>
        </div>
        <textarea
          className="w-full rounded-lg p-3 bg-gray-50 border-0 text-sm focus:ring-2 focus:ring-primary/50 focus:bg-white"
          value={inputTextContext}
          onChange={(e) => setInputTextContext(e.target.value)}
          placeholder="Add domain-specific information to generate a more accurate schema"
          rows={2}
        />
      </div>

      {/* Tag Settings */}
      <div className="flex flex-col mb-5 bg-white rounded-lg shadow-sm p-4">
        <div className="flex items-center space-x-2 mb-3">
          <Settings size={18} className="text-primary" />
          <h3 className="text-base font-medium text-left">Tag Settings</h3>
        </div>

        <div className="grid grid-cols-1 gap-4">
          {/* Tag Formats */}
          <div className="flex flex-col space-y-2 m-2">
            <div className="flex items-center space-x-2">
              <label className="text-sm font-medium text-gray-700 flex-1 text-left">
                Tag Format
              </label>
              <div className="group relative">
                <Info size={15} className="text-gray-400 cursor-help" />
                <div className="hidden group-hover:block absolute right-0 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                  Choose the type of tag to output. (ie. Yes/No only outputs Yes
                  or No)
                </div>
              </div>
            </div>
            <select
              className="w-full rounded-lg p-2 bg-gray-50 border-0 text-sm focus:ring-2 focus:ring-primary/50"
              value={tagType}
              onChange={(e) => setTagType(e.target.value)}
              disabled={structureType !== FLAT_VAR}
            >
              <option value={TAG_TYPES.ANY}>All tags</option>
              <option value={TAG_TYPES.BINARY}>Yes/No Tags</option>
            </select>
          </div>

          {/* Tag Coverage */}
          <div className="flex flex-col space-y-2 m-2">
            <div className="flex items-center space-x-2">
              <label className="text-sm font-medium text-gray-700 flex-1 text-left">
                Tag Coverage
              </label>
              <div className="group relative">
                <Info size={15} className="text-gray-400 cursor-help" />
                <div className="hidden group-hover:block absolute right-0 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                  Controls the number of tags in your schema. Lower values give
                  fewer, more general tags. Higher values give more detailed
                  tags covering more aspects of your data.
                </div>
              </div>
            </div>
            <div className="space-y-2">
              <div className="flex flex-col space-y-1">
                <input
                  type="range"
                  min={3}
                  max={40}
                  step={1}
                  value={maxNumberOfTags}
                  onChange={(e) => setMaxNumberOfTags(parseInt(e.target.value))}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-primary"
                />
                <div className="flex justify-between text-xs text-gray-500">
                  <span>Fewer tags</span>
                  <span className="font-medium">{maxNumberOfTags} tags</span>
                  <span>More tags</span>
                </div>
              </div>
              <div className="flex justify-between w-full px-1 -mt-1">
                <div className="flex flex-col items-center">
                  <div
                    className={`h-2 w-2 rounded-full cursor-pointer ${maxNumberOfTags <= 5 ? "bg-primary" : "bg-gray-300"}`}
                    onClick={() => setMaxNumberOfTags(5)}
                  ></div>
                  <span className="text-xs text-gray-500 mt-1">Broad</span>
                </div>
                <div className="flex flex-col items-center">
                  <div
                    className={`h-2 w-2 rounded-full cursor-pointer ${maxNumberOfTags > 5 && maxNumberOfTags <= 15 ? "bg-primary" : "bg-gray-300"}`}
                    onClick={() => setMaxNumberOfTags(10)}
                  ></div>
                  <span className="text-xs text-gray-500 mt-1">Normal</span>
                </div>
                <div className="flex flex-col items-center">
                  <div
                    className={`h-2 w-2 rounded-full cursor-pointer ${maxNumberOfTags > 15 && maxNumberOfTags <= 25 ? "bg-primary" : "bg-gray-300"}`}
                    onClick={() => setMaxNumberOfTags(20)}
                  ></div>
                  <span className="text-xs text-gray-500 mt-1">Detailed</span>
                </div>
                <div className="flex flex-col items-center">
                  <div
                    className={`h-2 w-2 rounded-full cursor-pointer ${maxNumberOfTags > 25 ? "bg-primary" : "bg-gray-300"}`}
                    onClick={() => setMaxNumberOfTags(30)}
                  ></div>
                  <span className="text-xs text-gray-500 mt-1">Specific</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Settings Section */}
      <div className="border rounded-lg">
        <button
          onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
          className="w-full flex items-center justify-between p-4 hover:bg-gray-50 text-left"
        >
          <div className="flex items-center space-x-3">
            <Settings size={20} className="text-gray-400" />
            <span className="font-medium">Advanced Options</span>
          </div>
          <ChevronDown
            className={`transform transition-transform ${
              isAdvancedOpen ? "rotate-180" : ""
            }`}
          />
        </button>

        {isAdvancedOpen && (
          <div className="p-4 space-y-4 border-t">
            {/* Tree Depth Setting */}
            <div
              className={`flex items-center justify-between space-x-4 ${structureType === FLAT_VAR ? "opacity-50" : ""}`}
            >
              <div className="flex items-center space-x-2 w-1/3">
                <label className="text-sm font-medium text-gray-700 text-left flex-1">
                  Schema Depth
                </label>
                <div className="group relative">
                  <Info size={16} className="text-gray-400 cursor-help" />
                  <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded shadow-lg">
                    {structureType === FLAT_VAR
                      ? "Depth is fixed at 1 level for flat structures"
                      : "How many levels deep your schema should be. 2-5 levels usually works best."}
                  </div>
                </div>
              </div>
              <div className="w-2/3 flex flex-col space-y-1">
                <input
                  type="range"
                  min={1}
                  max={10}
                  step={1}
                  value={maxDepth}
                  onChange={(e) => setMaxDepth(parseInt(e.target.value))}
                  disabled={structureType === FLAT_VAR}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-primary"
                />
                <div className="flex justify-between text-xs text-gray-500">
                  <span>
                    {maxDepth} level{maxDepth !== 1 ? "s" : ""}
                  </span>
                  <span className="text-gray-400">
                    {structureType === FLAT_VAR
                      ? "Fixed for flat structure"
                      : maxDepth < 2
                        ? "Too shallow"
                        : maxDepth > 5
                          ? "Very deep"
                          : "Recommended"}
                  </span>
                </div>
              </div>
            </div>

            {/* Context Level Setting */}
            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-2 w-1/3">
                <label className="text-sm font-medium text-gray-700 text-left flex-1">
                  Context Level
                </label>
                <div className="group relative">
                  <Info size={16} className="text-gray-400 cursor-help" />
                  <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded shadow-lg">
                    Choose whether to analyze at file or chunk level. Chunk
                    level provides more granular analysis but may take longer.
                  </div>
                </div>
              </div>
              <div className="w-2/3">
                <select
                  className="w-full border border-gray-300 rounded-md p-2"
                  value={scope}
                  onChange={(e) => setScope(e.target.value)}
                >
                  <option value="file">File Level</option>
                  <option value="chunk">Chunk Level</option>
                </select>
              </div>
            </div>

            {/* Tag Type Setting */}
            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-2 w-1/3">
                <label className="text-sm font-medium text-gray-700 text-left flex-1">
                  Select the type of tags to include
                </label>
                <div className="group relative">
                  <Info size={16} className="text-gray-400 cursor-help" />
                  <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded shadow-lg">
                    Choose the type of tag to output. (ie. Yes/No only outputs
                    Yes or No)
                  </div>
                </div>
              </div>
              <div className="w-2/3">
                <select
                  className="w-full border border-gray-300 rounded-md p-2"
                  value={tagType}
                  onChange={(e) => setTagType(e.target.value)}
                  disabled={structureType !== FLAT_VAR}
                >
                  <option value={TAG_TYPES.ANY}>All tags</option>
                  <option value={TAG_TYPES.BINARY}>Yes/No Tags</option>
                </select>
              </div>
            </div>

            {/* Tag Source Setting */}
            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-2 w-1/3">
                <label className="text-sm font-medium text-gray-700 text-left flex-1">
                  Schema Building Blocks
                </label>
                <div className="group relative">
                  <Info size={16} className="text-gray-400 cursor-help" />
                  <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded shadow-lg">
                    Choose what to use when building your schema
                  </div>
                </div>
              </div>
              <div className="w-2/3">
                <select
                  className="w-full border border-gray-300 rounded-md p-2"
                  value={selectedSource}
                  onChange={(e) => handleSourceSelect(e.target.value)}
                >
                  <option value="existing">Metadata library (all)</option>
                  <option value="extracted">
                    Metadata library (extracted tags)
                  </option>
                  <option value="netNew">New tags</option>
                </select>

                {/* Tags List Dropdown */}
                {(selectedSource === "existing" ||
                  selectedSource === "extracted") && (
                  <div className="mt-2 border border-gray-200 rounded-md max-h-32 overflow-y-auto">
                    {selectedSource === "existing" &&
                      tagType === TAG_TYPES.ANY &&
                      savedTags.map((tag, idx) => (
                        <div
                          key={idx}
                          className="p-2 text-sm hover:bg-gray-50 border-b border-gray-100"
                        >
                          {tag.name}
                        </div>
                      ))}
                    {selectedSource === "existing" &&
                      tagType === TAG_TYPES.BINARY &&
                      savedTags
                        .filter(
                          (tag) =>
                            tag.available_values.includes("Yes") &&
                            tag.available_values.includes("No") &&
                            tag.available_values.length === 2,
                        )
                        .map((tag, idx) => (
                          <div
                            key={idx}
                            className="p-2 text-sm hover:bg-gray-50 border-b border-gray-100"
                          >
                            {tag.name}
                          </div>
                        ))}
                    {selectedSource === "extracted" &&
                      tagType === TAG_TYPES.ANY &&
                      uniqueTags.map((tag, idx) => (
                        <div
                          key={idx}
                          className="p-2 text-sm hover:bg-gray-50 border-b border-gray-100"
                        >
                          {tag.name}
                        </div>
                      ))}
                    {selectedSource === "extracted" &&
                      tagType === TAG_TYPES.BINARY &&
                      uniqueTags
                        .filter(
                          (tag) =>
                            tag.available_values.includes("Yes") &&
                            tag.available_values.includes("No") &&
                            tag.available_values.length === 2,
                        )
                        .map((tag, idx) => (
                          <div
                            key={idx}
                            className="p-2 text-sm hover:bg-gray-50 border-b border-gray-100"
                          >
                            {tag.name}
                          </div>
                        ))}
                  </div>
                )}
              </div>
            </div>

            {/* Additional Context */}
            <div className="flex items-center justify-between space-x-4">
              <label className="text-sm font-medium text-gray-700 w-1/3 text-left">
                Extra Context
              </label>
              <textarea
                className="w-2/3 rounded-md p-2 border border-gray-300"
                value={inputTextContext}
                onChange={(e) => setInputTextContext(e.target.value)}
                placeholder="Add any extra information that might help generate a better schema"
                rows={3}
              />
            </div>
          </div>
        )}
      </div>

      {/* Generate Button */}
      <button
        onClick={handleAutoSuggestClick}
        className={`w-full mt-3 px-6 py-3 rounded-lg font-medium transition-all duration-200 flex items-center justify-center gap-2 ${
          generatingSchema || disableAutoSuggest || !oobTaggedFileCount
            ? "bg-gray-200 text-gray-400 cursor-not-allowed"
            : "bg-primary text-white hover:bg-primary/80 shadow-lg active:transform active:scale-[0.98]"
        }`}
        title={!oobTaggedFileCount ? "Please analyze some data first" : ""}
        disabled={generatingSchema || disableAutoSuggest || !oobTaggedFileCount}
      >
        {generatingSchema ? (
          <>
            <span className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
            <span>Thinking ... (est. time: 30 seconds - 5 mins)</span>
          </>
        ) : (
          <>
            <span>Generate Schema</span>
          </>
        )}
      </button>

      {/* Mini Model Warning Dialog */}
      <Modal
        open={showMiniWarning}
        onClose={() => setShowMiniWarning(false)}
        aria-labelledby="mini-warning-title"
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-xl p-6 w-[500px]">
          <h2
            className="text-xl font-semibold mb-4 text-red"
            id="mini-warning-title"
          >
            Model Not Recommended
          </h2>
          <p className="text-gray-600 mb-6">
            GPT-4o-mini is not recommended for tag suggestion as it may produce
            less accurate and comprehensive results. Please switch to another
            endpoint in your LLM configuration for better results.
          </p>
          <div className="flex justify-end gap-3">
            <button
              onClick={() => setShowMiniWarning(false)}
              className="px-4 py-2 rounded-lg border border-gray-300 text-gray-700 font-semibold hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setShowMiniWarning(false);
                setShowConfirmDialog(true);
              }}
              className="px-4 py-2 rounded-lg bg-amber-700 text-white font-semibold hover:bg-amber-700"
            >
              Proceed Anyway
            </button>
          </div>
        </div>
      </Modal>

      {/* Prepare Data Confirmation Dialog */}
      <Modal
        open={showPrepareDataDialog}
        onClose={() => setShowPrepareDataDialog(false)}
        aria-labelledby="confirm-prepare-data-title"
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-xl p-6 w-[500px]">
          <h2
            className="text-xl font-semibold mb-4"
            id="confirm-prepare-data-title"
          >
            Confirm Data Preparation
          </h2>
          <p className="text-gray-600 mb-6">
            This will analyze your data files to enable Deasy auto-suggestions.
            The process will run in the background and may take some time
            depending on the size of your dataset. Do you want to proceed?
          </p>
          <div className="flex justify-end gap-3">
            <button
              onClick={() => setShowPrepareDataDialog(false)}
              className="px-4 py-2 rounded-lg border border-gray-300 text-gray-700 font-semibold hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setShowPrepareDataDialog(false);

                const nodeFileCount = {
                  total_files: getTotalDatasetsCount(
                    hierarchyStats,
                    selectedNodeData?.nodePath,
                  ),
                };

                classifyAll(
                  vdbmLastActive,
                  llmLastActive,
                  deasyApiKey,
                  selectedNodeData,
                  setJobId,
                  isClassifying,
                  setIsClassifying,
                  nodeFileCount,
                  null,
                  null,
                  true,
                  false,
                );
              }}
              className="px-4 py-2 rounded-lg bg-primary text-white font-semibold hover:bg-emerald-700"
            >
              Proceed
            </button>
          </div>
        </div>
      </Modal>

      {/* Confirmation Dialog */}
      <Modal
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="confirm-auto-suggest-title"
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-xl p-6 w-[500px]">
          <h2
            className="text-xl font-semibold mb-4"
            id="confirm-auto-suggest-title"
          >
            Confirm Auto-Suggestion
          </h2>
          <p className="text-gray-600 mb-6">
            This will generate a hierarchical schema tree based on your dataset
            context. The process may take a few seconds depending on the size of
            your dataset. Do you want to proceed?
          </p>
          <div className="flex justify-end gap-3">
            <button
              onClick={() => setShowConfirmDialog(false)}
              className="px-4 py-2 rounded-lg border border-gray-300 text-gray-700 font-semibold hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                setShowConfirmDialog(false);
                await handleAutoSuggest();
              }}
              className="px-4 py-2 rounded-lg bg-primary text-white font-semibold hover:bg-emerald-700"
            >
              Proceed
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
