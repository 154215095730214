import React from "react";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { metadataService } from "../../../../../../services/api";

const DeleteMetadataConfirmationModal = ({
  showConfirmModal,
  setShowConfirmModal,
  selectedTagsToDelete,
  deleteStats,
  vdbProfileName,
  deasyApiKey,
  setRefreshKey,
}) => {
  const handleFinalDelete = async () => {
    try {
      const response = await metadataService.deleteMetadata(
        vdbProfileName,
        deasyApiKey,
        null, // fileNames
        selectedTagsToDelete, // tags
        null, // conditions
      );

      toast.success(
        `Successfully deleted metadata:
        ${response.data.chunk_deleted_count} chunks and
        ${response.data.file_deleted_count} files affected`,
        { duration: 5000 },
      );

      setShowConfirmModal(false);
      // Refresh the metadata table
      setRefreshKey((prev) => prev + 1);
    } catch (error) {
      console.error("Error deleting metadata:", error);
      toast.error("Failed to delete metadata. Please try again.", {
        duration: 4000,
      });
    }
  };

  if (!showConfirmModal) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div
        className="bg-white rounded-xl p-6 max-w-3xl w-full mx-4 shadow-2xl"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mb-4">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-red-100 rounded-lg">
              <FaTrash className="w-5 h-5 text-red-600" />
            </div>
            <h3 className="text-xl font-semibold text-gray-900">
              Confirm Deletion
            </h3>
          </div>

          <p className="text-gray-600 text-base text-left mb-4 mt-8">
            You are about to clear the following metadata tags from Deasylabs:
          </p>

          <div className="max-h-[40vh] overflow-y-auto border rounded-lg divide-y">
            {selectedTagsToDelete.map((tag) => (
              <div key={tag} className="p-4 bg-gray-50">
                <h3 className="font-medium text-gray-900 mb-2 flex items-center gap-2">
                  {tag}
                </h3>
                {deleteStats[tag] && (
                  <div className="space-y-2 text-sm text-gray-600">
                    <div className="flex items-start gap-2">
                      <svg
                        className="w-4 h-4 text-blue-500 mt-0.5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      <span>
                        Files affected:{" "}
                        <span className="font-semibold">
                          {deleteStats[tag].file_count_with_tag}
                        </span>
                      </span>
                    </div>

                    {deleteStats[tag].graphs_with_tag.length > 0 && (
                      <div className="flex items-start gap-2">
                        <svg
                          className="w-4 h-4 text-purple-500 mt-0.5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                          />
                        </svg>
                        <span>
                          Used in graphs:{" "}
                          <span className="font-semibold">
                            {deleteStats[tag].graphs_with_tag.join(", ")}
                          </span>
                        </span>
                      </div>
                    )}

                    {deleteStats[tag].dataslices_with_tag.length > 0 && (
                      <div className="flex items-start gap-2">
                        <svg
                          className="w-4 h-4 text-green-500 mt-0.5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                          />
                        </svg>
                        <span>
                          Used in use cases:{" "}
                          <span className="font-semibold">
                            {deleteStats[tag].dataslices_with_tag.join(", ")}
                          </span>
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>

          <p className="text-gray-600 text-left text-sm mt-4 bg-yellow-50 p-3 rounded border border-yellow-200">
            ⚠️ This action cannot be undone. All associated metadata entries
            will be permanently removed.
          </p>
        </div>
        <div className="flex justify-end gap-3 mt-4">
          <button
            onClick={() => setShowConfirmModal(false)}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleFinalDelete}
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Clear Tags
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteMetadataConfirmationModal;
