import { useState, useEffect, useContext } from "react";
import { AlertTriangle, X, Loader2 } from "lucide-react";
import { tagService } from "../../../../../services/api";
import { BaseContext } from "../../../../../contexts/BaseContext";

export const DeleteModal = ({
  tagName,
  tagInEditor,
  setTagInEditor,
  setShowDeleteModal,
}) => {
  const { setSavedTags, deasyUserConfig } = useContext(BaseContext);
  const [inputValue, setInputValue] = useState("");
  const [filesAffected, setFilesAffected] = useState(0);
  const [graphsAffected, setGraphsAffected] = useState([]);
  const [dataslicesAffected, setDataslicesAffected] = useState([]);
  const [loadingStats, setLoadingStats] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const handleDelete = async () => {
    try {
      setLoadingDelete(true);
      await tagService.deleteTag(tagName, deasyUserConfig.deasyApiKey);
      const response = await tagService.getSavedTags(
        deasyUserConfig.deasyApiKey,
      );
      setSavedTags(response.data.tags || []);

      if (tagInEditor?.name === tagName) setTagInEditor({});
    } catch (error) {
      console.error("Failed to delete tag:", error);
    } finally {
      setShowDeleteModal(false);
      setLoadingDelete(false);
    }
  };

  useEffect(
    () => {
      const fetchDeleteTagStats = async () => {
        try {
          setLoadingStats(true);
          const response = await tagService.getTagDeleteStats(
            deasyUserConfig.deasyApiKey,
            tagName,
          );
          setFilesAffected(response.data.file_count_with_tag);
          setGraphsAffected(response.data.graphs_with_tag);
          setDataslicesAffected(response.data.dataslices_with_tag);
          setLoadingStats(false);
        } catch (error) {
          console.error("Failed to fetch delete tag stats:", error);
          setLoadingStats(false);
        } finally {
          setLoadingStats(false);
        }
      };
      fetchDeleteTagStats();
    },
    // eslint-disable-next-line
    [],
  );

  if (loadingStats) {
    return (
      <div className="fixed inset-0 z-[10000] flex items-center justify-center bg-black/60 backdrop-blur-sm px-4 py-6 transition-opacity">
        <div className="relative w-full max-w-lg rounded-lg bg-white p-6 shadow-xl ring-1 ring-gray-200">
          <div className="flex items-center justify-center">
            <Loader2 className="w-8 h-8 animate-spin text-red-500" />
            <p className="text-sm text-gray-500">Loading...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-[10000] flex items-center justify-center bg-black/60 backdrop-blur-sm px-4 py-6 transition-opacity">
      <div className="relative w-full max-w-lg rounded-lg bg-white p-6 shadow-xl ring-1 ring-gray-200">
        <button
          onClick={() => setShowDeleteModal(false)}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
          aria-label="Close"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="flex flex-col items-center justify-center space-y-1">
          <AlertTriangle className="w-8 h-8 text-red-500" />
          <h3 className="mt-1 text-2xl font-semibold text-gray-900">
            Delete Tag
          </h3>
          <p className="text-sm italic text-gray-500">
            This action cannot be undone
          </p>
        </div>

        {graphsAffected.length > 0 && (
          <div
            className="my-4 rounded-lg bg-orange-50 p-4 text-center"
            title={`${tagName} is currently being used in the following graphs, please remove from all graphs before deleting.`}
          >
            <div className="flex items-center justify-center gap-2 text-orange-800">
              <AlertTriangle className="w-5 h-5" />
              <h4 className="text-xl font-semibold">Graph Conflicts</h4>
            </div>
            <p className="text-sm text-orange-800 opacity-80 mb-2 italic">
              Cannot Delete Tag While In Use
            </p>
            <ul className="mt-2 text-sm text-orange-800 flex flex-wrap gap-2">
              {graphsAffected.map((graph, index) => (
                <li
                  key={index}
                  className="font-medium bg-orange-100 px-2 py-1 rounded-md"
                >
                  {graph}
                </li>
              ))}
            </ul>
          </div>
        )}
        {dataslicesAffected.length > 0 && (
          <div
            className="my-4 rounded-lg bg-orange-50 p-4 text-center"
            title={`${tagName} is currently being used in the following data slices, please update all affected data slices before deleting.`}
          >
            <div className="flex items-center justify-center gap-2 text-orange-800">
              <AlertTriangle className="w-5 h-5" />
              <h4 className="text-xl font-semibold">Data Slice Conflicts</h4>
            </div>
            <p className="text-sm text-orange-800 opacity-80 mb-2 italic">
              Cannot Delete Tag While In Use
            </p>
            <ul className="mt-2 text-sm text-orange-800 flex flex-wrap gap-2">
              {dataslicesAffected.map((dataslice, index) => (
                <li
                  key={index}
                  className="font-medium bg-orange-100 px-2 py-1 rounded-md"
                >
                  {dataslice}
                </li>
              ))}
            </ul>
          </div>
        )}

        {graphsAffected.length === 0 && dataslicesAffected.length === 0 && (
          <div className="my-6 rounded-lg bg-red-50 p-4 text-center">
            <p className="text-sm text-red-800">
              <span className="font-medium">Warning:</span> Deleting this tag
              will remove metadata values from{" "}
              <span className="text-red-900 font-medium">
                {filesAffected?.toLocaleString()}
              </span>
              {filesAffected > 1
                ? " files"
                : filesAffected === 1
                  ? " file"
                  : ""}
            </p>
          </div>
        )}

        {graphsAffected.length === 0 && (
          <div className="space-y-4 text-center">
            <p className="text-sm text-gray-700">
              Type{" "}
              <code className="rounded bg-gray-200 px-2 py-1 font-mono text-gray-800">
                {tagName}
              </code>{" "}
              to confirm
            </p>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="w-full rounded-lg border border-gray-300 px-4 py-2.5 outline-none transition-all"
              placeholder={tagName}
              autoComplete="off"
              style={{ fontStyle: "italic" }}
            />
          </div>
        )}

        <div className="mt-8 flex justify-end gap-3">
          {!loadingDelete && (
            <button
              onClick={() => setShowDeleteModal(false)}
              className="rounded-lg px-5 py-2.5 font-medium text-gray-700 transition-colors hover:bg-gray-100 bg-gray-50"
            >
              {graphsAffected.length === 0 ? "Close" : "Cancel"}
            </button>
          )}
          {graphsAffected.length === 0 && (
            <button
              onClick={handleDelete}
              disabled={inputValue !== tagName || loadingDelete}
              className="rounded-lg bg-red-400 px-5 py-2.5 font-medium text-white transition-colors hover:bg-red-500 disabled:cursor-not-allowed disabled:opacity-50"
            >
              {loadingDelete ? "Deleting..." : "Delete Permanently"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
