import { useState, React } from "react";
import { Info, ChevronRight } from "lucide-react";

const cleanSchema = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  const cleanedObj = {};

  for (const [key, value] of Object.entries(obj)) {
    // Skip description and output_type fields
    if (key === "description" || key === "output_type") {
      continue;
    }

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      cleanedObj[key] = cleanSchema(value);
    } else {
      cleanedObj[key] = value;
    }
  }

  return cleanedObj;
};

const PathNavigator = ({ nodePath }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!nodePath?.length) {
    return <span className="text-primary font-semibold">All Data</span>;
  }

  // Create pairs of [tag, value] from the path
  const pathPairs = [];
  for (let i = 0; i < nodePath.length; i += 2) {
    if (nodePath[i] && nodePath[i + 1]) {
      pathPairs.push([nodePath[i], nodePath[i + 1]]);
    }
  }

  // If not expanded, only show the current node
  if (!isExpanded) {
    const currentPair = pathPairs[pathPairs.length - 1];
    return (
      <div className="flex items-center gap-2">
        <span className="text-primary font-semibold">{currentPair[1]}</span>
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsExpanded(true);
          }}
          className="text-xs text-gray-500 hover:text-gray-700 flex items-center gap-1"
        >
          <Info size={12} />
          Show full path
        </button>
      </div>
    );
  }

  // Show full path when expanded
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2 text-gray-500">
        <span>Full path:</span>
        <button
          onClick={() => setIsExpanded(false)}
          className="text-xs hover:text-gray-700"
        >
          Hide
        </button>
      </div>
      <div className="flex items-center flex-wrap gap-2">
        <span className="text-gray-500">All Data</span>
        {pathPairs.map(([tag, value], index) => (
          <div key={index} className="flex items-center gap-2">
            <ChevronRight size={16} className="text-gray-400" />
            <span className="text-gray-600">{tag}:</span>
            <span className="text-primary font-semibold">{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export { cleanSchema, PathNavigator };
