import React, { useEffect, useCallback, useState, useContext } from "react";
import { FaEye } from "react-icons/fa";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { FilePreviewModal } from "./FilePreviewModal";

const ChunkRow = ({
  metadata: { chunk, chunkId, row, displayColumns, deasyFileKey },
  layout: { TABLE_COLUMN_WIDTHS },
  textState: { activeFullText, toggleChunkText },
  evidenceState: {
    activeEvidence,
    showEvidence,
    handleFetchChunkEvidence,
    setShowEvidence,
    renderEvidenceRow,
    loadingEvidenceMap,
  },
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const { deasyUserConfig } = useContext(BaseContext);
  const vdbProfileName = deasyUserConfig?.vdbmConfig?.LastActive;
  const vdbConfig = deasyUserConfig?.vdbmConfig?.Configs[vdbProfileName];
  const vdbIsDataSource =
    vdbConfig?.type === "SharepointDataSourceManager" ||
    vdbConfig?.type === "S3DataSourceManager";

  // Helper function to check if evidence is currently loading for this specific item
  const isEvidenceLoading = useCallback(
    (tagName, tagValue) => {
      const fileName = row[deasyFileKey];
      const evidenceKey = `${fileName}:${chunkId}:${tagName}:${tagValue}`;
      return loadingEvidenceMap && loadingEvidenceMap[evidenceKey] === true;
    },
    [row, deasyFileKey, chunkId, loadingEvidenceMap],
  );

  // Effect to automatically show evidence when loading completes
  useEffect(() => {
    if (
      activeEvidence?.chunkId === chunkId &&
      chunk.evidence?.[activeEvidence.tagName]?.[activeEvidence.value] &&
      !isEvidenceLoading(activeEvidence.tagName, activeEvidence.value)
    ) {
      setShowEvidence(true);
    }
  }, [
    activeEvidence,
    chunk.evidence,
    loadingEvidenceMap,
    chunkId,
    setShowEvidence,
    isEvidenceLoading,
  ]);

  const handlePreviewClick = (e) => {
    e.stopPropagation();
    setShowPreviewModal(true);
  };

  const closePreviewModal = () => {
    setShowPreviewModal(false);
  };

  return (
    <React.Fragment>
      <tr className="hover:bg-gray-50 border-b border-gray-100 last:border-0">
        {displayColumns.map((column) => {
          let value = "";
          if (column === deasyFileKey) {
            value = (
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center">
                  <span className="text-xs text-gray-400 mr-2">└─</span>
                  <span>Chunk {chunkId}</span>
                </div>
                <div className="flex items-center gap-2">
                  {vdbIsDataSource && (
                    <div className="relative group">
                      <button
                        className="text-xs text-gray-400 hover:text-primary p-1 rounded-full flex items-center justify-center bg-gray-100"
                        onClick={handlePreviewClick}
                        title="Preview OCR'd text"
                      >
                        <FaEye size={12} />
                      </button>
                      <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity duration-200 bottom-full mb-2 right-0 whitespace-nowrap bg-gray-800 text-white text-xs py-1 px-2 rounded pointer-events-none z-10">
                        Preview OCR'd text
                        <div className="absolute top-full left-1/2 transform -translate-x-1/2 -translate-y-1/4 border-4 border-transparent border-t-gray-800"></div>
                      </div>
                    </div>
                  )}
                  {chunk.text && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleChunkText(chunkId);
                      }}
                      className="text-xs text-gray-400 hover:text-primary px-2"
                    >
                      {activeFullText === chunkId ? "Hide Text" : "Show Text"}
                    </button>
                  )}
                </div>
              </div>
            );
          } else {
            const tag = chunk.tags?.[column];
            if (tag) {
              const tagValue =
                typeof tag === "object" && "value" in tag ? tag.value : tag;

              // Check if evidence is loading for this specific tag
              const loading = isEvidenceLoading(column, tagValue);
              const isActive =
                activeEvidence?.chunkId === chunkId &&
                activeEvidence?.tagName === column &&
                activeEvidence?.value === tagValue;

              value = (
                <div className="relative">
                  <div className="break-words whitespace-pre-wrap">
                    {Array.isArray(tagValue) ? tagValue.join(", ") : tagValue}
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFetchChunkEvidence(
                        row[deasyFileKey],
                        chunkId,
                        column,
                        tagValue,
                      );
                      // Only toggle if already showing, otherwise let the useEffect handle it
                      if (isActive && showEvidence) {
                        setShowEvidence(false);
                      }
                    }}
                    className="text-xs text-gray-400 hover:text-primary mt-1 text-left flex items-center gap-1"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <div className="w-3 h-3 border-t-transparent border-2 border-primary rounded-full animate-spin"></div>
                        <span>Loading evidence...</span>
                      </>
                    ) : isActive && showEvidence ? (
                      "Hide Evidence"
                    ) : (
                      "Show Evidence"
                    )}
                  </button>
                  {isActive &&
                    showEvidence &&
                    renderEvidenceRow(chunk, chunkId, row)}
                </div>
              );
            }
          }

          return (
            <td
              key={column}
              className="px-4 py-2 text-xs text-gray-600 relative"
              style={{
                width:
                  column === deasyFileKey
                    ? TABLE_COLUMN_WIDTHS[deasyFileKey]
                    : TABLE_COLUMN_WIDTHS.default,
                minWidth:
                  column === deasyFileKey
                    ? TABLE_COLUMN_WIDTHS[deasyFileKey]
                    : TABLE_COLUMN_WIDTHS.default,
                maxWidth:
                  column === deasyFileKey
                    ? TABLE_COLUMN_WIDTHS[deasyFileKey]
                    : TABLE_COLUMN_WIDTHS.default,
              }}
            >
              {typeof value === "string" ? (
                <div
                  className="break-words whitespace-pre-wrap"
                  title={value || "-"}
                >
                  {value || "-"}
                </div>
              ) : (
                value
              )}
            </td>
          );
        })}
      </tr>

      {/* Text row */}
      {activeFullText === chunkId && chunk.text && (
        <tr key={`${chunkId}-text`}>
          <td
            colSpan={displayColumns.length}
            className="px-4 py-2 bg-white border-t border-gray-100"
          >
            <div className="text-xs text-gray-600 whitespace-pre-wrap">
              {chunk.text}
            </div>
          </td>
        </tr>
      )}

      {/* File Preview Modal */}
      {showPreviewModal && (
        <FilePreviewModal
          filename={row[deasyFileKey]}
          closePreviewModal={closePreviewModal}
          startingChunk={chunkId}
        />
      )}
    </React.Fragment>
  );
};

export default ChunkRow;
