import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  FiPlus,
  FiEdit2,
  FiTrash2,
  FiX,
  FiSearch,
  FiTag,
  FiHash,
  FiAlertTriangle,
} from "react-icons/fi";
import { useContext } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import { tagGroupService } from "../../../services/api";
import { SmallDataContext } from "../../../contexts/SmallDataContext";
import { toast } from "react-hot-toast";
import { useDrop } from "react-dnd";

// Droppable group component
const DroppableGroup = ({ group, onDrop, children }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "TAG",
    drop: (item) => {
      onDrop(item.tag, group);
      return { dropped: true }; // Return a value to signal successful drop
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className={`border rounded-lg mb-4 transition-all duration-200 relative ${
        isOver
          ? "bg-primary-50 border-primary-300 shadow-md ring-2 ring-primary-300 ring-opacity-50"
          : "bg-white border-gray-200 hover:border-primary-200 hover:shadow-sm"
      }`}
    >
      {isOver && (
        <div className="absolute inset-0 bg-primary-100 bg-opacity-10 pointer-events-none flex items-center justify-center z-10">
          <div className="bg-white bg-opacity-90 px-3 py-1.5 rounded-full text-primary-600 text-sm font-medium shadow-sm border border-primary-200">
            Drop to add tag
          </div>
        </div>
      )}
      <div className="relative z-0">{children}</div>
    </div>
  );
};

// Droppable area for creating a new group
const DroppableNewGroup = ({ onDrop, children }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "TAG",
    drop: (item) => {
      onDrop(item.tag);
      return { dropped: true }; // Return a value to signal successful drop
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className={`border-2 border-dashed rounded-lg p-6 mb-4 transition-all duration-300 relative ${
        isOver
          ? "bg-primary-50 border-primary-300 shadow-lg transform scale-[1.02]"
          : "bg-gray-50 border-gray-300 hover:border-primary-200 hover:bg-gray-100"
      }`}
    >
      {isOver && (
        <div className="absolute inset-0 bg-primary-100 bg-opacity-10 pointer-events-none flex items-center justify-center z-10">
          <div className="bg-white bg-opacity-90 px-4 py-2 rounded-full text-primary-600 text-base font-medium shadow-sm border border-primary-200">
            Release to create new group
          </div>
        </div>
      )}
      <div className="relative z-0">{children}</div>
    </div>
  );
};

// Droppable area for the tag editor
const DroppableTagEditor = ({ onDrop, children }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "TAG",
    drop: (item) => {
      onDrop(item.tag);
      return { dropped: true }; // Return a value to signal successful drop
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className={`relative transition-all duration-200 ${
        isOver ? "ring-2 ring-primary-300 ring-opacity-50" : ""
      }`}
    >
      {isOver && (
        <div className="absolute inset-0 bg-primary-100 bg-opacity-10 pointer-events-none flex items-center justify-center z-10 rounded-lg">
          <div className="bg-white bg-opacity-90 px-3 py-1.5 rounded-full text-primary-600 text-sm font-medium shadow-sm border border-primary-200">
            Drop to add tag to group
          </div>
        </div>
      )}
      <div className="relative z-0">{children}</div>
    </div>
  );
};

const TagGroups = forwardRef(({ initialSelectedTags = [] }, ref) => {
  const { deasyApiKey } = useContext(BaseContext);
  const { tags, tagGroups, setTagGroups, loadingTagGroups } =
    useContext(SmallDataContext);

  const [activeGroup, setActiveGroup] = useState(null);
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [newGroupDescription, setNewGroupDescription] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [newlyAddedTagIds, setNewlyAddedTagIds] = useState([]);

  // Delete confirmation modal state
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [deleteAssociatedTags, setDeleteAssociatedTags] = useState(false);

  // Handle dropping a tag onto a group
  const handleDropOnGroup = async (tag, group) => {
    // Get the latest group data from state to ensure we have the most up-to-date tag_ids
    const currentGroup = tagGroups.find((g) => g.group_id === group.group_id);
    if (!currentGroup) {
      toast.error(`Group not found. Please refresh the page.`);
      return;
    }

    // Check if tag is already in the group
    if (currentGroup.tag_ids.includes(tag.tag_id)) {
      toast.error(
        `Tag "${tag.name}" is already in group "${currentGroup.name}"`,
      );
      return;
    }

    // Create a loading toast
    const loadingToastId = toast.loading(
      `Adding "${tag.name}" to group "${currentGroup.name}"...`,
    );

    try {
      // Get the most up-to-date tag_ids from the current group
      // and add the new tag ID to it
      const updatedTagIds = [...currentGroup.tag_ids, tag.tag_id];

      // Update the group in the database first
      await updateGroupInDatabase(
        currentGroup.group_id,
        currentGroup.name,
        currentGroup.description,
        updatedTagIds,
      );

      // If the database update succeeds, update the UI using functional update to ensure latest state
      setTagGroups((prevGroups) => {
        // Find the group again in the latest state
        const latestGroup = prevGroups.find(
          (g) => g.group_id === group.group_id,
        );
        if (!latestGroup) return prevGroups;

        // Make sure we're not adding a duplicate tag
        if (latestGroup.tag_ids.includes(tag.tag_id)) return prevGroups;

        // Return updated groups with the new tag added to the correct group
        return prevGroups.map((g) => {
          if (g.group_id === currentGroup.group_id) {
            return {
              ...g,
              tag_ids: [...g.tag_ids, tag.tag_id],
            };
          }
          return g;
        });
      });

      // If we're currently editing this group, update the selected tags as well
      if (activeGroup && activeGroup.group_id === currentGroup.group_id) {
        const tagObject = tags.find((t) => t.tag_id === tag.tag_id);
        if (tagObject) {
          // Use functional update to ensure we're working with the latest state
          setSelectedTags((prev) => {
            // Check if the tag is already in selectedTags
            if (prev.some((t) => t.tag_id === tag.tag_id)) {
              return prev;
            }
            // Return a new array with the tag added
            return [...prev, tagObject];
          });

          // Highlight the newly added tag
          setNewlyAddedTagIds((prev) => [...prev, tag.tag_id]);
          setTimeout(() => {
            setNewlyAddedTagIds((prev) =>
              prev.filter((id) => id !== tag.tag_id),
            );
          }, 2000);
        }
      }

      // Show success toast
      toast.success(`Added "${tag.name}" to group "${currentGroup.name}"`, {
        id: loadingToastId,
      });
    } catch (error) {
      // If the database update fails, show an error toast
      toast.error(
        `Failed to add "${tag.name}" to group "${currentGroup.name}": ${error.message || "Unknown error"}`,
        {
          id: loadingToastId,
        },
      );
      console.error("Error adding tag to group:", error);
    }
  };

  // Handle dropping a tag onto the new group area
  const handleDropOnNewGroup = async (tag) => {
    // If we're already editing an existing group, add the tag to it and update the database
    if (activeGroup) {
      // Get the latest group data from state to ensure we have the most up-to-date information
      const currentGroup = tagGroups.find(
        (g) => g.group_id === activeGroup.group_id,
      );
      if (!currentGroup) {
        toast.error(`Group not found. Please refresh the page.`);
        return;
      }

      // Check if tag is already in the selected tags
      if (selectedTags.some((t) => t.tag_id === tag.tag_id)) {
        toast.error(`Tag "${tag.name}" is already in this group`);
        return;
      }

      // Create a loading toast
      const loadingToastId = toast.loading(
        `Adding "${tag.name}" to the group being edited...`,
      );

      try {
        // Update the group in the database first using the current tag IDs plus the new one
        const updatedTagIds = [...currentGroup.tag_ids, tag.tag_id];

        await updateGroupInDatabase(
          currentGroup.group_id,
          newGroupName,
          newGroupDescription,
          updatedTagIds,
        );

        // If the database update succeeds, update the UI with functional update
        setSelectedTags((prev) => {
          // Check if the tag is already in selectedTags
          if (prev.some((t) => t.tag_id === tag.tag_id)) {
            return prev;
          }
          return [...prev, tag];
        });

        // Highlight the newly added tag
        setNewlyAddedTagIds((prev) => [...prev, tag.tag_id]);
        setTimeout(() => {
          setNewlyAddedTagIds((prev) => prev.filter((id) => id !== tag.tag_id));
        }, 2000);

        // Update the groups list to reflect the change using functional update
        setTagGroups((prevGroups) => {
          // Find the group again in the latest state
          const latestGroup = prevGroups.find(
            (g) => g.group_id === activeGroup.group_id,
          );
          if (!latestGroup) return prevGroups;

          // Make sure we're not adding a duplicate tag
          if (latestGroup.tag_ids.includes(tag.tag_id)) return prevGroups;

          // Return updated groups with the new tag added to the correct group
          return prevGroups.map((g) => {
            if (g.group_id === currentGroup.group_id) {
              return {
                ...g,
                tag_ids: [...g.tag_ids, tag.tag_id],
              };
            }
            return g;
          });
        });

        // Show success toast
        toast.success(`Added "${tag.name}" to the group being edited`, {
          id: loadingToastId,
        });
      } catch (error) {
        // If the database update fails, show an error toast
        toast.error(
          `Failed to add "${tag.name}" to the group: ${error.message || "Unknown error"}`,
          {
            id: loadingToastId,
          },
        );
        console.error("Error adding tag to group:", error);
      }

      return;
    }

    // If we're creating a new group, just add the tag to the local state
    if (isCreatingGroup) {
      // Check if tag is already in the selected tags
      if (selectedTags.some((t) => t.tag_id === tag.tag_id)) {
        toast.error(`Tag "${tag.name}" is already in this new group`);
        return;
      }

      // Add tag to the new group being created
      setSelectedTags((prev) => [...prev, tag]);

      // Highlight the newly added tag
      setNewlyAddedTagIds((prev) => [...prev, tag.tag_id]);
      setTimeout(() => {
        setNewlyAddedTagIds((prev) => prev.filter((id) => id !== tag.tag_id));
      }, 2000);

      toast.success(`Added "${tag.name}" to the new group`);
      return;
    }

    // Start creating a new group with this tag
    setIsCreatingGroup(true);
    setActiveGroup(null);
    setNewGroupName("");
    setNewGroupDescription("");
    setSelectedTags([tag]);

    // Highlight the newly added tag
    setNewlyAddedTagIds([tag.tag_id]);
    setTimeout(() => {
      setNewlyAddedTagIds([]);
    }, 2000);

    toast.success(`Start creating a new group with tag "${tag.name}"`);
  };

  // Handle dropping a tag directly into the editor view.
  const handleDropOnEditor = async (tag) => {
    // If we're not editing or creating a group, we can't add tags
    if (!activeGroup && !isCreatingGroup) {
      toast.error("Please create or edit a group first");
      return;
    }

    // Check if tag is already in the selected tags
    if (selectedTags.some((t) => t.tag_id === tag.tag_id)) {
      toast.error(`Tag "${tag.name}" is already in this group`);
      return;
    }

    // Create a loading toast
    const loadingToastId = toast.loading(
      `Adding "${tag.name}" to the group...`,
    );

    try {
      // If we're editing an existing group, update it in the database
      if (activeGroup) {
        // Get the latest group data from state to ensure we have the most up-to-date information
        const currentGroup = tagGroups.find(
          (g) => g.group_id === activeGroup.group_id,
        );
        if (!currentGroup) {
          toast.error(`Group not found. Please refresh the page.`);
          toast.dismiss(loadingToastId);
          return;
        }

        // Update the group in the database with the current tag IDs plus the new one
        const updatedTagIds = [...currentGroup.tag_ids, tag.tag_id];

        await updateGroupInDatabase(
          currentGroup.group_id,
          newGroupName,
          newGroupDescription,
          updatedTagIds,
        );

        // Update the groups list to reflect the change using functional update
        setTagGroups((prevGroups) => {
          // Find the group again in the latest state
          const latestGroup = prevGroups.find(
            (g) => g.group_id === activeGroup.group_id,
          );
          if (!latestGroup) return prevGroups;

          // Make sure we're not adding a duplicate tag
          if (latestGroup.tag_ids.includes(tag.tag_id)) return prevGroups;

          // Return updated groups with the new tag added to the correct group
          return prevGroups.map((g) => {
            if (g.group_id === currentGroup.group_id) {
              return {
                ...g,
                tag_ids: [...g.tag_ids, tag.tag_id],
              };
            }
            return g;
          });
        });
      }

      // Update the selected tags using functional update
      setSelectedTags((prev) => {
        // Check if the tag is already in selectedTags
        if (prev.some((t) => t.tag_id === tag.tag_id)) {
          return prev;
        }
        return [...prev, tag];
      });

      // Highlight the newly added tag
      setNewlyAddedTagIds((prev) => [...prev, tag.tag_id]);
      setTimeout(() => {
        setNewlyAddedTagIds((prev) => prev.filter((id) => id !== tag.tag_id));
      }, 2000);

      // Show success toast
      toast.success(`Added "${tag.name}" to the group`, {
        id: loadingToastId,
      });
    } catch (error) {
      // If the database update fails, show an error toast
      toast.error(
        `Failed to add "${tag.name}" to the group: ${error.message || "Unknown error"}`,
        {
          id: loadingToastId,
        },
      );
      console.error("Error adding tag to group:", error);
    }
  };

  // Helper function to update a group in the database
  const updateGroupInDatabase = async (groupId, name, description, tagIds) => {
    try {
      console.log("Updating group in database:", {
        groupId,
        name,
        description,
        tagIds,
      });

      const response = await tagGroupService.updateTagGroup(
        deasyApiKey,
        groupId,
        name,
        description,
        tagIds,
      );

      console.log("Group update response:", response);

      // If the response indicates success, update the local state
      if (response && response.status === 200) {
        console.log("Group updated successfully");
        return true;
      } else {
        throw new Error("Failed to update group: Unexpected response");
      }
    } catch (error) {
      console.error("Error updating group:", error);
      toast.error(
        "Failed to update group: " + (error.message || "Unknown error"),
      );
      throw error; // Re-throw to allow the caller to handle it
    }
  };

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    startCreateGroupWithTags: async (tags) => {
      if (activeGroup) {
        // If we're editing an existing group, add the new tags and update the database
        const existingTagIds = selectedTags.map((tag) => tag.tag_id);
        const newTags = tags.filter(
          (tag) => !existingTagIds.includes(tag.tag_id),
        );

        // Only update if there are new tags to add
        if (newTags.length > 0) {
          const newTagIds = newTags.map((tag) => tag.tag_id);

          // Use functional update to ensure we're working with the latest state
          let updatedSelectedTags;
          setSelectedTags((prev) => {
            updatedSelectedTags = [...prev, ...newTags];
            return updatedSelectedTags;
          });

          const updatedTagIds = [...existingTagIds, ...newTagIds];

          // Create a loading toast
          const loadingToastId = toast.loading(
            `Adding ${newTags.length} tag(s) to group...`,
          );

          try {
            // Update the group in the database
            await updateGroupInDatabase(
              activeGroup.group_id,
              newGroupName,
              newGroupDescription,
              updatedTagIds,
            );

            // Update the UI - already done with the functional update above
            setNewlyAddedTagIds(newTagIds);

            // Update the groups list to reflect the change
            setTagGroups((prevGroups) =>
              prevGroups.map((g) => {
                if (g.group_id === activeGroup.group_id) {
                  return {
                    ...g,
                    tag_ids: updatedTagIds,
                  };
                }
                return g;
              }),
            );

            // Show success toast
            toast.success(`Added ${newTags.length} tag(s) to group`, {
              id: loadingToastId,
            });

            setTimeout(() => {
              setNewlyAddedTagIds([]);
            }, 2000);
          } catch (error) {
            // If the database update fails, show an error toast
            toast.error(
              `Failed to add tags to group: ${error.message || "Unknown error"}`,
              {
                id: loadingToastId,
              },
            );
            console.error("Error adding tags to group:", error);
          }
        }
      } else if (isCreatingGroup) {
        // If we're already creating a new group, just add the new tags without resetting name/description
        const existingTagIds = selectedTags.map((tag) => tag.tag_id);
        const newTags = tags.filter(
          (tag) => !existingTagIds.includes(tag.tag_id),
        );

        // Only update if there are new tags to add
        if (newTags.length > 0) {
          const newTagIds = newTags.map((tag) => tag.tag_id);
          setNewlyAddedTagIds(newTagIds);

          // Use functional update to ensure we're working with the latest state
          setSelectedTags((prev) => [...prev, ...newTags]);

          toast.success(`Added ${newTags.length} tag(s) to new group`);

          setTimeout(() => {
            setNewlyAddedTagIds([]);
          }, 2000);
        }
      } else {
        // If we're not editing or creating a group, start a new one
        setIsCreatingGroup(true);
        setActiveGroup(null);
        setNewGroupName("");
        setNewGroupDescription("");
        setSelectedTags(tags);

        toast.success(
          `Started creating a new group with ${tags.length} tag(s)`,
        );
      }
    },
    scrollIntoView: (options) => {
      // This will be called by the parent component
    },
    get isEditingGroup() {
      return activeGroup !== null;
    },
    get isCreatingGroup() {
      return isCreatingGroup;
    },
  }));

  // Apply initialSelectedTags when they change
  useEffect(
    () => {
      if (initialSelectedTags && initialSelectedTags.length > 0) {
        // Only apply initialSelectedTags if we're not already editing a group or creating a new one
        // This prevents the component from refreshing when adding tags to an existing group
        if (!activeGroup && !isCreatingGroup) {
          setSelectedTags(initialSelectedTags);
          setIsCreatingGroup(true);
        }
      }
    },
    // eslint-disable-next-line
    [initialSelectedTags],
  );

  // Filter tag groups based on search term
  const filteredGroups = tagGroups.filter(
    (group) =>
      group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      group.description.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleCreateGroup = async () => {
    if (!newGroupName.trim()) return;

    const newName = newGroupName.trim();
    const newDescription = newGroupDescription.trim();
    const newTagIds = selectedTags.map((tag) => tag.tag_id);

    // Create a loading toast
    const loadingToastId = toast.loading("Creating tag group...");
    setIsSaving(true);

    try {
      console.log("Creating tag group:", {
        name: newName,
        description: newDescription,
        tagIds: newTagIds,
      });

      const response = await tagGroupService.createTagGroup(
        deasyApiKey,
        newName,
        newDescription,
        newTagIds,
      );

      console.log("Create group response:", response);

      if (response && response.data && response.data.tag_group) {
        const newGroup = {
          group_id: response.data.tag_group.group_id,
          name: newName,
          description: newDescription,
          tag_ids: newTagIds,
        };

        // Use functional update to ensure we're using the latest state
        setTagGroups((prevGroups) => [...prevGroups, newGroup]);
        setNewGroupName("");
        setNewGroupDescription("");
        setSelectedTags([]);
        setIsCreatingGroup(false);

        toast.success("Tag group created successfully", {
          id: loadingToastId,
        });
      } else {
        throw new Error("Failed to create group: Unexpected response");
      }
    } catch (error) {
      console.error("Error creating group:", error);
      toast.error(
        `Failed to create group: ${error.response?.data?.detail || error.message || "Unknown error"}`,
        {
          id: loadingToastId,
        },
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateGroup = async () => {
    if (!activeGroup || !newGroupName.trim()) return;

    const updatedName = newGroupName.trim();
    const updatedDescription = newGroupDescription.trim();
    const updatedTagIds = selectedTags.map((tag) => tag.tag_id);

    // Create a loading toast
    const loadingToastId = toast.loading("Updating tag group...");
    setIsSaving(true);

    try {
      console.log("Updating tag group:", {
        groupId: activeGroup.group_id,
        name: updatedName,
        description: updatedDescription,
        tagIds: updatedTagIds,
      });

      const response = await tagGroupService.updateTagGroup(
        deasyApiKey,
        activeGroup.group_id,
        updatedName,
        updatedDescription,
        updatedTagIds,
      );

      console.log("Update group response:", response);

      if (response && response.status === 200) {
        // Use functional update to ensure we're using the latest state
        setTagGroups((prevGroups) =>
          prevGroups.map((group) =>
            group.group_id === activeGroup.group_id
              ? {
                  ...group,
                  name: updatedName,
                  description: updatedDescription,
                  tag_ids: updatedTagIds,
                }
              : group,
          ),
        );

        setActiveGroup(null);
        setNewGroupName("");
        setNewGroupDescription("");
        setSelectedTags([]);

        toast.success("Tag group updated successfully", {
          id: loadingToastId,
        });
      } else {
        throw new Error("Failed to update group: Unexpected response");
      }
    } catch (error) {
      console.error("Error updating group:", error);
      toast.error(
        `Failed to update group: ${error.response?.data?.detail || error.message || "Unknown error"}`,
        {
          id: loadingToastId,
        },
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteGroup = async (groupId, deleteAssociatedTags = false) => {
    // Call the API to delete the group
    try {
      const response = await tagGroupService.deleteTagGroup(
        deasyApiKey,
        groupId,
        deleteAssociatedTags,
      );

      // If we get a successful response with data about deleted tags
      if (response?.data?.successful_tags) {
        const failedTags = response.data.failed_tags || [];
        const successfullyDeletedTagObjects =
          response.data.successful_tags || {};

        // Extract tag IDs and names safely
        const successfullyDeletedTagIds = Object.values(
          successfullyDeletedTagObjects,
        )
          .map((tag) => tag.tag_id)
          .filter(Boolean);

        const successfullyDeletedTagNames = Object.values(
          successfullyDeletedTagObjects,
        )
          .map((tag) => tag.name)
          .filter(Boolean);

        // Use functional update to ensure we're using the latest state
        setTagGroups((prevGroups) => {
          // Filter out the deleted group
          const updatedGroups = prevGroups.filter(
            (group) => group.group_id !== groupId,
          );

          // Update other groups to remove the deleted tags
          return updatedGroups.map((group) => ({
            ...group,
            tag_ids: group.tag_ids.filter(
              (id) => !successfullyDeletedTagIds.includes(id),
            ),
          }));
        });

        // Update selected tags if needed
        if (selectedTags.length > 0) {
          setSelectedTags(
            selectedTags.filter(
              (tag) => !successfullyDeletedTagNames.includes(tag.name),
            ),
          );
        }

        // Show appropriate toast message
        if (failedTags.length > 0) {
          toast.warning(
            `Some tags could not be deleted due to dependencies: ${failedTags.join(", ")}. Successfully deleted: ${successfullyDeletedTagNames.join(", ")}`,
          );
        } else {
          toast.success("Tag group deleted successfully");
        }
      } else {
        // Simple success case without detailed tag information
        // Use functional update to ensure we're using the latest state
        setTagGroups((prevGroups) =>
          prevGroups.filter((group) => group.group_id !== groupId),
        );
        toast.success("Tag group deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting group:", error);
      toast.error(error.response.data.detail);
    } finally {
      // Reset active group state if we were editing this group
      if (activeGroup && activeGroup.group_id === groupId) {
        setActiveGroup(null);
        setNewGroupName("");
        setNewGroupDescription("");
        setSelectedTags([]);
      }
    }
  };

  const handleEditGroup = (group) => {
    setActiveGroup(group);
    setNewGroupName(group.name);
    setNewGroupDescription(group.description);
    setSelectedTags(
      group.tag_ids
        .filter((tag_id) => tags.find((t) => t.tag_id === tag_id))
        .map((tag_id) => tags.find((t) => t.tag_id === tag_id)),
    );
  };

  const handleCancelEdit = () => {
    setActiveGroup(null);
    setNewGroupName("");
    setNewGroupDescription("");
    setSelectedTags([]);
    setIsCreatingGroup(false);
  };

  // New function to open delete confirmation modal
  const openDeleteModal = (group) => {
    setGroupToDelete(group);
    setDeleteAssociatedTags(false);
    setShowDeleteModal(true);
  };

  // New function to handle confirmed deletion
  const handleConfirmDelete = async () => {
    if (groupToDelete) {
      // Delete the group
      try {
        setIsSaving(true);
        await handleDeleteGroup(groupToDelete.group_id, deleteAssociatedTags);

        // Close the modal
        setShowDeleteModal(false);
        setGroupToDelete(null);
      } catch (error) {
        console.error("Error deleting group:", error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <div className="w-full bg-white shadow-md rounded-lg border border-gray-200">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-gray-800 flex items-center">
            <span className="bg-primary-50 text-primary-600 p-1.5 rounded-md mr-2">
              <FiTag className="w-5 h-5" />
            </span>
            Tag Groups
            {filteredGroups.length > 0 && (
              <span className="ml-2 text-sm font-normal text-gray-500">
                ({filteredGroups.length}{" "}
                {filteredGroups.length === 1 ? "group" : "groups"})
              </span>
            )}
          </h2>
          <button
            onClick={() => {
              setIsCreatingGroup(true);
              setActiveGroup(null);
              setNewGroupName("");
              setNewGroupDescription("");
              setSelectedTags([]);
            }}
            className="inline-flex items-center gap-1.5 px-4 py-2 bg-primary text-white text-base font-medium rounded-md shadow-sm hover:bg-primary-600 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isCreatingGroup || activeGroup || isSaving}
          >
            <FiPlus className="w-5 h-5" />
            New Group
          </button>
        </div>
      </div>

      <div className="p-4 border-b border-gray-200 bg-gray-50">
        <div className="relative">
          <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search tag groups..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all duration-300 ease-in-out"
          />
        </div>
        {searchTerm && (
          <div className="mt-2 text-xs text-gray-500">
            Found {filteredGroups.length}{" "}
            {filteredGroups.length === 1 ? "result" : "results"} for "
            {searchTerm}"
          </div>
        )}
      </div>

      {/* Group Creation/Editing Form */}
      {(isCreatingGroup || activeGroup) && (
        <div className="p-6 border-b border-gray-200 bg-gray-50">
          <h3 className="text-lg font-medium text-gray-800 mb-4">
            {activeGroup ? "Edit Tag Group" : "Create New Tag Group"}
          </h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Group Name
              </label>
              <input
                type="text"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                placeholder="Enter group name"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                value={newGroupDescription}
                onChange={(e) => setNewGroupDescription(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                placeholder="Enter group description"
                rows={3}
              />
            </div>
            <div>
              <DroppableTagEditor onDrop={handleDropOnEditor}>
                <div className="p-4 bg-gray-50 rounded-lg border border-gray-200 mb-3">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-medium text-gray-700 flex items-center">
                      <span>{`${selectedTags.length} tag${selectedTags.length !== 1 ? "s" : ""} selected`}</span>
                      <span className="ml-2 px-2 py-0.5 bg-primary-50 text-primary-700 text-xs rounded-full border border-primary-100">
                        Drop zone
                      </span>
                    </span>
                    {selectedTags.length > 0 && (
                      <button
                        onClick={() => setSelectedTags([])}
                        className="text-xs text-red-600 hover:text-red-800"
                      >
                        Clear all
                      </button>
                    )}
                  </div>

                  <div className="flex flex-wrap gap-2 mb-2">
                    {selectedTags.length === 0 ? (
                      <div className="flex flex-col items-center justify-center w-full py-4 text-center">
                        <FiTag className="w-8 h-8 text-gray-300 mb-2" />
                        <span className="text-gray-500 text-sm">
                          No tags selected yet
                        </span>
                        <p className="text-xs text-gray-400 mt-1 max-w-md">
                          Drag tags from the left panel to add them to this
                          group. Tags help organize and categorize your content.
                        </p>
                      </div>
                    ) : (
                      <div className="w-full">
                        <div className="flex flex-wrap gap-2">
                          {selectedTags.map((tag) => (
                            <div
                              key={tag.tag_id}
                              className={`flex items-center ${
                                newlyAddedTagIds.includes(tag.tag_id)
                                  ? "bg-green-100 text-green-800 border-green-200 animate-pulse"
                                  : "bg-primary-50 text-primary-800 border-primary-100"
                              } px-3 py-1.5 rounded-md text-sm border shadow-sm hover:shadow-md transition-shadow`}
                            >
                              <span className="mr-1">
                                <FiHash className="w-3 h-3 inline-block" />
                              </span>
                              {tag.name}
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setSelectedTags(
                                    selectedTags.filter(
                                      (t) => t.tag_id !== tag.tag_id,
                                    ),
                                  );
                                }}
                                className="ml-2 text-primary-600 hover:text-primary-800 focus:outline-none"
                                title="Remove tag"
                              >
                                <FiX className="w-4 h-4" />
                              </button>
                            </div>
                          ))}
                        </div>
                        <p className="text-xs text-gray-500 mt-2">
                          Click on a tag to remove it from the selection
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </DroppableTagEditor>
            </div>

            <div className="flex justify-end space-x-3 pt-2">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleCancelEdit();
                }}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={async (e) => {
                  try {
                    setIsSaving(true);
                    e.preventDefault();
                    activeGroup
                      ? await handleUpdateGroup()
                      : await handleCreateGroup();
                  } catch (error) {
                    console.error("Error saving group:", error);
                  } finally {
                    setIsSaving(false);
                  }
                }}
                disabled={!newGroupName.trim() || isSaving}
                className={`px-4 py-2 rounded-md text-white ${
                  !newGroupName.trim() || isSaving
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-primary hover:bg-primary-600"
                }`}
              >
                {isSaving ? (
                  <span className="flex items-center">
                    <svg
                      className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Saving...
                  </span>
                ) : activeGroup ? (
                  "Update Group"
                ) : (
                  "Create Group"
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Drop area for creating a new group */}
      {!isCreatingGroup && !activeGroup && (
        <div className="p-4">
          <DroppableNewGroup onDrop={handleDropOnNewGroup}>
            <div className="flex flex-col items-center justify-center py-6 text-center">
              <div className="bg-gray-100 p-3 rounded-full mb-3">
                <FiTag className="w-10 h-10 text-primary" />
              </div>
              <h3 className="text-lg font-medium text-gray-700 mb-1">
                Drag a tag here
              </h3>
              <p className="text-sm text-gray-500 max-w-md">
                to create a new group with it
              </p>
            </div>
          </DroppableNewGroup>
        </div>
      )}

      {/* List of existing groups */}
      <div className="p-4 overflow-y-auto max-h-[500px]">
        {loadingTagGroups ? (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
          </div>
        ) : filteredGroups.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            {searchTerm ? "No matching groups found" : "No tag groups yet"}
          </div>
        ) : (
          filteredGroups.map((group) => (
            <DroppableGroup
              key={group.group_id}
              group={group}
              onDrop={async (tag) => {
                await handleDropOnGroup(tag, group);
              }}
            >
              <div className="p-4">
                <div className="flex justify-between items-start mb-2">
                  <div className="text-left">
                    <h3 className="text-lg font-medium text-gray-800">
                      {group.name}
                    </h3>
                    {group.description && (
                      <p className="italic text-sm text-gray-600 mt-1">
                        {group.description}
                      </p>
                    )}
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleEditGroup(group);
                      }}
                      className="text-gray-600 hover:text-gray-800 bg-gray-50 p-2 rounded-md hover:bg-gray-100 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                      title="Edit this tag group"
                      disabled={isCreatingGroup || activeGroup || isSaving}
                    >
                      <FiEdit2 className="w-5 h-5" />
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openDeleteModal(group);
                      }}
                      className="text-red-600 hover:text-red-900 bg-red-50 p-2 rounded-md hover:bg-red-100 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                      title="Delete this tag group"
                      disabled={isCreatingGroup || activeGroup || isSaving}
                    >
                      <FiTrash2 className="w-5 h-5" />
                    </button>
                  </div>
                </div>

                {/* Tags in this group */}
                <div className="mt-3">
                  <div className="text-xs text-gray-500 mb-2">
                    {group.tag_ids.length} tag
                    {group.tag_ids.length !== 1 ? "s" : ""}
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {group.tag_ids.length === 0 ? (
                      <span className="text-gray-400 text-sm">No tags</span>
                    ) : (
                      group.tag_ids
                        .map((tagId) => tags.find((t) => t.tag_id === tagId))
                        .filter(Boolean)
                        .map((tag) => (
                          <div
                            key={tag.tag_id}
                            className="bg-gray-100 text-gray-800 px-2 py-1 rounded-md text-xs flex items-center"
                          >
                            <span className="mr-1">
                              <FiHash className="w-3 h-3 inline-block" />
                            </span>
                            {tag.name}
                          </div>
                        ))
                    )}
                  </div>
                </div>
              </div>
            </DroppableGroup>
          ))
        )}
      </div>

      {/* Delete confirmation modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex items-center text-red-600 mb-4">
              <FiAlertTriangle className="w-6 h-6 mr-2" />
              <h3 className="text-lg font-medium">Delete Tag Group</h3>
            </div>
            <p className="mb-4 text-gray-700">
              Are you sure you want to delete the group "{groupToDelete.name}"?
              This action cannot be undone.
            </p>
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={deleteAssociatedTags}
                  onChange={(e) => setDeleteAssociatedTags(e.target.checked)}
                  className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                />
                <span className="ml-2 text-sm text-gray-700">
                  Also delete all tags in this group
                </span>
              </label>
            </div>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                disabled={isSaving}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSaving ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default TagGroups;
