import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
// Error boundaries must be class components per React's current support
// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary

import { useRouteError } from "react-router-dom";

export function ErrorBoundary() {
  const error = useRouteError();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="h-screen w-full bg-white flex flex-col items-center justify-center space-y-4 p-4">
      <svg
        className="w-20 h-20 text-red-500 animate-pulse"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
          fill="currentColor"
        />
      </svg>
      <h1 className="text-3xl font-bold text-gray-800 text-center">
        Oops! An error has occurred
      </h1>
      <p className="text-gray-600 text-center max-w-md">
        Something went wrong while loading this page. Try refreshing the page
        and trying again.
      </p>
      <button
        onClick={handleRefresh}
        className="mt-4 px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
      >
        Refresh Page
      </button>
      <p className="text-sm text-gray-500 mt-8">
        If the problem persists, contact our support team
      </p>
    </div>
  );
}

export default ErrorBoundary;
