import {
  POSTGRESQL_TYPE,
  QDRANT_TYPE,
  S3_TYPE,
  SHAREPOINT_DEST,
  SHAREPOINT_TYPE,
  POSTGRESQL_DEST,
} from "../components/MainContent/DeasyConfig/ConfigElements/utils";

const defaultVectorDB = {
  type: process.env.REACT_APP_VECTOR_DB_TYPE || "QdrantVectorDBManager",
  collection: process.env.REACT_APP_VECTOR_DB_COLLECTION || "",
  apiKey: process.env.REACT_APP_VECTOR_DB_API_KEY || "",
  url: process.env.REACT_APP_VECTOR_DB_URL || "",
};
const defaultLLMEndpoint = {
  llmType: process.env.REACT_APP_LLM_ENDPOINT_TYPE || "openai",
  api_key: process.env.REACT_APP_LLM_ENDPOINT_API_KEY || "",
};
const defaultDeasyApiKey = process.env.REACT_APP_DEASY_API_KEY || "";
const disabledPages = process.env.REACT_APP_DISABLED_PAGES?.split(",") || [
  "evaluateRAG",
];

export const deasyFileKey = "filename";

export const config = {
  vectorDB: { default: defaultVectorDB },
  llmEndpoint: { default: defaultLLMEndpoint },
  deasy: { apiKey: defaultDeasyApiKey },
  features: {
    disabledPages,
  },
  deasyUserConfig: {
    deasyApiKey: "",
    vdbmConfig: {
      LastActive: "",
      Configs: {},
    },
    llmConfig: {
      LastActive: "",
      Configs: {},
    },
    destinationConfig: {
      LastActive: "",
      Configs: {},
    },
  },
};

export const getVdbLogo = (type) => {
  switch (type) {
    case QDRANT_TYPE:
      return "/images/vdb-logos/qdrant.png";
    case "PineconeVectorDBManager":
      return "/images/vdb-logos/pinecone.png";
    case POSTGRESQL_TYPE:
      return "/images/vdb-logos/postgresql.png";
    case S3_TYPE:
      return "/images/vdb-logos/s3.png";
    case SHAREPOINT_TYPE:
      return "/images/vdb-logos/sharepoint.png";
    case POSTGRESQL_DEST:
      return "/images/vdb-logos/postgresql.png";
    default:
      console.log("No logo found for type", type);
      return null;
  }
};

export const getLlmLogo = (type) => {
  switch (type) {
    case "openai":
      return "/images/llm-logos/openai.png";
    case "deasy_compute":
      return "/deasy_shrimp_og.png";
    case "gemini":
      return "/images/llm-logos/gemini.png";
    default:
      return null;
  }
};

export const OutTheBoxMetadataKeys = {
  KEY_QUESTIONS: { value: "Key_Questions" },
  PRIMARY_ENTITY: { value: "Primary_Entity" },
  SUMMARY: { value: "Summary" },
  DEFINING_INFORMATIONS: { value: "Defining_Informations" },
  YEAR: { value: "Year" },
  DATE: { value: "Date" },
  LANGUAGE: { value: "Language" },
  TOPICS: { value: "Topics" },
  AUTHOR: { value: "Author" },
  LOCATION: { value: "Location" },
  ORGANIZATIONS: { value: "Organizations" },
  DOCUMENT_TYPE: { value: "Document_Type" },
};

export const DEFAULT_DATE_FORMAT = "%d-%m-%Y";

export const DATE_FORMAT_OPTIONS = [
  { value: "%Y-%m-%d", label: "YYYY-MM-DD (2023-01-31)" },
  { value: "%m/%d/%Y", label: "MM/DD/YYYY (01/31/2023)" },
  { value: "%d/%m/%Y", label: "DD/MM/YYYY (31/01/2023)" },
  { value: "%Y/%m/%d", label: "YYYY/MM/DD (2023/01/31)" },
  { value: "%d-%m-%Y", label: "DD-MM-YYYY (31-01-2023)" },
  { value: "%m-%d-%Y", label: "MM-DD-YYYY (01-31-2023)" },
];
export const getDestinationLogo = (type) => {
  switch (type) {
    case SHAREPOINT_DEST:
      return "/images/vdb-logos/sharepoint.png";
    case POSTGRESQL_DEST || "Postgres":
      return "/images/vdb-logos/postgresql.png";
    default:
      console.log("No logo found for destination type", type);
      return null;
  }
};
