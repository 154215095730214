import React, { useState } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
} from "@mui/material";
import { FaFilter, FaSync } from "react-icons/fa";
import { BsLightning } from "react-icons/bs";
import { TABLE_COLUMN_WIDTHS } from "../metadataTableUtils";

const TableHeader = ({
  displayColumns,
  deasyFileKey,
  loading,
  totalRows,
  handleRefresh,
  inputSearchQuery,
  setInputSearchQuery,
  setPage,
  setSearchQuery,
  handleFilterClick,
  effectiveFilters,
  renderDistributionChart,
  onStandardizeClick,
}) => {
  const handleSearch = () => {
    setPage(0);
    setSearchQuery(inputSearchQuery);
  };

  const handleClearSearch = () => {
    setPage(0);
    setSearchQuery("");
    setInputSearchQuery("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <TableHead>
      <TableRow>
        {displayColumns.map((column) => (
          <TableCell
            key={column}
            sx={{
              width:
                column === deasyFileKey
                  ? TABLE_COLUMN_WIDTHS[deasyFileKey]
                  : TABLE_COLUMN_WIDTHS.default,
              minWidth:
                column === deasyFileKey
                  ? TABLE_COLUMN_WIDTHS[deasyFileKey]
                  : TABLE_COLUMN_WIDTHS.default,
              maxWidth:
                column === deasyFileKey
                  ? TABLE_COLUMN_WIDTHS[deasyFileKey]
                  : TABLE_COLUMN_WIDTHS.default,
              padding: "8px 16px",
              backgroundColor: "#f9fafb",
              fontWeight: 500,
              fontSize: "0.8125rem",
              height: column !== deasyFileKey ? "120px" : "48px",
              lineHeight: 1.2,
              color: "#374151",
              borderBottom: "1px solid #e5e7eb",
              position: "sticky",
              top: 0,
              ...(column === deasyFileKey && {
                left: 0,
                zIndex: 3,
              }),
              ...(column !== deasyFileKey && {
                zIndex: 2,
              }),
              overflow: "visible",
            }}
          >
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between gap-2 mb-2">
                {column === deasyFileKey ? (
                  <FileNameColumn
                    loading={loading}
                    totalRows={totalRows}
                    handleRefresh={handleRefresh}
                    inputSearchQuery={inputSearchQuery}
                    setInputSearchQuery={setInputSearchQuery}
                    handleSearch={handleSearch}
                    handleClearSearch={handleClearSearch}
                    handleKeyDown={handleKeyDown}
                  />
                ) : (
                  <StandardColumn
                    column={column}
                    handleFilterClick={handleFilterClick}
                    effectiveFilters={effectiveFilters}
                    onStandardizeClick={onStandardizeClick}
                  />
                )}
              </div>
              {column !== deasyFileKey && (
                <div className="h-[80px] relative">
                  {renderDistributionChart(column)}
                </div>
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const FileNameColumn = ({
  loading,
  totalRows,
  handleRefresh,
  inputSearchQuery,
  setInputSearchQuery,
  handleSearch,
  handleClearSearch,
  handleKeyDown,
}) => (
  <div className="flex items-start justify-between w-full flex-col gap-4">
    <div className="flex items-center gap-4">
      <span className="text-sm font-medium whitespace-nowrap">File Name</span>
      <div className="flex items-center gap-3 bg-gray-50 px-3 py-1.5 rounded-lg border border-gray-200">
        <Tooltip title="Current data slice"></Tooltip>

        <span className="text-base font-semibold text-gray-900">
          {loading ? "..." : totalRows}
        </span>
        <span className="text-sm text-gray-600 whitespace-nowrap">files</span>
        <div className="h-6 w-[1px] bg-gray-200 mx-2"></div>

        <Tooltip title="Refresh data and clear filters">
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleRefresh();
            }}
            disabled={loading}
            sx={{
              padding: 0.5,
              backgroundColor: "white",
              border: "1px solid #E5E7EB",
              "&:hover": { backgroundColor: "#F3F4F6" },
            }}
          >
            <FaSync
              className={loading ? "animate-spin" : ""}
              size={14}
              color="#374151"
            />
          </IconButton>
        </Tooltip>
      </div>
    </div>
    <div className="flex gap-2 w-full">
      <div className="relative flex-1">
        <input
          type="text"
          placeholder="Search files..."
          value={inputSearchQuery}
          onChange={(e) => setInputSearchQuery(e.target.value)}
          className="w-full px-3 py-1.5 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 pr-20"
          onKeyDown={handleKeyDown}
        />
        {inputSearchQuery && (
          <button
            onClick={handleClearSearch}
            className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 text-sm"
          >
            Clear
          </button>
        )}
      </div>
      <button
        onClick={handleSearch}
        className="px-3 py-1.5 text-sm btn-secondary rounded-md disabled:opacity-50 whitespace-nowrap"
        disabled={loading}
      >
        Search
      </button>
    </div>
  </div>
);

const StandardColumn = ({
  column,
  handleFilterClick,
  effectiveFilters,
  onStandardizeClick,
}) => {
  const [loading] = useState(false);

  const handleStandardizeClick = () => {
    onStandardizeClick(column);
  };

  return (
    <div className="flex items-center justify-between w-full gap-2">
      <Tooltip
        title={column
          .replace(/_/g, " ")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
      >
        <div className="truncate">
          {column
            .replace(/_/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </div>
      </Tooltip>
      <div className="flex items-center gap-0.5 flex-shrink-0">
        <Tooltip title="Standardize Output Values">
          <IconButton
            size="small"
            onClick={handleStandardizeClick}
            disabled={loading}
            sx={{
              padding: 1,
              "&:hover": { backgroundColor: "#f9fafb" },
              zIndex: 10,
            }}
          >
            <BsLightning size={11} color="#9ca3af" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Filter">
          <IconButton
            size="small"
            onClick={(e) => handleFilterClick(e, column)}
            sx={{
              padding: 1,
              backgroundColor: effectiveFilters[column]
                ? "#ecfdf5"
                : "transparent",
              "&:hover": { backgroundColor: "#f9fafb" },
              zIndex: 10,
            }}
          >
            <FaFilter
              size={10}
              color={effectiveFilters[column] ? "#4FA892" : "#9ca3af"}
            />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default TableHeader;
