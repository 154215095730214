import React, { useState, useContext, useEffect, useCallback } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";
import {
  unstructuredService,
  connectorService,
} from "../../../../services/api";
import {
  prepareEndpointManagerConfig,
  prepareVectorDBConfiguration,
  createPostgresFromUnstructuredUtils,
} from "../../../../services/utils";
import { v4 as uuidv4 } from "uuid";
import { LLM_OPTIONS } from "./LLMConfig";
import { Edit, Trash2, AlertCircle, RefreshCw } from "lucide-react";
import { toast } from "react-hot-toast";
import { Modal } from "@mui/material";
import { getLlmLogo, getVdbLogo } from "../../../../config/config";
import { getVdbType, getDisplayType } from "./utils";
import { auth } from "../../../../config/firebase";

const UnstructuredProfileCard = ({
  type,
  name,
  isActive,
  onSelect,
  onEdit,
  onDelete,
  details,
  showActions = true,
}) => {
  const { deasyUserConfig, setDeasyUserConfig, setVdbProfiles } =
    useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;
  const llmEndpointConfiguration =
    deasyUserConfig.llmConfig.Configs[deasyUserConfig.llmConfig.LastActive];
  const vectorDBConfiguration = deasyUserConfig.vdbmConfig.Configs[name];

  const [syncedPercentage, setSyncedPercentage] = useState(0);
  const [synced, setSynced] = useState(0);
  const [notSynced, setNotSynced] = useState(0);
  const [outOfSync, setOutOfSync] = useState(0);
  const [fileTypeDistribution, setFileTypeDistribution] = useState({});
  const [addedFiles, setAddedFiles] = useState(0);
  const [fileTypeSyncCounts, setFileTypeSyncCounts] = useState({});

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUpdatingSyncStats, setIsUpdatingSyncStats] = useState(false);
  const [showDeasyComputeWarning, setShowDeasyComputeWarning] = useState(false);
  const [showSyncConfirmation, setShowSyncConfirmation] = useState(false);
  const [showCreateVectorDBModal, setShowCreateVectorDBModal] = useState(false);

  const [useLLM, setUseLLM] = useState(false);
  const [isEligibleForLLMSync, setIsEligibleForLLMSync] = useState(false);
  const [isCreatingVectorDB, setIsCreatingVectorDB] = useState(false);

  const runSync = async () => {
    const newJobId = uuidv4();

    const pendingJob = {
      tracker_id: newJobId,
      task_type: "ocr",
      status: "pending",
      start_time: new Date().toISOString(),
      progress: 0,
      total_files: notSynced || 1000,
      processed_files: 0,
      file_details: {
        total: notSynced || 1000,
        processed: 0,
        successful: 0,
        failed: 0,
      },
      additional_stats: {
        connector_name: vectorDBConfiguration.name,
        use_llm: useLLM,
      },
      is_pending: true,
    };

    const existingPendingJobs = JSON.parse(
      localStorage.getItem("pendingProgressJobs") || "[]",
    );
    localStorage.setItem(
      "pendingProgressJobs",
      JSON.stringify([...existingPendingJobs, pendingJob]),
    );

    unstructuredService.ingestUnstructuredData(
      deasyApiKey, // deasyApiKey
      prepareEndpointManagerConfig(llmEndpointConfiguration), // llmEndpointConfiguration
      prepareVectorDBConfiguration(vectorDBConfiguration), // vectorDBConfiguration
      newJobId, // newJobId
      null, // fileNames
      notSynced, // fileCountToRun
      useLLM, // useLLM
    );

    toast.success(
      "Monitor progress in the Progress Tracking tab, this can take a few minutes to start.",
      { duration: 10000 },
    );
  };

  useEffect(() => {
    if (vectorDBConfiguration?.syncStats) {
      const stats = vectorDBConfiguration.syncStats;
      setSyncedPercentage(stats.syncedPercentage || 0);
      setSynced(stats.synced || 0);
      setNotSynced(stats.notSynced || 0);
      setOutOfSync(stats.outOfSync || 0);
      setFileTypeDistribution(stats.fileTypeDistribution || {});
      setAddedFiles(stats.added || 0);
      setFileTypeSyncCounts(stats.per_file_counts_synced || {});
    }
  }, [vectorDBConfiguration, addedFiles]);

  useEffect(() => {
    if (deasyUserConfig?.subscription?.tier_id) {
      const tierNumber =
        parseInt(deasyUserConfig.subscription.tier_id.replace(/\D/g, "")) || 0;
      // Check if user is on demo tier or tier 5+
      setIsEligibleForLLMSync(
        deasyUserConfig.subscription.tier_id === "demo_tier" ||
          tierNumber >= 5 ||
          deasyUserConfig.subscription.tier_id.includes("custom"),
      );
    }
  }, [deasyUserConfig]);

  const updateSyncStats = useCallback(async () => {
    setIsUpdatingSyncStats(true);
    try {
      const result = await unstructuredService.getSyncStats(deasyApiKey, [
        prepareVectorDBConfiguration(vectorDBConfiguration),
      ]);
      const sourceStats = result.data.sources.find(
        (source) => source.source_name === vectorDBConfiguration.name,
      );

      if (sourceStats) {
        setFileTypeDistribution(sourceStats.file_type_distribution || {});
        setSyncedPercentage(sourceStats.synced_percentage);
        setSynced(sourceStats.synced);
        setNotSynced(sourceStats.not_synced);
        setOutOfSync(sourceStats.out_of_sync);
        setAddedFiles(sourceStats.added);
        setFileTypeSyncCounts(sourceStats.per_file_counts_synced || {});

        // Show toast if new files were added
        if (sourceStats.added > 0) {
          toast(
            `Data source ${vectorDBConfiguration.name} has added ${sourceStats.added} files since last sync`,
            { duration: 5000 },
          );
        }
      } else {
        setFileTypeSyncCounts({});
        setFileTypeDistribution({});
        setSyncedPercentage(0);
        setSynced(0);
        setNotSynced(0);
        setOutOfSync(0);
        setAddedFiles(0);
      }
    } catch (error) {
      console.error("Error updating sync stats", error);
    } finally {
      setIsUpdatingSyncStats(false);
    }
  }, [deasyApiKey, vectorDBConfiguration]);

  useEffect(() => {
    let isMounted = true;

    const fetchStats = async () => {
      if (isDropdownOpen) {
        setIsUpdatingSyncStats(true);
        try {
          if (isMounted) {
            await updateSyncStats();
          }
        } finally {
          if (isMounted) {
            setIsUpdatingSyncStats(false);
          }
        }
      }
    };

    fetchStats();

    return () => {
      isMounted = false;
    };
  }, [isDropdownOpen, updateSyncStats]);

  const handleSyncClick = (e) => {
    e.stopPropagation();
    if (
      isUpdatingSyncStats ||
      (syncedPercentage === 1 && notSynced === 0 && outOfSync === 0)
    )
      return;

    setShowSyncConfirmation(true);
  };

  // Add this new function to safely toggle the LLM option
  const toggleLLMOption = (e) => {
    // Prevent all default behaviors and propagation
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (isEligibleForLLMSync) {
      // Use functional state update to ensure we're working with the latest state
      setUseLLM((prevState) => !prevState);
    }
  };

  // Function to create the Vector DB when confirmed
  const createVectorDB = async () => {
    setIsCreatingVectorDB(true);
    const user = auth.currentUser.email;
    try {
      // Create minimal Postgres connector configuration
      const pgConfigData = createPostgresFromUnstructuredUtils(
        vectorDBConfiguration,
        user,
      );

      if (!pgConfigData) {
        toast.error("Failed to create PostgreSQL configuration data");
        return;
      }

      // Create the new connector using backend endpoint
      const response = await connectorService.createPostgresFromUnstructured(
        pgConfigData,
        deasyApiKey,
      );

      if (response && response.data && response.data.profile_id) {
        // Get the updated connector list to refresh the UI
        const vdbResponse = await connectorService.getVDBProfiles(deasyApiKey);
        const updatedProfiles = vdbResponse.data.connectors;

        // Get the newly created connector
        const newConnector = updatedProfiles[pgConfigData.connector_name];

        // Update local state
        setVdbProfiles(updatedProfiles);

        // Update the user config
        const newConfigs = { ...deasyUserConfig.vdbmConfig.Configs };
        newConfigs[pgConfigData.connector_name] = newConnector;

        setDeasyUserConfig({
          ...deasyUserConfig,
          vdbmConfig: {
            ...deasyUserConfig.vdbmConfig,
            Configs: newConfigs,
          },
        });

        toast.success(
          `Created PostgreSQL connector: ${pgConfigData.connector_name}`,
        );
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error creating Vector DB:", error);
      toast.error("Failed to create PostgreSQL connector");
    } finally {
      setIsCreatingVectorDB(false);
      setShowCreateVectorDBModal(false);
    }
  };

  return (
    <div
      className={`group relative p-6 rounded-xl border-2 transition-all cursor-pointer text-left shadow-sm hover:shadow-md
        ${
          isActive
            ? "border-primary bg-primary-50/70"
            : "border-gray-100 hover:border-emerald-200 bg-white"
        }`}
      onClick={async () => await onSelect(name)}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2">
          <img
            src={getVdbLogo(getVdbType(type))}
            alt={type}
            className="w-6 h-6"
          />
          <h3 className="font-semibold text-gray-900 text-lg">{name}</h3>
          {addedFiles > 0 && (
            <div className="relative group/tooltip">
              <AlertCircle size={16} className="text-amber-500" />
              <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 px-3 py-1 bg-gray-900 text-white text-sm rounded-lg opacity-0 group-hover/tooltip:opacity-100 whitespace-nowrap transition-opacity">
                Data source {name} has added {addedFiles} files since last sync
              </div>
            </div>
          )}
        </div>
        {showActions && (
          <div className="flex items-center gap-2">
            {onEdit && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(name);
                }}
                className="p-1 hover:bg-gray-100 rounded"
              >
                <Edit size={16} className="text-gray-500" />
              </button>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(name);
              }}
              className="p-1 hover:bg-gray-100 rounded"
            >
              <Trash2 size={16} className="text-gray-500" />
            </button>
          </div>
        )}
      </div>

      <div className="space-y-2">
        {Object.entries(details).map(([key, value]) => {
          if (key === "Type") return null;
          return (
            <div key={key} className="text-sm flex items-center">
              <span className="text-gray-500 font-medium min-w-[100px]">
                {key}:{" "}
              </span>
              <span className="text-gray-700">
                {key.toLowerCase().includes("key") ||
                key.toLowerCase().includes("password")
                  ? "••••••"
                  : value}
              </span>
            </div>
          );
        })}
        <details
          className="group/details"
          open={isDropdownOpen}
          onToggle={(e) => setIsDropdownOpen(e.target.open)}
        >
          <summary
            className="text-sm flex items-center gap-2 cursor-pointer list-none absolute right-4 bottom-4"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-400 transition-transform group-open/details:rotate-90"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-gray-700 font-medium">Sync Status</span>
          </summary>

          <div
            className="pt-3 text-sm"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="text-sm flex items-center gap-3">
              <span className="text-gray-500 font-medium min-w-[90px]">
                Sync Percentage:
              </span>
              <div className="relative flex-1 h-3 bg-gray-100 rounded-full overflow-hidden">
                <div
                  className={`h-full transition-all duration-500 ${
                    isUpdatingSyncStats
                      ? "animate-pulse bg-emerald-500"
                      : "bg-emerald-500"
                  }`}
                  style={{ width: `${syncedPercentage * 100}%` }}
                />
              </div>
              <span className="text-sm font-medium text-gray-600 text-right">
                {isUpdatingSyncStats ? (
                  <div className="flex gap-1">
                    <span className="animate-bounce">.</span>
                    <span
                      className="animate-bounce"
                      style={{ animationDelay: "0.1s" }}
                    >
                      .
                    </span>
                    <span
                      className="animate-bounce"
                      style={{ animationDelay: "0.2s" }}
                    >
                      .
                    </span>
                  </div>
                ) : (
                  Math.round(syncedPercentage * 100) + "%"
                )}
              </span>
            </div>

            {/* Stats Row */}
            <div className="flex gap-4 mb-4 mt-2">
              <div
                className="flex-1 bg-green-50 rounded-lg p-2"
                title="Files synced and loaded to Deasy"
              >
                <div className="text-green-600 font-medium">
                  {isUpdatingSyncStats ? (
                    <div className="flex gap-1">
                      <span className="animate-bounce">.</span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "0.1s" }}
                      >
                        .
                      </span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "0.2s" }}
                      >
                        .
                      </span>
                    </div>
                  ) : (
                    synced.toLocaleString()
                  )}
                </div>
                <div className="text-xs text-green-500">Files synced</div>
              </div>

              <div
                className="flex-1 bg-orange-50 rounded-lg p-2"
                title="Files not yet OCR'd and loaded to Deasy"
              >
                <div className="text-orange-600 font-medium">
                  {isUpdatingSyncStats ? (
                    <div className="flex gap-1">
                      <span className="animate-bounce">.</span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "0.1s" }}
                      >
                        .
                      </span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "0.2s" }}
                      >
                        .
                      </span>
                    </div>
                  ) : (
                    notSynced.toLocaleString()
                  )}
                </div>
                <div className="text-xs text-orange-500">Files not synced</div>
              </div>
              <div
                className="flex-1 bg-red-50 rounded-lg p-2"
                title="Files that exist in Deasy but are no longer present in the external source"
              >
                <span className="text-red-600 font-medium">
                  {isUpdatingSyncStats ? (
                    <div className="flex gap-1">
                      <span className="animate-bounce">.</span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "0.1s" }}
                      >
                        .
                      </span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "0.2s" }}
                      >
                        .
                      </span>
                    </div>
                  ) : (
                    outOfSync.toLocaleString()
                  )}
                </span>
                <div className="text-xs text-red-500">Files out of sync</div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h4 className="font-medium text-gray-700 mb-2">
              File Type Distribution
            </h4>
            <div className="grid grid-cols-2 gap-2">
              {isUpdatingSyncStats ? (
                // Loader placeholders
                <>
                  {[1, 2, 3, 4].map((i) => (
                    <div
                      key={i}
                      className="bg-gray-50 rounded-lg p-2 animate-pulse"
                    >
                      <div className="h-5 bg-gray-200 rounded w-20 mb-1"></div>
                      <div className="h-4 bg-gray-200 rounded w-16"></div>
                    </div>
                  ))}
                </>
              ) : (
                // Actual content
                Object.entries(fileTypeDistribution)
                  .filter(([_, count]) => count > 0)
                  .map(([type, count]) => (
                    <div key={type} className="bg-gray-50 rounded-lg p-2">
                      <div className="flex items-center justify-between">
                        <div className="text-sm font-medium text-gray-900 capitalize">
                          {type === "others" ? "Other Types" : type}
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="w-24 bg-gray-200 h-2 rounded-full">
                            <div
                              className="h-full bg-emerald-400 rounded-full"
                              style={{
                                width: `${Math.floor(((fileTypeSyncCounts[type] || 0) / count) * 100)}%`,
                              }}
                            ></div>
                          </div>
                          <span className="text-sm font-medium text-gray-900">
                            {Math.floor(
                              ((fileTypeSyncCounts[type] || 0) / count) * 100,
                            )}
                            %
                          </span>
                        </div>
                      </div>
                      <div className="mt-1 text-sm text-gray-500">
                        {fileTypeSyncCounts[type] || 0}/{count} files synced
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </details>
      </div>

      {/* Deasy Compute Warning Modal */}
      <Modal
        open={showDeasyComputeWarning}
        onClose={() => setShowDeasyComputeWarning(false)}
        aria-labelledby="deasy-compute-warning-title"
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-xl p-6 w-[500px]">
          <h2
            className="text-xl font-semibold mb-4 text-red-600"
            id="deasy-compute-warning-title"
          >
            Model Not Supported
          </h2>
          <p className="text-gray-600 mb-6">
            The Deasy endpoint does not support dataset syncing. Please switch
            to another endpoint in your LLM configuration to proceed with
            syncing.
          </p>
          <div className="flex justify-end gap-3">
            <button
              onClick={() => setShowDeasyComputeWarning(false)}
              className="px-4 py-2 rounded-lg bg-primary text-white font-semibold hover:bg-emerald-700"
            >
              Got it
            </button>
          </div>
        </div>
      </Modal>

      {/* Sync Confirmation Modal */}
      <Modal
        open={showSyncConfirmation}
        onClose={() => setShowSyncConfirmation(false)}
        aria-labelledby="sync-confirmation-title"
      >
        <div
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-xl p-6 w-[500px]"
          onClick={(e) => e.stopPropagation()} // Stop clicks within modal from propagating
        >
          <h2
            className="text-xl font-semibold mb-4 text-gray-900"
            id="sync-confirmation-title"
          >
            Confirm Sync
          </h2>
          <p className="text-gray-600 mb-6">
            Starting the OCR and sync process. This may take a while. Do you
            want to continue?
          </p>

          <div
            className="mb-6 p-4 rounded-lg border"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="flex items-start"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="ml-4" onClick={(e) => e.stopPropagation()}>
                <div
                  className="mt-2 flex items-center gap-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className={`flex items-center ${isEligibleForLLMSync ? "cursor-pointer" : "cursor-not-allowed"}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleLLMOption(e);
                    }}
                  >
                    <div className="relative inline-block h-6 w-11 mr-2">
                      <input
                        type="checkbox"
                        className="sr-only"
                        checked={useLLM}
                        onChange={(e) => {
                          e.stopPropagation();
                          toggleLLMOption(e);
                        }}
                        disabled={!isEligibleForLLMSync}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <div
                        className={`absolute inset-0 rounded-full transition-colors ${useLLM && isEligibleForLLMSync ? "bg-gray-600" : "bg-gray-200"} ${!isEligibleForLLMSync ? "opacity-60" : ""}`}
                      ></div>
                      <div
                        className={`absolute top-[2px] left-[2px] bg-white border border-gray-300 rounded-full h-5 w-5 transition-transform ${useLLM && isEligibleForLLMSync ? "translate-x-full border-white" : ""}`}
                      ></div>
                    </div>
                    <span
                      className={`text-sm font-medium ${isEligibleForLLMSync ? "text-gray-700" : "text-gray-500"}`}
                    >
                      Enhanced Sync
                    </span>
                  </div>
                </div>
                {isEligibleForLLMSync ? (
                  <p className="mt-2 text-sm text-gray-700">
                    Enables LLM processing to produce higher quality document
                    processing but may take longer. Recommended for complex
                    documents.
                  </p>
                ) : (
                  <p className="mt-2 text-sm text-gray-500">
                    This feature is available on the demo tier or Tier 5 and
                    above.
                    <a
                      href="/tokens"
                      className="ml-1 text-indigo-600 hover:text-indigo-800 font-medium"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Upgrade your subscription
                    </a>{" "}
                    to access enhanced document processing with LLM.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-3">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowSyncConfirmation(false);
              }}
              className="px-4 py-2 rounded-lg border border-gray-300 text-gray-700 font-semibold hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowSyncConfirmation(false);
                if (llmEndpointConfiguration) {
                  runSync();
                } else {
                  toast.error(
                    "Please set an active LLM connection before syncing",
                  );
                }
              }}
              className="px-4 py-2 rounded-lg bg-primary text-white font-semibold hover:bg-emerald-700"
            >
              Start Sync
            </button>
          </div>
        </div>
      </Modal>

      {/* Create Vector DB Modal */}
      <Modal
        open={showCreateVectorDBModal}
        onClose={() => setShowCreateVectorDBModal(false)}
        aria-labelledby="create-vectordb-modal"
      >
        <div
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                    bg-white p-8 rounded-xl shadow-xl max-w-lg w-full"
          onClick={(e) => e.stopPropagation()}
        >
          <h3 className="text-xl font-semibold mb-4">
            Create PostgreSQL Vector DB
          </h3>
          <p className="mb-3 text-gray-600">
            This will create a new PostgreSQL Vector DB connector using
            environment variables and data from this{" "}
            {getDisplayType(vectorDBConfiguration.type)} connector.
          </p>
          <p className="mb-4 text-gray-600">
            <strong>Key benefit:</strong> You can query any metadata exported to
            this connector using the{" "}
            <code className="bg-gray-100 px-1 rounded">Deasy Select Agent</code>{" "}
            from the{" "}
            <a
              href="https://github.com/Deasie-internal/deasy-sdk"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              Deasy SDK
            </a>
            . This enables powerful RAG applications and search functionality
            with filtering based on metadata.
          </p>

          <div className="bg-gray-50 p-4 rounded-lg mb-4 overflow-auto max-h-60">
            <p className="text-xs font-medium text-gray-700 mb-2">
              Example SDK usage:
            </p>
            <pre className="text-xs bg-gray-100 p-2 rounded">
              {`from deasy import Deasy

# Initialize the client
client = Deasy(
    x_user="your_username",
    x_token="your_deasy_api_key"
)

# Query your Vector DB with natural language
question = "What is the primary objective of the trial?"

# vdb_profile_name is the name of your new connector
response = client.metadata.deasy_select.query(
    query=question,
    vdb_profile_name="${vectorDBConfiguration.name} Vector DB",
    columns=["id", "filename", "text", "dense"]
)`}
            </pre>
            <p className="text-xs mt-2 text-gray-600">
              Have a look at the{" "}
              <a
                href="https://github.com/Deasie-internal/deasy-sdk"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Deasy SDK
              </a>{" "}
              for more examples and documentation.
            </p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg mb-6">
            <p className="text-sm text-gray-700 mb-2">
              <span className="font-medium">Collection Name:</span>{" "}
              {vectorDBConfiguration.type === "SharepointDataSourceManager"
                ? `Sharepoint_${vectorDBConfiguration.sharepoint_site_name}_${vectorDBConfiguration.name}`
                : `S3_${vectorDBConfiguration.bucket_name}_${vectorDBConfiguration.name}`}
            </p>
            <p className="text-sm text-gray-700">
              <span className="font-medium">Connector Name:</span>{" "}
              {`${vectorDBConfiguration.name} Vector DB`}
            </p>
          </div>

          <div className="flex justify-end gap-4">
            <button
              className="px-4 py-2 rounded-lg border border-gray-300 text-gray-700 hover:bg-gray-50 transition-colors"
              onClick={() => setShowCreateVectorDBModal(false)}
              disabled={isCreatingVectorDB}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 rounded-lg bg-primary text-white hover:bg-primary-dark transition-colors flex items-center gap-2"
              onClick={createVectorDB}
              disabled={isCreatingVectorDB}
            >
              {isCreatingVectorDB ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Creating...
                </>
              ) : (
                "Create"
              )}
            </button>
          </div>
        </div>
      </Modal>

      {/* Always show the buttons, regardless of isActive status */}
      <div className="mt-10 flex items-center justify-between">
        <button
          onClick={handleSyncClick}
          className="flex items-center gap-2 text-sm font-medium text-primary hover:text-primary-dark transition-colors absolute left-4 bottom-4"
        >
          <RefreshCw size={16} />
          Sync Files
        </button>
      </div>
    </div>
  );
};

const VDBProfileCard = ({
  type,
  name,
  isActive,
  onSelect,
  onEdit,
  onDelete,
  details,
  showActions = true,
  icon,
  isReadOnly = false,
  isDestination = false,
}) => {
  const provider = LLM_OPTIONS.find((opt) => opt.value === type)?.value;

  return (
    <div
      className={`group relative p-6 rounded-xl border-2 transition-all cursor-pointer text-left shadow-sm hover:shadow-md
        ${isActive ? "border-primary bg-primary-50/70" : "border-gray-100 hover:border-emerald-200 bg-white"}`}
      onClick={async () => await onSelect(name)}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2">
          <img
            src={provider ? getLlmLogo(type) : getVdbLogo(getVdbType(type))}
            alt={type}
            className="w-6 h-6"
          />
          <h3 className="font-semibold text-gray-900 text-lg">{name}</h3>
        </div>
        {showActions && (
          <div className="flex items-center gap-2">
            {onEdit && !isReadOnly && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(name);
                }}
                className="p-1 hover:bg-gray-100 rounded"
              >
                <Edit size={16} className="text-gray-500" />
              </button>
            )}
            {onDelete && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(name);
                }}
                className="p-1 hover:bg-gray-100 rounded"
              >
                <Trash2 size={16} className="text-gray-500" />
              </button>
            )}
          </div>
        )}
      </div>

      {true && (
        <div className="space-y-2">
          {Object.entries(details).map(([key, value]) => {
            if (key === "Type" || key === "Create_new_collection") return null;
            return (
              <div key={key} className="text-sm flex items-center">
                <span className="text-gray-500 font-medium min-w-[100px]">
                  {key}:
                </span>
                <span className="text-gray-700 truncate">{value}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const ProfileCard = ({
  type,
  name,
  isActive,
  onSelect,
  showActions = true,
  onEdit,
  onDelete,
  details,
  icon,
  isDestination = false,
  isReadOnly = false,
}) => {
  if (type === "S3" || (type === "Sharepoint" && !isDestination)) {
    return (
      <UnstructuredProfileCard
        type={type}
        name={name}
        isActive={isActive}
        onSelect={onSelect}
        onEdit={onEdit}
        onDelete={onDelete}
        details={details}
        showActions={showActions}
        icon={icon}
      />
    );
  } else {
    return (
      <VDBProfileCard
        type={type}
        name={name}
        isActive={isActive}
        onSelect={onSelect}
        onEdit={onEdit}
        onDelete={onDelete}
        details={details}
        showActions={showActions}
        icon={icon}
        isDestination={isDestination}
        isReadOnly={isReadOnly}
      />
    );
  }
};
