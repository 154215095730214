import React, { useState, useEffect } from "react";

export const ExpandValueListModal = ({
  isOpen,
  onClose,
  categoryName,
  values = [],
  onRemoveValue,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredValues, setFilteredValues] = useState(values);

  // Reset search and filtered values when modal opens or values change
  useEffect(() => {
    setSearchQuery("");
    setFilteredValues(values);
  }, [isOpen, values]);

  // Filter values when search query changes
  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredValues(values);
      return;
    }

    const filtered = values.filter((value) =>
      value.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    setFilteredValues(filtered);
  }, [searchQuery, values]);

  if (!isOpen) return null;

  return (
    <>
      {/* Backdrop - fixed position without transforms */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-[60]"
        onClick={onClose}
      />

      {/* Modal - using absolute positioning with explicit translate for better centering */}
      <div
        className="fixed z-[61] bg-white rounded-xl shadow-xl border border-gray-200 overflow-hidden"
        style={{
          width: "800px",
          maxWidth: "90vw",
          maxHeight: "85vh",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          transformOrigin: "center",
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
        }}
      >
        {/* Header */}
        <div className="sticky top-0 z-10 bg-white flex items-center justify-between p-4 border-b border-gray-200">
          <div>
            <h3 className="text-lg font-medium text-gray-900">
              All Values in{" "}
              <span className="text-primary font-semibold">{categoryName}</span>
            </h3>
            <p className="text-sm text-gray-500 mt-1 text-left">
              {values.length} total values
            </p>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 p-1 rounded-md hover:bg-gray-100"
          >
            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {/* Search bar */}
        <div className="px-4 pt-3 pb-2 border-b border-gray-100">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg
                className="h-5 w-5 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Search values..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="block w-full pl-10 pr-3 py-2 border border-gray-200 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary focus:border-primary"
            />
            {searchQuery && (
              <button
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setSearchQuery("")}
              >
                <svg
                  className="h-5 w-5 text-gray-400 hover:text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>

        {/* Content */}
        <div
          className="p-4 overflow-y-auto bg-gray-50"
          style={{ maxHeight: "calc(85vh - 180px)" }}
        >
          {filteredValues.length > 0 ? (
            <div className="grid grid-cols-3 gap-3">
              {filteredValues.map((value, index) => (
                <div
                  key={`full-list-${index}`}
                  className="inline-flex items-center gap-1 px-3 py-2 bg-white border border-gray-200 rounded-md shadow-sm group hover:border-gray-300 hover:shadow transition-all"
                >
                  <span className="text-sm text-gray-700 truncate flex-1">
                    {value}
                  </span>
                  {onRemoveValue && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRemoveValue(value);
                      }}
                      className="ml-1 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                      title="Remove from category"
                    >
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              ))}
            </div>
          ) : searchQuery ? (
            <div className="flex flex-col items-center justify-center py-8 text-gray-500">
              <svg
                className="w-12 h-12 mb-3 text-gray-300"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <p>No values matching "{searchQuery}"</p>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center py-8 text-gray-500">
              <svg
                className="w-12 h-12 mb-3 text-gray-300"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
              </svg>
              <p>No values available in this category</p>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="sticky bottom-0 z-10 bg-white border-t border-gray-200 p-4 rounded-b-lg flex justify-between items-center">
          <div className="text-sm text-gray-500">
            {filteredValues.length === values.length
              ? `Showing all ${values.length} values`
              : `Showing ${filteredValues.length} of ${values.length} values`}
          </div>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-primary text-white text-sm rounded-md font-medium hover:bg-primary-dark transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};
