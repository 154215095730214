import FileSelectionDropdown from "./components/FileSelectionDropdown";
import TaggingStudioOutput from "./components/TaggingStudioOutput";
import ActionBar from "./components/ActionBar";
import { useContext } from "react";
import TagSelector from "../SavedTags/components/TagSelector";
import { TSContext } from "./TSContext";
import TagEditor from "./components/TSEditor";

const TaggingStudio = () => {
  // TS Context
  const {
    tagInEditor,
    setTagInEditor,
    isTagSelectorExpanded,
    setIsTagSelectorExpanded,
  } = useContext(TSContext);

  return (
    <div className="h-full flex flex-col">
      <div className="flex-1 overflow-y-auto p-2 bg-white m-4 rounded-lg shadow-sm border border-gray-200">
        {isTagSelectorExpanded && (
          <>
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-40"
              onClick={() => setIsTagSelectorExpanded(false)}
            />
            <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50 w-[90%] h-[90%] max-w-7xl bg-white rounded-xl shadow-2xl overflow-hidden">
              <button
                onClick={() => setIsTagSelectorExpanded(false)}
                className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-md text-gray-500 hover:text-gray-700 transition-colors duration-200 z-50"
                title="Collapse"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <div className="h-full">
                <TagSelector
                  tagInEditor={tagInEditor}
                  setTagInEditor={setTagInEditor}
                  setIsTagSelectorExpanded={setIsTagSelectorExpanded}
                />
              </div>
            </div>
          </>
        )}

        <div className="px-4 py-4 h-full">
          <div className="grid grid-cols-3 gap-x-8 mb-2 text-left">
            <div className="flex flex-col gap-y-1 p-1">
              <h3 className="text-lg font-medium">
                1. Select and configure your tag
              </h3>
              <p className="text-sm text-gray-500">
                Select and configure the metadata tag to your liking.
              </p>
            </div>
            <div className="flex flex-col gap-y-1 p-1">
              <h3 className="text-lg font-medium">2. Select testing data</h3>
              <p className="text-sm text-gray-500">
                Select the files you want to test the metadata tag on.
              </p>
            </div>
            <div className="flex flex-col gap-y-1 p-1">
              <h3 className="text-lg font-medium">3. Fine tune your tag</h3>
              <p className="text-sm text-gray-500">
                Fine tune your tag to your liking.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-x-8">
            <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden h-full">
              <TagEditor />
            </div>

            <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden h-full">
              <FileSelectionDropdown />
            </div>

            <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden h-full">
              <TaggingStudioOutput />
            </div>
          </div>
        </div>
      </div>
      <ActionBar />
    </div>
  );
};

export default TaggingStudio;
