import React, { useState, useContext } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import { VectorDBConfiguration } from "./ConfigElements/VDBConfig";
import { LLMEndpointConfiguration } from "./ConfigElements/LLMConfig";
import { DestinationConfiguration } from "./ConfigElements/DestinationConfig";
import { updateTokenServiceSecret } from "./ConfigUtils";
import { DB_OPTIONS, DESTINATION_OPTIONS } from "./ConfigElements/utils";
import { connectorService } from "../../../services/api";
import {
  prepareEndpointManagerConfig,
  prepareVectorDBConfiguration,
} from "../../../services/utils";
import { ExternalLink } from "lucide-react";
import { WorkflowType } from "../../../models/WorkflowModel";
import { getPathFromWorkflowType } from "../../Navigation/NavUtils";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const DeasyConfig = () => {
  const {
    deasyUserConfig,
    setDeasyUserConfig,
    deasyApiKey,
    setVdbProfiles,
    setLlmProfiles,
    vdbProfiles,
  } = useContext(BaseContext);

  const activeProfile = deasyUserConfig?.vdbmConfig?.LastActive;

  const navigate = useNavigate();

  const handleGoToExtraction = () => {
    if (!activeProfile || Object.keys(vdbProfiles).length === 0) {
      toast.error("Please add and select an active data source first");
      return;
    }

    const path = getPathFromWorkflowType(
      WorkflowType.EXTRACT_METADATA,
      activeProfile,
    );
    navigate(path, {
      state: {
        workflowType: WorkflowType.EXTRACT_METADATA,
      },
    });
  };

  const [formState, setFormState] = useState({
    deasyApiKey: deasyApiKey || "",
    type:
      deasyUserConfig.vdbmConfig?.Configs[
        deasyUserConfig.vdbmConfig?.LastActive
      ]?.type || "",
    collection:
      deasyUserConfig.vdbmConfig?.Configs[
        deasyUserConfig.vdbmConfig?.LastActive
      ]?.collection || "",
    apiKey:
      deasyUserConfig.vdbmConfig?.Configs[
        deasyUserConfig.vdbmConfig?.LastActive
      ]?.apiKey || "",
    url:
      deasyUserConfig.vdbmConfig?.Configs[
        deasyUserConfig.vdbmConfig?.LastActive
      ]?.url || "",
    model:
      deasyUserConfig.llmConfig?.Configs[deasyUserConfig.llmConfig?.LastActive]
        ?.model || "",
    llmType:
      deasyUserConfig.llmConfig?.Configs[deasyUserConfig.llmConfig?.LastActive]
        ?.llmType || "",
    api_key:
      deasyUserConfig.llmConfig?.Configs[deasyUserConfig.llmConfig?.LastActive]
        ?.api_key ||
      deasyUserConfig.llmConfig?.Configs[deasyUserConfig.llmConfig?.LastActive]
        ?.llmApiKey ||
      "", // TODO: remove llmApiKey
    requestsPerMinute:
      deasyUserConfig.llmConfig?.Configs[
        deasyUserConfig.llmConfig?.LastActive
      ]?.requestsPerMinute?.toString() || "",
    tokensPerMinute:
      deasyUserConfig.llmConfig?.Configs[
        deasyUserConfig.llmConfig?.LastActive
      ]?.tokensPerMinute?.toString() || "",
    embeddingRequestsPerMinute:
      deasyUserConfig.llmConfig?.Configs[
        deasyUserConfig.llmConfig?.LastActive
      ]?.embeddingRequestsPerMinute?.toString() || "",
    embeddingTokensPerMinute:
      deasyUserConfig.llmConfig?.Configs[
        deasyUserConfig.llmConfig?.LastActive
      ]?.embeddingTokensPerMinute?.toString() || "",
  });
  const handleInputChange = (field, value) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  };

  const handleLoadConfig = (type, name) => {
    if (!name) {
      // Reset form state when no configuration is selected
      if (type === "vectorDB") {
        setFormState((prev) => ({
          ...prev,
          type: "",
          collection: "",
          apiKey: "",
          url: "",
        }));
      } else if (type === "llm") {
        setFormState((prev) => ({
          ...prev,
          llmType: "",
          api_key: "",
          requestsPerMinute: "",
          tokensPerMinute: "",
          embeddingRequestsPerMinute: "",
          embeddingTokensPerMinute: "",
        }));
      } else if (type === "destination") {
        setFormState((prev) => ({
          ...prev,
          type: "",
          collection: "",
          client_id: "",
          client_secret: "",
          tenant_id: "",
          sharepoint_site_name: "",
          documents_library_folder_name: "",
        }));
      }
      return;
    }

    // Load configuration based on type
    if (type === "vectorDB") {
      const config = deasyUserConfig?.vdbmConfig?.Configs?.[name];
      if (config) {
        setFormState((prev) => {
          // Get all possible fields from the selected DB type
          const dbFields =
            DB_OPTIONS.find((opt) => opt.value === config.type)?.fields || [];

          // Create an object with all fields initialized to empty strings
          const fieldValues = dbFields.reduce(
            (acc, field) => ({
              ...acc,
              [field]: config[field] || "",
            }),
            {},
          );

          return {
            ...prev,
            type: config.type || "",
            ...fieldValues,
          };
        });
      }
    } else if (type === "llm") {
      const config = deasyUserConfig?.llmConfig?.Configs?.[name];
      if (config) {
        setFormState((prev) => ({
          ...prev,
          llmType: config.llmType || "",
          api_key: config.api_key || config?.llmApiKey || "", // TODO: remove llmApiKey
          requestsPerMinute: config.requestsPerMinute?.toString() || "",
          tokensPerMinute: config.tokensPerMinute?.toString() || "",
          embeddingRequestsPerMinute:
            config.embeddingRequestsPerMinute?.toString() || "",
          embeddingTokensPerMinute:
            config.embeddingTokensPerMinute?.toString() || "",
        }));
      }
    } else if (type === "deasyApiKey") {
      const newConfig = {
        ...deasyUserConfig,
        deasyApiKey: name, // Save the API key directly
      };
      setDeasyUserConfig(newConfig);
      updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));
    } else if (type === "destination") {
      const config = deasyUserConfig?.destinationConfig?.Configs?.[name];
      if (config) {
        setFormState((prev) => {
          // Get all possible fields from the selected DB type
          const destFields =
            DESTINATION_OPTIONS.find((opt) => opt.value === config.type)
              ?.fields || [];

          // Create an object with all fields initialized to empty strings
          const fieldValues = destFields.reduce(
            (acc, field) => ({
              ...acc,
              [field]: config[field] || "",
            }),
            {},
          );

          return {
            ...prev,
            type: config.type || "",
            ...fieldValues,
          };
        });
      }
    }
  };

  const handleSaveNewConfig = async (type, name, config) => {
    if (type === "vectorDB") {
      const newConfigs = { ...deasyUserConfig?.vdbmConfig?.Configs };
      if (config === null) {
        delete newConfigs[name];
        // Find first available config or set to empty
        const nextConfig = Object.keys(newConfigs)[0] || "";

        const newConfig = {
          ...deasyUserConfig,
          vdbmConfig: {
            LastActive: nextConfig,
            Configs: newConfigs,
          },
        };

        setDeasyUserConfig(newConfig);
        setVdbProfiles(newConfigs);

        handleLoadConfig("vectorDB", nextConfig);
      } else {
        // check if the profile name already exists
        const isUpdate = Object.keys(
          deasyUserConfig.vdbmConfig.Configs,
        ).includes(name);

        newConfigs[name] = config;
        const newConfig = {
          ...deasyUserConfig,
          vdbmConfig: {
            LastActive: name,
            Configs: newConfigs,
          },
        };

        if (isUpdate) {
          await connectorService.updateVDBConnector(
            name,
            prepareVectorDBConfiguration(config),
            deasyApiKey,
          );
        } else {
          await connectorService.createVDBConnector(
            name,
            prepareVectorDBConfiguration(config),
            deasyApiKey,
          );
        }

        setVdbProfiles((prev) => ({ ...prev, [name]: config }));
        setDeasyUserConfig(newConfig);
      }
    } else if (type === "llm") {
      const newConfigs = { ...deasyUserConfig?.llmConfig?.Configs };
      if (config === null) {
        delete newConfigs[name];
        // Find first available config or set to empty
        const nextConfig = Object.keys(newConfigs)[0] || "";

        const newConfig = {
          ...deasyUserConfig,
          llmConfig: {
            LastActive: nextConfig,
            Configs: newConfigs,
          },
        };

        await connectorService.deleteLLMConnector(name, deasyApiKey);

        setDeasyUserConfig(newConfig);
        setLlmProfiles(newConfigs);

        handleLoadConfig("llm", nextConfig);
      } else {
        const isUpdate = Object.keys(
          deasyUserConfig.llmConfig.Configs,
        ).includes(name);

        newConfigs[name] = config;
        const newConfig = {
          ...deasyUserConfig,
          llmConfig: {
            LastActive: name,
            Configs: newConfigs,
          },
        };

        if (isUpdate) {
          await connectorService.updateLLMConnector(
            name,
            prepareEndpointManagerConfig(config),
            deasyApiKey,
          );
        } else {
          await connectorService.createLLMConnector(
            name,
            prepareEndpointManagerConfig(config),
            deasyApiKey,
          );
        }

        setLlmProfiles((prev) => ({ ...prev, [name]: config }));
        setDeasyUserConfig(newConfig);
      }
    } else if (type === "deasyApiKey") {
      const newConfig = {
        ...deasyUserConfig,
        deasyApiKey: name, // Save the API key directly
      };
      setDeasyUserConfig(newConfig);
      updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));
    } else if (type === "destination") {
      const newConfigs = { ...deasyUserConfig?.destinationConfig?.Configs };
      if (config === null) {
        delete newConfigs[name];
        // Find first available config or set to empty
        const nextConfig = Object.keys(newConfigs)[0] || "";

        const newConfig = {
          ...deasyUserConfig,
          destinationConfig: {
            LastActive: nextConfig,
            Configs: newConfigs,
          },
        };

        setDeasyUserConfig(newConfig);
        updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));

        // Immediately load the next config or reset form
        handleLoadConfig("destination", nextConfig);
      } else {
        newConfigs[name] = config;
        const newConfig = {
          ...deasyUserConfig,
          destinationConfig: {
            LastActive: name,
            Configs: newConfigs,
          },
        };
        setDeasyUserConfig(newConfig);
        updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));
      }
    }
  };

  return (
    <div className="flex flex-col relative">
      <div className="mx-auto p-6 w-full h-[100vh] grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="h-full overflow-hidden min-w-[350px]">
          <VectorDBConfiguration
            formState={formState}
            setFormState={setFormState}
            handleInputChange={handleInputChange}
            handleLoadConfig={handleLoadConfig}
            handleSaveNewConfig={handleSaveNewConfig}
          />
        </div>
        <div className="h-full overflow-hidden min-w-[350px]">
          <LLMEndpointConfiguration
            formState={formState}
            handleInputChange={handleInputChange}
            handleLoadConfig={handleLoadConfig}
            handleSaveNewConfig={handleSaveNewConfig}
          />
        </div>
        <div className="h-full overflow-hidden min-w-[350px]">
          <DestinationConfiguration
            formState={formState}
            setFormState={setFormState}
            handleInputChange={handleInputChange}
            handleLoadConfig={handleLoadConfig}
            handleSaveNewConfig={handleSaveNewConfig}
            deasyUserConfig={deasyUserConfig}
            setDeasyUserConfig={setDeasyUserConfig}
          />
        </div>
      </div>

      {/* Floating action button */}
      {activeProfile && Object.keys(vdbProfiles).length > 0 && (
        <div className="fixed bottom-8 right-8 z-10">
          <button
            onClick={handleGoToExtraction}
            className="flex items-center justify-center gap-2 px-6 py-3 bg-primary text-white rounded-full hover:bg-primary-dark transition-colors shadow-lg"
          >
            <span>Next: Extract Metadata</span>
            <ExternalLink size={16} />
          </button>
        </div>
      )}
    </div>
  );
};

export default DeasyConfig;
