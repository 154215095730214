/* eslint-disable react-hooks/exhaustive-deps */
import { useNodesState, useEdgesState } from "reactflow";
import "reactflow/dist/style.css";
import { GraphContext } from "../../../../../../contexts/GraphContext";
import { useContext, useEffect, useState } from "react";
import { AddNodeSidePanel } from "../Graph/Panels/AddNodeSidePanel";
import {
  metadataService,
  dataSliceService,
  sourceMetadataService,
} from "../../../../../../services/api";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { auth } from "../../../../../../config/firebase";
import { prepareVectorDBConfiguration } from "../../../../../../services/utils";
import { WorkflowType } from "../../../../../../models/WorkflowModel";
import { processData } from "../GraphUtils";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import CreateDataSlice from "../../../../../utils/CreateDataSlice";
import { CircularProgress } from "@mui/material";
import { TagPanel } from "./components/TagPanel";
import { ExtractBanner } from "./components/ExtractBanner";
import {
  getLayoutedElements,
  getNewConditionsFromNodes,
} from "./components/Utils";
import { ExtractMetaGraphPage } from "./components/ExtractMetaGraphPage";
import { DB_OPTIONS } from "../../../../../../components/MainContent/DeasyConfig/ConfigElements/utils";
import React from "react";

const ExtractMetadataTab = () => {
  const {
    deasyUserConfig,
    setCurrentScreen,
    setSelectedDataSlice,
    vdbFilesCount,
    isConfigRefreshing,
    setLoadingVdbFilesCount,
    setVdbFilesCount,
    loadingVdbFilesCount,
  } = useContext(BaseContext);

  const {
    setGraphs,
    hierarchyStats,
    setHierarchyStats,
    discoveryGraphData,
    updateDiscoveryGraphData,
    selectedDiscoveryGraph,
    selectedNodes,
    sidePanelOpen,
    shouldFitView,
    setShouldFitView,
    handleSaveGraph,
    loadingUniqueTags,
    fetchUniqueTags,
  } = useContext(GraphContext);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [generatingSchema, setGeneratingSchema] = useState(false);
  const [selectedNodesFileCount, setSelectedNodesFileCount] = useState(0);
  const [isLoadingFileCount, setIsLoadingFileCount] = useState(false);
  const [showCreateDataSlice, setShowCreateDataSlice] = useState(false);

  const [selectedClassifyFiles, setSelectedClassifyFiles] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [showTagPanel, setShowTagPanel] = useState(true);

  const [excludedTags, setExcludedTags] = useState(new Set());

  const [showValueNodes, setShowValueNodes] = useState(true);
  const [showTagNodes, setShowTagNodes] = useState(true);

  const {
    vdbmConfig: { Configs: vdbmConfigs, LastActive: vdbmLastActive },
    deasyApiKey,
  } = deasyUserConfig;
  const llmConfiguration =
    deasyUserConfig.llmConfig.Configs[deasyUserConfig.llmConfig.LastActive];
  const vectorDBConfiguration = vdbmConfigs[vdbmLastActive];
  const activeProfile = vdbmLastActive;
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [disableInitialRefresh, setDisableInitialRefresh] = useState(false);

  // Reset shouldFitView after initial render
  useEffect(() => {
    if (shouldFitView) {
      // Reset shouldFitView immediately
      setShouldFitView(false);
    }
  }, [shouldFitView, setShouldFitView]);

  // Fetch the total VDB files count
  useEffect(() => {
    const fetchTotalVdbFiles = async () => {
      // Skip if already loading or we already have a count for this profile
      if (
        loadingVdbFilesCount ||
        (vdbFilesCount?.profile && vdbFilesCount.profile === activeProfile)
      ) {
        return;
      }

      // Validate configuration first
      const dbOption = DB_OPTIONS.find(
        (opt) => opt.value === vectorDBConfiguration?.type,
      );
      const hasRequiredFields = dbOption?.fields
        .filter((field) => !field.toLowerCase().includes("key"))
        .every((field) => vectorDBConfiguration?.[field]);

      if (!dbOption || !hasRequiredFields) {
        console.log(
          "Skipping VDB files count fetch - incomplete vector DB config",
        );
        return;
      }

      try {
        setLoadingVdbFilesCount(true);
        const response = await sourceMetadataService.getVdbTotalFiles(
          prepareVectorDBConfiguration({
            ...vectorDBConfiguration,
            user: auth.currentUser.email,
          }),
          deasyApiKey,
        );
        setVdbFilesCount({
          total_files: response.data.total_files,
          profile: activeProfile,
        });
      } catch (error) {
        console.error("Error fetching total VDB files:", error);
        // Don't set to null on error, keep previous state
      } finally {
        setLoadingVdbFilesCount(false);
      }
    };

    if (deasyApiKey && vectorDBConfiguration) {
      console.log(
        "Fetching total VDB files, confirmed having both api and vector db config",
      );
      fetchTotalVdbFiles();
    }
  }, [deasyApiKey, vectorDBConfiguration, activeProfile, vdbFilesCount]);

  useEffect(() => {
    const fetchInitialStats = async () => {
      if (disableInitialRefresh) return;

      try {
        const dbOption = DB_OPTIONS.find(
          (opt) => opt.value === vectorDBConfiguration?.type,
        );
        const hasRequiredFields = dbOption?.fields
          .filter((field) => !field.toLowerCase().includes("key"))
          .every((field) => vectorDBConfiguration?.[field]);

        if (
          !dbOption ||
          !hasRequiredFields ||
          !discoveryGraphData ||
          Object.keys(discoveryGraphData).length === 0
        ) {
          console.log(
            "Skipping hierarchy stats fetch - no graph or incomplete VDB config",
          );
          return;
        }

        const activeLlmConfig = llmConfiguration || {};
        // Build conditions for all nodes in the tree
        const buildTreeConditions = (data) => {
          const conditions = [];
          const processNode = (node, tagId) => {
            if (!node || typeof node !== "object") return;
            // If node has TagAvailableValues, it's a tag node
            if (node.TagAvailableValues) {
              conditions.push({
                tag_id: tagId,
                values: node.TagAvailableValues,
              });
            }
            // Process child nodes
            Object.entries(node).forEach(([key, value]) => {
              if (key !== "TagAvailableValues" && typeof value === "object") {
                processNode(value, key);
              }
            });
          };
          Object.entries(data).forEach(([key, value]) => {
            processNode(value, key);
          });
          return conditions;
        };

        const conditions = buildTreeConditions(discoveryGraphData);

        const data = {
          vector_db_config: prepareVectorDBConfiguration({
            ...vectorDBConfiguration,
            user: auth.currentUser.email,
          }),
          endpoint_manager_config: activeLlmConfig,
          conditions: conditions,
          current_tree: JSON.stringify(discoveryGraphData) || [],
          include_extraction_stats: true,
        };

        const response = await metadataService.getHierarchyCountDistributions(
          data,
          deasyApiKey,
        );

        if (response.data?.hierarchy) {
          setHierarchyStats(response.data.hierarchy);
        }
      } catch (err) {
        console.error("Error fetching initial stats:", err);
      }
    };

    if (deasyApiKey && vectorDBConfiguration) {
      fetchInitialStats();
    }
  }, [
    deasyApiKey,
    vectorDBConfiguration,
    llmConfiguration,
    discoveryGraphData,
    setHierarchyStats,
    disableInitialRefresh, // Flag to prevent race conditions of this refreshing
  ]);

  // Update nodes when hierarchyStats changes
  useEffect(() => {
    const getFilteredNodes = (nodes) => {
      return nodes.map((node) => {
        // Always show root nodes
        if (node.type === "root") return node;

        // Hide value nodes if showValueNodes is false
        if (node.type === "value" && !showValueNodes) {
          return { ...node, hidden: true };
        }

        // Hide tag nodes if showTagNodes is false
        if (node.type === "question" && !showTagNodes) {
          return { ...node, hidden: true };
        }

        return { ...node, hidden: false };
      });
    };

    const { nodes: updatedNodes, edges: updatedEdges } =
      processData(discoveryGraphData);

    // 1. Layout the nodes/edges with dagre
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      updatedNodes,
      updatedEdges,
      "TB",
    );

    // 2. Apply visibility filters
    const filteredNodes = getFilteredNodes(layoutedNodes);

    setNodes(filteredNodes);
    setEdges(layoutedEdges);
  }, [
    hierarchyStats,
    discoveryGraphData,
    setNodes,
    setEdges,
    showValueNodes,
    showTagNodes,
  ]);

  // For highlighting matching nodes based on label
  useEffect(() => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        // Only consider it a match if there's a search term and the label includes it
        const labelMatches =
          searchTerm.trim() !== "" &&
          node?.data?.label?.toLowerCase()?.includes(searchTerm.toLowerCase());

        return {
          ...node,
          className: labelMatches
            ? " shadow-2xl scale-125 z-50 border-2 border-primary rounded-lg animate-pulse"
            : "",
        };
      }),
    );
  }, [searchTerm, setNodes]);

  const handleAddNodeApplied = async (selectedNodeData, tag) => {
    if (!selectedNodeData || !tag) return;

    setDisableInitialRefresh(true);

    // Create a deep copy of the data structure
    const updatedData = JSON.parse(JSON.stringify(discoveryGraphData));

    const addNodeToStructure = (data, nodePath, newTag) => {
      let current = data;

      // Use the tag's available_values directly
      const tagAvailableValues = newTag.available_values || [];

      // Create the tag object with TagAvailableValues
      const tagObject = {
        TagAvailableValues: tagAvailableValues,
      };

      if (selectedNodeData.label === "Root") {
        // Add directly to root
        current[newTag.name] = { ...tagObject };

        // Add value nodes
        if (!tagAvailableValues.length) {
          current[newTag.name]["<ANY>"] = {};
        } else {
          tagAvailableValues.forEach((value) => {
            current[newTag.name][value] = {};
          });
        }
      } else if (selectedNodeData.nodeType === "value") {
        // Navigate to the correct position
        for (let i = 0; i < nodePath.length; i++) {
          const pathItem = nodePath[i];
          if (!(pathItem in current)) {
            current[pathItem] = {};
          }
          current = current[pathItem];
        }

        // Add the new tag
        current[newTag.name] = { ...tagObject };

        // Add value nodes
        if (!tagAvailableValues.length) {
          current[newTag.name]["<ANY>"] = {};
        } else {
          tagAvailableValues.forEach((value) => {
            current[newTag.name][value] = {};
          });
        }
      } else {
        // For question nodes
        for (let i = 0; i < nodePath.length - 2; i++) {
          const pathItem = nodePath[i];
          if (!(pathItem in current)) {
            current[pathItem] = {};
          }
          current = current[pathItem];
        }

        const targetNodeName = nodePath[nodePath.length - 2];
        current[targetNodeName][newTag.name] = { ...tagObject };

        // Add value nodes
        if (!tagAvailableValues.length) {
          current[targetNodeName][newTag.name]["<ANY>"] = {};
        } else {
          tagAvailableValues.forEach((value) => {
            current[targetNodeName][newTag.name][value] = {};
          });
        }
      }
    };

    // Add the new node to the data structure
    if (Array.isArray(tag)) {
      tag.forEach((t) =>
        addNodeToStructure(updatedData, selectedNodeData.nodePath || [], t),
      );
    } else {
      addNodeToStructure(updatedData, selectedNodeData.nodePath || [], tag);
    }

    // Update the graph data
    updateDiscoveryGraphData(updatedData);

    // Immediately save the updated graph to prevent tags from disappearing
    await handleSaveGraph({
      ...selectedDiscoveryGraph,
      graph_data: updatedData,
    });

    // Update the graphs state
    setGraphs((prev) =>
      prev.map((graph) =>
        graph.graph_id === selectedDiscoveryGraph.graph_id
          ? { ...graph, graph_data: updatedData }
          : graph,
      ),
    );

    // Process and update nodes/edges
    const { nodes: updatedNodes, edges: updatedEdges } = processData(
      updatedData,
      hierarchyStats?.file_count || 0,
    );

    setNodes(updatedNodes);
    setEdges(updatedEdges);

    setDisableInitialRefresh(false);

    // Fit view after adding nodes
    setShouldFitView(true);
  };

  // Update the useEffect for node highlighting
  useEffect(() => {
    // Get all tags that would be extracted from selected nodes
    const extractableTags = new Set();
    const excludedNodeTags = new Set();
    const selectedNodePaths = new Set(selectedNodes.map((node) => node.id));

    // Helper to collect tags from a node
    const collectTags = (data) => {
      const traverse = (obj) => {
        if (!obj || typeof obj !== "object") return;
        Object.entries(obj).forEach(([key, value]) => {
          if (key !== "TagAvailableValues" && value?.TagAvailableValues) {
            if (excludedTags.has(key)) {
              excludedNodeTags.add(key);
            } else {
              extractableTags.add(key);
            }
          }
          if (typeof value === "object" && value !== null) {
            traverse(value);
          }
        });
      };
      traverse(data);
    };

    if (!selectedNodes.length) {
      // When no nodes selected, check all nodes for tags
      collectTags(discoveryGraphData);
    } else {
      // For selected nodes, only check their subtrees
      selectedNodes.forEach((node) => {
        const path = node.data.nodePath;
        let currentData = discoveryGraphData;

        // Navigate to the selected node
        for (let i = 0; i < path.length; i++) {
          if (currentData[path[i]]) {
            currentData = currentData[path[i]];
          }
        }

        collectTags(currentData);
      });
    }

    // Update node styling to show both extractable and excluded tags
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        const hasExtractableTag = node.data?.nodePath?.some((path) =>
          extractableTags.has(path),
        );
        const hasExcludedTag = node.data?.nodePath?.some((path) =>
          excludedNodeTags.has(path),
        );
        const isSelectedNode = selectedNodePaths.has(node.id);
        const isRootNode = node.type === "root";

        let nodeClass = (node.className || "")
          .replace(/bg-primary\/\d+|opacity-\d+|bg-gray-\d+/g, "")
          .trim();
        if (hasExcludedTag) {
          nodeClass = `${nodeClass}  opacity-40`;
        }
        // Only apply opacity if it's not the root node, not selected, and not extractable/excluded
        if (
          !isRootNode &&
          !hasExtractableTag &&
          !hasExcludedTag &&
          !isSelectedNode
        ) {
          nodeClass = `${nodeClass} opacity-40`;
        }

        return {
          ...node,
          className: nodeClass.trim(),
        };
      }),
    );
  }, [selectedNodes, discoveryGraphData, excludedTags, setNodes]);

  // Fetch file count when selected nodes change
  useEffect(() => {
    const fetchFileCount = async () => {
      if (!selectedNodes.length) {
        setSelectedNodesFileCount(0);
        return;
      }

      setIsLoadingFileCount(true);
      try {
        const newConditions = getNewConditionsFromNodes(selectedNodes);
        const response = await dataSliceService.getDatasliceFileCount(
          prepareVectorDBConfiguration({
            ...vectorDBConfiguration,
            user: auth.currentUser.email,
          }),
          newConditions,
          null,
          deasyApiKey,
        );
        if (response.data?.file_count !== undefined) {
          setSelectedNodesFileCount(response.data.file_count);
        }
      } catch (error) {
        console.error("Error fetching file count:", error);
        setSelectedNodesFileCount(0);
      } finally {
        setIsLoadingFileCount(false);
      }
    };

    fetchFileCount();
  }, [selectedNodes, deasyApiKey, vectorDBConfiguration]);

  const hasInitiallyFetchedTags = React.useRef(false);

  useEffect(() => {
    if (
      !hasInitiallyFetchedTags.current &&
      deasyApiKey &&
      vectorDBConfiguration &&
      !loadingUniqueTags
    ) {
      hasInitiallyFetchedTags.current = true;
      fetchUniqueTags();
    }
  }, [deasyApiKey, vectorDBConfiguration, fetchUniqueTags, loadingUniqueTags]);

  return (
    <div className="flex flex-col h-full">
      {/* Loading overlay */}
      {isConfigRefreshing && (
        <div className="absolute inset-0 bg-white/80 z-50 flex items-center justify-center">
          <div className="flex flex-col items-center gap-3">
            <CircularProgress size={24} className="text-primary" />
            <span className="text-sm text-gray-600">
              Updating configuration...
            </span>
          </div>
        </div>
      )}

      <div className="flex flex-col h-full">
        <ExtractBanner
          // State values
          isRefreshing={isRefreshing}
          searchTerm={searchTerm}
          showTagNodes={showTagNodes}
          showValueNodes={showValueNodes}
          // Core functions
          setIsRefreshing={setIsRefreshing}
          setSearchTerm={setSearchTerm}
          setShowTagNodes={setShowTagNodes}
          setShowValueNodes={setShowValueNodes}
        />
        <ExtractMetaGraphPage
          // Graph data
          nodes={nodes}
          edges={edges}
          // Graph state setters
          setNodes={setNodes}
          setEdges={setEdges}
          // Graph event handlers
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          handleAddNodeApplied={handleAddNodeApplied}
          // Selection state
          setSelectedClassifyFiles={setSelectedClassifyFiles}
          shouldFitView={shouldFitView}
        />

        {sidePanelOpen && (
          <AddNodeSidePanel
            handleAddNodeApplied={handleAddNodeApplied}
            generatingSchema={generatingSchema}
            setGeneratingSchema={setGeneratingSchema}
          />
        )}

        <div
          className={`
            fixed bottom-3 right-3 z-10 
            flex flex-col gap-2 p-4 
            rounded-lg border border-gray-200/20
            bg-gray-900/90 backdrop-blur-sm
            transition-all duration-300 
            ${showTagPanel ? "w-80" : "w-40"}
          `}
        >
          {/* Panel Header */}
          <div className="flex items-center justify-between text-white/90 mb-1">
            <h3 className="text-sm font-medium"> Action Panel</h3>
            <button
              onClick={() => setShowTagPanel(!showTagPanel)}
              className="p-1 hover:bg-white/10 rounded-md transition-colors"
            >
              {showTagPanel ? <FiChevronRight /> : <FiChevronLeft />}
            </button>
          </div>

          {showTagPanel && (
            <TagPanel
              // Selection state
              selectedClassifyFiles={selectedClassifyFiles}
              selectedNodesFileCount={selectedNodesFileCount}
              // Tag state
              excludedTags={excludedTags}
              // Loading states
              isLoadingFileCount={isLoadingFileCount}
              // Actions
              setSelectedClassifyFiles={setSelectedClassifyFiles}
              setShowCreateDataSlice={setShowCreateDataSlice}
              setExcludedTags={setExcludedTags}
              // Helper functions
              getNewConditionsFromNodes={getNewConditionsFromNodes}
            />
          )}
        </div>

        {showCreateDataSlice && (
          <CreateDataSlice
            isOpen={showCreateDataSlice}
            onClose={() => setShowCreateDataSlice(false)}
            fileCount={
              selectedClassifyFiles === null
                ? vdbFilesCount?.total_files || 0
                : selectedNodesFileCount
            }
            discoveryGraphData={discoveryGraphData}
            selectedDiscoveryGraph={selectedDiscoveryGraph}
            onSuccess={(dataSlice) => {
              setSelectedDataSlice(dataSlice);
              setCurrentScreen(WorkflowType.DATA_SLICE_MANAGEMENT);
            }}
            isGraphMode={true}
            isAllFiles={selectedClassifyFiles === null}
            conditions={
              selectedClassifyFiles === null
                ? []
                : getNewConditionsFromNodes(selectedNodes)
            }
          />
        )}
      </div>
    </div>
  );
};

export { ExtractMetadataTab };
