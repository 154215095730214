import dagre from "dagre";
import { useReactFlow } from "reactflow";
import { useEffect } from "react";

const getLayoutedElements = (nodes, edges, direction = "TB") => {
  // Skip layout if no nodes
  if (nodes.length === 0) {
    return { nodes, edges };
  }

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({
    rankdir: direction,
    ranksep: 100,
    nodeSep: 50,
    align: null,
    ranker: "network-simplex",
    marginx: 50,
    marginy: 50,
  });

  // Assign each node in dagre
  nodes.forEach((node) => {
    const width = node.width || 180;
    const height = node.height || 60;
    dagreGraph.setNode(node.id, { width, height });
  });

  // Assign edges
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  // Run the layout algorithm
  dagre.layout(dagreGraph);

  // Update node positions based on dagre's calculations
  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    if (!nodeWithPosition) {
      return node;
    }

    const width = node.width || 180;
    const height = node.height || 60;

    return {
      ...node,
      position: {
        x: nodeWithPosition.x - width / 2,
        y: nodeWithPosition.y - height / 2,
      },
      positionAbsolute: {
        x: nodeWithPosition.x - width / 2,
        y: nodeWithPosition.y - height / 2,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
};

// Helpers for ExtractMetaGraphPanel
const excludeKey = (obj, key) => {
  const { [key]: _, ...rest } = obj; // Destructure to exclude the key
  return rest;
};

const checkNodeOverlap = (selectedNode, allNodes) => {
  // Skip if no selected node
  if (!selectedNode || selectedNode.type !== "question") {
    return null;
  }

  // Helper function to get node position (handles both position and positionAbsolute)
  const getNodePosition = (node) => {
    return node.positionAbsolute || node.position;
  };

  // Helper function to check if two rectangles overlap
  const doNodesOverlap = (node1, node2) => {
    const pos1 = getNodePosition(node1);
    const pos2 = getNodePosition(node2);

    // Calculate boundaries for both nodes
    const node1Bounds = {
      left: pos1.x,
      right: pos1.x + (node1.width || 0),
      top: pos1.y,
      bottom: pos1.y + (node1.height || 0),
    };

    const node2Bounds = {
      left: pos2.x,
      right: pos2.x + (node2.width || 0),
      top: pos2.y,
      bottom: pos2.y + (node2.height || 0),
    };

    // Check for overlap
    return !(
      node1Bounds.right < node2Bounds.left ||
      node1Bounds.left > node2Bounds.right ||
      node1Bounds.bottom < node2Bounds.top ||
      node1Bounds.top > node2Bounds.bottom
    );
  };

  // Find first overlapping node
  for (const node of allNodes) {
    // Skip checking against itself
    if (node.id === selectedNode.id) {
      continue;
    }

    if (doNodesOverlap(selectedNode, node)) {
      if (node.data.nodePath.includes(selectedNode.data.label)) return null;
      if (node.type === "value") return node.id;
      return null;
    }
  }

  return null;
};

const removeNodeFromStructure = (data, nodePath) => {
  let current = data;

  // Handle empty path or root node
  if (!nodePath || nodePath.length === 0) {
    return;
  }

  // Navigate to parent node
  for (let i = 0; i < nodePath.length - 1; i++) {
    const pathItem = nodePath[i];
    if (!(pathItem in current)) {
      return; // Node path is invalid
    }
    current = current[pathItem];
  }

  const targetNodeName = nodePath[nodePath.length - 1];

  // Delete the node
  if (current) {
    delete current[targetNodeName];

    // Only filter TagAvailableValues if it exists
    if (
      current.TagAvailableValues &&
      Array.isArray(current.TagAvailableValues)
    ) {
      current.TagAvailableValues = current.TagAvailableValues.filter(
        (value) => value !== targetNodeName,
      );
    }

    // Clean up empty objects
    if (
      Object.keys(current).length === 0 ||
      (Object.keys(current).length === 1 &&
        current.TagAvailableValues?.length === 0)
    ) {
      delete current.TagAvailableValues;
    }
  }
};

// This component will fit the view whenever nodes change
const FitViewOnNodesChange = ({
  nodes,
  edges,
  shouldFitView = false,
  setShouldFitView,
}) => {
  const reactFlowInstance = useReactFlow();

  useEffect(() => {
    if (!shouldFitView || !reactFlowInstance) return;

    reactFlowInstance.fitView({
      padding: 0.1,
      duration: 800,
      minZoom: 0.05,
      maxZoom: 3.5,
      includeHiddenNodes: false,
      center: true,
    });

    const timeout = setTimeout(() => {
      setShouldFitView(false);
    }, 800);

    return () => clearTimeout(timeout);
  }, [shouldFitView, reactFlowInstance, setShouldFitView]);

  return null;
};

const getNewConditionsFromNodes = (nodes) => {
  if (nodes.length === 0) {
    return null;
  }
  let rootCondition = {
    condition: "OR",
    children: [],
  };
  const conditions = nodes.map((node) => {
    const nodePath = node.data.nodePath;
    let parentCondition = {
      condition: "AND",
      children: [],
    };
    for (let i = 0; i < nodePath.length; i += 2) {
      const tag = nodePath[i];
      const value = nodePath[i + 1];
      if (tag && value) {
        parentCondition.children.push({
          tag: {
            name: tag,
            values: [value],
          },
        });
      }
    }
    if (parentCondition.children.length === 0) {
      return null;
    }
    return parentCondition;
  });
  if (conditions.length === 1 && conditions[0] === null) {
    return null;
  }
  rootCondition.children.push(...conditions);
  return rootCondition;
};

export {
  getLayoutedElements,
  excludeKey,
  checkNodeOverlap,
  removeNodeFromStructure,
  FitViewOnNodesChange,
  getNewConditionsFromNodes,
};
