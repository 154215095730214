import React, { useState, useRef } from "react";
import { SimpleStandardizationModal } from "../StandardizationUtils";

export const ExistingValuesSection = ({
  uniqueTagCount,
  filteredValues,
  searchQuery,
  setSearchQuery,
  handleDragStart,
  handleRemoveValueFromCategory,
  performSimpleStandardization,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const recentlyDroppedRef = useRef({});

  const [showSimpleStandardizationModal, setShowSimpleStandardizationModal] =
    useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedValue = e.dataTransfer.getData("text/plain");
    const sourceCategory = e.dataTransfer.getData("sourceCategory");

    if (sourceCategory) {
      const now = Date.now();
      const dropId = `${sourceCategory}:${droppedValue}`;

      if (
        recentlyDroppedRef.current[dropId] &&
        now - recentlyDroppedRef.current[dropId] < 500
      ) {
        console.log("Preventing duplicate drop handling");
        setIsDragOver(false);
        return;
      }

      recentlyDroppedRef.current[dropId] = now;

      setTimeout(() => {
        if (recentlyDroppedRef.current[dropId] === now) {
          delete recentlyDroppedRef.current[dropId];
        }
      }, 1000);

      if (!filteredValues.includes(droppedValue)) {
        handleRemoveValueFromCategory(sourceCategory, droppedValue);
      } else {
        console.log(
          "Value already exists in filtered values, just removing from category",
        );

        handleRemoveValueFromCategory(sourceCategory, droppedValue);
      }
    }
    setIsDragOver(false);
  };

  const handleCreateOneToOneMapping = () => {
    if (filteredValues.length > 30) {
      setShowSimpleStandardizationModal(true);
    } else {
      // For single value, proceed directly without confirmation
      performSimpleStandardization();
    }
  };

  return (
    <div
      className={`border rounded-lg flex-1 transition-all ${
        isDragOver
          ? "border-primary/30 border-2 border-dashed"
          : "border-gray-200"
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <SimpleStandardizationModal
        isOpen={showSimpleStandardizationModal}
        onClose={() => setShowSimpleStandardizationModal(false)}
        onConfirm={() => {
          performSimpleStandardization();
          setShowSimpleStandardizationModal(false);
        }}
        valueCount={filteredValues.length}
      />
      <div className="flex items-center justify-between p-3 border-b bg-gray-50">
        <h3 className="text-base font-medium ">
          Existing Values{" "}
          {uniqueTagCount > 0 && (
            <span className="text-sm ml-1 text-gray-500">
              (<span className="text-primary">{uniqueTagCount}</span> total
              values)
            </span>
          )}
        </h3>
        <div className="flex items-center gap-2">
          <button
            onClick={handleCreateOneToOneMapping}
            className={`px-3 py-1.5 text-sm font-medium ${
              filteredValues.length === 0
                ? "text-gray-400 border-gray-300 cursor-not-allowed"
                : "text-primary border-primary hover:bg-primary/5"
            } bg-white border rounded-md transition-colors flex items-center gap-1`}
            title="Create a separate category for each value"
            disabled={filteredValues.length === 0}
          >
            <svg
              className="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
              />
            </svg>
            1:1 Mapping
          </button>
          <input
            type="text"
            placeholder="Search values..."
            className="px-3 py-1.5 text-md min-w-[14vw] border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div
        className={`p-4 overflow-y-auto transition-colors ${isDragOver ? "bg-primary/10" : ""}`}
        style={{ maxHeight: "63vh" }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="grid grid-cols-1 gap-2">
          {filteredValues.length > 0 ? (
            filteredValues.map((value, index) => (
              <div
                key={`existing-${index}`}
                className="p-2 border border-gray-200 rounded-md bg-gray-50 hover:bg-gray-100 transition-colors cursor-move flex items-center justify-between group"
                draggable
                onDragStart={(e) => handleDragStart(e, value)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="text-sm text-gray-700 truncate">{value}</span>
                <div className="flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    className="p-1 text-gray-500 hover:text-primary hover:bg-gray-200 rounded"
                  >
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="w-full flex items-center justify-center gap-2 py-8 text-gray-500 h-[500px]">
              <svg
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 16s-1.5-2-4-2-4 2-4 2M9 9h.01M15 9h.01"
                />
                <circle cx="12" cy="12" r="10" strokeWidth="2" />
              </svg>
              <span className="text-sm">
                No existing values available for standardization
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
