import { useState, useEffect, useContext } from "react";
import { toast } from "react-hot-toast";
import { BaseContext } from "../../../../contexts/BaseContext";
import GeneralSection from "../../SavedTags/components/TagEditor/GeneralSection";
import ExamplesSection from "../../SavedTags/components/TagEditor/ExamplesSection";
import { TagEditorHeader } from "../../SavedTags/components/TagEditor/TagEditorUtils";
import { TSContext } from "../TSContext";
import { handleSaveMetadata } from "../Utils";
import { DEFAULT_DATE_FORMAT } from "../../../../config/config";

const TagEditor = () => {
  const { deasyApiKey } = useContext(BaseContext);
  const {
    tagInEditor,
    setTagInEditor,
    activeTagEditorSection,
    setActiveTagEditorSection,
    setIsTagSelectorExpanded,
    formData,
    setFormData,
  } = useContext(TSContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isAddingValue, setIsAddingValue] = useState(false);
  const [newValue, setNewValue] = useState("");

  useEffect(
    () => {
      if (tagInEditor && Object.keys(tagInEditor).length > 0) {
        const { isNewlySaved, ...cleanTag } = tagInEditor;

        const newFormData = {
          ...cleanTag,
          output_type: cleanTag.output_type || "word",
          available_values: cleanTag.available_values || [],
          tuned: typeof cleanTag.tuned === "number" ? cleanTag.tuned : 0,
          examples: cleanTag.examples || [],
          date_format: cleanTag.date_format || DEFAULT_DATE_FORMAT,
        };

        setFormData(newFormData);
      } else {
        setFormData({
          name: "",
          description: "",
          output_type: "word",
          available_values: [],
          tuned: 0,
          examples: [],
          date_format: DEFAULT_DATE_FORMAT,
        });
      }
    },
    // eslint-disable-next-line
    [tagInEditor],
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "available_values") {
      const valueArray = value === "" ? [] : value.split("\n");

      setFormData((prev) => ({
        ...prev,
        [name]: valueArray,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    try {
      const resp = await handleSaveMetadata(formData, deasyApiKey);

      const standardized_tag_name = resp.tag?.name;
      if (standardized_tag_name && standardized_tag_name !== formData.name) {
        toast(
          `Your tag name has been standardized to "${standardized_tag_name}"`,
          {
            duration: 3000,
          },
        );
      }

      toast.success("Tag saved successfully!");
    } catch (error) {
      console.error("Error saving metadata:", error);
      if (error?.response?.data?.detail) {
        toast.error("Failed to save tag: " + error?.response?.data?.detail);
      } else {
        toast.error("Failed to save tag");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative h-full">
      <TagEditorHeader
        collapseDirection={"vertical"}
        disableCollapse={true}
        isTagEditorCollapsed={false}
        setIsTagEditorCollapsed={null}
        setIsTagSelectorExpanded={setIsTagSelectorExpanded}
        tagInEditor={tagInEditor}
        context="taggingstudio"
      />

      <div className="h-[calc(100%-64px)] flex flex-col">
        <div className="flex-1 overflow-y-auto">
          <div className="flex bg-white border-b border-gray-200 w-full">
            <button
              className={`flex-1 py-3 px-6 text-sm font-medium text-center transition-all
                ${
                  activeTagEditorSection === "general"
                    ? "border-b-2 border-gray-400 text-black "
                    : "border-transparent text-gray-600 hover:bg-gray-50"
                }`}
              onClick={() => setActiveTagEditorSection("general")}
            >
              General Settings
            </button>
            <button
              className={`flex-1 py-3 px-6 text-sm font-medium text-center transition-all
                ${
                  activeTagEditorSection === "examples"
                    ? "border-b-2 border-gray-400 text-black "
                    : "border-transparent text-gray-600 hover:bg-gray-50"
                }`}
              onClick={() => setActiveTagEditorSection("examples")}
            >
              Training Examples
            </button>
          </div>

          <div className="p-6 space-y-6">
            {!tagInEditor ? (
              <div className="text-center py-12">
                <div className="text-gray-400 text-lg">No Tag Selected</div>
                <p className="text-gray-500 mt-2">
                  Select a tag from the list to edit its properties
                </p>
              </div>
            ) : activeTagEditorSection === "general" ? (
              <GeneralSection
                formData={formData}
                setFormData={setFormData}
                handleInputChange={handleInputChange}
                setTagInEditor={setTagInEditor}
                isAddingValue={isAddingValue}
                setIsAddingValue={setIsAddingValue}
                newValue={newValue}
                setNewValue={setNewValue}
              />
            ) : activeTagEditorSection === "examples" ? (
              <ExamplesSection
                formData={formData}
                setFormData={setFormData}
                setTagInEditor={setTagInEditor}
              />
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="p-4 absolute right-0 bottom-0">
          <button
            className={`w-full px-4 py-2 rounded-md text-sm font-medium transition-all duration-200
              ${
                formData.name && formData.description
                  ? "bg-primary text-white hover:bg-primary/90"
                  : "bg-gray-100 text-gray-400 cursor-not-allowed"
              }
            `}
            disabled={!formData.name || !formData.description || isLoading}
            onClick={handleSaveClick}
          >
            <span className="flex items-center justify-center gap-2">
              {isLoading ? (
                <>
                  <svg
                    className="animate-spin h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  <span>Adding tag...</span>
                </>
              ) : (
                <span className="text-xl">Save tag</span>
              )}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagEditor;
