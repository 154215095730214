import React from "react";

function LoadingScreen() {
  return (
    <div className="h-screen w-full bg-white flex flex-col items-center justify-center space-y-4">
      <svg
        className="w-16 h-16 text-emerald-500 animate-pulse"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"
          fill="currentColor"
        />
      </svg>
      <h1 className="text-2xl font-medium text-gray-800">Loading</h1>
      <p className="text-gray-500 text-sm animate-pulse">
        Shrimpy is getting things together
      </p>
    </div>
  );
}

export default LoadingScreen;
