import React, { createContext, useContext, useState } from "react";
import { BaseContext } from "../../../contexts/BaseContext";

export const TSContext = createContext();

export const useTSContext = () => useContext(TSContext);

export const TSProvider = ({ children }) => {
  const { startingTagInTS } = useContext(BaseContext);

  const [activeTagEditorSection, setActiveTagEditorSection] =
    useState("general");
  const [taggingStudioOutput, setTaggingStudioOutput] = useState(null);

  const [tsRunning, setTsRunning] = useState(false);
  const [tagInEditor, setTagInEditor] = useState(startingTagInTS);
  const [isTagSelectorExpanded, setIsTagSelectorExpanded] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editingExistingTag, setEditingExistingTag] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    output_type: "word",
    available_values: [],
    tuned: false,
    examples: [],
  });

  return (
    <TSContext.Provider
      value={{
        // Getters
        activeTagEditorSection,
        tsRunning,
        taggingStudioOutput,
        tagInEditor,
        isTagSelectorExpanded,
        selectedFiles,
        editingExistingTag,
        formData,
        // Setters
        setActiveTagEditorSection,
        setTsRunning,
        setTaggingStudioOutput,
        setTagInEditor,
        setIsTagSelectorExpanded,
        setSelectedFiles,
        setEditingExistingTag,
        setFormData,
        // Functions
      }}
    >
      {children}
    </TSContext.Provider>
  );
};
