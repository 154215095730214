import React, { useState, useRef } from "react";
import { FaChevronDown, FaSearch } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import { format } from "date-fns";

const CompactDataSliceSelector = ({
  selectedDataSlice,
  onSelect,
  vdbFilesCount,
  dataSlices,
  deasyUserConfig,
  tableHeader = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);

  // Filter data slices to only show ones matching current VDB config
  const relevantDataSlices = dataSlices.filter((slice) => {
    const currentVdbConfig =
      deasyUserConfig.vdbmConfig?.Configs?.[
        deasyUserConfig.vdbmConfig?.LastActive
      ];
    const sliceVdbConfig = slice.vector_db_config;
    return (
      sliceVdbConfig?.type === currentVdbConfig?.type &&
      sliceVdbConfig?.collection_name === currentVdbConfig?.collection
    );
  });

  const filteredDataSlices = relevantDataSlices.filter((slice) => {
    const query = searchQuery.toLowerCase();
    return (
      slice.name.toLowerCase().includes(query) ||
      slice.description?.toLowerCase().includes(query)
    );
  });

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className={`flex items-center w-full gap-2 px-3 py-1.5 text-xs rounded-lg transition-colors
          ${
            selectedDataSlice
              ? "bg-emerald-50 text-emerald-700 hover:bg-emerald-100"
              : "bg-gray-50 text-gray-600 hover:bg-gray-100"
          } border border-gray-200`}
      >
        <span className="truncate max-w-[150px]">
          {selectedDataSlice?.name || "All Data"}
        </span>
        <FaChevronDown className="w-3 h-3 flex-shrink-0" />
      </button>

      <AnimatePresence>
        {isExpanded && (
          <>
            <div
              className="fixed inset-0"
              style={{ zIndex: 9998 }}
              onClick={() => setIsExpanded(false)}
            />
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              style={{
                position: "fixed",
                top:
                  dropdownRef.current?.getBoundingClientRect().bottom + 8 || 0,
                left: tableHeader
                  ? dropdownRef.current?.getBoundingClientRect().left || 0
                  : dropdownRef.current?.getBoundingClientRect().left - 400 ||
                    0,
                width: "40vw",
                zIndex: 9999,
              }}
              className="bg-white border rounded-lg shadow-xl "
            >
              <div className="p-4 border-b text-left">
                <div className="relative">
                  <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search data slices..."
                    className="w-full pl-10 pr-4 py-2.5 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-primary/50"
                  />
                </div>
              </div>

              <div className="max-h-[30vh] overflow-y-auto shadow-inner">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Data Slice Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Files
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Last Updated
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr
                      onClick={() => {
                        onSelect(null);
                        setIsExpanded(false);
                      }}
                      className={`cursor-pointer hover:bg-gray-50 transition-colors text-left duration-200 ${
                        !selectedDataSlice ? "bg-emerald-50" : ""
                      }`}
                    >
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                        All Data
                      </td>
                      <td className="px-6 py-3 text-sm text-gray-500">
                        No filters applied
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                        {vdbFilesCount?.total_files?.toLocaleString() || "-"}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                        -
                      </td>
                    </tr>
                    {filteredDataSlices.map((slice) => (
                      <tr
                        key={slice.id}
                        onClick={() => {
                          onSelect(slice);
                          setIsExpanded(false);
                        }}
                        className={`cursor-pointer text-left hover:bg-gray-50 transition-colors duration-200 ${
                          selectedDataSlice?.id === slice.id
                            ? "bg-emerald-50"
                            : ""
                        }`}
                      >
                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                          {slice.name}
                        </td>
                        <td className="px-6 py-3 text-sm text-gray-500">
                          {slice.description || "-"}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                          {slice.dataPoints?.toLocaleString() || "-"}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                          {format(new Date(slice.lastUpdated), "MMM d, yyyy")}
                        </td>
                      </tr>
                    ))}
                    {filteredDataSlices.length === 0 && (
                      <tr>
                        <td
                          colSpan="4"
                          className="px-6 py-4 text-center text-gray-500"
                        >
                          {relevantDataSlices.length === 0
                            ? "No data slices available for current VDB configuration."
                            : "No data slices found matching your search."}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CompactDataSliceSelector;
