import { MarkerType } from "reactflow";

export const processData = (data) => {
  let nodeId = 0;
  const rootNodeId = `node_${nodeId++}`;

  // Constants for layout
  const HORIZONTAL_SPACING = 400;
  const VERTICAL_SPACING = 150;
  const VALUE_HORIZONTAL_OFFSET = 100;
  const ROOT_Y_OFFSET = -200; // Only for root node

  // Initialize nodes with root at center position
  const nodes = [
    {
      id: rootNodeId,
      type: "root",
      position: { x: 0, y: ROOT_Y_OFFSET }, // Let ReactFlow's fitView handle centering
      data: {
        label: "Root",
        nodePath: [],
      },
    },
  ];
  const edges = [];

  // Track min and max X positions
  let minX = 0;
  let maxX = 0;

  // Rest of the tracking variables
  const levelRightmostX = new Map();
  const subtreeWidths = new Map();

  // First pass: Calculate subtree widths
  const calculateSubtreeWidth = (node, depth = 0) => {
    if (!node || typeof node !== "object") return 0;

    let width = 0;
    const isQuestion = depth % 2 === 0;

    if (isQuestion) {
      // For question nodes, sum up widths of all children
      Object.entries(node).forEach(([key, value]) => {
        if (key !== "TagAvailableValues") {
          width += calculateSubtreeWidth(value, depth + 1);
        }
      });
      width = Math.max(width, HORIZONTAL_SPACING); // Minimum width
    } else {
      // For value level, count available values
      const valueCount = node.TagAvailableValues?.length || 1;
      width = valueCount * (HORIZONTAL_SPACING + VALUE_HORIZONTAL_OFFSET);
    }

    subtreeWidths.set(nodeId, width);
    return width;
  };

  // Calculate initial subtree widths
  calculateSubtreeWidth(data);

  const processNode = (
    node,
    depth = 0,
    parentId = null,
    parentX = 0,
    currentPath = [],
  ) => {
    const isQuestion = depth % 2 === 0;

    if (!isQuestion) {
      const availableValues = Array.isArray(node.TagAvailableValues)
        ? node.TagAvailableValues
        : [];

      if (node.TagAvailableValues !== undefined) {
        const values = [
          ...new Set([
            ...Object.keys(node).filter((k) => k !== "TagAvailableValues"),
            ...availableValues,
          ]),
        ].sort();

        const totalWidth = values.length * HORIZONTAL_SPACING;
        const startX = parentX - totalWidth / 2 + HORIZONTAL_SPACING / 2;

        values.forEach((value, index) => {
          const xPos = startX + index * HORIZONTAL_SPACING;
          // Track min and max X positions
          minX = Math.min(minX, xPos);
          maxX = Math.max(maxX, xPos);

          const valueNodeId = `node_${nodeId++}`;
          const valuePath = [...currentPath, value];

          nodes.push({
            id: valueNodeId,
            type: "value",
            position: {
              x: xPos,
              y: depth * VERTICAL_SPACING,
            },
            data: {
              id: valueNodeId,
              label: value,
              isOutOfSync: !availableValues.includes(value),
              nodePath: valuePath,
              parentId,
            },
          });

          levelRightmostX.set(
            depth,
            Math.max(
              levelRightmostX.get(depth) || 0,
              xPos + HORIZONTAL_SPACING,
            ),
          );

          if (parentId) {
            edges.push({
              id: `edge_${parentId}_${valueNodeId}`,
              source: parentId,
              target: valueNodeId,
              type: "step",
            });
          }

          // Process child nodes
          if (value in node) {
            processNode(node[value], depth + 1, valueNodeId, xPos, valuePath);
          }
        });
      }
    } else {
      Object.entries(node).forEach(([key, value], index) => {
        if (key === "TagAvailableValues") return;

        const currentId = `node_${nodeId++}`;
        const newPath = [...currentPath, key];
        const subtreeWidth = subtreeWidths.get(nodeId) || HORIZONTAL_SPACING;

        const xPos =
          parentX +
          index * subtreeWidth -
          ((Object.keys(node).length - 1) * subtreeWidth) / 2;

        // Track min and max X positions
        minX = Math.min(minX, xPos);
        maxX = Math.max(maxX, xPos);

        nodes.push({
          id: currentId,
          type: "question",
          position: {
            x: xPos,
            y: depth * VERTICAL_SPACING,
          },
          data: {
            id: currentId,
            label: key,
            nodePath: newPath,
            parentId,
          },
        });

        levelRightmostX.set(
          depth,
          Math.max(levelRightmostX.get(depth) || 0, xPos + HORIZONTAL_SPACING),
        );

        edges.push({
          id: `edge_${parentId || rootNodeId}_${currentId}`,
          source: parentId || rootNodeId,
          target: currentId,
          type: "smoothstep",
          sourceHandle: "bottom",
          targetHandle: "top",
          style: {
            strokeWidth: 2,
          },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
          },
        });

        if (value && typeof value === "object") {
          processNode(value, depth + 1, currentId, xPos, newPath);
        }
      });
    }
  };

  processNode(data);

  // Calculate the center point and offset needed
  const graphWidth = maxX - minX;
  const centerOffset = -graphWidth / 2 - minX;

  // Apply the centering offset to all nodes
  nodes.forEach((node) => {
    node.position.x += centerOffset;
  });

  return { nodes, edges };
};

export const nodePathToConditions = (nodePath) => {
  if (Array.isArray(nodePath)) {
    const conditions = [];
    const startIndex = nodePath[0] === "root" ? 1 : 0;
    for (let i = startIndex; i < nodePath.length - 1; i += 2) {
      conditions.push({
        tag_id: nodePath[i],
        values: [nodePath[i + 1]],
      });
    }
    if (nodePath.length === 1 && nodePath[0] !== "root") {
      conditions.push({
        tag_id: nodePath[0],
        values: [],
      });
    }
    return conditions;
  }

  const conditions = [];
  const pathParts = nodePath.split(">");
  const startIndex = pathParts[0] === "root" ? 1 : 0;
  for (let i = startIndex; i < pathParts.length - 1; i += 2) {
    conditions.push({
      tag_id: pathParts[i],
      values: [pathParts[i + 1]],
    });
  }
  if (pathParts.length === 2 && pathParts[0] !== "root") {
    conditions.push({
      tag_id: pathParts[0],
      values: [],
    });
  }
  return conditions;
};

export const getNewConditionsFromNodes = (nodes) => {
  let rootCondition = {
    condition: "OR",
    children: [],
  };
  if (nodes.length === 1 && nodes[0] !== "root") {
    return null;
  }
  const conditions = nodes.map((node) => {
    const nodePath = node.data.nodePath;
    let parentCondition = {
      condition: "AND",
      children: [],
    };
    for (let i = 0; i < nodePath.length; i += 2) {
      const tag = nodePath[i];
      const value = nodePath[i + 1];
      if (tag && value) {
        parentCondition.children.push({
          tag: {
            name: tag,
            values: [value],
          },
        });
      }
    }
    return parentCondition;
  });
  rootCondition.children.push(...conditions);
  if (rootCondition.children.length === 0) {
    return null;
  }
  return rootCondition;
};

export const getNewConditionsFromListOfStringConditions = (
  listOfStringConditions,
) => {
  if (!listOfStringConditions || listOfStringConditions.length === 0) {
    return null;
  }

  const conditions = {
    condition: "AND",
    children: [],
  };
  for (let i = 0; i < listOfStringConditions.length; i = i + 2) {
    const tagName = listOfStringConditions[i];
    if (i + 1 >= listOfStringConditions.length) {
      break;
    }
    const value = listOfStringConditions[i + 1];
    conditions.children.push({
      tag: {
        name: tagName,
        values: [value],
      },
    });
  }

  return conditions;
};

export const isEquivalent = (a, b) => {
  const aProps = [];
  const bProps = [];

  const isEqual = (a, b) => {
    if (aProps.indexOf(a) !== -1 && bProps.indexOf(b) !== -1) {
      return true;
    }

    if (a == null || b == null) {
      return a === b;
    }

    if (typeof a !== typeof b) {
      return false;
    }

    if (typeof a !== "object") {
      return a === b;
    }

    aProps.push(a);
    bProps.push(b);

    if (a.constructor !== b.constructor) {
      return false;
    }

    if (Array.isArray(a)) {
      if (a.length !== b.length) {
        return false;
      }
      for (let i = 0; i < a.length; i++) {
        if (!isEqual(a[i], b[i])) {
          return false;
        }
      }
      return true;
    }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) {
      return false;
    }

    return keysA.every((key) => keysB.includes(key) && isEqual(a[key], b[key]));
  };

  return isEqual(a, b);
};

export const TAG_TYPES = {
  ANY: "any",
  BINARY: "binary",
};
