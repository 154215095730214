import { tagService } from "../../../services/api";

export const handleSaveMetadata = async (formData, deasyApiKey) => {
  if (!formData.name || !formData.description || !formData.output_type) {
    console.log("Please fill out all fields");
    return;
  }

  try {
    const dataToSave = {
      ...formData,
      output_type: formData.output_type || "word",
      available_values: formData.available_values || [],
      tuned: typeof formData.tuned === "number" ? formData.tuned : 0,
    };
    let response;
    if (formData.tag_id) {
      response = await tagService.updateTag(dataToSave, deasyApiKey);
    } else {
      response = await tagService.createTag(dataToSave, deasyApiKey);
    }

    return response.data;
  } catch (error) {
    console.error("Error saving tag:", error);
    throw error;
  }
};
