import { useState, useEffect, useContext } from "react";
import GeneralSection from "./GeneralSection";
import ExamplesSection from "./ExamplesSection";
import { TagEditorHeader } from "./TagEditorUtils";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { toast } from "react-hot-toast";
import { DEFAULT_DATE_FORMAT } from "../../../../../config/config";

const TagEditor = ({
  collapseDirection = "horizontal",
  disableCollapse = false,
  showHeader = true,
  tagInEditor,
  setTagInEditor,
  isTagEditorCollapsed,
  setIsTagEditorCollapsed,
  setIsTagSelectorExpanded = null,
  activeTagEditorSection = "general",
  setActiveTagEditorSection,
  selectedNodeData,
  handleAddNodeApplied,
  context,
  editingExistingTag,
  onSave,
  onFormChange,
  onSaveSuccess,
}) => {
  const isExpanded = !isTagEditorCollapsed || disableCollapse;

  const { handleSaveMetadata, savedTags } = useContext(BaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    output_type: "word",
    available_values: [],
    tuned: false,
    examples: [],
    maxValues: null,
    date_format: DEFAULT_DATE_FORMAT,
  });
  const [isAddingValue, setIsAddingValue] = useState(false);
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    if (tagInEditor && Object.keys(tagInEditor).length > 0) {
      const { isNewlySaved, ...cleanTag } = tagInEditor;

      const newFormData = {
        ...cleanTag,
        output_type: cleanTag.output_type || "word",
        available_values: cleanTag.available_values || [],
        tuned: typeof cleanTag.tuned === "number" ? cleanTag.tuned : 0,
        examples: cleanTag.examples || [],
        maxValues: cleanTag.maxValues || null,
        date_format: cleanTag.date_format || DEFAULT_DATE_FORMAT,
      };

      setFormData(newFormData);
    } else {
      setFormData({
        name: "",
        description: "",
        output_type: "word",
        available_values: [],
        tuned: 0,
        examples: [],
        maxValues: null,
        date_format: DEFAULT_DATE_FORMAT,
      });
    }
  }, [tagInEditor]);

  const handleCheckUnconfirmedValuesWithToast = (callback) => {
    if (isAddingValue && newValue.trim()) {
      toast.error(
        "You have an unconfirmed output value. Please click 'Add' or 'Cancel' before saving.",
      );
      return false;
    }
    callback();
    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "available_values") {
      const valueArray = value === "" ? [] : value.split("\n");

      setFormData((prev) => ({
        ...prev,
        [name]: valueArray,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    onFormChange?.();
  };

  const handleSaveClick = async () => {
    return handleCheckUnconfirmedValuesWithToast(async () => {
      setIsLoading(true);
      try {
        const existingTag = savedTags.find((tag) => tag.name === formData.name);
        if (!editingExistingTag && existingTag) {
          toast.error("Tag with this name already exists");
          setIsLoading(false);
          return;
        }
        const resp = await handleSaveMetadata(formData);

        const standardized_tag_name = resp.tag?.name;
        if (standardized_tag_name && standardized_tag_name !== formData.name) {
          toast(
            `Your tag name has been standardized to "${standardized_tag_name}"`,
            {
              duration: 3000,
            },
          );
        }

        if (context === "graph") {
          const tagToAdd = {
            ...resp.tag,
            name: resp.tag.name,
            available_values: resp.tag.available_values || [],
          };

          await handleAddNodeApplied(selectedNodeData, tagToAdd);

          setTagInEditor(null);
          setFormData({
            name: "",
            description: "",
            output_type: "word",
            available_values: [],
            tuned: 0,
            examples: [],
            maxValues: null,
            date_format: DEFAULT_DATE_FORMAT,
          });
        }

        toast.success("Tag saved successfully!");
        setIsTagEditorCollapsed(true);
        onSave?.();

        if (onSaveSuccess) {
          onSaveSuccess(resp.tag);
        }
      } catch (error) {
        console.error("Error saving metadata:", error);
        toast.error("Failed to save tag");
      } finally {
        setIsLoading(false);
      }
    });
  };

  return (
    <div className="relative h-full">
      <TagEditorHeader
        collapseDirection={collapseDirection}
        disableCollapse={disableCollapse}
        isTagEditorCollapsed={isTagEditorCollapsed}
        setIsTagEditorCollapsed={setIsTagEditorCollapsed}
        setIsTagSelectorExpanded={setIsTagSelectorExpanded}
        tagInEditor={tagInEditor}
        context={context}
      />

      <div
        className={`${showHeader ? "h-[calc(100%-64px)]" : "h-full"} flex flex-col`}
      >
        <div className="flex-1 overflow-y-auto">
          <div className="flex bg-white border-b border-gray-200 w-full">
            <button
              className={`flex-1 py-3 px-6 text-sm font-medium text-center transition-all
                ${
                  activeTagEditorSection === "general"
                    ? "border-b-2 border-gray-400 text-black "
                    : "border-transparent text-gray-600 hover:bg-gray-50"
                }`}
              onClick={() => setActiveTagEditorSection("general")}
            >
              General Settings
            </button>
            <button
              className={`flex-1 py-3 px-6 text-sm font-medium text-center transition-all
                ${
                  activeTagEditorSection === "examples"
                    ? "border-b-2 border-gray-400 text-black "
                    : "border-transparent text-gray-600 hover:bg-gray-50"
                }`}
              onClick={() => setActiveTagEditorSection("examples")}
            >
              Training Examples
            </button>
          </div>

          {isExpanded && (
            <div className="p-6 space-y-6">
              {!tagInEditor ? (
                <div className="text-center py-12">
                  <div className="text-gray-400 text-lg">No Tag Selected</div>
                  <p className="text-gray-500 mt-2">
                    Select a tag from the list to edit its properties
                  </p>
                </div>
              ) : activeTagEditorSection === "general" ? (
                <GeneralSection
                  formData={formData}
                  setFormData={setFormData}
                  handleInputChange={handleInputChange}
                  setTagInEditor={setTagInEditor}
                  isAddingValue={isAddingValue}
                  setIsAddingValue={setIsAddingValue}
                  newValue={newValue}
                  setNewValue={setNewValue}
                />
              ) : activeTagEditorSection === "examples" ? (
                <ExamplesSection
                  tagInEditor={tagInEditor}
                  setTagInEditor={setTagInEditor}
                  formData={formData}
                  setFormData={setFormData}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        </div>

        <div className="p-4 absolute right-0 bottom-0">
          <button
            className={`w-full px-4 py-2 rounded-md text-sm font-medium transition-all duration-200
              ${
                formData.name && formData.description
                  ? "bg-primary text-white hover:bg-primary/90"
                  : "bg-gray-100 text-gray-400 cursor-not-allowed"
              }
            `}
            disabled={!formData.name || !formData.description || isLoading}
            onClick={handleSaveClick}
          >
            <span className="flex items-center justify-center gap-2">
              {isLoading ? (
                <>
                  <svg
                    className="animate-spin h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  <span>Adding tag...</span>
                </>
              ) : formData.name && formData.description ? (
                <span className="text-xl">
                  {context === "graph" ? "Add tag to graph" : "Save tag"}
                </span>
              ) : (
                <span className="text-xl">
                  {context === "graph"
                    ? "Enter name and description"
                    : "Save tag"}
                </span>
              )}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagEditor;
