import React, { useContext } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
  useNavigate,
  Outlet,
} from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// Context imports
import { BaseContext } from "../../contexts/BaseContext";
import { GraphProvider } from "../../contexts/GraphContext";
import { SmallDataProvider } from "../../contexts/SmallDataContext";

// Model imports
import { WorkflowType, MainItemType } from "../../models/WorkflowModel";

// Component imports
import MainNavigation from "../Navigation/MainNavigation";
import TutorialModal from "../Navigation/TutorialModal";

// Screen imports
import ViewMetadataTab from "../MainContent/Workflows/screens/DataDiscovery/ViewMetadata/ViewMetadataTab";
import TokenDashboard from "../MainContent/Token/TokenDashboard";
import { ProgressTracking } from "../MainContent/ProgressTracking/ProgressTracking";
import MetadataLibrary from "../MainContent/MetadataLibrary/MetadataLibrary";
import TaggingStudio from "../MainContent/TaggingStudio/TaggingStudio";
import DeasyConfig from "../MainContent/DeasyConfig/DeasyConfig";
import { ExtractMetadataTab } from "../MainContent/Workflows/screens/DataDiscovery/ExtractMetadata/ExtractMetadataTab";
import DataSliceManagementScreen from "../MainContent/Workflows/screens/DataSlice/DataSliceManagementScreen";
import ExportMetadataScreen from "../MainContent/Workflows/screens/ExportMetadata/ExportMetadataScreen";
import { TSProvider } from "../MainContent/TaggingStudio/TSContext";
import Login from "../Auth/Login";
import PrivateRoute from "../Auth/PrivateRoute";
// Function imports.
import {
  WORKFLOW_STEPS,
  getPathFromWorkflowType,
  getLocationStateFromPath,
} from "../Navigation/NavUtils";

import * as Sentry from "@sentry/react";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppLayout = () => {
  const location = useLocation();

  location.state =
    location.state || getLocationStateFromPath(location.pathname);

  if (!location.state) {
    // this is a catch all for when the user navigates to a page that doesn't have a location state and we haven't redirected properly
    // getWorkflowTypeFromPath will defualt return the Connect Data workflow, so we shouldn't need to worry about that
    console.error("No location state found");
    return <Navigate to={`/${MainItemType.WORKFLOWS}`} />;
  }

  const getTitle = () => {
    switch (location.state.workflowType) {
      case WorkflowType.VIEW_METADATA:
        return "View Metadata";
      case WorkflowType.EXTRACT_METADATA:
        return "Extract Metadata";
      case WorkflowType.CONNECT_DATA:
        return "Connect your data";
      case WorkflowType.DATA_SLICE_MANAGEMENT:
        return "Data Slices";
      case WorkflowType.EXPORT_METADATA:
        return "Export Metadata";
      default:
        // a bit hacky but our default workflow is connect data
        return "Connect your data";
    }
  };

  const getDescription = () => {
    if (!location.state) {
      return "";
    }
    switch (location.state.workflowType) {
      case WorkflowType.VIEW_METADATA:
        return "Explore and analyze your metadata structure.";
      case WorkflowType.EXTRACT_METADATA:
        return "Define and organize how your metadata should be structured. Extract and process metadata from your datasets.";
      case WorkflowType.CONNECT_DATA:
        return "Connect your data to Deasy to start powering your workflows.";
      case WorkflowType.DATA_SLICE_MANAGEMENT:
        return "Create and manage data slices to power your use cases.";
      case WorkflowType.EXPORT_METADATA:
        return "Export your metadata to a vector database.";
      default:
        // a bit hacky but our default workflow is connect data
        return "Connect your data to Deasy to start powering your workflows.";
    }
  };

  const ScreenWrapper = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const { deasyUserConfig } = useContext(BaseContext);

    const dataSource =
      deasyUserConfig.vdbmConfig.Configs[deasyUserConfig.vdbmConfig.LastActive];

    const currentStep =
      location.state?.workflowType || WorkflowType.CONNECT_DATA;
    const currentIndex = WORKFLOW_STEPS.indexOf(currentStep);

    const dataSourceName = params.dataSourceName || dataSource?.name;

    const [isTutorialOpen, setIsTutorialOpen] = React.useState(false);

    const handleNavigate = (direction) => {
      const newIndex = currentIndex + (direction === "next" ? 1 : -1);
      if (newIndex >= 0 && newIndex < WORKFLOW_STEPS.length) {
        const nextStep = WORKFLOW_STEPS[newIndex];
        const path = getPathFromWorkflowType(nextStep, dataSourceName);
        navigate(path, {
          state: {
            page: MainItemType.WORKFLOWS,
            workflowType: nextStep,
          },
        });
      }
    };

    return (
      <div className="flex flex-col h-full">
        <div className="flex flex-row gap-3 px-6 py-3 items-center mt-1 border-b relative">
          {location.state.page === MainItemType.WORKFLOWS && (
            <>
              <button
                className={`absolute left-2 px-4 py-2 text-lg ${
                  currentIndex !== 0
                    ? "text-gray-600 hover:text-gray-800"
                    : "text-gray-300 cursor-not-allowed"
                }`}
                onClick={() => handleNavigate("prev")}
                disabled={currentIndex === 0}
              >
                ←
              </button>
              <button
                className={`absolute right-2 px-4 py-2 text-lg ${
                  currentIndex < WORKFLOW_STEPS.length - 1
                    ? "text-gray-600 hover:text-gray-800"
                    : "text-gray-300 cursor-not-allowed"
                }`}
                onClick={() => handleNavigate("next")}
                disabled={currentIndex === WORKFLOW_STEPS.length - 1}
              >
                →
              </button>
            </>
          )}
          <div className="flex flex-row gap-3 pl-8">
            <h1 className="text-lg font-bold items-end text-secondary">
              {getTitle()}
            </h1>
            <p className="text-gray-600">{getDescription()}</p>
          </div>
          <button
            className="absolute right-12 px-3 py-2 text-gray-600 hover:text-gray-800"
            onClick={() => setIsTutorialOpen(true)}
            title="Help"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        {children}
        <TutorialModal
          isOpen={isTutorialOpen}
          onClose={() => setIsTutorialOpen(false)}
        />
      </div>
    );
  };

  const WorflowLayout = () => {
    return (
      <>
        <ScreenWrapper>
          <Outlet />
        </ScreenWrapper>
      </>
    );
  };

  const Layout = () => {
    return (
      <PrivateRoute>
        <div className="flex flex-1 h-screen bg-white overflow-auto">
          <MainNavigation />
          <div className="flex-1 bg-gray-50 flex max-w-full flex-col overflow-auto h-screen">
            <Outlet />
          </div>
        </div>
      </PrivateRoute>
    );
  };

  return (
    <div className="flex h-screen bg-white overflow-auto">
      <DndProvider backend={HTML5Backend}>
        <GraphProvider>
          <SentryRoutes>
            <Route path="/" element={<Layout />}>
              {/* Workflow Navigation Routes */}
              <Route
                path={`${MainItemType.WORKFLOWS}`}
                element={<WorflowLayout />}
              >
                {/* Default route */}
                <Route
                  index
                  element={<Navigate to={`${WorkflowType.EXTRACT_METADATA}`} />}
                />
                <Route
                  path={`${WorkflowType.VIEW_METADATA}/:dataSourceName`}
                  element={
                    <div className="flex-1 px-6 py-5">
                      <ViewMetadataTab />
                    </div>
                  }
                />
                <Route
                  path={`${WorkflowType.EXTRACT_METADATA}/:dataSourceName`}
                  element={
                    <div className="flex-1 px-6 py-5">
                      <ExtractMetadataTab />
                    </div>
                  }
                />
                <Route
                  path={`${WorkflowType.DATA_SLICE_MANAGEMENT}/:dataSourceName`}
                  element={<DataSliceManagementScreen />}
                />
                <Route
                  path={`${WorkflowType.EXPORT_METADATA}/:dataSourceName`}
                  element={<ExportMetadataScreen dataSliceSpecific={false} />}
                />
              </Route>
              {/* Main Navigation Routes */}
              <Route
                path={`${MainItemType.TOKEN_DASHBOARD}`}
                element={<TokenDashboard />}
              />
              <Route
                path={`${MainItemType.DATA_CONNECTIONS}`}
                element={<DeasyConfig />}
              />
              <Route
                path={`${MainItemType.METADATA_LIBRARY}`}
                element={
                  <SmallDataProvider>
                    <MetadataLibrary />
                  </SmallDataProvider>
                }
              />
              <Route
                path={`${MainItemType.METADATA_STUDIO}/:dataSourceName`}
                element={
                  <TSProvider>
                    <TaggingStudio />
                  </TSProvider>
                }
              />
              <Route
                path={`/${MainItemType.PROGRESS_TRACKING}`}
                element={<ProgressTracking />}
              />
              <Route
                index
                element={<Navigate to={`/${MainItemType.DATA_CONNECTIONS}`} />}
              />
              <Route
                index
                element={<Navigate to={`/${MainItemType.DATA_CONNECTIONS}`} />}
              />
            </Route>
            <Route path="login" element={<Login />} />
            {/* Default route */}
            <Route
              path="*"
              element={<Navigate to={`/${MainItemType.DATA_CONNECTIONS}`} />}
            />
          </SentryRoutes>
        </GraphProvider>
      </DndProvider>
    </div>
  );
};

export { AppLayout };
