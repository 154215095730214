import { MainItemType, WorkflowType } from "../../models/WorkflowModel";

const getWorkflowTypeFromPath = (path) => {
  if (
    path.includes(MainItemType.WORKFLOWS) &&
    path.includes(WorkflowType.VIEW_METADATA)
  ) {
    return WorkflowType.VIEW_METADATA;
  }
  if (
    path.includes(MainItemType.WORKFLOWS) &&
    path.includes(WorkflowType.EXTRACT_METADATA)
  ) {
    return WorkflowType.EXTRACT_METADATA;
  }
  if (
    path.includes(MainItemType.WORKFLOWS) &&
    path.includes(WorkflowType.DATA_SLICE_MANAGEMENT)
  ) {
    return WorkflowType.DATA_SLICE_MANAGEMENT;
  }
  if (
    path.includes(MainItemType.WORKFLOWS) &&
    path.includes(WorkflowType.EXPORT_METADATA)
  ) {
    return WorkflowType.EXPORT_METADATA;
  }
  return WorkflowType.EXTRACT_METADATA;
};
const getLocationStateFromPath = (path) => {
  if (path.includes(MainItemType.WORKFLOWS)) {
    const workflowType = getWorkflowTypeFromPath(path);
    return { page: MainItemType.WORKFLOWS, workflowType: workflowType };
  }
  if (path.includes(MainItemType.METADATA_LIBRARY)) {
    return { page: MainItemType.METADATA_LIBRARY, workflowType: null };
  }
  if (path.includes(MainItemType.METADATA_STUDIO)) {
    return { page: MainItemType.METADATA_STUDIO, workflowType: null };
  }
  if (path.includes(MainItemType.DATA_CONNECTIONS)) {
    return { page: MainItemType.DATA_CONNECTIONS, workflowType: null };
  }
  if (path.includes(MainItemType.TOKEN_DASHBOARD)) {
    return { page: MainItemType.TOKEN_DASHBOARD, workflowType: null };
  }
  if (path.includes(MainItemType.PROGRESS_TRACKING)) {
    return { page: MainItemType.PROGRESS_TRACKING, workflowType: null };
  }
  return {
    page: MainItemType.WORKFLOWS,
    workflowType: WorkflowType.EXTRACT_METADATA,
  };
};

// Add workflow order configuration at the top
const WORKFLOW_STEPS = [
  WorkflowType.EXTRACT_METADATA,
  WorkflowType.CONNECT_DATA,
  WorkflowType.VIEW_METADATA,
  WorkflowType.DATA_SLICE_MANAGEMENT,
  WorkflowType.EXPORT_METADATA,
];

// Takes in a main item type and returns the path
const getPathFromMainItem = (item, dataSourceName = null) => {
  switch (item) {
    case MainItemType.WORKFLOWS:
      return `/${MainItemType.WORKFLOWS}/${WorkflowType.EXTRACT_METADATA}${dataSourceName ? `/${dataSourceName}` : ""}`;
    case MainItemType.METADATA_LIBRARY:
      return `/${MainItemType.METADATA_LIBRARY}`;
    case MainItemType.METADATA_STUDIO:
      if (!dataSourceName) {
        console.error("A data source is required for metadata studio");
        return `/${MainItemType.METADATA_STUDIO}/none-selected`;
      }
      return `/${MainItemType.METADATA_STUDIO}/${dataSourceName}`;
    case MainItemType.PROGRESS_TRACKING:
      return `/${MainItemType.PROGRESS_TRACKING}`;
    case MainItemType.TOKEN_DASHBOARD:
      return `/${MainItemType.TOKEN_DASHBOARD}`;
    case MainItemType.DATA_CONNECTIONS:
      return `/${MainItemType.DATA_CONNECTIONS}`;
    default:
      console.error("Invalid main item type");
      return "";
  }
};

const getPathFromWorkflowType = (
  screen,
  dataSourceName = null,
  dataSliceId = null,
) => {
  switch (screen) {
    case WorkflowType.CONNECT_DATA:
      return `/${MainItemType.WORKFLOWS}`;
    case WorkflowType.VIEW_METADATA:
      if (!dataSourceName) {
        console.error("A data source is required for view metadata");
        return `/${MainItemType.WORKFLOWS}/${WorkflowType.VIEW_METADATA}/none-selected`;
      }
      return `/${MainItemType.WORKFLOWS}/${WorkflowType.VIEW_METADATA}/${dataSourceName}`;
    case WorkflowType.EXTRACT_METADATA:
      if (!dataSourceName) {
        console.error("A data source is required for extract metadata");
        return `/${MainItemType.WORKFLOWS}/${WorkflowType.EXTRACT_METADATA}/none-selected`;
      }
      return `/${MainItemType.WORKFLOWS}/${WorkflowType.EXTRACT_METADATA}/${dataSourceName}`;
    case WorkflowType.DATA_SLICE_MANAGEMENT:
      if (!dataSourceName) {
        console.error("A data source is required for data slice management");
        return `/${MainItemType.WORKFLOWS}/${WorkflowType.DATA_SLICE_MANAGEMENT}/none-selected`;
      }
      return `/${MainItemType.WORKFLOWS}/${WorkflowType.DATA_SLICE_MANAGEMENT}/${dataSourceName}`;
    case WorkflowType.EXPORT_METADATA:
      if (!dataSourceName) {
        console.error("A data source is required for export metadata");
        return `/${MainItemType.WORKFLOWS}/${WorkflowType.EXPORT_METADATA}/none-selected`;
      }
      return `/${MainItemType.WORKFLOWS}/${WorkflowType.EXPORT_METADATA}/${dataSourceName}`;
    default:
      return "";
  }
};

export {
  getLocationStateFromPath,
  getWorkflowTypeFromPath,
  getPathFromWorkflowType,
  getPathFromMainItem,
  WORKFLOW_STEPS,
};
