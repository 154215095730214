import { useState, useContext } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";
import { tokenService, connectorService } from "../../../../services/api";
import { FormInput, LoadingSpinner } from "../ConfigUtils";
import { toast } from "react-hot-toast";
import { ProfileCard } from "./ProfileCard";
import {
  getDisplayType,
  DB_OPTIONS,
  POSTGRESQL_TYPE,
  QDRANT_TYPE,
  S3_TYPE,
  SHAREPOINT_TYPE,
} from "./utils";
import { prepareVectorDBConfiguration } from "../../../../services/utils";
import { VDBDeleteModal } from "./VDBDeleteModal";

import { FaSearch, FaPlus, FaTimes } from "react-icons/fa";
import { Modal } from "@mui/material";
import { getVdbLogo } from "../../../../config/config";
import { getVdbType } from "./utils";

// Add default values from AbstractVectorDBManager
const DEFAULT_FILENAME_KEY = "filename";
const DEFAULT_TEXT_KEY = "text";
const DEFAULT_TAGS_KEY = "tags";
const DEFAULT_ID_KEY = "id";

// Add at the top with other constants

export const VectorDBConfiguration = ({
  formState,
  setFormState,
  handleInputChange,
  handleSaveNewConfig,
}) => {
  const [addingProfile, setAddingProfile] = useState(false);
  const [editingProfile, setEditingProfile] = useState(null);
  const [profileName, setProfileName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const { vdbProfiles, deasyUserConfig, setDeasyUserConfig } =
    useContext(BaseContext);
  const activeProfile = deasyUserConfig?.vdbmConfig?.LastActive;
  const [deleteModalProfile, setDeleteModalProfile] = useState(null);

  const handleAddNew = () => {
    setEditingProfile(null);
    setAddingProfile(true);
    setProfileName("");
    setShowModal(true);

    // Reset form state
    const newFormState = { ...formState };
    DB_OPTIONS.forEach((db) => {
      db.fields.forEach((field) => {
        newFormState[field] = "";
      });
    });
    newFormState.type = "";
    setFormState({
      ...newFormState,
      filename_key: newFormState.filename_key || DEFAULT_FILENAME_KEY,
      text_key: newFormState.text_key || DEFAULT_TEXT_KEY,
      ...(newFormState.type === POSTGRESQL_TYPE && {
        tags_key: newFormState.tags_key || DEFAULT_TAGS_KEY,
        id_key: newFormState.id_key || DEFAULT_ID_KEY,
      }),
    });
    setValidationError(null);
  };

  const handleEdit = (profileName) => {
    const existingConfig = vdbProfiles[profileName];
    setAddingProfile(false);
    setEditingProfile(profileName);
    setProfileName(profileName);
    setShowModal(true);
    // Initialize form with existing config and default key values
    setFormState({
      ...existingConfig,
      filename_key: existingConfig.filename_key || DEFAULT_FILENAME_KEY,
      text_key: existingConfig.text_key || DEFAULT_TEXT_KEY,
      ...(existingConfig.type === POSTGRESQL_TYPE && {
        tags_key: existingConfig.tags_key || DEFAULT_TAGS_KEY,
        id_key: existingConfig.id_key || DEFAULT_ID_KEY,
      }),
    });
  };

  // Handlers
  const handleConfigLoad = async (name) => {
    if (name === "new") {
      return;
    }
    const config = vdbProfiles[name];
    if (!config) return;
    // Update form state with the loaded config
    setFormState({
      ...config,
      filename_key: config.filename_key || DEFAULT_FILENAME_KEY,
      text_key: config.text_key || DEFAULT_TEXT_KEY,
      ...(config.type === POSTGRESQL_TYPE && {
        tags_key: config.tags_key || DEFAULT_TAGS_KEY,
        id_key: config.id_key || DEFAULT_ID_KEY,
      }),
    });

    const newConfig = {
      ...deasyUserConfig,
      vdbmConfig: {
        ...deasyUserConfig.vdbmConfig,
        LastActive: name,
        Configs: deasyUserConfig.vdbmConfig.Configs,
      },
    };
    !addingProfile && setDeasyUserConfig(newConfig);

    await connectorService.setActiveProfile(
      name,
      "vdb",
      deasyUserConfig.deasyApiKey,
    );
  };

  const validateFields = () => {
    const errors = [];

    if (!profileName?.trim()) {
      errors.push("Profile name is required");
    }

    if (
      vdbProfiles[profileName?.trim()] &&
      (!editingProfile || profileName.trim() !== editingProfile)
    ) {
      errors.push("A profile with this name already exists");
    }

    if (!formState.type) {
      errors.push("Please select a database type");
      return errors;
    }

    const dbConfig = DB_OPTIONS.find((opt) => opt.value === formState.type);
    if (!dbConfig) {
      errors.push("Invalid database type selected");
      return errors;
    }

    // Validate all required fields from DB_OPTIONS
    dbConfig.fields.forEach((field) => {
      if (!formState[field]?.trim()) {
        errors.push(
          `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, " $1")} is required`,
        );
      }
    });

    dbConfig.schemaFields.forEach((field) => {
      if (!formState[field]?.trim()) {
        errors.push(
          `${field.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())} is required`,
        );
      }
    });
    return errors;
  };

  const validateAndSave = async () => {
    const validationErrors = validateFields();
    if (validationErrors.length > 0) {
      toast.error(validationErrors.join(", "));
      return;
    }

    setIsValidating(true);
    setValidationError(null);

    try {
      const dbConfig = DB_OPTIONS.find((opt) => opt.value === formState.type);
      const config = dbConfig.fields.reduce(
        (acc, field) => ({
          ...acc,
          [field]: formState[field]?.trim(),
        }),
        {
          type: formState.type,
          name: profileName.trim(),
          filename_key: formState.filename_key,
          text_key: formState.text_key,
          ...(formState.type === POSTGRESQL_TYPE && {
            tags_key: formState.tags_key,
            id_key: formState.id_key,
          }),
        },
      );

      // First validate the configuration works
      const preparedConfig = prepareVectorDBConfiguration(config);
      const response = await tokenService.validateVectorDB(preparedConfig);

      if (!response.data.success) {
        toast.error(response.data.message || "Validation failed");
        return;
      }
      handleSaveNewConfig("vectorDB", profileName.trim(), config);
      // Check for required index fields
      const indexFieldsResponse = await tokenService.getVectorDBIndexFieldInfo(
        config.name,
        config.type,
        config,
      );

      if (config.type === POSTGRESQL_TYPE) {
        config.index_info = indexFieldsResponse.data;
      } else if (config.type === QDRANT_TYPE) {
        config.index_info = {
          total_indexes_found: indexFieldsResponse.data.total_indexes_found,
        };
      }
      if (
        indexFieldsResponse.data.total_indexes_found === 0 &&
        (config.type !== S3_TYPE || config.type !== SHAREPOINT_TYPE) &&
        config.type === QDRANT_TYPE
      ) {
        toast.custom(() => (
          <div className="bg-yellow-50 text-yellow-800 border border-yellow-200 p-3 rounded-md flex items-start">
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
            <p>
              To improve performance, place an index within this data source on
              field "{config.filename_key}", especially if managing a large
              number of files.
            </p>
          </div>
        ));
      }

      if (
        config.type === POSTGRESQL_TYPE &&
        !indexFieldsResponse.data.found_indexes.includes(config.filename_key)
      ) {
        toast.custom(() => (
          <div className="bg-yellow-50 text-yellow-800 border border-yellow-200 p-3 rounded-md flex items-start">
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
            <p>
              To improve performance, place an index within this data source on
              field "{config.filename_key}", especially if managing a large
              number of files.
            </p>
          </div>
        ));
      }
      toast.success("Vector database configuration saved successfully");
    } catch (error) {
      // Handle error from validation endpoint
      const errorMessage =
        error.response?.data?.detail ||
        error.response?.data?.message ||
        error.message ||
        "Failed to validate configuration";
      toast.error(`Validation failed: ${errorMessage}`);
      return; // Prevent saving on validation failure
    } finally {
      setIsValidating(false);
      setAddingProfile(false);
    }
  };

  const getButtonText = () => {
    if (isValidating) {
      return (
        <div className="flex items-center justify-center">
          <LoadingSpinner />
          Verifying credentials...
        </div>
      );
    }
    return editingProfile ? "Save Changes" : "Save New Profile";
  };

  const filteredProfiles = Object.entries(vdbProfiles || {}).filter(
    ([name, config]) =>
      name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      getDisplayType(config.type)
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
  );

  const handleDelete = (name) => {
    setDeleteModalProfile(name);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 h-full flex flex-col">
      <div className="pb-6">
        <div className="flex justify-between items-center mb-6 flex-1">
          <h2 className="text-xl font-semibold text-gray-700">
            Data Source Profiles
          </h2>
          <div className="flex items-center gap-3">
            <button
              onClick={handleAddNew}
              className="p-2 bg-white text-primary rounded-full hover:bg-primary-dark shadow-md transition-all flex items-center justify-center"
              title="Add new data source"
            >
              <FaPlus />
            </button>
          </div>
        </div>
        <div className="mb-4 relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FaSearch className="text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Search data sources..."
            className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchQuery && (
            <button
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
              onClick={() => setSearchQuery("")}
            >
              <FaTimes className="text-gray-400 hover:text-gray-600" />
            </button>
          )}
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          {filteredProfiles.map(([name, config]) => (
            <ProfileCard
              key={name}
              type={getDisplayType(config.type)}
              name={name}
              isActive={name === activeProfile}
              onSelect={handleConfigLoad}
              onEdit={handleEdit}
              onDelete={handleDelete}
              icon={
                <img
                  src={getVdbLogo(getVdbType(config.type))}
                  alt={config.type}
                  className="w-6 h-6"
                />
              }
              details={Object.fromEntries(
                DB_OPTIONS.find((opt) => opt.value === config.type)
                  ?.fields.filter(
                    (field) => !field.toLowerCase().includes("key"),
                  )
                  .map((field) => [
                    field
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase()),
                    config[field] || "N/A",
                  ]) || [],
              )}
            />
          ))}
        </div>
      </div>

      {/* New Modal for Adding/Editing Profiles */}
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setAddingProfile(false);
        }}
        aria-labelledby="connector-form-modal"
        aria-describedby="add-or-edit-connector-form"
      >
        <div className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-800">
                {editingProfile
                  ? `Edit ${editingProfile}`
                  : "Add New Data Source"}
              </h2>
              <button
                onClick={() => {
                  setShowModal(false);
                  setAddingProfile(false);
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <FaTimes size={18} />
              </button>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Profile Name
              </label>
              <input
                type="text"
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
                placeholder="Enter profile name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                disabled={!!editingProfile}
              />
            </div>

            {/* Database Type Selection */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-3">
                Database Type
              </label>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                {DB_OPTIONS.map((db) => (
                  <div
                    key={db.value}
                    className={`flex flex-col items-center justify-center p-4 rounded-lg border-2 cursor-pointer transition-all ${
                      formState.type === db.value
                        ? "border-primary bg-primary-50"
                        : "border-gray-200 hover:border-primary-light"
                    }`}
                    onClick={() => handleInputChange("type", db.value)}
                  >
                    <img
                      src={getVdbLogo(getVdbType(db.label))}
                      alt={db.label}
                      className="w-10 h-10 mb-2"
                    />
                    <span className="text-sm font-medium text-center">
                      {db.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* Connection Details */}
            {formState.type && (
              <>
                <div className="mb-6">
                  <h3 className="font-medium text-gray-900 mb-4">
                    Connection Details
                  </h3>
                  {DB_OPTIONS.find(
                    (opt) => opt.value === formState.type,
                  )?.fields.map((field) => (
                    <FormInput
                      key={field}
                      label={
                        field.charAt(0).toUpperCase() +
                        field.slice(1).replace(/([A-Z])/g, " $1")
                      }
                      type={
                        field.toLowerCase().includes("key") ||
                        field.toLowerCase().includes("password") ||
                        field.toLowerCase().includes("secret")
                          ? "password"
                          : "text"
                      }
                      value={formState[field] || ""}
                      onChange={(e) => handleInputChange(field, e.target.value)}
                      placeholder={`Enter your ${field
                        .replace(/([A-Z])/g, " $1")
                        .toLowerCase()}`}
                    />
                  ))}
                </div>

                {/* Advanced Configuration */}
                {formState.type !== "S3DataSourceManager" &&
                  formState.type !== "SharepointDataSourceManager" && (
                    <div className="mb-6 pt-6 border-t border-gray-200">
                      <div className="flex items-center justify-between mb-4">
                        <h3 className="font-medium text-gray-900">
                          Advanced Configuration
                        </h3>
                        <button
                          type="button"
                          onClick={() => {
                            // Reset to defaults
                            handleInputChange(
                              "filename_key",
                              DEFAULT_FILENAME_KEY,
                            );
                            handleInputChange("text_key", DEFAULT_TEXT_KEY);
                            if (formState.type === POSTGRESQL_TYPE) {
                              handleInputChange("tags_key", DEFAULT_TAGS_KEY);
                              handleInputChange("id_key", DEFAULT_ID_KEY);
                            }
                          }}
                          className="text-sm text-primary hover:text-primary-dark"
                        >
                          Reset to defaults
                        </button>
                      </div>

                      <FormInput
                        label="Filename Key"
                        type="text"
                        value={formState.filename_key || DEFAULT_FILENAME_KEY}
                        onChange={(e) =>
                          handleInputChange("filename_key", e.target.value)
                        }
                        placeholder="Enter filename key (e.g., filename)"
                      />

                      <FormInput
                        label="Text Key"
                        type="text"
                        value={formState.text_key || DEFAULT_TEXT_KEY}
                        onChange={(e) =>
                          handleInputChange("text_key", e.target.value)
                        }
                        placeholder="Enter text key (e.g., text)"
                      />

                      {formState.type === POSTGRESQL_TYPE && (
                        <>
                          <FormInput
                            label="Tags Key"
                            type="text"
                            value={formState.tags_key || DEFAULT_TAGS_KEY}
                            onChange={(e) =>
                              handleInputChange("tags_key", e.target.value)
                            }
                            placeholder="Enter tags key (e.g., tags)"
                          />

                          <FormInput
                            label="ID Key"
                            type="text"
                            value={formState.id_key || DEFAULT_ID_KEY}
                            onChange={(e) =>
                              handleInputChange("id_key", e.target.value)
                            }
                            placeholder="Enter ID key (e.g., id)"
                          />
                        </>
                      )}
                    </div>
                  )}
              </>
            )}

            {validationError && (
              <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md">
                {validationError}
              </div>
            )}

            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={() => {
                  setShowModal(false);
                  setAddingProfile(false);
                }}
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={validateAndSave}
                disabled={isValidating}
                className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark flex items-center gap-2"
              >
                {isValidating ? (
                  <>
                    <LoadingSpinner size={4} /> Validating...
                  </>
                ) : (
                  getButtonText()
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Existing Delete Modal */}
      {deleteModalProfile && (
        <VDBDeleteModal
          profileName={deleteModalProfile}
          isOpen={!!deleteModalProfile}
          onClose={() => setDeleteModalProfile(null)}
          onConfirm={async () => {
            await handleSaveNewConfig("vectorDB", deleteModalProfile, null);
            setDeleteModalProfile(null);
          }}
        />
      )}
    </div>
  );
};
