import React, { useState, useContext, useEffect } from "react";
import { dataSliceService } from "../../../../../../services/api";
import { BaseContext } from "../../../../../../contexts/BaseContext";

import DataSliceOverview from "./DataSliceOverview";
import { getVdbLogo, getLlmLogo } from "../../../../../../config/config";
import ExportMetadataScreen from "../../ExportMetadata/ExportMetadataScreen";

const DataSliceSummaryScreen = ({ setSelectedStep, dataSlice }) => {
  const { deasyUserConfig } = useContext(BaseContext);
  const {
    vdbmConfig: { Configs: vdbmConfigs, LastActive: vdbmLastActive },
    deasyApiKey,
  } = deasyUserConfig;

  // Find matching config from vdbmConfigs based on dataSlice's vector DB config
  const matchingConfig = Object.values(vdbmConfigs).find(
    (config) =>
      config.type === dataSlice?.vector_db_config?.type &&
      config.collection === dataSlice?.vector_db_config?.collection_name,
  );
  const vectorDBConfiguration = matchingConfig || vdbmConfigs[vdbmLastActive];

  const [expandedPanels, setExpandedPanels] = useState(["overview", "export"]);
  const [metricsData, setMetricsData] = useState(null);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);

  const togglePanel = (panelName) => {
    setExpandedPanels((current) => {
      if (current.includes(panelName)) {
        return current.filter((p) => p !== panelName);
      } else {
        return [...current, panelName];
      }
    });
  };

  // Add useEffect for fetching data
  useEffect(() => {
    const fetchInitialData = () => {
      setIsLoadingMetrics(true);

      // Metrics fetch
      dataSliceService
        .getDatasliceMetrics(null, null, null, dataSlice?.id, null, deasyApiKey)
        .then((response) => setMetricsData(response.data))
        .catch((error) => console.error("Failed to fetch metrics data:", error))
        .finally(() => {
          setIsLoadingMetrics(false);
        });
    };

    if (dataSlice?.id) {
      fetchInitialData();
    }
  }, [dataSlice?.id, deasyApiKey]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-auto">
        <div className="p-6 pt-4 mb-10">
          <div className="justify-start text-left">
            {/* Back Button */}
            <div className="mb-6">
              <button
                onClick={() => setSelectedStep(0)}
                className="flex items-center text-gray-600 hover:text-gray-900"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                All Data Slices
              </button>
            </div>

            {/* Overview Section */}
            <DataSliceOverview
              isOverviewOpen={expandedPanels.includes("overview")}
              setIsOverviewOpen={(isOpen) => {
                if (isOpen) {
                  setExpandedPanels((current) => [...current, "overview"]);
                } else {
                  setExpandedPanels((current) =>
                    current.filter((p) => p !== "overview"),
                  );
                }
              }}
              dataslice_id={dataSlice?.id}
              dataslice_name={dataSlice?.name}
              dataslice_conditions_new={dataSlice?.conditions_new}
              dataslice_conditions_old={dataSlice?.conditions}
              metricsData={metricsData}
              isLoadingMetrics={isLoadingMetrics}
            />

            {/* Select Metadata Section */}
            <div className="bg-white rounded-xl shadow-sm border border-gray-200 mb-6">
              <button
                onClick={() => togglePanel("select")}
                className="w-full px-6 py-4 text-left font-medium flex items-center justify-between hover:bg-gray-50 rounded-xl transition-colors duration-200"
              >
                <div className="flex items-center gap-3">
                  <h2 className="text-xl font-semibold text-gray-800">
                    Export Metadata from
                  </h2>
                  <img
                    src={getLlmLogo("deasy_compute")}
                    alt="Deasy Shrimp"
                    className="h-8"
                  />
                  <span>to</span>
                  <img
                    src={getVdbLogo(vectorDBConfiguration?.type)}
                    alt={vectorDBConfiguration?.type}
                    className="h-8 rounded-lg"
                  />
                </div>
                <span
                  className="transform transition-transform duration-200 text-gray-400"
                  style={{
                    transform: expandedPanels.includes("select")
                      ? "rotate(180deg)"
                      : "",
                  }}
                >
                  ▼
                </span>
              </button>
            </div>
            {/* Export Metadata Section */}
            {expandedPanels.includes("select") && (
              <ExportMetadataScreen
                dataSliceSpecific={true} // Indicates this is being rendered within a data slice view
                dataSliceSpecificdataSliceId={dataSlice?.id} // The dataslice ID from a data slice view
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataSliceSummaryScreen;
