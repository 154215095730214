import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { BaseContext } from "../../../../../contexts/BaseContext";
import {
  dataSliceService,
  suggestionService,
} from "../../../../../services/api";
import { toast } from "react-hot-toast";
import { prepareVectorDBConfiguration } from "../../../../../services/utils";
import { getVdbLogo, getLlmLogo } from "../../../../../config/config";
import DataSliceMetadata from "../DataSlice/screens/DataSliceMetadata";
import TagDistributionChart from "../DataSlice/screens/TagDistributionChart";
import { Dialog } from "@mui/material";
import DataSliceOverview from "../DataSlice/screens/DataSliceOverview";
import MetadataTable from "../MetadataTable/MetadataTable";
import { getDestinationLogo } from "../../../../../config/config";

const ExportMetadataScreen = ({
  // curDataSlice,
  // dataslice_id,
  dataSliceSpecific = false,
  dataSliceSpecificdataSliceId = null,
}) => {
  const {
    deasyUserConfig,
    dataSlices,
    selectedDataSlice,
    setSelectedDataSlice,
  } = useContext(BaseContext);

  const dataSliceId = selectedDataSlice?.id;
  const {
    vdbmConfig: { Configs: vdbmConfigs, LastActive: vdbmLastActive },
    deasyApiKey,
  } = deasyUserConfig;

  // Instead of using first slice, we'll let user select one

  // Match the whole vdb config according to the data slice selected
  const matchingConfig = Object.values(vdbmConfigs).find(
    (config) =>
      config?.type === selectedDataSlice?.vector_db_config?.type &&
      (config?.collection ===
        selectedDataSlice?.vector_db_config?.collection_name ||
        config?.collection === selectedDataSlice?.vector_db_config?.collection),
  );
  const vectorDBConfiguration = matchingConfig || vdbmConfigs[vdbmLastActive];

  // Get destination configurations
  const destinationProfiles = useMemo(
    () => deasyUserConfig?.destinationConfig?.Configs || {},
    [deasyUserConfig],
  );
  const activeDestinationProfile = useMemo(
    () => deasyUserConfig?.destinationConfig?.LastActive,
    [deasyUserConfig],
  );

  const [expandedPanels, setExpandedPanels] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [selectedExportFormat, setSelectedExportFormat] = useState("csv");
  const [metadataLevel, setMetadataLevel] = useState("both");
  const [metricsData, setMetricsData] = useState(null);
  const [syncScore, setSyncScore] = useState(null);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);
  const [isLoadingSyncScore, setIsLoadingSyncScore] = useState(false);
  const [tagDistribution, setTagDistribution] = useState(null);
  const [isLoadingTagDistribution, setIsLoadingTagDistribution] =
    useState(false);
  const [showSyncGraphs, setShowSyncGraphs] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [pendingExportFormat, setPendingExportFormat] = useState(null);

  // Add new state for search
  const [customSliceSearch, setCustomSliceSearch] = useState("");

  // Add new state variables
  const [recommendedTags, setRecommendedTags] = useState([]);
  const [isSuggesting, setIsSuggesting] = useState(false);

  // Add new state for table filters
  const [tableFilters, setTableFilters] = useState([]);
  const [tableConditionsNew, setTableConditionsNew] = useState(null);
  const [hasSelectedCustomSlice, setHasSelectedCustomSlice] = useState(false);

  // Update export structure
  const [exportType, setExportType] = useState("file"); // "file" or "destination"
  const [selectedFileFormat, setSelectedFileFormat] = useState("csv");
  const [selectedDestination, setSelectedDestination] = useState(null);

  // Add new state for delete confirmation modal
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  // Add new state for export content
  const [exportContent, setExportContent] = useState("metadata");

  const togglePanel = (panelName) => {
    setExpandedPanels((current) => {
      if (current.includes(panelName)) {
        return current.filter((p) => p !== panelName);
      } else {
        return [...current, panelName];
      }
    });
  };

  const refreshMetrics = () => {
    if (!selectedDataSlice) return;
    if (!deasyApiKey) {
      toast.error(
        "No API key found. Please set an API key in the API Keys & Tier page.",
      );
      return;
    }

    setIsLoadingSyncScore(true);
    setIsLoadingTagDistribution(true);
    const prepared_vdb_config = hasSelectedCustomSlice
      ? null
      : prepareVectorDBConfiguration(vectorDBConfiguration);
    dataSliceService
      .getSyncScore(
        dataSliceSpecific
          ? dataSliceSpecificdataSliceId
          : hasSelectedCustomSlice
            ? selectedDataSlice.id
            : null,
        prepared_vdb_config,
        deasyApiKey,
      )
      .then((response) => setSyncScore(response.data))
      .catch((error) => console.error("Failed to fetch sync score:", error))
      .finally(() => setIsLoadingSyncScore(false));

    dataSliceService
      .getTagDistribution(
        dataSliceSpecific
          ? dataSliceSpecificdataSliceId
          : hasSelectedCustomSlice
            ? selectedDataSlice.id
            : null,
        prepared_vdb_config,
        deasyApiKey,
      )
      .then((response) => setTagDistribution(response.data.tag_distribution))
      .catch((error) =>
        console.error("Failed to fetch tag distribution:", error),
      )
      .finally(() => setIsLoadingTagDistribution(false));
  };

  // Handler for data slice selection
  const handleDataSliceSelect = (dataSlice) => {
    if (dataSlice.id === "all_data") {
      setSelectedDataSlice({
        id: "all_data",
        name: "Deasy_Data_All",
      });
    } else {
      setSelectedDataSlice(dataSlice);
    }

    setHasSelectedCustomSlice(!dataSlice.name.startsWith("Deasy_Data_"));
    // Reset states when changing data slice
    setSelectedTags([]);
    setSyncScore(null);
    setTagDistribution(null);
    setShowSyncGraphs(false);
    setMetricsData(null);
  };

  // Modified useEffect to use selectedDataSlice instead of activeDataSlice
  useEffect(() => {
    const fetchInitialData = () => {
      if (!selectedDataSlice) return;
      const prepared_vdb_config = hasSelectedCustomSlice
        ? null
        : prepareVectorDBConfiguration(vectorDBConfiguration);

      setIsLoadingMetrics(true);
      dataSliceService
        .getDatasliceMetrics(
          null,
          null,
          null,
          dataSliceSpecific
            ? dataSliceSpecificdataSliceId
            : hasSelectedCustomSlice
              ? selectedDataSlice.id
              : null,
          prepared_vdb_config,
          deasyApiKey,
        )
        .then((response) => setMetricsData(response.data))
        .catch((error) => console.error("Failed to fetch metrics data:", error))
        .finally(() => setIsLoadingMetrics(false));
    };
    if (deasyApiKey) {
      fetchInitialData();
    }
  }, [
    selectedDataSlice,
    deasyApiKey,
    hasSelectedCustomSlice,
    vectorDBConfiguration,
    dataSliceSpecific,
    dataSliceSpecificdataSliceId,
  ]);

  // Reset sync graphs when export format changes
  useEffect(() => {
    if (selectedExportFormat !== "vdb") {
      setShowSyncGraphs(false);
      setSyncScore(null);
      setTagDistribution(null);
    }
  }, [selectedExportFormat]);

  const handleCheckSync = () => {
    setShowSyncGraphs(true);
    refreshMetrics();
  };

  const handleExportClick = (exportFormat) => {
    setPendingExportFormat(exportFormat);
    setShowConfirmModal(true);
  };

  const handleConfirmExport = () => {
    setShowConfirmModal(false);
    if (exportType === "file") {
      // File export
      handleExport(pendingExportFormat);
    } else if (exportType === "destination") {
      if (selectedDestination === "vdb") {
        // Current data source (VDB) - use existing VDB export functionality
        handleExport("vdb");
      } else {
        // Other destinations - use new destination export
        handleExportToDestination(pendingExportFormat);
      }
    }
  };

  const handleExport = async (exportFormat) => {
    if (!selectedDataSlice) {
      toast.error("No data slice available. Please create a data slice first.");
      return;
    }

    try {
      setIsExporting(true);

      const exportLevel = {
        both: "both",
        file: "file",
        chunk: "chunk",
      }[metadataLevel];

      if (exportFormat === "vdb") {
        const vdbRequestBody = {
          dataslice_id: dataSliceSpecific
            ? dataSliceSpecificdataSliceId
            : selectedDataSlice.id === "all_data"
              ? null
              : selectedDataSlice.id,
          ori_vector_db_config: prepareVectorDBConfiguration(
            vectorDBConfiguration,
          ),
          target_vector_db_config: prepareVectorDBConfiguration(
            vectorDBConfiguration,
          ),
          export_level: exportLevel,
          export_tags: selectedTags.length > 0 ? selectedTags : undefined,
        };

        try {
          toast.loading("Exporting to vector database...", {
            id: "vdb-export",
          });
          await dataSliceService.exportToVectorDB(vdbRequestBody, deasyApiKey);
          toast.success("Successfully exported to vector database", {
            id: "vdb-export",
          });
          refreshMetrics();
        } catch (error) {
          console.error("VDB export failed:", error);
          const errorMessage =
            error.response?.data?.detail ||
            error.message ||
            "Failed to export to vector database";
          toast.error(
            typeof errorMessage === "string"
              ? errorMessage
              : JSON.stringify(errorMessage),
            { id: "vdb-export" },
          );
        }
        return;
      }

      // For CSV/JSON downloads
      const requestBody = {
        dataslice_id: dataSliceSpecific
          ? dataSliceSpecificdataSliceId
          : selectedDataSlice.id === "all_data"
            ? null
            : selectedDataSlice.id,
        export_format: selectedExportFormat,
        export_file_level: exportLevel === "file" || exportLevel === "both",
        vdb_profile_name: vdbmLastActive,
        selected_metadata_fields:
          selectedTags.length > 0 ? selectedTags : undefined,
      };

      const response = await dataSliceService.exportMetadata(
        requestBody,
        deasyApiKey,
      );
      const content =
        selectedExportFormat === "json"
          ? JSON.stringify(response.data, null, 2)
          : response.data;

      const blob = new Blob([content], {
        type: selectedExportFormat === "json" ? "application/json" : "text/csv",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `metadata_export.${selectedExportFormat}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success("Export completed successfully");
    } catch (error) {
      console.error("Export failed:", error);
      const errorMessage =
        error.response?.data?.detail || error.message || "Export failed";
      toast.error(
        typeof errorMessage === "string"
          ? errorMessage
          : JSON.stringify(errorMessage),
      );
    } finally {
      setIsExporting(false);
    }
  };

  const handleExportToDestination = async (destinationName) => {
    // metadata level should never be file
    let exportLevel = {
      both: "both",
      chunk: "chunk",
      file: "file",
    }[metadataLevel];

    if (!selectedDataSlice) {
      toast.error("No data slice available. Please create a data slice first.");
      return;
    }

    if (!destinationName) {
      toast.error("No destination selected. Please select a destination.");
      return;
    }
    try {
      setIsExporting(true);
      const destinationConfig = destinationProfiles[destinationName];

      if (!destinationConfig) {
        toast.error(
          `Destination configuration '${destinationName}' not found.`,
        );
        return;
      }

      toast.loading("Exporting to destination...", {
        id: "destination-export",
      });
      let exportNodes = true;
      let exportMetadata = false;
      if (exportContent === "metadata") {
        exportNodes = false;
        exportMetadata = true;
      } else if (exportContent === "both") {
        exportNodes = true;
        exportMetadata = true;
      } else if (exportContent === "nodes") {
        exportNodes = true;
        exportMetadata = false;
      }
      if (destinationConfig.type.includes("Sharepoint")) {
        setMetadataLevel("file");
        exportLevel = "file";
      }
      const response = await dataSliceService.exportMetadataToDestination(
        dataSliceSpecific
          ? dataSliceSpecificdataSliceId
          : selectedDataSlice.id === "all_data"
            ? null
            : selectedDataSlice.id,
        vectorDBConfiguration.name,
        destinationName,
        selectedTags.length > 0 ? selectedTags : undefined,
        exportLevel,
        exportNodes,
        exportMetadata,
        deasyApiKey,
      );
      if (
        response.data.failed > 0 ||
        response.data.failed_tags?.length > 0 ||
        response.data.failed_items?.length > 0
      ) {
        toast.error(
          `There were some failures exporting to ${destinationName} due to third party service issues. Please try again.`,
          {
            id: "destination-export",
          },
        );
      } else {
        toast.success(
          `Successfully exported ${
            exportContent === "nodes"
              ? `${response.data.success} nodes`
              : exportContent === "metadata"
                ? `metadata tags for ${response.data.success} ${exportLevel === "file" ? "files" : exportLevel === "chunk" ? "chunks" : "files and chunks"}`
                : `${response.data.success} nodes and their metadata tags`
          } in ${destinationName}`,
          {
            id: "destination-export",
          },
        );
      }
    } catch (error) {
      console.error("Export to destination failed:", error);
      const errorMessage =
        error.response?.data?.detail ||
        error.message ||
        "Export to destination failed";
      toast.error(
        typeof errorMessage === "string"
          ? errorMessage
          : JSON.stringify(errorMessage),
        { id: "destination-export" },
      );
    } finally {
      setIsExporting(false);
    }
  };

  const filterDistributionByTags = (distribution) => {
    if (!distribution) return {};
    return Object.entries(distribution).reduce((acc, [key, value]) => {
      if (
        key &&
        value !== undefined &&
        !key.startsWith("_") &&
        !key.startsWith("system/")
      ) {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const ConfirmationModal = () => {
    // Get the display format for the export
    const getDisplayFormat = () => {
      if (exportType === "file") {
        return pendingExportFormat?.toUpperCase();
      } else if (exportType === "destination") {
        if (pendingExportFormat === "vdb") {
          return "Vector Database";
        } else {
          // Get the destination type and make it more readable
          const destinationConfig = destinationProfiles[pendingExportFormat];
          if (!destinationConfig) return "Destination";

          let destinationType = destinationConfig.type || "";

          // Remove "Manager" or "NodeDestination" suffix if present
          destinationType = destinationType
            .replace(/Manager$/, "")
            .replace(/NodeDestination$/, "")
            .replace(/GraphDestination$/, " Graph");

          // Add spacing between camelCase or PascalCase words
          return destinationType.replace(/([A-Z])/g, " $1").trim();
        }
      }
      return "Unknown";
    };

    // Determine which tags to show in the modal - similar to the delete modal
    const tagsToDisplay =
      selectedTags.length > 0
        ? selectedTags
        : Object.keys(
            metricsData?.metadata_tag_counts_distribution || {},
          ).filter((tag) => !tag.startsWith("_") && !tag.startsWith("system/"));

    return (
      <Dialog
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "12px",
          },
        }}
      >
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-md font-semibold text-gray-900">
              Confirm Export
            </h2>
            <button
              onClick={() => setShowConfirmModal(false)}
              className="text-gray-400 hover:text-gray-500"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <p className="text-sm text-gray-500">
                You are about to export{" "}
                {exportContent === "nodes"
                  ? "nodes"
                  : exportContent === "both"
                    ? "nodes and metadata"
                    : "metadata"}{" "}
                for{" "}
                <span className="font-medium text-gray-900">
                  {selectedDataSlice?.name || "All Data"}
                </span>
              </p>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center gap-4">
                <div className="bg-primary/10 p-3 rounded-full">
                  <svg
                    className="w-6 h-6 text-primary"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0l-4 4m4-4v12"
                    />
                  </svg>
                </div>
                <div>
                  <p className="font-medium">Export Details</p>
                  <p className="text-sm text-gray-500">
                    {exportType === "file" ? (
                      <>
                        Format:{" "}
                        <span className="font-medium">
                          {getDisplayFormat()}
                        </span>
                      </>
                    ) : (
                      <>
                        Destination:{" "}
                        <span className="font-medium">
                          {pendingExportFormat === "vdb"
                            ? "Current Data Source"
                            : pendingExportFormat}
                        </span>{" "}
                        ({getDisplayFormat()})
                      </>
                    )}
                  </p>
                  <p className="text-sm text-gray-500">
                    Data Level:{" "}
                    <span className="font-medium">
                      {exportType === "destination" &&
                      pendingExportFormat !== "vdb"
                        ? "File Level Only"
                        : metadataLevel === "both"
                          ? "File & Chunk Level"
                          : metadataLevel === "file"
                            ? "File Level Only"
                            : "Chunk Level Only"}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* Tags Section - Only show when exporting metadata or both */}
            {exportContent !== "nodes" && (
              <div>
                <h3 className="text-sm font-medium text-gray-700 mb-2">
                  {selectedTags.length > 0
                    ? `Selected tags to export (${selectedTags.length}):`
                    : "All tags will be exported:"}
                </h3>
                <div className="max-h-60 overflow-y-auto border border-gray-200 rounded-lg p-3">
                  {tagsToDisplay.length > 0 ? (
                    <div className="flex flex-wrap gap-2">
                      {tagsToDisplay.map((tag) => (
                        <span
                          key={tag}
                          className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500">No tags available</p>
                  )}
                </div>
              </div>
            )}

            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowConfirmModal(false)}
                className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmExport}
                className="px-4 py-2 text-white bg-primary rounded-lg hover:bg-primary-dark transition-colors"
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  // Keep getExportRecommendations as a callback, but don't auto-trigger it
  const getExportRecommendations = useCallback(async () => {
    if (!selectedDataSlice) return;

    try {
      setIsSuggesting(true);
      // Ensure conditions is an array and handle all possible cases
      const conditions = selectedDataSlice.newConditions;

      const response = await suggestionService.suggestTags(
        deasyUserConfig.vdbmConfig.LastActive,
        deasyUserConfig.llmConfig.LastActive,
        {}, // Empty current tree since we want net new suggestions
        { label: selectedDataSlice.name, path: [] },
        "Suggest metadata tags that would be most suitable for filtering data for a Q&A system or for a chatbot getting questions based on the provided context.",
        "File",
        [],
        "both",
        6,
        deasyApiKey,
        {
          use_existing_tags: false,
          use_extracted_tags: true,
          use_net_new_tags: false,
          tag_type: "all",
        },
        conditions,
      );

      // Extract all tag names from the hierarchy, ignoring special fields
      const extractTagNames = (obj) => {
        let tags = new Set();

        const specialFields = [
          "TagAvailableValues",
          "output_type",
          "description",
          "available_values",
        ];

        const traverse = (node) => {
          if (typeof node !== "object" || node === null) return;

          // Add the keys that aren't special fields
          Object.keys(node).forEach((key) => {
            if (!specialFields.includes(key)) {
              tags.add(key);
              // Recursively traverse child nodes
              traverse(node[key]);
            }
          });
        };

        traverse(obj);
        return Array.from(tags);
      };

      // Extract all tag names from the hierarchy
      const suggestedTags = extractTagNames(response.data.suggestion);

      // Clean up tag names by removing symbols and spaces
      const cleanTagName = (tag) => {
        return tag
          .replace(/[^\w\s]/g, "") // Remove all non-word characters (except spaces)
          .replace(/\s+/g, "_") // Replace spaces with underscores
          .toLowerCase(); // Convert to lowercase
      };

      // Filter suggested tags to only include those that exist in the available tags
      const availableTags = Object.keys(
        metricsData?.metadata_tag_counts_distribution || {},
      );

      // Helper function to check if tags match or are subsets
      const tagsMatch = (tag1, tag2) => {
        const clean1 = cleanTagName(tag1);
        const clean2 = cleanTagName(tag2);
        return clean1.includes(clean2) || clean2.includes(clean1);
      };

      // Clean and deduplicate tags while maintaining the original tag names
      const validSuggestedTags = [
        ...new Set(
          suggestedTags
            .filter((tag) => {
              // Check if any available tag matches or is a subset
              return availableTags.some((availableTag) =>
                tagsMatch(availableTag, tag),
              );
            })
            .map((tag) => {
              // Find the matching available tag with correct casing/format
              // If multiple matches found, use the shortest one
              const matches = availableTags.filter((availableTag) =>
                tagsMatch(availableTag, tag),
              );
              return matches.reduce((shortest, current) =>
                shortest.length <= current.length ? shortest : current,
              );
            }),
        ),
      ];

      setRecommendedTags(validSuggestedTags);

      // Auto-select recommended tags if none are selected
      if (selectedTags.length === 0) {
        setSelectedTags(validSuggestedTags);
      }
    } catch (error) {
      toast.error("Failed to generate export recommendations");
    } finally {
      setIsSuggesting(false);
    }
  }, [
    selectedDataSlice,
    metricsData,
    deasyUserConfig,
    deasyApiKey,
    selectedTags.length,
  ]);

  // Modified useEffect to set default destination
  useEffect(() => {
    if (Object.keys(destinationProfiles).length > 0 && !selectedDestination) {
      setSelectedDestination(
        activeDestinationProfile || Object.keys(destinationProfiles)[0],
      );
    }
  }, [destinationProfiles, activeDestinationProfile, selectedDestination]);

  const handleDeleteDestinationMetadata = async () => {
    if (!selectedDestination || !selectedDataSlice) {
      toast.error("Please select a destination and data slice first");
      return;
    }

    // Show confirmation modal instead of immediately deleting
    setShowDeleteConfirmModal(true);
  };

  const confirmDeleteDestinationMetadata = async () => {
    try {
      setShowDeleteConfirmModal(false);
      toast.loading("Deleting metadata from destination...", {
        id: "delete-metadata",
      });

      const destinationConfig = destinationProfiles[selectedDestination];

      await dataSliceService.deleteDestinationMetadata(
        destinationConfig.name,
        // Pass selected tags if available, otherwise undefined
        selectedTags.length > 0
          ? selectedTags
          : Object.keys(
              metricsData?.metadata_tag_counts_distribution || {},
            ).filter(
              (tag) => !tag.startsWith("_") && !tag.startsWith("system/"),
            ),
        deasyApiKey,
      );

      toast.success("Successfully deleted metadata from destination", {
        id: "delete-metadata",
      });
    } catch (error) {
      console.error("Destination metadata deletion failed:", error);
      const errorMessage =
        error.response?.data?.detail ||
        error.message ||
        "Failed to delete metadata";
      toast.error(
        typeof errorMessage === "string"
          ? errorMessage
          : JSON.stringify(errorMessage),
        { id: "delete-metadata" },
      );
    }
  };

  // Create the Delete Confirmation Modal component
  const DeleteConfirmationModal = () => {
    // Determine which tags to show in the modal
    const tagsToDisplay =
      selectedTags.length > 0
        ? selectedTags
        : Object.keys(
            metricsData?.metadata_tag_counts_distribution || {},
          ).filter((tag) => !tag.startsWith("_") && !tag.startsWith("system/"));

    return (
      <Dialog
        open={showDeleteConfirmModal}
        onClose={() => setShowDeleteConfirmModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "12px",
          },
        }}
      >
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-md font-semibold text-gray-900">
              Confirm Metadata Deletion
            </h2>
            <button
              onClick={() => setShowDeleteConfirmModal(false)}
              className="text-gray-400 hover:text-gray-500"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="space-y-4">
            <div className="bg-red-50 p-4 rounded-lg">
              <div className="flex items-center gap-3">
                <div className="bg-red-100 p-2 rounded-full">
                  <svg
                    className="w-6 h-6 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div>
                  <p className="font-medium text-red-800">Warning</p>
                  <p className="text-sm text-red-700">
                    {selectedTags.length > 0
                      ? "You are about to delete the selected metadata tags from your destination."
                      : "You are about to delete ALL metadata tags from your destination."}{" "}
                    This action cannot be undone.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                {selectedTags.length > 0
                  ? "Selected tags to be deleted:"
                  : "All tags will be deleted:"}
              </h3>
              <div className="max-h-60 overflow-y-auto border border-gray-200 rounded-lg p-3">
                {tagsToDisplay.length > 0 ? (
                  <div className="flex flex-wrap gap-2">
                    {tagsToDisplay.map((tag) => (
                      <span
                        key={tag}
                        className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                ) : (
                  <p className="text-sm text-gray-500">No tags available</p>
                )}
              </div>
            </div>

            <div className="pt-4 border-t border-gray-200">
              <p className="text-sm text-gray-600 mb-4">
                This will delete the metadata from your destination:{" "}
                <strong>{selectedDestination}</strong>
              </p>
              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setShowDeleteConfirmModal(false)}
                  className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDeleteDestinationMetadata}
                  className="px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors"
                >
                  Delete Metadata
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <div className="flex-1 overflow-auto">
      <div className="p-6 pt-4 mb-10">
        <div className="space-y-6">
          {/* Select Data Section */}
          {!dataSliceSpecific && (
            <div className="bg-white rounded-xl shadow-sm border border-gray-200">
              <button
                onClick={() => togglePanel("data")}
                className="w-full px-6 py-4 text-left font-medium flex items-center justify-between hover:bg-gray-50 rounded-xl transition-colors duration-200"
              >
                <div className="flex items-center gap-3">
                  <h2 className="text-md font-semibold text-gray-800">
                    1. Select Data
                  </h2>
                  {selectedDataSlice && (
                    <>
                      <span className="text-gray-400">•</span>
                      <div className="flex items-center gap-2">
                        <span className="text-sm text-gray-600">
                          {selectedDataSlice.name}
                        </span>
                        {selectedDataSlice.vector_db_config?.type && (
                          <img
                            src={getVdbLogo(
                              selectedDataSlice.vector_db_config.type,
                            )}
                            className="h-8 w-8 object-contain rounded-lg"
                            alt={selectedDataSlice.vector_db_config.type}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
                <span
                  className="transform transition-transform duration-200 text-gray-400"
                  style={{
                    transform: expandedPanels.includes("data")
                      ? "rotate(180deg)"
                      : "",
                  }}
                >
                  ▼
                </span>
              </button>
              {expandedPanels.includes("data") && (
                <div className="border-t p-4">
                  <div className="flex gap-4">
                    {/* Left side - Deasy Slices */}
                    <div className="w-1/2 space-y-4">
                      <div>
                        <h3 className="text-sm font-medium text-gray-700 mb-2 text-left">
                          All Data
                        </h3>
                        <p className="text-sm text-gray-500 mb-4 text-left">
                          Contains all documents from your{" "}
                          {vectorDBConfiguration?.type?.replace("Manager", "")}{" "}
                          connector (
                          {vectorDBConfiguration?.type === "S3DataSourceManager"
                            ? vectorDBConfiguration?.bucketName
                            : vectorDBConfiguration?.collection_name ||
                              vectorDBConfiguration?.collection}
                          ). These are automatically created and synced with
                          your databases.
                        </p>
                      </div>
                      <div className="space-y-2">
                        <button
                          onClick={() =>
                            handleDataSliceSelect({
                              id: "all_data",
                              name: "Deasy_Data_All",
                              vector_db_config: vectorDBConfiguration,
                            })
                          }
                          className={`w-full p-4 text-left border rounded-lg transition-colors ${
                            selectedDataSlice?.id === "all_data"
                              ? "border-primary bg-primary/5"
                              : "border-gray-200 hover:bg-gray-50"
                          }`}
                        >
                          <div className="flex items-center justify-between">
                            <div>
                              <h3 className="font-medium text-gray-900">
                                All data from{" "}
                                {vectorDBConfiguration?.type?.replace(
                                  "Manager",
                                  "",
                                )}{" "}
                                {vectorDBConfiguration?.type ===
                                "S3DataSourceManager"
                                  ? vectorDBConfiguration?.bucketName
                                  : vectorDBConfiguration?.collection_name ||
                                    vectorDBConfiguration?.collection}
                              </h3>
                              <p className="text-sm text-gray-500 mt-1">
                                Contains all documents from your{" "}
                                {vectorDBConfiguration?.type ===
                                "S3DataSourceManager"
                                  ? "S3 bucket"
                                  : "vector database"}
                              </p>
                            </div>
                            {selectedDataSlice?.id === "all_data" && (
                              <svg
                                className="w-5 h-5 text-primary"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            )}
                          </div>
                        </button>
                      </div>
                    </div>

                    {/* Right side - Custom Data Slices */}
                    <div className="w-1/2 space-y-4">
                      <div>
                        <h3 className="text-sm font-medium text-gray-700 mb-2 text-left">
                          Data Slices
                        </h3>
                        <p className="text-sm text-gray-500 mb-4 text-left">
                          Data slices that you've created with specific filters
                          and conditions from{" "}
                          {vectorDBConfiguration?.type?.replace("Manager", "")}{" "}
                          (
                          {vectorDBConfiguration?.collection_name ||
                            vectorDBConfiguration?.collection}
                          ). These contain subsets of your data based on your
                          criteria.
                        </p>
                        <input
                          type="text"
                          placeholder="Search data slices..."
                          className="w-full px-3 py-2 border border-gray-200 rounded-lg mb-3"
                          value={customSliceSearch}
                          onChange={(e) => setCustomSliceSearch(e.target.value)}
                        />
                      </div>
                      <div className="space-y-2">
                        {dataSlices
                          .filter(
                            (slice) => !slice.name.startsWith("Deasy_Data_"),
                          )
                          .filter((slice) =>
                            slice.name
                              .toLowerCase()
                              .includes(customSliceSearch.toLowerCase()),
                          )
                          .map((slice) => (
                            <button
                              key={slice.id}
                              onClick={() => handleDataSliceSelect(slice)}
                              className={`w-full p-4 text-left border rounded-lg transition-colors ${
                                selectedDataSlice?.id === slice.id
                                  ? "border-primary bg-primary/5"
                                  : "border-gray-200 hover:bg-gray-50"
                              }`}
                            >
                              <div className="flex items-center justify-between">
                                <div>
                                  <h3 className="font-medium text-gray-900">
                                    {slice.name}
                                  </h3>
                                  {slice.description && (
                                    <p className="text-sm text-gray-500 mt-1">
                                      {slice.description}
                                    </p>
                                  )}
                                </div>
                                {selectedDataSlice?.id === slice.id && (
                                  <svg
                                    className="w-5 h-5 text-primary"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M5 13l4 4L19 7"
                                    />
                                  </svg>
                                )}
                              </div>
                            </button>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {!dataSliceSpecific && (
            <DataSliceOverview
              isOverviewOpen={expandedPanels.includes("overview")}
              setIsOverviewOpen={(isOpen) => {
                if (isOpen) {
                  setExpandedPanels((current) => [...current, "overview"]);
                } else {
                  setExpandedPanels((current) =>
                    current.filter((p) => p !== "overview"),
                  );
                }
              }}
              dataslice_id={
                hasSelectedCustomSlice ? selectedDataSlice?.id : null
              }
              dataslice_name={
                hasSelectedCustomSlice ? selectedDataSlice?.name : null
              }
              dataslice_conditions_new={
                hasSelectedCustomSlice ? selectedDataSlice?.conditionsNew : null
              }
              dataslice_conditions_old={
                hasSelectedCustomSlice ? selectedDataSlice?.conditions : null
              }
              metricsData={metricsData}
              isLoadingMetrics={isLoadingMetrics}
              compact={false}
              isExportScreen={true}
              vector_db_config={
                dataSliceSpecific || hasSelectedCustomSlice
                  ? null
                  : prepareVectorDBConfiguration(vectorDBConfiguration)
              }
            />
          )}

          {/* Select Metadata Section */}
          <div
            className={`bg-white rounded-xl shadow-sm border ${!selectedDataSlice ? "border-gray-100 opacity-75" : "border-gray-200"}`}
          >
            <button
              onClick={() => selectedDataSlice && togglePanel("select")}
              className={`w-full px-6 py-4 text-left font-medium flex items-center justify-between ${
                selectedDataSlice
                  ? "hover:bg-gray-50 cursor-pointer"
                  : "cursor-not-allowed"
              } rounded-xl transition-colors duration-200`}
            >
              <div className="flex items-center gap-3">
                {dataSliceSpecific ? (
                  <h2 className="text-md font-semibold text-gray-800">
                    1. Select Metadata from{" "}
                  </h2>
                ) : (
                  <h2 className="text-md font-semibold text-gray-800">
                    3. Select Metadata from{" "}
                  </h2>
                )}
                <img
                  src={getLlmLogo("deasy_compute")}
                  className="h-8 w-8 object-contain rounded-lg"
                  alt="Deasy Shrimp"
                />
                <span>to export</span>
                {!selectedDataSlice && (
                  <div className="group relative">
                    <svg
                      className="w-5 h-8 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <div className="absolute left-0 bottom-full mb-2 w-48 p-2 bg-gray-800 text-white text-xs rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
                      Please select a data first
                    </div>
                  </div>
                )}
              </div>
              <span
                className="transform transition-transform duration-200 text-gray-400"
                style={{
                  transform: expandedPanels.includes("select")
                    ? "rotate(180deg)"
                    : "",
                }}
              >
                ▼
              </span>
            </button>
            {expandedPanels.includes("select") && selectedDataSlice && (
              <DataSliceMetadata
                metricsData={metricsData}
                tagDistribution={tagDistribution}
                syncScore={syncScore}
                isLoadingMetrics={isLoadingMetrics}
                isLoadingTagDistribution={isLoadingTagDistribution}
                isLoadingSyncScore={isLoadingSyncScore}
                onTagsSelected={setSelectedTags}
                recommendedTags={recommendedTags}
                isSuggesting={isSuggesting}
                onGenerateRecommendations={getExportRecommendations}
                setRecommendedTags={setRecommendedTags}
              />
            )}
          </div>

          {/* Preview Section */}
          <div
            className={`max-w-screen bg-white rounded-xl shadow-sm border ${!selectedDataSlice ? "border-gray-100 opacity-75" : "border-gray-200"}`}
          >
            <button
              onClick={() => selectedDataSlice && togglePanel("preview")}
              className={`w-full px-6 py-4 text-left font-medium flex items-center justify-between ${
                selectedDataSlice
                  ? "hover:bg-gray-50 cursor-pointer"
                  : "cursor-not-allowed"
              } rounded-xl transition-colors duration-200`}
            >
              <div className="flex items-center gap-3">
                {dataSliceSpecific ? (
                  <h2 className="text-md font-semibold text-gray-800">
                    2. Preview Selected Metadata
                  </h2>
                ) : (
                  <h2 className="text-md font-semibold text-gray-800">
                    4. Preview Selected Metadata
                  </h2>
                )}
                <div className="bg-white p-4 rounded-lg">
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-600">
                      {selectedTags.length > 0
                        ? `${selectedTags.length} columns selected`
                        : "No columns selected"}
                    </span>
                  </div>
                </div>
              </div>
              <span
                className="transform transition-transform duration-200 text-gray-400"
                style={{
                  transform: expandedPanels.includes("preview")
                    ? "rotate(180deg)"
                    : "",
                }}
              >
                ▼
              </span>
            </button>
            {expandedPanels.includes("preview") && selectedDataSlice && (
              <div className="p-6 border-t">
                {selectedTags.length > 0 ? (
                  <>
                    <MetadataTable
                      dataslice_id={
                        dataSliceSpecific
                          ? dataSliceId
                          : hasSelectedCustomSlice
                            ? selectedDataSlice?.id
                            : null
                      }
                      compact={false}
                      backendFilters={tableFilters}
                      onFiltersChange={setTableFilters}
                      conditionsNew={tableConditionsNew}
                      setConditionsNew={setTableConditionsNew}
                      selectedColumns={["filename", ...selectedTags]}
                      availableColumns={["filename", ...selectedTags]}
                      showAllColumns={false}
                    />
                  </>
                ) : (
                  <div className="text-center py-4 text-gray-500">
                    Please select metadata columns to preview the data
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Sync Status Graphs - Only show when Vector DB is selected and sync check is clicked */}
          {selectedExportFormat === "vdb" && showSyncGraphs && (
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="bg-white p-4 rounded-lg border border-gray-200">
                <div className="flex items-start gap-2 mb-4">
                  <h4 className="text-sm font-medium text-gray-800">
                    Metadata in{" "}
                    {vectorDBConfiguration?.type === "S3DataSourceManager" ||
                    vectorDBConfiguration?.type ===
                      "SharepointDataSourceManager"
                      ? `Deasy VDB replica for ${vectorDBConfiguration?.type?.replace("Manager", "")}`
                      : vectorDBConfiguration?.type?.replace("Manager", "")}
                  </h4>
                  <div className="group relative">
                    <svg
                      className="w-4 h-4 text-gray-400 cursor-help"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-64 p-2 bg-gray-800 text-white text-xs rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
                      <img
                        src={getVdbLogo(vectorDBConfiguration?.type)}
                        className="w-16 h-12 pb-2 pr-2 pt-2"
                        alt={vectorDBConfiguration?.type}
                      />
                      <span>
                        Tags currently stored in your{" "}
                        {vectorDBConfiguration?.type?.replace("Manager", "")}{" "}
                        collection.
                      </span>
                    </div>
                  </div>
                </div>
                <TagDistributionChart
                  distribution={filterDistributionByTags(tagDistribution)}
                  hideChunkFileOption={true}
                  isLoading={isLoadingTagDistribution}
                  selectedTags={selectedTags}
                />
              </div>

              <div className="bg-white p-4 rounded-lg border border-gray-200">
                <div className="flex items-center gap-2 mb-4">
                  <h4 className="text-sm font-medium text-gray-800">
                    Unsynced Metadata between Deasy and{" "}
                    {vectorDBConfiguration?.type === "S3DataSourceManager" ||
                    vectorDBConfiguration?.type ===
                      "SharepointDataSourceManager"
                      ? `Deasy VDB replica for ${vectorDBConfiguration?.type?.replace("Manager", "")}`
                      : vectorDBConfiguration?.type?.replace("Manager", "")}
                  </h4>
                  <div className="group relative">
                    <svg
                      className="w-4 h-4 text-gray-400 cursor-help"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-64 p-2 bg-gray-800 text-white text-xs rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
                      Shows how many metadata tags from Deasy are not
                      synchronized with your{" "}
                      {vectorDBConfiguration?.type?.replace("Manager", "")}
                    </div>
                  </div>
                </div>
                <TagDistributionChart
                  distribution={filterDistributionByTags(
                    syncScore?.tag_counters,
                  )}
                  isSyncChart={true}
                  isLoading={isLoadingSyncScore}
                  selectedTags={selectedTags}
                />
              </div>
            </div>
          )}

          {/* Export Options Section */}
          <div
            className={`bg-white rounded-xl shadow-sm border ${!selectedDataSlice ? "border-gray-100 opacity-75" : "border-gray-200"}`}
          >
            <button
              onClick={() => selectedDataSlice && togglePanel("use")}
              className={`w-full px-6 py-4 text-left font-medium flex items-center justify-between ${
                selectedDataSlice
                  ? "hover:bg-gray-50 cursor-pointer"
                  : "cursor-not-allowed"
              } rounded-xl transition-colors duration-200`}
            >
              <div className="flex items-center gap-3">
                {dataSliceSpecific ? (
                  <h2 className="text-md font-semibold text-gray-800">
                    3. Export Metadata
                  </h2>
                ) : (
                  <h2 className="text-md font-semibold text-gray-800">
                    5. Export Metadata
                  </h2>
                )}
                <div className="bg-white rounded-lg">
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-600">
                      {selectedTags.length > 0
                        ? `${selectedTags.length} tags selected`
                        : "All tags will be exported"}
                    </span>
                  </div>
                </div>
                {!selectedDataSlice && (
                  <div className="group relative">
                    <svg
                      className="w-5 h-5 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <div className="absolute left-0 bottom-full mb-2 w-48 p-2 bg-gray-800 text-white text-xs rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
                      Please select a data first
                    </div>
                  </div>
                )}
              </div>
              <span
                className="transform transition-transform duration-200 text-gray-400"
                style={{
                  transform: expandedPanels.includes("use")
                    ? "rotate(180deg)"
                    : "",
                }}
              >
                ▼
              </span>
            </button>
            {expandedPanels.includes("use") && (
              <div className="border-t p-6">
                <div className="mb-6">
                  <div className="text-left">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Export Type
                    </label>
                    <div className="flex gap-4">
                      <button
                        onClick={() => {
                          setExportType("file");
                          setSelectedExportFormat("csv");
                          setSelectedFileFormat("csv");
                        }}
                        className={`px-4 py-2 rounded-lg ${
                          exportType === "file"
                            ? "bg-primary text-white"
                            : "bg-white border border-gray-300 text-gray-700 hover:bg-gray-50"
                        } transition-colors duration-200`}
                      >
                        File
                      </button>
                      <button
                        onClick={() => setExportType("destination")}
                        className={`px-4 py-2 rounded-lg ${
                          exportType === "destination"
                            ? "bg-primary text-white"
                            : "bg-white border border-gray-300 text-gray-700 hover:bg-gray-50"
                        } transition-colors duration-200`}
                      >
                        Destination
                      </button>
                    </div>
                  </div>
                </div>

                <div className="space-y-6">
                  <div className="space-y-4">
                    {/* File Format Options - Show when "File" is selected */}
                    {exportType === "file" && (
                      <div className="text-left">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          File Format
                        </label>
                        <div className="flex gap-4">
                          <button
                            onClick={() => {
                              setSelectedFileFormat("csv");
                              setSelectedExportFormat("csv");
                            }}
                            className={`px-4 py-2 rounded-lg ${
                              selectedFileFormat === "csv"
                                ? "bg-primary text-white"
                                : "bg-white border border-gray-300 text-gray-700 hover:bg-gray-50"
                            } transition-colors duration-200`}
                          >
                            CSV
                          </button>
                          <button
                            onClick={() => {
                              setSelectedFileFormat("json");
                              setSelectedExportFormat("json");
                            }}
                            className={`px-4 py-2 rounded-lg ${
                              selectedFileFormat === "json"
                                ? "bg-primary text-white"
                                : "bg-white border border-gray-300 text-gray-700 hover:bg-gray-50"
                            } transition-colors duration-200`}
                          >
                            JSON
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Destination Options - Show when "Destination" is selected */}
                    {exportType === "destination" && (
                      <div className="text-left">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Select Destination
                        </label>
                        <div className="grid grid-cols-1 gap-4 mb-4">
                          {/* Current Data Source Option */}
                          {/* {vectorDBConfiguration?.type !==
                            "S3DataSourceManager" &&
                            vectorDBConfiguration?.type !==
                              "SharepointDataSourceManager" && ( */}
                          <button
                            onClick={() => {
                              setSelectedDestination("vdb");
                              setSelectedExportFormat("vdb");
                            }}
                            className={`px-4 py-3 text-left border rounded-lg flex items-center ${
                              selectedDestination === "vdb"
                                ? "border-primary bg-primary/5"
                                : "border-gray-200 hover:bg-gray-50"
                            }`}
                          >
                            <div className="flex-1">
                              <div className="flex items-center gap-2">
                                <img
                                  src={
                                    vectorDBConfiguration?.type ===
                                      "S3DataSourceManager" ||
                                    vectorDBConfiguration?.type ===
                                      "SharepointDataSourceManager"
                                      ? getLlmLogo("deasy_compute")
                                      : getVdbLogo(vectorDBConfiguration?.type)
                                  }
                                  alt={vectorDBConfiguration?.type}
                                  className="w-6 h-6"
                                />
                                {vectorDBConfiguration?.type ===
                                  "S3DataSourceManager" ||
                                vectorDBConfiguration?.type ===
                                  "SharepointDataSourceManager" ? (
                                  <>
                                    <span className="font-medium">
                                      Deasy VDB replica for{" "}
                                      {vectorDBConfiguration?.type?.replace(
                                        "Manager",
                                        "",
                                      )}
                                    </span>
                                    <div className="group relative">
                                      <svg
                                        className="w-4 h-4 text-gray-400 cursor-help"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                      </svg>
                                      <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-64 p-2 bg-gray-800 text-white text-xs rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60]">
                                        This replica database is linked to your
                                        data source. If the data source is
                                        deleted, the replica and all metadata
                                        will be automatically deleted as well.
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <span className="font-medium">
                                    Current Data Source (
                                    {vectorDBConfiguration?.type?.replace(
                                      "Manager",
                                      "",
                                    )}
                                    )
                                  </span>
                                )}
                              </div>

                              <p className="text-sm text-gray-500 mt-1">
                                {vectorDBConfiguration?.type ===
                                  "S3DataSourceManager" ||
                                vectorDBConfiguration?.type ===
                                  "SharepointDataSourceManager"
                                  ? "Synchronize metadata with a Deasy-managed vdb for use in Deasy Select"
                                  : "Synchronize metadata with your current vector database"}
                              </p>
                            </div>
                            {selectedDestination === "vdb" && (
                              <svg
                                className="w-5 h-5 text-primary"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            )}
                          </button>
                          {/* Other Destination Options */}
                          {Object.entries(destinationProfiles).map(
                            ([name, config]) => (
                              <button
                                key={name}
                                onClick={() => setSelectedDestination(name)}
                                className={`px-4 py-3 text-left border rounded-lg flex items-center ${
                                  selectedDestination === name
                                    ? "border-primary bg-primary/5"
                                    : "border-gray-200 hover:bg-gray-50"
                                }`}
                              >
                                <div className="flex-1">
                                  <div className="flex items-center gap-2">
                                    <img
                                      src={getDestinationLogo(config.type)}
                                      alt={config.type}
                                      className="w-6 h-6"
                                    />
                                    <span className="font-medium">{name}</span>
                                  </div>
                                  <p className="text-sm text-gray-500 mt-1">
                                    {config.type?.replace("Manager", "")}
                                    {config.bucketName
                                      ? ` - ${config.bucketName}`
                                      : ""}
                                    {config.collection
                                      ? ` - ${config.collection}`
                                      : ""}
                                  </p>
                                </div>
                                {selectedDestination === name && (
                                  <svg
                                    className="w-5 h-5 text-primary"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M5 13l4 4L19 7"
                                    />
                                  </svg>
                                )}
                              </button>
                            ),
                          )}
                        </div>

                        {/* Postgres Export Options */}
                        {selectedDestination &&
                          destinationProfiles[
                            selectedDestination
                          ]?.type?.includes("Postgres") && (
                            <div className="mt-4">
                              <label className="block text-sm font-medium text-gray-700 mb-2">
                                Export Content
                              </label>
                              <div className="flex gap-4">
                                <button
                                  onClick={() => setExportContent("metadata")}
                                  className={`px-4 py-2 rounded-lg ${
                                    exportContent === "metadata"
                                      ? "bg-primary text-white"
                                      : "bg-white border border-gray-300 text-gray-700 hover:bg-gray-50"
                                  } transition-colors duration-200`}
                                >
                                  Metadata Only
                                </button>
                                <button
                                  onClick={() => setExportContent("both")}
                                  className={`px-4 py-2 rounded-lg ${
                                    exportContent === "both"
                                      ? "bg-primary text-white"
                                      : "bg-white border border-gray-300 text-gray-700 hover:bg-gray-50"
                                  } transition-colors duration-200`}
                                >
                                  Both Nodes & Metadata
                                </button>
                                <button
                                  onClick={() => setExportContent("nodes")}
                                  className={`px-4 py-2 rounded-lg ${
                                    exportContent === "nodes"
                                      ? "bg-primary text-white"
                                      : "bg-white border border-gray-300 text-gray-700 hover:bg-gray-50"
                                  } transition-colors duration-200`}
                                >
                                  Nodes Only
                                </button>
                              </div>
                            </div>
                          )}

                        {/* Warning for tag limit on destinations */}
                        {selectedDestination !== "vdb" &&
                          selectedDestination !== null &&
                          destinationProfiles[
                            selectedDestination
                          ]?.type?.includes("Sharepoint") &&
                          (selectedTags.length > 10 ||
                            (selectedTags.length === 0 &&
                              Object.keys(
                                metricsData?.metadata_tag_counts_distribution ||
                                  {},
                              ).filter(
                                (tag) =>
                                  !tag.startsWith("_") &&
                                  !tag.startsWith("system/"),
                              ).length > 10)) && (
                            <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-3 rounded-md text-sm mb-4">
                              <div className="flex items-start">
                                <svg
                                  className="w-5 h-5 mr-2 mt-0.5"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                                <div>
                                  <p className="font-medium">
                                    Export limitation
                                  </p>
                                  <p>
                                    Due to third-party limitations, you cannot
                                    export more than 10 tags at once to external
                                    destinations. Please select 10 or fewer
                                    tags.
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    )}

                    {/* Data Level Selection - Only show when exporting metadata */}
                    {(exportType === "file" ||
                      (exportType === "destination" &&
                        (exportContent === "metadata" ||
                          exportContent === "both") &&
                        destinationProfiles[
                          selectedDestination
                        ]?.type?.includes("Postgres")) ||
                      selectedDestination === "vdb") && (
                      <div className="text-left">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Data Level
                        </label>
                        <div className="relative">
                          <select
                            className={`w-[15vw] px-4 py-2 border rounded-lg ${
                              !selectedDataSlice
                                ? "bg-gray-50 cursor-not-allowed"
                                : "focus:ring-2 focus:ring-primary focus:border-primary"
                            }`}
                            value={metadataLevel}
                            onChange={(e) => setMetadataLevel(e.target.value)}
                            disabled={!selectedDataSlice}
                          >
                            {!destinationProfiles[
                              selectedDestination
                            ]?.type?.includes("Sharepoint") &&
                              exportType !== "file" && (
                                <option value="both">
                                  Both File & Chunk Level
                                </option>
                              )}
                            {(destinationProfiles[
                              selectedDestination
                            ]?.type?.includes("Sharepoint") ||
                              selectedDestination === "vdb" ||
                              exportType === "file") && (
                              <option value="file">File Level Only</option>
                            )}
                            {(destinationProfiles[
                              selectedDestination
                            ]?.type?.includes("Postgres") ||
                              selectedDestination === "vdb" ||
                              exportType === "file") && (
                              <option value="chunk">Chunk Level Only</option>
                            )}
                          </select>
                        </div>
                      </div>
                    )}

                    {/* Sync Status - Only for VDB Destination */}
                    {exportType === "destination" &&
                      selectedDestination === "vdb" && (
                        <div className="text-left">
                          <label className="block text-sm font-medium text-gray-700 mb-2">
                            Sync Status
                          </label>
                          <button
                            onClick={handleCheckSync}
                            className="px-4 py-2 bg-gray-100 text-gray-800 rounded-lg hover:bg-gray-200 transition-colors duration-200 flex items-center gap-2"
                            disabled={
                              isLoadingSyncScore || isLoadingTagDistribution
                            }
                          >
                            {isLoadingSyncScore || isLoadingTagDistribution ? (
                              <>
                                <svg
                                  className="animate-spin h-5 w-5 text-gray-400"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                                Checking...
                              </>
                            ) : (
                              <>
                                <svg
                                  className="w-5 h-5"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                  />
                                </svg>
                                Check Sync Status
                              </>
                            )}
                          </button>
                        </div>
                      )}
                  </div>
                </div>
                <div
                  className={`py-4 flex ${
                    selectedDestination &&
                    exportType === "destination" &&
                    selectedDestination !== "vdb" &&
                    destinationProfiles[selectedDestination]?.type?.includes(
                      "Sharepoint",
                    )
                      ? "justify-between"
                      : "justify-end"
                  }`}
                >
                  {exportType === "destination" &&
                    selectedDestination &&
                    selectedDestination !== "vdb" &&
                    destinationProfiles[selectedDestination]?.type?.includes(
                      "Sharepoint",
                    ) && (
                      <button
                        onClick={handleDeleteDestinationMetadata}
                        className="px-4 py-2 bg-white border border-red-400 text-red-600 rounded-lg hover:bg-red-50 transition-colors duration-200 flex items-center gap-2"
                        disabled={isExporting}
                      >
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                        Delete Selected Metadata from Destination
                      </button>
                    )}

                  <button
                    onClick={() => {
                      if (exportType === "file") {
                        handleExportClick(selectedFileFormat);
                      } else if (exportType === "destination") {
                        handleExportClick(selectedDestination);
                      }
                    }}
                    disabled={
                      isExporting ||
                      !selectedDataSlice ||
                      (exportType === "destination" &&
                        destinationProfiles[
                          selectedDestination
                        ]?.type?.includes("Sharepoint") &&
                        (selectedTags.length > 10 ||
                          (selectedTags.length === 0 &&
                            Object.keys(
                              metricsData?.metadata_tag_counts_distribution ||
                                {},
                            ).filter(
                              (tag) =>
                                !tag.startsWith("_") &&
                                !tag.startsWith("system/"),
                            ).length > 10)))
                    }
                    className={`px-6 py-2 rounded-lg ${
                      isExporting ||
                      !selectedDataSlice ||
                      (exportType === "destination" &&
                        destinationProfiles[
                          selectedDestination
                        ]?.type?.includes("Sharepoint") &&
                        (selectedTags.length > 10 ||
                          (selectedTags.length === 0 &&
                            Object.keys(
                              metricsData?.metadata_tag_counts_distribution ||
                                {},
                            ).filter(
                              (tag) =>
                                !tag.startsWith("_") &&
                                !tag.startsWith("system/"),
                            ).length > 10)))
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-primary text-white hover:bg-primary-dark"
                    } transition-colors duration-200 flex items-center gap-2`}
                  >
                    {isExporting
                      ? "Exporting..."
                      : `Export ${exportContent === "nodes" ? "Nodes" : exportContent === "metadata" ? "Metadata" : exportContent === "both" ? "Nodes & Metadata" : "Metadata"}`}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteConfirmationModal />
      <ConfirmationModal />
    </div>
  );
};

export default ExportMetadataScreen;
