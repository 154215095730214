import React from "react";
import { highlightText, getHighlightTerms } from "../metadataTableUtils";

const EvidenceRow = ({
  chunk,
  chunkId,
  activeEvidence,
  activeFullText,
  evidenceRefs,
  toggleChunkText,
  TABLE_COLUMN_WIDTHS,
}) => {
  if (
    !chunk?.evidence ||
    !activeEvidence ||
    activeEvidence.chunkId !== chunkId
  ) {
    return null;
  }

  const { tagName, value } = activeEvidence;
  const evidenceData = chunk.evidence[tagName]?.[value]?.[chunkId];

  if (!evidenceData) {
    return null;
  }

  const evidenceKey = `${chunkId}-${tagName}`;

  return (
    <div
      ref={(el) => {
        if (el) {
          evidenceRefs.set(evidenceKey, el);
        } else {
          evidenceRefs.delete(evidenceKey);
        }
      }}
      className="absolute left-0 bg-white shadow-lg border border-gray-200 rounded-lg mt-2 z-10"
      style={{
        minWidth: TABLE_COLUMN_WIDTHS.default,
        width:
          activeFullText === chunkId ? "600px" : TABLE_COLUMN_WIDTHS.default,
        transition: "all 0.3s ease-in-out",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="p-3 text-xs space-y-3">
        <div className="flex items-center justify-between">
          <div className="font-medium text-gray-700">
            Evidence for {tagName}
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              toggleChunkText(chunkId);
            }}
            className={`px-3 py-1 text-xs rounded-full border transition-colors ${
              activeFullText === chunkId
                ? "bg-primary text-white border-primary hover:bg-primary/90"
                : "bg-white text-gray-600 border-gray-200 hover:bg-gray-50"
            }`}
          >
            {activeFullText === chunkId ? "Hide" : "View"} Full Text
          </button>
        </div>

        <div className="space-y-3">
          <div className="bg-yellow-50 p-3 rounded-lg border border-yellow-100">
            <div className="whitespace-pre-wrap text-gray-700 break-words">
              {evidenceData}
            </div>
          </div>

          {activeFullText === chunkId && chunk.fullText && (
            <div className="bg-white p-3 rounded-lg border border-gray-200">
              <div
                dangerouslySetInnerHTML={{
                  __html: highlightText(
                    chunk.fullText,
                    getHighlightTerms(
                      chunkId,
                      value,
                      chunk.evidence[tagName][value],
                    ),
                  ),
                }}
                className="whitespace-pre-wrap text-gray-700 break-words"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EvidenceRow;
