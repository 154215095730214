import React, { useState, useContext, useEffect } from "react";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import MetadataTable from "../../MetadataTable/MetadataTable";
import LoadingSpinner from "../../../../Utils/LoadingSpinner";
import { prepareVectorDBConfiguration } from "../../../../../../services/utils";
import { metadataService } from "../../../../../../services/api";
import { deasyFileKey } from "../../../../../../config/config";
import BlankMetadataScreen from "../../MetadataTable/BlankMetadataScreen";
import { GraphContext } from "../../../../../../contexts/GraphContext";

const ViewMetadataTab = () => {
  const { deasyUserConfig } = useContext(BaseContext);
  const { graphs, fetchGraphs } = useContext(GraphContext);
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);
  const [tableFilters, setTableFilters] = useState({});
  const [tableConditionsNew, setTableConditionsNew] = useState(null);
  // Check if we've already done this initial fetch
  const [hasMetadata, setHasMetadata] = useState(false);

  // Fetch graphs when component mounts
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (deasyUserConfig.deasyApiKey && !graphs.length) {
      fetchGraphs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deasyUserConfig.deasyApiKey]);

  useEffect(() => {
    const fetchMetadata = async () => {
      setLoading(true);
      if (
        !deasyUserConfig.vdbmConfig?.Configs?.[
          deasyUserConfig.vdbmConfig?.LastActive
        ] ||
        !deasyUserConfig.deasyApiKey
      ) {
        setLoading(false);
        return;
      }
      const vdbConfig = prepareVectorDBConfiguration(
        deasyUserConfig.vdbmConfig?.Configs?.[
          deasyUserConfig.vdbmConfig?.LastActive
        ],
      );
      // We just want to see if they have any metadata to display
      const response = await metadataService.getFilteredMetadata(
        vdbConfig, // vector db config
        0, // offset
        1, // limit
        deasyUserConfig.deasyApiKey, // deasy_api_key
        null, // dataslice_id
        [], // node_conditions
      );
      const transformedData = Object.entries(response.data.metadata).map(
        ([fileName, metadata]) => ({
          [deasyFileKey]: fileName,
          ...metadata,
          chunks: [],
        }),
      );
      if (transformedData.length > 0) {
        setHasMetadata(true);
      }
      setLoading(false);
    };
    fetchMetadata();
  }, [deasyUserConfig]);

  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-center">
          <div className="text-red-500 mb-2">Error: {error}</div>
          <button
            onClick={() => window.location.reload()}
            className="text-primary hover:text-primary/80"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col -mx-6 -my-5">
      {loading ? (
        <div className="flex-1 flex items-center justify-center">
          <LoadingSpinner text="Loading metadata..." />
        </div>
      ) : hasMetadata ? (
        <div className="flex h-full px-6 py-5 w-full">
          <MetadataTable
            backendFilters={tableFilters}
            onFiltersChange={setTableFilters}
            conditionsNew={tableConditionsNew}
            setConditionsNew={setTableConditionsNew}
          />
        </div>
      ) : (
        <BlankMetadataScreen />
      )}
    </div>
  );
};

export default ViewMetadataTab;
