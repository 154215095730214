import React, { useContext } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import { Select, MenuItem, CircularProgress } from "@mui/material";
import { sourceMetadataService } from "../../../services/api";
import { prepareVectorDBConfiguration } from "../../../services/utils";
import { auth } from "../../../config/firebase";
import { DB_OPTIONS } from "../DeasyConfig/ConfigElements/utils";
import { getLlmLogo, getVdbLogo } from "../../../config/config";
import { GraphContext } from "../../../contexts/GraphContext";
import { connectorService } from "../../../services/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getLocationStateFromPath,
  getPathFromMainItem,
  getPathFromWorkflowType,
} from "../../../components/Navigation/NavUtils";
import { WorkflowType, MainItemType } from "../../../models/WorkflowModel";

export const WorkflowContextBanner = () => {
  const {
    deasyUserConfig,
    setDeasyUserConfig,
    setVdbFilesCount,
    setLoadingVdbFilesCount,
    isConfigRefreshing,
    setIsConfigRefreshing,
  } = useContext(BaseContext);

  const { refreshHierarchyStats } = useContext(GraphContext);

  const {
    vdbmConfig: { Configs: vdbmConfigs, LastActive: vdbmLastActive },
    llmConfig: { Configs: llmConfigs, LastActive: llmLastActive },
  } = deasyUserConfig;

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dataSourceName = params.dataSourceName || "";

  const { page, workflowType } =
    location.state || getLocationStateFromPath(location.pathname);

  const fetchTotalVdbFiles = async (vectorDBConfiguration) => {
    try {
      setLoadingVdbFilesCount(true);

      // Validate configuration first
      const dbOption = DB_OPTIONS.find(
        (opt) => opt.value === vectorDBConfiguration?.type,
      );
      const hasRequiredFields = dbOption?.fields
        .filter((field) => !field.toLowerCase().includes("key"))
        .every((field) => vectorDBConfiguration?.[field]);

      if (!dbOption || !hasRequiredFields) {
        return;
      }

      const response = await sourceMetadataService.getVdbTotalFiles(
        prepareVectorDBConfiguration({
          ...vectorDBConfiguration,
          user: auth.currentUser.email,
        }),
        deasyUserConfig.deasyApiKey,
      );

      setVdbFilesCount({
        total_files: response.data.total_files,
        profile: vdbmLastActive,
      });
    } catch (error) {
      console.error("Error fetching total VDB files:", error);
    } finally {
      setLoadingVdbFilesCount(false);
    }
  };

  // Handle config changes
  const handleConfigChange = async (type, value) => {
    if (type === "vdbmConfig" && dataSourceName && dataSourceName !== "") {
      if (
        workflowType !== null &&
        workflowType !== WorkflowType.CONNECT_DATA &&
        page === MainItemType.WORKFLOWS
      ) {
        // we know we're on a workflow page that requires a data source
        const newLocation = getPathFromWorkflowType(workflowType, value);
        navigate(newLocation, {
          state: {
            page,
            workflowType,
          },
        });
        // we are not within a workflow page
      } else if (workflowType == null) {
        if (page === MainItemType.METADATA_STUDIO) {
          const newLocation = getPathFromMainItem(page, value);
          navigate(newLocation, {
            state: {
              page,
              workflowType,
            },
          });
        }
      }
    }
    setIsConfigRefreshing(true);

    const newConfig = {
      ...deasyUserConfig,
      [type]: {
        ...deasyUserConfig[type],
        LastActive: value,
      },
    };
    setDeasyUserConfig(newConfig);
    await connectorService.setActiveProfile(
      value,
      type === "vdbmConfig" ? "vdb" : "llm",
      deasyUserConfig.deasyApiKey,
    );

    try {
      if (type === "vdbmConfig") {
        await fetchTotalVdbFiles(newConfig.vdbmConfig.Configs[value]);
      }
      await refreshHierarchyStats(deasyUserConfig.deasyApiKey);
    } catch (error) {
      console.error("Error refreshing after config change:", error);
    } finally {
      setIsConfigRefreshing(false);
    }
  };

  return (
    <div className={`relative items-start`}>
      {/* Expanded content */}
      <div className="overflow-hidden max-h-96 opacity-100 max-w-30">
        <div className="flex flex-col gap-4 w-full overflow-hidden">
          {/* Vector DB Configuration */}
          <div className="space-y-2 mt-2">
            <div className="flex items-center gap-2">
              <label className="text-gray-400 text-sm">
                Active Data Source
              </label>
            </div>
            <div className="relative flex justify-start">
              <Select
                value={vdbmLastActive}
                onChange={(e) =>
                  handleConfigChange("vdbmConfig", e.target.value)
                }
                autoFocus={false}
                size="small"
                fullWidth
                className="text-white border-gray-600"
                sx={{
                  width: "150px", // limit the overall width
                  "& .MuiSelect-select": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    paddingRight: "24px",
                    color: "#cbd5e0",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4b5563",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6b7280",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#9ca3af",
                  },
                  // Override disabled border styles:
                  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4b5563",
                  },
                  // Override disabled text color so it remains unchanged:
                  "& .MuiInputBase-input.Mui-disabled": {
                    color: "#1a1f2b",
                    WebkitTextFillColor: "#1a1f2b",
                    opacity: 1,
                  },
                  // Optionally, override the disabled icon color:
                  "&.Mui-disabled .MuiSvgIcon-root": {
                    color: "#9ca3af",
                    opacity: 1,
                  },
                  "&.Mui-focusVisible": {
                    // Add focus highlight
                    backgroundColor: "#fffff",
                  },
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      backgroundColor: "#1a1f2b",
                      border: "1px solid #4b5563",
                      color: "#cbd5e0",
                    },
                  },
                }}
                disabled={isConfigRefreshing}
              >
                {Object.entries(vdbmConfigs)
                  .sort((a, b) => a[0].localeCompare(b[0]))
                  .map(([configName, config]) => (
                    <MenuItem
                      autoFocus={false}
                      key={configName}
                      value={configName}
                      sx={{
                        maxWidth: "100%", // limit the overall width
                        "& .MuiSelect-select": {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                        "&:hover": {
                          backgroundColor: "#374151",
                        },
                        "&.Mui-selected": {
                          color: "#34d399",
                        },
                        "&.Mui-focusVisible": {
                          // Add focus highlight
                          backgroundColor: "#374151",
                        },
                        color: "#9ca3af",
                      }}
                    >
                      <div className="flex items-center gap-2">
                        {isConfigRefreshing ? (
                          <CircularProgress
                            size={16}
                            className="text-primary"
                          />
                        ) : (
                          <img
                            src={getVdbLogo(config.type)}
                            alt={config.type}
                            className="w-4 h-4 opacity-80"
                          />
                        )}
                        <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                          {configName}
                        </div>
                      </div>
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>

          {/* LLM Configuration */}
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <label className="text-gray-400 text-sm">Active LLM</label>
            </div>
            <div className="relative flex justify-start">
              <Select
                value={llmLastActive}
                onChange={(e) =>
                  handleConfigChange("llmConfig", e.target.value)
                }
                size="small"
                maxHeight="200"
                className="bg-[#1a1f2b] text-gray-400 border-gray-600"
                sx={{
                  width: "150px", // limit the overall width
                  "& .MuiSelect-select": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    paddingRight: "24px",
                    color: "#cbd5e0",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4b5563",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6b7280",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#9ca3af",
                  },
                  // Override disabled border styles:
                  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4b5563",
                  },
                  // Override disabled text color so it remains unchanged:
                  "& .MuiInputBase-input.Mui-disabled": {
                    color: "#1a1f2b",
                    WebkitTextFillColor: "#1a1f2b",
                    opacity: 1,
                  },
                  // Optionally, override the disabled icon color:
                  "&.Mui-disabled .MuiSvgIcon-root": {
                    color: "#9ca3af",
                    opacity: 1,
                  },
                }}
                MenuProps={{
                  autoFocus: false,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      backgroundColor: "#1a1f2b",
                      border: "1px solid #4b5563",
                      color: "#cbd5e0",
                    },
                  },
                }}
                disabled={isConfigRefreshing}
              >
                {Object.entries(llmConfigs)
                  .sort((a, b) => a[0].localeCompare(b[0]))
                  .map(([configName, config]) => (
                    <MenuItem
                      key={configName}
                      value={configName}
                      sx={{
                        maxWidth: "100%", // limit the overall width
                        "& .MuiSelect-select": {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                        "&:hover": {
                          backgroundColor: "#374151",
                        },
                        "&.Mui-selected": {
                          color: "#34d399",
                          backgroundColor: "#374151",
                        },
                        color: "#9ca3af",
                      }}
                    >
                      <div className="flex items-center gap-2">
                        {isConfigRefreshing ? (
                          <CircularProgress
                            size={16}
                            className="text-primary"
                          />
                        ) : (
                          <img
                            src={getLlmLogo(config.llmType)}
                            alt={config.llmType}
                            className="w-4 h-4 opacity-80"
                          />
                        )}
                        <span>{configName}</span>
                      </div>
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
