import { useState } from "react";
import { createPortal } from "react-dom";

export const ValueSelectionModal = ({
  onClose,
  onSelect,
  onAddNew,
  availableValues = [],
  selectedTag,
}) => {
  const [newValue, setNewValue] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);

  const handleValueSelect = (value) => {
    // If value is already selected, remove it, otherwise add it
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((v) => v !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  const handleSelectAll = () => {
    if (selectedValues.length === availableValues.length) {
      // If all are selected, deselect all
      setSelectedValues([]);
    } else {
      // Otherwise select all
      setSelectedValues([...availableValues]);
    }
  };

  const handleAddSelected = () => {
    if (selectedValues.length > 0) {
      // Add all selected values at once
      onAddNew(selectedValues);
      onClose();
    } else if (newValue.trim()) {
      // If no values selected but there's a new value, add it
      onAddNew(newValue.trim());
      onClose();
    }
  };

  return createPortal(
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="text-lg font-semibold mb-4 max-w-full overflow-auto whitespace-nowrap rounded-md transition-all">
          Add Output Value for {selectedTag}
        </h3>

        {/* Existing Values */}
        <div className="mt-6 border rounded-lg overflow-hidden">
          <div className="flex justify-between items-center px-4 py-2 bg-gray-50 border-b">
            <h4 className="font-medium">
              <span className="font-medium">Existing Output Values for </span>
              <span className="font-medium text-primary">{selectedTag}</span>
            </h4>
            <button
              onClick={handleSelectAll}
              className="text-sm text-primary hover:text-primary/80 cursor-pointer"
            >
              {selectedValues.length === availableValues.length
                ? "Deselect All"
                : "Select All"}
            </button>
          </div>
          <div className="max-h-60 overflow-y-auto">
            {availableValues.map((value, index) => (
              <div
                key={index}
                className={`w-full px-4 py-2.5 text-left hover:bg-gray-50 text-gray-700 border-b last:border-b-0 transition-colors duration-150 flex items-center ${
                  selectedValues.includes(value) ? "bg-blue-50" : ""
                }`}
                onClick={() => handleValueSelect(value)}
              >
                <input
                  type="checkbox"
                  checked={selectedValues.includes(value)}
                  onChange={() => {}}
                  className="mr-3"
                />
                <span>{value}</span>
              </div>
            ))}
          </div>
        </div>
        {/* New Value Input */}
        <div className="mt-8">
          <input
            type="text"
            placeholder="Enter new value..."
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
          <div className="flex gap-2 flex-row justify-between mt-2">
            <button
              className="px-4 py-2 text-gray-600 hover:bg-gray-50 border border-gray-200 rounded-md w-full"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="w-full px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90"
              onClick={handleAddSelected}
            >
              {selectedValues.length > 0
                ? `Add ${selectedValues.length} Selected Value${selectedValues.length > 1 ? "s" : ""}`
                : newValue.trim()
                  ? `Add ${newValue}`
                  : "Add"}
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
};
