import { useContext } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";
import { FiPlay } from "react-icons/fi";
import { classificationService } from "../../../../services/api";
import { toast } from "react-hot-toast";
import { TSContext } from "../TSContext";
import * as Sentry from "@sentry/react";

const ActionBar = () => {
  const {
    selectedFiles,
    tagInEditor,
    formData,
    setTaggingStudioOutput,
    tsRunning,
    setTsRunning,
  } = useContext(TSContext);

  const { deasyUserConfig } = useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;

  const handleTestTagging = async () => {
    setTsRunning(true);
    setTaggingStudioOutput(null);

    if (!tagInEditor?.name || selectedFiles.length === 0) {
      alert("Please select a tag and at least one file to test");
      setTsRunning(false);
      return;
    }

    try {
      const result = await classificationService.classify(
        deasyUserConfig.vdbmConfig.LastActive, // vectorDBConfiguration
        deasyUserConfig.llmConfig.LastActive, // llmEndpointConfiguration
        selectedFiles, // filenames
        { [formData?.name]: formData }, // tags
        deasyApiKey, // deasyApiKey
        true, // soft_run
        true, // overwrite
        {}, // currentTree
        null, // jobId
        null, // dataSliceId
        true, // blockingCall
      );
      setTaggingStudioOutput(result.data.output.file_results);
      toast.success("Tagging test completed successfully", { duration: 3000 });
    } catch (error) {
      console.error("Error testing tag:", error);
      Sentry.captureException(error);
      toast.error("An error occurred while testing the tag", {
        duration: 3000,
      });
    } finally {
      setTsRunning(false);
    }
  };

  const isDisabled = !tagInEditor?.name || selectedFiles.length === 0;

  return (
    <div className="fixed bottom-6 right-6 z-30">
      <button
        className={`btn flex items-center gap-2 rounded-lg shadow-lg transition-all duration-200 ${
          tsRunning || isDisabled
            ? "bg-gray-100 text-gray-400 cursor-not-allowed"
            : "bg-primary hover:bg-primary-dark text-white font-medium"
        }`}
        onClick={handleTestTagging}
        disabled={tsRunning || isDisabled}
      >
        {tsRunning ? (
          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
        ) : (
          <FiPlay className="w-10 h-10" />
        )}
        <span className="text-xl">Test Tag</span>
      </button>
    </div>
  );
};

export default ActionBar;
