import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import LoadingScreen from "../Fallback/LoadingScreen";
import { useLocation, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

function PrivateRoute({ children }) {
  const { user, loading } = useAuth();
  const location = useLocation();
  if (loading) {
    return <LoadingScreen />;
  }

  if (!user) {
    Sentry.setUser(null);
    return <Navigate to="/login" state={{ from: location }} />;
  } else {
    Sentry.setUser({
      id: user.uid,
      email: user.email,
    });
    return children;
  }
}

export default PrivateRoute;
