import { useState, useEffect, useContext, useCallback } from "react";
import { progressTrackingService } from "../../../services/api";
import { BaseContext } from "../../../contexts/BaseContext";
import { LoadingScreen } from "../../utils/LoadingScreen";
import { ProgressTrackerRow } from "./ProgressTrackerRow";

// Mock data
export const ProgressTracking = ({ filterTaskType }) => {
  // Get deasyApiKey
  const { deasyUserConfig } = useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;

  // State
  const [progressTrackers, setProgressTrackers] = useState([]);
  const [selectedTrackers, setSelectedTrackers] = useState([]);
  const [pendingJobs, setPendingJobs] = useState([]);

  // Incorporate the externally passed filterTaskType by setting it as the default value.
  const [filters, setFilters] = useState({
    status: "",
    taskType: filterTaskType || "",
    timeRange: "all",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // All action loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // If filterTaskType prop changes, update the filters accordingly.
  useEffect(() => {
    if (filterTaskType) {
      setFilters((prev) => ({ ...prev, taskType: filterTaskType }));
    }
  }, [filterTaskType]);

  const processPendingJobs = useCallback((trackers) => {
    const currentPendingJobs = JSON.parse(
      localStorage.getItem("pendingProgressJobs") || "[]",
    );

    if (currentPendingJobs.length === 0) {
      return;
    }

    const progressTrackersArray = Object.values(trackers);

    const updatedPendingJobs = currentPendingJobs.filter((pendingJob) => {
      const exactMatch = progressTrackersArray.some(
        (tracker) =>
          tracker.tracker_id === pendingJob.tracker_id &&
          tracker.task_type === pendingJob.task_type,
      );

      if (exactMatch) {
        return false;
      }

      const sameIdDifferentType = progressTrackersArray.some(
        (tracker) =>
          tracker.tracker_id === pendingJob.tracker_id &&
          tracker.task_type !== pendingJob.task_type,
      );

      if (sameIdDifferentType) {
        return false;
      }

      const similarJob = progressTrackersArray.some((tracker) => {
        if (tracker.task_type !== pendingJob.task_type) return false;

        const pendingStart = new Date(pendingJob.start_time).getTime();
        const trackerStart = new Date(tracker.start_time).getTime();
        const timeDiff = Math.abs(pendingStart - trackerStart);

        const isMatch = timeDiff < 30 * 1000;
        return isMatch;
      });

      if (similarJob) {
        return false;
      }

      return true;
    });

    if (updatedPendingJobs.length !== currentPendingJobs.length) {
      localStorage.setItem(
        "pendingProgressJobs",
        JSON.stringify(updatedPendingJobs),
      );
      setPendingJobs(updatedPendingJobs);
    } else {
      console.log("NO CHANGES: All pending jobs still pending");
    }
  }, []); // Empty dependencies since it doesn't use any props or state

  // Handlers
  const handleDelete = async (trackerIds, e) => {
    if (e) e.stopPropagation();
    if (isDeleting) return;

    const itemCount = trackerIds.length;
    const confirmMessage =
      itemCount === 1
        ? "Are you sure you want to delete this tracker?"
        : `Are you sure you want to delete these ${itemCount} trackers?`;

    if (!window.confirm(confirmMessage)) {
      return;
    }

    setIsDeleting(true);
    try {
      await progressTrackingService.deleteProgressTrackers(
        trackerIds,
        deasyApiKey,
      );
      setSelectedTrackers([]);
      const progressTrackers =
        await progressTrackingService.getProgressTrackers(deasyApiKey);
      setProgressTrackers(progressTrackers.data.progress_trackers);
    } catch (error) {
      console.error("Failed to delete trackers:", error);
      alert("Failed to delete trackers. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedTrackers(filteredAndSortedTrackers.map((t) => t.tracker_id));
    } else {
      setSelectedTrackers([]);
    }
  };

  const handleCheckboxClick = (e, trackerId) => {
    e.stopPropagation();
    setSelectedTrackers((prev) => {
      if (prev.includes(trackerId)) {
        return prev.filter((id) => id !== trackerId);
      } else {
        return [...prev, trackerId];
      }
    });
  };

  const filterTrackers = (trackers) => {
    const combinedTrackers = [...Object.values(trackers), ...pendingJobs];

    return combinedTrackers.filter((tracker) => {
      if (tracker.is_pending) {
        const matchesTaskType =
          !filters.taskType || tracker.task_type === filters.taskType;
        const matchesStatus =
          !filters.status || filters.status === "in_progress";

        const matchesTimeRange = true;

        const shouldInclude =
          matchesTaskType && matchesStatus && matchesTimeRange;
        return shouldInclude;
      }
      const isStaleInProgress =
        tracker.status === "in_progress" &&
        Date.now() - new Date(tracker.start_time).getTime() >
          24 * 60 * 60 * 1000;

      const effectiveStatus = isStaleInProgress ? "failed" : tracker.status;

      const matchesStatus =
        !filters.status || effectiveStatus === filters.status;
      const matchesTaskType =
        !filters.taskType || tracker.task_type === filters.taskType;

      let matchesTimeRange = true;
      if (filters.timeRange !== "all") {
        const startTime = new Date(tracker.start_time).getTime();
        const now = Date.now();
        const dayInMs = 24 * 60 * 60 * 1000;

        switch (filters.timeRange) {
          case "24h":
            matchesTimeRange = now - startTime <= dayInMs;
            break;
          case "7d":
            matchesTimeRange = now - startTime <= 7 * dayInMs;
            break;
          case "30d":
            matchesTimeRange = now - startTime <= 30 * dayInMs;
            break;
          default:
            matchesTimeRange = true;
        }
      }

      const shouldInclude =
        matchesStatus && matchesTaskType && matchesTimeRange;
      if (tracker.is_pending && shouldInclude) {
        console.log(
          `[filterTrackers] Including pending tracker: ${tracker.tracker_id}`,
          {
            task_type: tracker.task_type,
            status: tracker.status,
          },
        );
      }
      return shouldInclude;
    });
  };

  const sortTrackers = (trackers) => {
    return trackers.sort((a, b) => {
      // First sort by status (in_progress first)
      if (a.status === "in_progress" && b.status !== "in_progress") return -1;
      if (b.status === "in_progress" && a.status !== "in_progress") return 1;

      // Then sort by start time (newest first)
      return new Date(b.start_time) - new Date(a.start_time);
    });
  };

  const paginateTrackers = (trackers) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return trackers.slice(startIndex, endIndex);
  };

  // Effects
  useEffect(() => {
    const fetchProgressTrackers = async () => {
      setIsLoading(true);
      try {
        if (!deasyApiKey) {
          setIsLoading(false);
          return;
        }

        const progressTrackers =
          await progressTrackingService.getProgressTrackers(deasyApiKey);
        setProgressTrackers(progressTrackers.data.progress_trackers);
      } catch (error) {
        console.error("Failed to fetch trackers:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProgressTrackers();
  }, [deasyApiKey]);

  // Get unique task types and statuses for filters
  const taskTypes = [
    ...new Set(Object.values(progressTrackers).map((t) => t.task_type)),
  ];
  const statuses = [
    ...new Set(Object.values(progressTrackers).map((t) => t.status)),
  ];

  const getNonPendingTrackers = (trackers) => {
    return trackers.filter((tracker) => !tracker.is_pending);
  };

  const filteredTrackers = filterTrackers(progressTrackers);
  const filteredAndSortedTrackers = sortTrackers(
    getNonPendingTrackers(filteredTrackers),
  );
  const paginatedTrackers = paginateTrackers(filteredAndSortedTrackers);

  const pendingFilteredTrackers = filteredTrackers.filter(
    (tracker) => tracker.is_pending,
  );

  const formatTaskType = (taskType) => {
    if (!taskType) return "";
    return taskType
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const PaginationControls = () => {
    if (totalPages <= 1) return null;

    return (
      <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
        <div className="flex items-center">
          <select
            className="mr-4 rounded-md border border-gray-300 p-1"
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={10}>10 per page</option>
            <option value={25}>25 per page</option>
            <option value={50}>50 per page</option>
          </select>
          <span className="text-sm text-gray-700">
            Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
            {Math.min(
              currentPage * itemsPerPage,
              filteredAndSortedTrackers.length,
            )}{" "}
            of {filteredAndSortedTrackers.length} results
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className="px-3 py-1 rounded-md border border-gray-300 bg-white text-sm disabled:opacity-50"
          >
            First
          </button>
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-3 py-1 rounded-md border border-gray-300 bg-white text-sm disabled:opacity-50"
          >
            Previous
          </button>
          <span className="px-4 py-1 text-sm">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className="px-3 py-1 rounded-md border border-gray-300 bg-white text-sm disabled:opacity-50"
          >
            Next
          </button>
          <button
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            className="px-3 py-1 rounded-md border border-gray-300 bg-white text-sm disabled:opacity-50"
          >
            Last
          </button>
        </div>
      </div>
    );
  };

  const handleRefresh = async () => {
    if (isRefreshing) return;
    setIsRefreshing(true);
    try {
      const progressTrackers =
        await progressTrackingService.getProgressTrackers(deasyApiKey);
      setProgressTrackers(progressTrackers.data.progress_trackers);
    } catch (error) {
      console.error("Failed to refresh trackers:", error);
      alert("Failed to refresh trackers. Please try again.");
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    const storedPendingJobs = JSON.parse(
      localStorage.getItem("pendingProgressJobs") || "[]",
    );

    // Clear stale pending jobs (older than 5 minutes)
    const now = Date.now();
    const filteredJobs = storedPendingJobs.filter((job) => {
      const jobTime = new Date(job.start_time).getTime();
      const isStale = now - jobTime > 5 * 60 * 1000; // 5 minutes
      if (isStale) {
        console.log(
          `Removing stale pending job ${job.tracker_id} created at ${job.start_time}`,
        );
      }
      return !isStale;
    });

    if (filteredJobs.length !== storedPendingJobs.length) {
      localStorage.setItem("pendingProgressJobs", JSON.stringify(filteredJobs));
    }

    setPendingJobs(filteredJobs);

    const intervalId = setInterval(() => {
      const shouldRefreshFromServer = Math.random() < 0.3;

      if (shouldRefreshFromServer) {
        progressTrackingService
          .getProgressTrackers(deasyApiKey)
          .then((response) => {
            const latestTrackers = response.data.progress_trackers;
            setProgressTrackers(latestTrackers);
            processPendingJobs(latestTrackers);
          })
          .catch((error) => {});
      } else {
        setProgressTrackers((currentTrackers) => {
          processPendingJobs(currentTrackers);
          return currentTrackers;
        });
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [deasyApiKey, processPendingJobs]);

  const totalPages = Math.ceil(filteredAndSortedTrackers.length / itemsPerPage);

  return (
    <div className="px-6 py-5">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Progress Tracking</h1>
        <div className="flex gap-2">
          <button
            onClick={handleRefresh}
            disabled={isRefreshing}
            className="px-3 py-1.5 rounded-md border border-gray-300 bg-white text-sm hover:bg-gray-50 flex items-center gap-2 disabled:opacity-50"
          >
            <svg
              className={`w-4 h-4 ${isRefreshing ? "animate-spin" : ""}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
            {isRefreshing ? "Refreshing..." : "Refresh"}
          </button>
        </div>
      </div>

      {isLoading ? (
        <LoadingScreen message="Loading progress trackers" />
      ) : (
        <>
          {/* Filters */}
          <div className="mb-6 flex gap-4 bg-white p-4 rounded-lg border border-gray-200">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Status
              </label>
              <select
                className="rounded-md border border-gray-300 p-2"
                value={filters.status}
                onChange={(e) =>
                  setFilters((prev) => ({ ...prev, status: e.target.value }))
                }
              >
                <option value="">All</option>
                {statuses.map((status) => (
                  <option key={status} value={status}>
                    {status.replace("_", " ")}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Task Type
              </label>
              <select
                className="rounded-md border border-gray-300 p-2"
                value={filters.taskType}
                onChange={(e) =>
                  setFilters((prev) => ({ ...prev, taskType: e.target.value }))
                }
                // Optionally disable this select if filterTaskType is passed in
                disabled={!!filterTaskType}
              >
                <option value="">All</option>
                {taskTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Time Range
              </label>
              <select
                className="rounded-md border border-gray-300 p-2"
                value={filters.timeRange}
                onChange={(e) =>
                  setFilters((prev) => ({ ...prev, timeRange: e.target.value }))
                }
              >
                <option value="all">All Time</option>
                <option value="24h">Last 24 Hours</option>
                <option value="7d">Last 7 Days</option>
                <option value="30d">Last 30 Days</option>
              </select>
            </div>
          </div>

          {/* Pending Jobs Section */}
          {pendingFilteredTrackers.length > 0 && (
            <div className="mb-6">
              <div className="bg-amber-50 border border-amber-200 rounded-lg p-4 shadow-sm">
                <h3 className="text-amber-800 font-medium text-sm mb-3 flex items-center">
                  <svg
                    className="animate-spin h-4 w-4 mr-2 text-amber-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Initializing Tasks
                </h3>

                <div className="grid gap-3">
                  {pendingFilteredTrackers.map((tracker) => (
                    <div
                      key={tracker.tracker_id}
                      className="bg-white border border-amber-100 rounded-md p-3 shadow-sm"
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                          <span className="font-semibold rounded-md px-2 py-1 bg-gray-100 shadow-sm border border-gray-200 text-sm">
                            {formatTaskType(tracker.task_type)}
                          </span>
                          <span className="text-sm text-gray-600">
                            {tracker.total_files} files
                          </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="text-amber-600 text-sm flex items-center">
                            <svg
                              className="animate-spin h-3 w-3 mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Initializing
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Add batch actions */}
          {selectedTrackers.length > 0 && (
            <div className="mb-4 flex items-center gap-2">
              <span className="text-sm text-gray-600">
                {selectedTrackers.length} items selected
              </span>
              <button
                onClick={async () => await handleDelete(selectedTrackers)}
                className="text-red-500 hover:text-red-700 bg-red-50 px-3 py-1.5 rounded-md text-sm"
              >
                Delete Selected
              </button>
            </div>
          )}

          <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="pl-6 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <input
                        type="checkbox"
                        className="rounded border-gray-300 text-primary focus:ring-primary"
                        checked={
                          selectedTrackers.length ===
                          filteredAndSortedTrackers.length
                        }
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th className="pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Task Type
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Progress
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Files
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Status
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {paginatedTrackers.map((tracker) => (
                    <ProgressTrackerRow
                      key={tracker.tracker_id}
                      tracker={tracker}
                      handleDelete={handleDelete}
                      handleCheckboxClick={handleCheckboxClick}
                      selectedTrackers={selectedTrackers}
                      setSelectedTrackers={setSelectedTrackers}
                      deasyApiKey={deasyApiKey}
                      setProgressTrackers={setProgressTrackers}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <PaginationControls />
          </div>

          {!progressTrackers ||
            (Object.keys(progressTrackers).length === 0 &&
              pendingFilteredTrackers.length === 0 && (
                <div className="text-center py-12">
                  <p className="text-gray-500 mb-4">
                    No available progress tracking data
                  </p>
                </div>
              ))}
        </>
      )}
    </div>
  );
};
