import { useState } from "react";
import {
  FormInput,
  LoadingSpinner,
  updateTokenServiceSecret,
} from "../ConfigUtils";
import { toast } from "react-hot-toast";
import { ProfileCard } from "./ProfileCard";
import { getDestinationLogo } from "../../../../config/config";
import {
  getDisplayType,
  DESTINATION_OPTIONS,
  SHAREPOINT_DEST,
  POSTGRESQL_DEST,
} from "./utils";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Modal } from "@mui/material";
import { tokenService } from "../../../../services/api";

// Add constants for defaults
const DEFAULT_SHAREPOINT_DOCUMENTS_LIBRARY = "Documents";
// Default schema field values for PostgreSQL
const DEFAULT_ID_KEY = "id";
const DEFAULT_FILENAME_KEY = "filename";
const DEFAULT_TEXT_KEY = "text";
const DEFAULT_TAGS_KEY = "tags";
const DEFAULT_DIMENSION_THRESHOLD = 384;

export const DestinationConfiguration = ({
  formState,
  setFormState,
  handleInputChange,
  handleSaveNewConfig,
  handleLoadConfig,
  deasyUserConfig,
  setDeasyUserConfig,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [editingProfile, setEditingProfile] = useState(null);
  const [profileName, setProfileName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const profiles = deasyUserConfig?.destinationConfig?.Configs || {};
  const activeProfile = deasyUserConfig?.destinationConfig?.LastActive;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleAddNew = () => {
    setEditingProfile(null);
    setProfileName("");
    setShowModal(true);

    // Reset form without defaulting to any type
    setFormState({
      type: "",
    });
  };

  const handleEdit = (profileName) => {
    const existingConfig = profiles[profileName];
    setEditingProfile(profileName);
    setProfileName(profileName);
    setShowModal(true);

    // Initialize form with existing config and appropriate defaults
    const updatedConfig = { ...existingConfig };

    // Apply type-specific defaults
    if (existingConfig.type === SHAREPOINT_DEST) {
      updatedConfig.documents_library_folder_name =
        existingConfig.documents_library_folder_name ||
        DEFAULT_SHAREPOINT_DOCUMENTS_LIBRARY;
    } else if (existingConfig.type === POSTGRESQL_DEST) {
      // Add schema field defaults for PostgreSQL
      updatedConfig.id_key = existingConfig.id_key;
      updatedConfig.create_new_collection = false;
      updatedConfig.filename_key = existingConfig.filename_key;
      updatedConfig.tags_key = existingConfig.tags_key;
      updatedConfig.text_key = existingConfig.text_key;
      updatedConfig.dimension_threshold = existingConfig.dimension_threshold;
    }

    setFormState(updatedConfig);
  };

  const handleConfigLoad = (name) => {
    handleLoadConfig("destination", name);

    const newConfig = {
      ...deasyUserConfig,
      destinationConfig: {
        ...deasyUserConfig.destinationConfig,
        LastActive: name,
        Configs: deasyUserConfig.destinationConfig.Configs,
      },
    };
    setDeasyUserConfig(newConfig);
    updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));
  };

  const validateAndSave = async () => {
    setIsValidating(true);
    setValidationError(null);

    // Validate profile name
    if (!profileName.trim()) {
      setValidationError({
        field: "profileName",
        message: "Profile name is required",
      });
      setIsValidating(false);
      return;
    }

    if (
      profiles[profileName.trim()] &&
      (!editingProfile || profileName.trim() !== editingProfile)
    ) {
      setValidationError({
        field: "profileName",
        message: "A profile with this name already exists",
      });
      setIsValidating(false);
      return;
    }

    // Validate type selection
    if (!formState.type) {
      setValidationError({
        field: "type",
        message: "Please select a destination type",
      });
      setIsValidating(false);
      return;
    }

    // Find destination config
    const destConfig = DESTINATION_OPTIONS.find(
      (opt) => opt.value === formState.type,
    );
    if (!destConfig) {
      setValidationError({
        field: "type",
        message: "Invalid destination type selected",
      });
      setIsValidating(false);
      return;
    }

    // Validate all required fields
    for (const field of destConfig.fields) {
      // Skip create_new_collection as it's a boolean
      if (field === "create_new_collection") continue;

      if (
        !formState[field] ||
        (typeof formState[field] === "string" && !formState[field].trim())
      ) {
        setValidationError({
          field,
          message: `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, " $1")} is required`,
        });
        setIsValidating(false);
        return;
      }
    }

    // Validate schema fields if applicable
    if (destConfig.schemaFields && destConfig.schemaFields.length > 0) {
      for (const field of destConfig.schemaFields) {
        if (
          !formState[field] ||
          (typeof formState[field] === "string" && !formState[field].trim())
        ) {
          setValidationError({
            field,
            message: `${field.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())} is required`,
          });
          setIsValidating(false);
          return;
        }
      }
    }

    // Validate the destination
    try {
      const response = await tokenService.validateDestination(formState);

      if (!response.data.success) {
        toast.error(response.data.message || "Validation failed");
        setIsValidating(false);
        return;
      }
    } catch (error) {
      toast.error("Failed to validate destination: " + error.message);
      setIsValidating(false);
      return;
    }

    try {
      // Create config object including both regular fields and schema fields
      const config = {
        type: formState.type,
        name: profileName.trim(),
        ...destConfig.fields.reduce(
          (acc, field) => ({
            ...acc,
            [field]:
              typeof formState[field] === "string"
                ? formState[field].trim()
                : formState[field],
          }),
          {},
        ),
        // Add schema fields if they exist
        ...(destConfig.schemaFields
          ? destConfig.schemaFields.reduce(
              (acc, field) => ({
                ...acc,
                [field]:
                  typeof formState[field] === "string"
                    ? formState[field].trim()
                    : formState[field],
              }),
              {},
            )
          : {}),
      };

      // Save the configuration
      await handleSaveNewConfig("destination", profileName.trim(), config);
      setShowModal(false);
      setEditingProfile(null);
      toast.success("Export destination saved successfully");
    } catch (error) {
      toast.error("Failed to save configuration: " + error.message);
    } finally {
      setIsValidating(false);
    }
  };

  const getButtonText = () => {
    if (isValidating) {
      return (
        <div className="flex items-center justify-center">
          <LoadingSpinner />
          Verifying credentials...
        </div>
      );
    }
    return editingProfile ? "Save Changes" : "Save New Profile";
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 h-full flex flex-col">
      <div className="pb-6">
        <div className="flex justify-between items-center mb-6 flex-1">
          <h2 className="text-xl font-semibold text-gray-700">
            Export Destination Profiles
          </h2>
          <div className="flex items-center gap-3">
            <button
              onClick={handleAddNew}
              className="p-2 bg-white text-primary rounded-full hover:bg-primary-dark shadow-md transition-all flex items-center justify-center"
            >
              <FaPlus size={12} />
            </button>
          </div>
        </div>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search destinations..."
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <div className="flex-grow overflow-y-auto">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          {Object.entries(profiles)
            .filter(([name, config]) => {
              const matchesSearch =
                name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                getDisplayType(config.type)
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase());
              return matchesSearch;
            })
            .map(([name, config]) => (
              <ProfileCard
                key={name}
                type={getDisplayType(config.type)}
                name={name}
                isActive={name === activeProfile}
                onSelect={(name) => handleConfigLoad(name)}
                onEdit={handleEdit}
                onDelete={() => {
                  setProfileName(name);
                  setShowDeleteModal(true);
                }}
                isDestination={true}
                icon={
                  <img
                    src={getDestinationLogo(config.type)}
                    alt={config.type}
                    className="w-6 h-6"
                  />
                }
                details={(() => {
                  const destConfig = DESTINATION_OPTIONS.find(
                    (opt) => opt.value === config.type,
                  );
                  if (!destConfig) return {};

                  return {
                    Type: getDisplayType(config.type),
                    ...destConfig.fields.reduce(
                      (acc, field) => ({
                        ...acc,
                        [field.charAt(0).toUpperCase() +
                        field.slice(1).replace(/([A-Z])/g, " $1")]:
                          field.toLowerCase().includes("key") ||
                          field.toLowerCase().includes("secret") ||
                          field.toLowerCase().includes("password")
                            ? "********"
                            : config[field] || "N/A",
                      }),
                      {},
                    ),
                  };
                })()}
              />
            ))}
        </div>
      </div>

      {/* Modals - Make sure they're wrapped properly */}
      <>
        {/* New Modal for Adding/Editing Profiles */}
        <Modal
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          aria-labelledby="destination-form-modal"
          aria-describedby="add-or-edit-destination-form"
        >
          <div className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-800">
                  {editingProfile
                    ? `Edit ${editingProfile}`
                    : "Add New Destination"}
                </h2>
                <button
                  onClick={() => {
                    setShowModal(false);
                  }}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <FaTimes size={18} />
                </button>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Profile Name
                </label>
                <input
                  type="text"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                  placeholder="Enter profile name"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  disabled={!!editingProfile}
                />
              </div>
              {/* Destination Type Selection */}
              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-3">
                  Destination Type
                </label>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {DESTINATION_OPTIONS.map((dest) => (
                    <div
                      key={dest.value}
                      className={`flex items-center gap-3 p-4 rounded-lg border-2 cursor-pointer transition-all ${
                        formState.type === dest.value
                          ? "border-primary bg-primary-50"
                          : "border-gray-200 hover:border-primary-light"
                      }`}
                      onClick={() => {
                        // When changing type, reset form and set default values for the selected type
                        const newFormState = { type: dest.value };

                        if (dest.value === SHAREPOINT_DEST) {
                          newFormState.documents_library_folder_name =
                            DEFAULT_SHAREPOINT_DOCUMENTS_LIBRARY;
                        } else if (dest.value === POSTGRESQL_DEST) {
                          newFormState.id_key = DEFAULT_ID_KEY;
                          newFormState.filename_key = DEFAULT_FILENAME_KEY;
                          newFormState.text_key = DEFAULT_TEXT_KEY;
                          newFormState.tags_key = DEFAULT_TAGS_KEY;
                          newFormState.dimension_threshold =
                            DEFAULT_DIMENSION_THRESHOLD;
                        }

                        setFormState(newFormState);
                      }}
                    >
                      <img
                        src={getDestinationLogo(dest.value)}
                        alt={dest.label}
                        className="w-8 h-8 object-contain"
                      />
                      <span className="text-gray-700 font-medium">
                        {dest.label}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              {formState.type &&
                !editingProfile &&
                DESTINATION_OPTIONS.find(
                  (opt) => opt.value === formState.type,
                )?.fields.includes("create_new_collection") && (
                  <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-3">
                      Collection Action
                    </label>
                    <div className="grid grid-cols-2 gap-4">
                      <div
                        className={`flex items-center justify-center gap-2 p-3 rounded-lg border-2 cursor-pointer transition-all ${
                          formState.create_new_collection
                            ? "border-primary bg-primary-50"
                            : "border-gray-200 hover:border-primary-light"
                        }`}
                        onClick={() =>
                          handleInputChange("create_new_collection", true)
                        }
                      >
                        <span className="text-gray-700 font-medium">
                          Create a new collection
                        </span>
                      </div>
                      <div
                        className={`flex items-center justify-center gap-2 p-3 rounded-lg border-2 cursor-pointer transition-all ${
                          formState.create_new_collection === false
                            ? "border-primary bg-primary-50"
                            : "border-gray-200 hover:border-primary-light"
                        }`}
                        onClick={() =>
                          handleInputChange("create_new_collection", false)
                        }
                      >
                        <span className="text-gray-700 font-medium">
                          Use an existing collection
                        </span>
                      </div>
                    </div>
                  </div>
                )}

              {/* Connection Details - Only show if type doesn't require collection action OR collection action has been selected */}
              {formState.type &&
                (DESTINATION_OPTIONS.find(
                  (opt) => opt.value === formState.type,
                )?.fields.includes("create_new_collection")
                  ? formState.create_new_collection !== undefined
                  : true) && (
                  <div className="mb-6">
                    <h3 className="font-medium text-gray-900 mb-4">
                      Connection Details
                    </h3>
                    {DESTINATION_OPTIONS.find(
                      (opt) => opt.value === formState.type,
                    )
                      ?.fields.filter(
                        (field) => field !== "create_new_collection",
                      )
                      .map((field) => (
                        <FormInput
                          key={field}
                          label={
                            field.charAt(0).toUpperCase() +
                            field.slice(1).replace(/([A-Z])/g, " $1")
                          }
                          type={
                            field.toLowerCase().includes("key") ||
                            field.toLowerCase().includes("password") ||
                            field.toLowerCase().includes("secret")
                              ? "password"
                              : "text"
                          }
                          value={formState[field] || ""}
                          onChange={(e) =>
                            handleInputChange(field, e.target.value)
                          }
                          placeholder={`Enter your ${field
                            .replace(/([A-Z])/g, " $1")
                            .toLowerCase()}`}
                          disabled={
                            editingProfile &&
                            (field.toLowerCase().includes("password") ||
                              field.toLowerCase().includes("secret") ||
                              field.toLowerCase().includes("key"))
                          }
                        />
                      ))}
                  </div>
                )}

              {/* Schema Fields Section - Only for PostgreSQL and only if collection action has been selected */}
              {formState.type === POSTGRESQL_DEST &&
                (DESTINATION_OPTIONS.find(
                  (opt) => opt.value === formState.type,
                )?.fields.includes("create_new_collection")
                  ? formState.create_new_collection !== undefined
                  : true) && (
                  <div className="mb-6 pt-6 border-t border-gray-200">
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="font-medium text-gray-900">
                        Schema Configuration
                      </h3>
                      <button
                        type="button"
                        onClick={() => {
                          // Reset to defaults
                          const updatedForm = { ...formState };
                          updatedForm.id_key = DEFAULT_ID_KEY;
                          updatedForm.filename_key = DEFAULT_FILENAME_KEY;
                          updatedForm.tags_key = DEFAULT_TAGS_KEY;
                          updatedForm.text_key = DEFAULT_TEXT_KEY;
                          updatedForm.dimension_threshold =
                            DEFAULT_DIMENSION_THRESHOLD;
                          setFormState(updatedForm);
                        }}
                        className="text-sm text-primary hover:text-primary-dark"
                      >
                        Reset to defaults
                      </button>
                    </div>

                    <FormInput
                      label="ID Key"
                      type="text"
                      value={formState.id_key}
                      onChange={(e) =>
                        handleInputChange("id_key", e.target.value)
                      }
                      placeholder="Field name for record ID"
                    />

                    <FormInput
                      label="Filename Key"
                      type="text"
                      value={formState.filename_key}
                      onChange={(e) =>
                        handleInputChange("filename_key", e.target.value)
                      }
                      placeholder="Field name for filename"
                    />

                    <FormInput
                      label="Text Key"
                      type="text"
                      value={formState.text_key}
                      onChange={(e) =>
                        handleInputChange("text_key", e.target.value)
                      }
                      placeholder="Field name for text content"
                    />

                    <FormInput
                      label="Tags Key"
                      type="text"
                      value={formState.tags_key}
                      onChange={(e) =>
                        handleInputChange("tags_key", e.target.value)
                      }
                      placeholder="Field name for tags"
                    />
                    <FormInput
                      label="Dimension Threshold"
                      type="number"
                      value={formState.dimension_threshold}
                      onChange={(e) =>
                        handleInputChange("dimension_threshold", e.target.value)
                      }
                    />
                  </div>
                )}

              {validationError && (
                <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md">
                  {validationError.message}
                </div>
              )}

              <div className="flex justify-end gap-3 mt-6">
                <button
                  onClick={() => {
                    setShowModal(false);
                    setEditingProfile(null);
                  }}
                  className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={validateAndSave}
                  disabled={isValidating}
                  className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark flex items-center gap-2"
                >
                  {isValidating ? (
                    <>
                      <LoadingSpinner size={4} /> Validating...
                    </>
                  ) : (
                    getButtonText()
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* Delete Modal */}
        {showDeleteModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg max-w-md w-full p-6">
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Delete Destination Profile
              </h3>
              <p className="text-gray-600 mb-6">
                Are you sure you want to delete the destination profile{" "}
                <strong>{profileName}</strong>? This action cannot be undone.
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowDeleteModal(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={async () => {
                    await handleSaveNewConfig("destination", profileName, null);
                    setShowDeleteModal(false);
                    toast.success(
                      `Destination profile "${profileName}" deleted successfully`,
                    );
                  }}
                  className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};
