import React, { useContext, useState } from "react";
import { TableCell as MuiTableCell } from "@mui/material";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { FaEye } from "react-icons/fa";
import { FilePreviewModal } from "./FilePreviewModal";

const CustomTableCell = ({
  row,
  column,
  deasyFileKey,
  expandedRow,
  tableColumnWidths,
  processTagValue,
  onTagClick,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const { deasyUserConfig } = useContext(BaseContext);
  const vdbProfileName = deasyUserConfig?.vdbmConfig?.LastActive;
  const vdbConfig = deasyUserConfig?.vdbmConfig?.Configs[vdbProfileName];
  const vdbIsDataSource =
    vdbConfig?.type === "SharepointDataSourceManager" ||
    vdbConfig?.type === "S3DataSourceManager";

  if (!row || !column) return null;

  const columnWidth =
    column === deasyFileKey
      ? tableColumnWidths[deasyFileKey]
      : tableColumnWidths.default;

  const handleTagClick = (e, item) => {
    if (column === deasyFileKey) return;

    if (typeof onTagClick !== "function") {
      console.error("onTagClick is not a function");
      return;
    }
    e.stopPropagation();
    onTagClick(column, item, row[deasyFileKey]);
  };

  const handlePreviewClick = (e) => {
    e.stopPropagation();
    setShowPreviewModal(true);
  };

  const closePreviewModal = () => {
    setShowPreviewModal(false);
  };

  return (
    <>
      <MuiTableCell
        sx={{
          width: columnWidth,
          minWidth: columnWidth,
          maxWidth: columnWidth,
          padding: "8px 16px",
          whiteSpace: "normal",
          wordBreak: "break-word",
          position: "relative",
          ...(column === deasyFileKey && {
            position: "sticky",
            left: 0,
            backgroundColor: "white",
            zIndex: 1,
          }),
        }}
      >
        <div
          className={`flex items-start gap-2`}
          style={{
            maxHeight: "120px",
            overflowY: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            "&::WebkitScrollbar": {
              display: "none",
            },
          }}
        >
          {column === deasyFileKey && row.chunks?.length > 0 && (
            <span
              style={{
                fontSize: "0.75rem",
                color: "#6B7280",
                transition: "transform 0.2s",
                transform:
                  expandedRow === row[deasyFileKey] ? "rotate(90deg)" : "none",
                flexShrink: 0,
                marginTop: "2px",
              }}
            >
              ▶
            </span>
          )}
          {Array.isArray(row[column]) ? (
            <div className="flex flex-wrap gap-1">
              {row[column].map((item, index) => (
                <span
                  key={index}
                  className={`bg-gray-100 px-2 py-1 rounded-sm text-sm 
                    ${column !== deasyFileKey ? "cursor-pointer hover:bg-gray-200" : ""}`}
                  onClick={(e) => handleTagClick(e, item)}
                >
                  {processTagValue(item)}
                </span>
              ))}
            </div>
          ) : processTagValue(row[column]) === "Untagged" ? (
            <div className="bg-red-50 text-red-600 px-2 py-1 rounded-md text-xs font-medium">
              Untagged
            </div>
          ) : (
            <span
              className={`break-words whitespace-pre-wrap 
                ${column !== deasyFileKey ? "cursor-pointer hover:bg-gray-100" : ""} 
                px-2 py-1 rounded-sm`}
              onClick={(e) => handleTagClick(e, row[column])}
            >
              {processTagValue(row[column])}
            </span>
          )}

          {vdbIsDataSource && column === deasyFileKey && (
            <div className="relative group ml-auto">
              <button
                className="p-1.5 text-primary hover:opacity-80 shadow-sm bg-gray-100 rounded-full flex-shrink-0 flex items-center justify-center transition-colors duration-200"
                onClick={handlePreviewClick}
                title="Preview OCR'd text"
              >
                <FaEye size={12} />
              </button>
              <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity duration-200 bottom-full mb-2 right-0 whitespace-nowrap bg-gray-800 text-white text-xs py-1 px-2 rounded pointer-events-none z-10">
                Preview OCR'd text
                <div className="absolute top-full left-1/2 transform -translate-x-1/2 -translate-y-1/4 border-4 border-transparent border-t-gray-800"></div>
              </div>
            </div>
          )}
        </div>
      </MuiTableCell>

      {showPreviewModal && (
        <FilePreviewModal
          filename={row[deasyFileKey]}
          closePreviewModal={closePreviewModal}
        />
      )}
    </>
  );
};

export default CustomTableCell;
