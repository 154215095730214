import { FaInfoCircle, FaDatabase } from "react-icons/fa";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useState } from "react";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const MetricCard = ({
  title,
  value,
  onClick,
  isClickable = false,
  isActive = false,
  info,
}) => (
  <div
    className={`bg-white p-4 rounded-lg border transition-all duration-200 h-[120px] flex flex-col justify-between ${
      isClickable ? "cursor-pointer hover:border-primary hover:shadow-md" : ""
    }`}
    onClick={onClick}
  >
    <div className="flex items-start gap-2">
      <h4 className="text-base font-semibold text-gray-600 break-words text-left">
        {title}
      </h4>
      {info && (
        <div className="group relative flex-shrink-0">
          <FaInfoCircle className="text-gray-400 hover:text-gray-600 cursor-help w-4 h-4" />
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-64 p-3 bg-gray-900 text-white text-sm rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
            {info}
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-900"></div>
          </div>
        </div>
      )}
    </div>
    <div className="flex items-center justify-between mt-auto">
      <p className="text-2xl font-semibold text-gray-900">
        {value === null ? (
          <span className="inline-block animate-[pulse_1s_ease-in-out_infinite] text-2xl font-bold tracking-wider opacity-20 hover:opacity-90">
            ...
          </span>
        ) : (
          value
        )}
      </p>
      {isClickable && (
        <span className="text-primary text-base">
          {isActive ? "Close all metadata ▲" : "Click to view metadata ▼"}
        </span>
      )}
    </div>
  </div>
);

export const TagDistributionChart = ({
  distribution,
  title = "",
  isSyncChart = false,
  hideChunkFileOption = false,
  dataSlice,
  isLoading = false,
}) => {
  const [displayMode, setDisplayMode] = useState("chunks");

  if (isLoading) {
    return (
      <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-800">{title}</h3>
        </div>
        <div className="h-[400px] flex items-center justify-center">
          <div className="flex items-center justify-center gap-2">
            <div className="w-4 h-4 border-2 border-primary border-t-transparent rounded-full animate-spin" />
            <span className="text-sm text-primary">Loading data...</span>
          </div>
        </div>
      </div>
    );
  }

  if (!distribution) return null;

  const isArrayValues = Object.values(distribution)[0]?.length !== undefined;

  const sortedEntries = Object.entries(distribution).sort(([, a], [, b]) => {
    if (isSyncChart) {
      return b[0] / b[1] - a[0] / a[1];
    }
    if (!isArrayValues) {
      return b - a;
    }
    return hideChunkFileOption
      ? b[0] - a[0]
      : b[displayMode === "chunks" ? 0 : 1] -
          a[displayMode === "chunks" ? 0 : 1];
  });

  const data = {
    labels: sortedEntries.map(([label]) => label),
    datasets: [
      {
        data: sortedEntries.map(([, values]) => {
          if (isSyncChart) {
            return (values[0] / values[1]) * 100;
          }
          if (!isArrayValues) {
            return values;
          }
          return hideChunkFileOption
            ? values[0]
            : displayMode === "chunks"
              ? values[0]
              : values[1];
        }),
        backgroundColor: "#4FA892",
        borderColor: "#3E8674",
        borderWidth: 1,
        barThickness: 16, // Fixed bar thickness
        minBarLength: 2, // Minimum bar length
      },
    ],
  };

  const options = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            isSyncChart
              ? `${context.raw.toFixed(1)}%`
              : `Count: ${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          callback: isSyncChart ? (value) => `${value}%` : undefined,
          precision: 0,
          font: {
            size: 8,
          },
        },
        position: "top",
        max: isSyncChart ? 100 : undefined,
      },
      y: {
        ticks: {
          autoSkip: false,
          font: {
            size: 8,
          },
        },
        afterFit: (scaleInstance) => {
          scaleInstance.width = 150;
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
    barPercentage: 0.8,
    categoryPercentage: 0.8,
  };

  const heightPerBar = 30;
  const totalHeight = sortedEntries.length * heightPerBar;

  return (
    <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium text-gray-800">{title}</h3>
        {title.includes("Data Source") &&
          dataSlice?.vector_db_config?.collection_name && (
            <div className="group relative flex items-center gap-2 text-gray-600 bg-gray-50 px-3 py-1 rounded-lg">
              <FaDatabase className="text-primary" />
              <span className="text-sm font-medium">
                {dataSlice.vector_db_config.collection_name}
              </span>
              <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs rounded px-2 py-1 -top-8 left-1/2 transform -translate-x-1/2">
                Collection Name
              </div>
            </div>
          )}
        {!isSyncChart && !hideChunkFileOption && (
          <div className="flex items-center gap-2 bg-gray-100 p-1 rounded-lg">
            <button
              className={`px-3 py-1 rounded-md text-sm transition-all ${
                displayMode === "chunks"
                  ? "bg-white shadow text-gray-800"
                  : "text-gray-600 hover:text-gray-800"
              }`}
              onClick={() => setDisplayMode("chunks")}
            >
              Chunk Level
            </button>
            <button
              className={`px-3 py-1 rounded-md text-sm transition-all ${
                displayMode === "files"
                  ? "bg-white shadow text-gray-800"
                  : "text-gray-600 hover:text-gray-800"
              }`}
              onClick={() => setDisplayMode("files")}
            >
              File Level
            </button>
          </div>
        )}
      </div>
      <div className="overflow-y-auto" style={{ height: "400px" }}>
        <div
          style={{
            height: `${Math.max(totalHeight, 400)}px`,
            minHeight: "400px",
          }}
        >
          <Bar
            data={data}
            options={{
              ...options,
              maintainAspectRatio: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};
