import { ErrorDataTable } from "./ErrorDataTable";
import ReactFlow, { Background, ReactFlowProvider } from "reactflow";
import { nodeTypes } from "../Workflows/screens/DataDiscovery/Graph/GraphNodes";
import { processData } from "../Workflows/screens/DataDiscovery/GraphUtils";
import { FaTrash } from "react-icons/fa";
import { useState } from "react";
import {
  ProgressBar,
  formatTimeRemaining,
  formatElapsedTime,
  formatDateTime,
  getCurrentTimeInUTC,
} from "./utils";
import { progressTrackingService } from "../../../services/api";
import {
  FiTag,
  FiList,
  FiDatabase,
  FiCloud,
  FiCpu,
  FiClock,
  FiRefreshCw,
  FiAlertTriangle,
  FiHash,
} from "react-icons/fi";

const isTrackerStale = (tracker) => {
  return (
    tracker.status === "in_progress" &&
    Date.now() - new Date(tracker.start_time).getTime() >= 24 * 60 * 60 * 1000
  );
};

const isTrackerActive = (tracker) => {
  return (
    tracker.status === "in_progress" &&
    Date.now() - new Date(tracker.start_time).getTime() < 24 * 60 * 60 * 1000
  );
};

export const ProgressTrackerRow = ({
  tracker,
  handleDelete,
  handleCheckboxClick,
  selectedTrackers,
  setSelectedTrackers,
  deasyApiKey,
  setProgressTrackers,
  isPending = false,
}) => {
  const isStale = isTrackerStale(tracker);
  const isActive = isTrackerActive(tracker);
  const [abortingTracker, setAbortingTracker] = useState(false);
  const [deletingTracker, setDeletingTracker] = useState(false);

  // Files state
  const [filesSearchQuery, setFilesSearchQuery] = useState("");
  const [filesPage, setFilesPage] = useState(1);
  const [filesPerPage, setFilesPerPage] = useState(10);
  const [expanded, setExpanded] = useState(false);

  // Row actions
  const handleRowClick = (trackerId) => {
    setExpanded((prev) => !prev);
    setSelectedTrackers((prev) =>
      prev.includes(trackerId)
        ? prev.filter((id) => id !== trackerId)
        : [...prev, trackerId],
    );
  };

  const handleAbort = async (trackerId, e) => {
    e.stopPropagation();

    // Add confirmation dialog
    const confirmed = window.confirm(
      "Are you sure you want to abort this task? This action cannot be undone.",
    );
    if (!confirmed) return;

    try {
      setAbortingTracker(true);
      await progressTrackingService.abortProgressTracker(
        trackerId,
        deasyApiKey,
      );
      // Refresh the list
      const progressTrackers =
        await progressTrackingService.getProgressTrackers(deasyApiKey);
      setProgressTrackers(progressTrackers.data.progress_trackers);
    } catch (error) {
      console.error("Failed to abort task:", error);
    } finally {
      setAbortingTracker(false);
    }
  };

  // Render helpers
  const renderTimelineInfo = (tracker) => {
    const startTime = new Date(tracker.start_time);
    const elapsedMs = getCurrentTimeInUTC() - startTime;
    const elapsedStr = formatElapsedTime(elapsedMs);
    const progress = isTrackerActive(tracker)
      ? tracker.completed_progress_increments /
        tracker.total_progress_increments
      : null;

    return (
      <div className="text-xs text-gray-500 text-center">
        {/* Only show elapsed time for active trackers */}
        {isTrackerActive(tracker) && <div>Time elapsed: {elapsedStr}</div>}

        {/* Show remaining time or calculation message */}
        {progress !== null && (
          <div>
            {progress > 0
              ? `~${formatTimeRemaining(elapsedMs, progress)} remaining`
              : "Calculating remaining time..."}
          </div>
        )}

        {/* Show tag creation count if available */}
        {tracker.tags_created !== undefined && (
          <div>
            {tracker.tags_created.toLocaleString()} new tag value pair
            {tracker.tags_created !== 1 && "s"}
          </div>
        )}
      </div>
    );
  };

  const renderProgressCell = (tracker) => {
    // If stale, show as failed instead
    if (isTrackerStale(tracker)) {
      return (
        <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
          <div className="flex items-center">
            <span className="text-red-600 mr-2">✗</span>
            <span className="text-gray-500">Failed (Timeout)</span>
          </div>
        </td>
      );
    }

    const isComplete = tracker.status === "completed";
    const progress =
      tracker.completed_progress_increments /
      Math.max(tracker.total_progress_increments, 1);

    if (isComplete && progress === 1) {
      return (
        <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
          <div className="flex items-center">
            <span className="text-green-600 mr-2">✓</span>
            <span className="text-gray-500">Complete</span>
          </div>
        </td>
      );
    }

    return (
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-full">
        <ProgressBar
          value={progress}
          active={tracker.status === "in_progress"}
        />
      </td>
    );
  };

  return (
    <>
      <tr
        key={tracker.tracker_id}
        className={`${
          selectedTrackers.includes(tracker.tracker_id)
            ? "bg-primary/15"
            : "hover:bg-gray-50"
        } ${tracker.status === "completed" ? "h-12" : ""} cursor-pointer ${
          isPending ? "bg-yellow-50" : ""
        }`}
        onClick={() => handleRowClick(tracker.tracker_id)}
      >
        <td
          className="pl-6 pr-2 text-left"
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type="checkbox"
            className="rounded border-gray-300 text-primary focus:ring-primary"
            checked={selectedTrackers.includes(tracker.tracker_id)}
            onChange={(e) => handleCheckboxClick(e, tracker.tracker_id)}
          />
        </td>
        <td className="pr-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
          <span className="font-semibold rounded-md px-2 py-1 bg-gray-100 shadow-md border border-gray-200">
            {tracker.task_type}
          </span>
        </td>
        {renderProgressCell(tracker)}
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
          {tracker.files === "ALL" ||
          (Array.isArray(tracker.files) && tracker.files.length === 0) ||
          tracker.files === null
            ? tracker.task_type === "ocr_data_ingestion"
              ? `${tracker.total_progress_increments / 3} files`
              : `${tracker.total_progress_increments} files`
            : `${Array.isArray(tracker.files) ? tracker.files.length : 0} files`}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-left">
          <div className="flex items-center">
            <div className="text-sm font-medium text-gray-900">
              {tracker.is_pending ? (
                <span className="text-amber-600 font-medium flex items-center gap-1">
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-amber-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Initializing
                </span>
              ) : (
                <span
                  className={`px-2 py-1 rounded-full text-xs ${
                    isStale || tracker.status === "failed"
                      ? "bg-red-100 text-red-800"
                      : tracker.status === "completed"
                        ? "bg-green-100 text-green-800"
                        : "bg-blue-100 text-blue-800"
                  }`}
                >
                  {isStale ? "Failed" : tracker.status.replace("_", " ")}
                </span>
              )}
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex gap-2 justify-end">
          {isActive ? (
            <button
              onClick={async (e) => await handleAbort(tracker.tracker_id, e)}
              className="text-red-500 hover:text-red-700 bg-red-50 px-2 py-1 rounded-md"
            >
              {abortingTracker ? (
                <div className="flex items-center gap-2">
                  <span>Aborting</span>
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-400" />
                </div>
              ) : (
                "Abort"
              )}
            </button>
          ) : (
            <>
              {/* Retry button for completed, failed or stale trackers that are classify tasks */}

              <button
                onClick={async (e) => {
                  setDeletingTracker(true);
                  await handleDelete([tracker.tracker_id], e);
                  setDeletingTracker(false);
                }}
                className="text-red-500 hover:text-red-700 px-2 py-1 rounded-md"
              >
                {deletingTracker ? (
                  <div className="flex items-center gap-2">
                    <span>Deleting</span>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-400" />
                  </div>
                ) : (
                  <FaTrash />
                )}
              </button>
            </>
          )}
        </td>
      </tr>
      {expanded && (
        <tr className="bg-gray-50">
          <td colSpan="6" className="px-6 py-4 space-y-6">
            {/* Enhanced Classification Details */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {/* Tag Hierarchy Visualization */}
              {tracker?.hierarchy &&
                (() => {
                  const { nodes, edges } = processData(
                    tracker?.hierarchy,
                    tracker.total_progress_increments,
                  );
                  return (
                    <div className="p-4 bg-white rounded-lg shadow-md border border-gray-100 last:odd:col-span-2">
                      <p className="text-xs font-bold uppercase tracking-wider text-gray-800 mb-2">
                        Tag Hierarchy
                      </p>
                      <div className="space-y-2 h-96 w-full">
                        <ReactFlowProvider>
                          <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            nodeTypes={nodeTypes}
                            fitView
                            minZoom={0.1}
                            maxZoom={4}
                            defaultEdgeOptions={{
                              type: "step",
                              style: {
                                stroke: "#d1d5db",
                              },
                            }}
                          >
                            <Background />
                          </ReactFlow>
                        </ReactFlowProvider>
                      </div>
                    </div>
                  );
                })()}

              {/* Tag Details Panel */}
              {tracker?.tags && (
                <div className="bg-white p-4 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow text-left">
                  <div className="flex items-center gap-2 mb-4">
                    <FiTag className="text-emerald-600" size={12} />
                    <h3 className="text-lg font-semibold text-gray-800">
                      Tag Details
                    </h3>
                  </div>
                  <div className="h-96 overflow-y-auto pr-2 space-y-3">
                    {Object.entries(tracker.tags).map(([tagName, tag]) => (
                      <div
                        key={tagName}
                        className="p-4 bg-gray-50 rounded-lg border border-gray-100 hover:border-purple-200 transition-colors"
                      >
                        <div className="flex items-start justify-between mb-3">
                          <div className="flex items-start gap-2">
                            <FiHash className="mt-1 text-gray-400" size={12} />
                            <div>
                              <h3 className="text-base font-medium text-gray-800 text-left">
                                {tagName}
                              </h3>
                              {tag.description && (
                                <p className="text-sm text-gray-600 mt-1 text-left">
                                  {tag.description}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        {tag.available_values?.length > 0 && (
                          <div className="mt-3">
                            <div className="flex items-center gap-2 mb-2">
                              <FiList className="text-gray-400" size={12} />
                              <span className="text-sm font-medium text-gray-700">
                                Available Values
                              </span>
                            </div>
                            <div className="flex flex-wrap gap-2">
                              {tag.available_values.map((value) => (
                                <div className="px-2.5 py-1 bg-white text-gray-700 text-sm border border-gray-200 rounded-md truncate max-w-[160px] hover:border-gray-300 transition-colors">
                                  {value}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Classification Details */}
              <div className="bg-white p-4 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow lg:col-span-2 text-left">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  {tracker?.llm_vendor && (
                    <div className="p-3 bg-gray-50 rounded-lg">
                      <div className="flex items-center gap-2">
                        <FiCloud className="text-primary" size={12} />
                        <span className="text-sm font-medium text-gray-500">
                          LLM Vendor
                        </span>
                      </div>
                      <p className="mt-1 text-gray-700 font-medium">
                        {tracker.llm_vendor}
                      </p>
                    </div>
                  )}

                  {tracker?.llm_model && (
                    <div className="p-3 bg-gray-50 rounded-lg">
                      <div className="flex items-center gap-2">
                        <FiCpu className="text-primary" size={12} />
                        <span className="text-sm font-medium text-gray-500">
                          LLM Model
                        </span>
                      </div>
                      <p className="mt-1 text-gray-700 font-medium">
                        {tracker.llm_model}
                      </p>
                    </div>
                  )}

                  {tracker?.vdb_data_source && (
                    <div className="p-3 bg-gray-50 rounded-lg">
                      <div className="flex items-center gap-2">
                        <FiDatabase className="text-primary" size={12} />
                        <span className="text-sm font-medium text-gray-500">
                          Data Source
                        </span>
                      </div>
                      <p className="mt-1 text-gray-700 font-medium">
                        {tracker.vdb_data_source}
                      </p>
                    </div>
                  )}

                  {tracker?.end_time && (
                    <div className="p-3 bg-gray-50 rounded-lg">
                      <div className="flex items-center gap-2">
                        <FiClock className="text-primary" size={12} />
                        <span className="text-sm font-medium text-gray-500">
                          Processing Time
                        </span>
                      </div>
                      <p className="mt-1 text-gray-700 font-medium">
                        {formatElapsedTime(
                          new Date(tracker.end_time) -
                            new Date(tracker.start_time),
                        )}
                      </p>
                    </div>
                  )}

                  {tracker?.overwrite && (
                    <div className="p-3 bg-gray-50 rounded-lg">
                      <div className="flex items-center gap-2">
                        <FiRefreshCw className="text-primary" size={12} />
                        <span className="text-sm font-medium text-gray-500">
                          Re-Run
                        </span>
                      </div>
                      <p className="mt-1 text-gray-700 font-medium">Enabled</p>
                    </div>
                  )}

                  {tracker?.error_data && (
                    <div className="p-3 bg-gray-50 rounded-lg">
                      <div className="flex items-center gap-2">
                        <FiAlertTriangle className="text-red-600" size={12} />
                        <span className="text-sm font-medium text-gray-500">
                          Error Rate
                        </span>
                      </div>
                      <p className="mt-1 text-gray-700 font-medium">
                        {(
                          (Object.keys(tracker.error_data).length /
                            (tracker.task_type === "ocr_data_ingestion"
                              ? tracker.total_progress_increments / 3
                              : tracker.total_progress_increments)) *
                          100
                        ).toFixed(2)}
                        %
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {tracker?.files && (
              <div className="p-4 bg-white rounded-lg shadow-md border border-gray-100 last:odd:col-span-2">
                <div className="flex justify-between items-center mb-4">
                  <p className="text-xs font-bold uppercase tracking-wider text-gray-800">
                    Files
                  </p>
                  <input
                    type="text"
                    placeholder="Search files..."
                    className="px-3 py-1 border rounded-md text-sm w-48"
                    value={filesSearchQuery}
                    onChange={(e) => {
                      setFilesSearchQuery(e.target.value);
                      setFilesPage(1);
                    }}
                  />
                </div>
                <div className="space-y-4">
                  {/* Paginated Files */}
                  <div className="space-y-2">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            File Name
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {tracker?.files
                          .filter((file) =>
                            file
                              .toLowerCase()
                              .includes(filesSearchQuery.toLowerCase()),
                          )
                          .slice(
                            (filesPage - 1) * filesPerPage,
                            filesPage * filesPerPage,
                          )
                          .map((file, index) => (
                            <tr key={index} className="hover:bg-gray-50">
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-700 truncate max-w-[200px] text-left">
                                {file}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination Controls */}
                  <div className="flex items-center justify-between mt-4">
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => setFilesPage((p) => Math.max(1, p - 1))}
                        disabled={filesPage === 1}
                        className="px-3 py-1 text-sm border rounded-md disabled:opacity-50"
                      >
                        Previous
                      </button>
                      <span className="text-sm text-gray-600">
                        Page {filesPage} of{" "}
                        {Math.ceil(
                          tracker.files.filter((file) =>
                            file
                              .toLowerCase()
                              .includes(filesSearchQuery.toLowerCase()),
                          ).length / filesPerPage,
                        )}
                      </span>
                      <button
                        onClick={() => setFilesPage((p) => p + 1)}
                        disabled={
                          filesPage * filesPerPage >=
                          tracker.files.filter((file) =>
                            file
                              .toLowerCase()
                              .includes(filesSearchQuery.toLowerCase()),
                          ).length
                        }
                        className="px-3 py-1 text-sm border rounded-md disabled:opacity-50"
                      >
                        Next
                      </button>
                    </div>
                    <select
                      value={filesPerPage}
                      onChange={(e) => {
                        setFilesPerPage(Number(e.target.value));
                        setFilesPage(1);
                      }}
                      className="p-1 text-sm border rounded-md"
                    >
                      <option value={10}>10 per page</option>
                      <option value={25}>25 per page</option>
                      <option value={50}>50 per page</option>
                    </select>
                  </div>
                </div>
              </div>
            )}

            {/* Timeline section */}
            <div className="p-4 bg-white rounded-lg shadow-md">
              <p className="text-xs font-bold uppercase tracking-wider text-gray-800 mb-2">
                Timeline
              </p>
              <div className="flex justify-between items-center gap-8">
                <div>
                  <p className="text-xs text-gray-500 mb-1">Started</p>
                  <p className="text-sm">
                    {formatDateTime(tracker.start_time)}
                  </p>
                </div>
                <div className="flex-1">
                  <div className="h-0.5 bg-gray-200 relative mb-4">
                    <div
                      className={`absolute inset-y-0 left-0 ${
                        tracker.status === "completed"
                          ? "bg-green-400"
                          : tracker.status === "failed" ||
                              tracker.status === "aborted"
                            ? "bg-red-400"
                            : "bg-emerald-400"
                      }`}
                      style={{
                        width: `${
                          (tracker.completed_progress_increments /
                            tracker.total_progress_increments) *
                          100
                        }%`,
                      }}
                    />
                  </div>

                  <div className="w-full flex flex-col items-center justify-center">
                    {renderTimelineInfo(tracker)}
                  </div>
                </div>
                <div>
                  <p className="text-xs text-gray-500 mb-1">
                    {tracker.status === "completed"
                      ? "Completed"
                      : tracker.status === "failed"
                        ? "Failed"
                        : tracker.status === "aborted"
                          ? "Aborted"
                          : "In Progress"}
                  </p>
                  <p className="text-sm">
                    {tracker.end_time
                      ? formatDateTime(tracker.end_time)
                      : "----------------"}
                  </p>
                </div>
              </div>
            </div>

            {/* Error Message section - only show for failed status */}
            {tracker?.error_data &&
              Object.keys(tracker.error_data).length > 0 && (
                <ErrorDataTable errorData={tracker.error_data} />
              )}
          </td>
        </tr>
      )}
    </>
  );
};
