import React, { useState, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const INITIAL_VISIBLE_TAGS = 10;

const TagDistributionChart = ({
  distribution,
  isSyncChart = false,
  isLoading,
  selectedTags = [],
}) => {
  const [showAll, setShowAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("count");
  const [sortOrder, setSortOrder] = useState("asc");

  const processedEntries = useMemo(() => {
    if (!distribution) return [];

    let entries = Object.entries(distribution);

    if (selectedTags.length > 0) {
      entries = entries.filter(([key]) => selectedTags.includes(key));
    }

    if (searchTerm) {
      entries = entries.filter(([key]) =>
        key.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    entries.sort(([keyA, valueA], [keyB, valueB]) => {
      if (sortBy === "count") {
        const countA = Array.isArray(valueA) ? valueA[0] : valueA;
        const countB = Array.isArray(valueB) ? valueB[0] : valueB;
        return sortOrder === "desc" ? countB - countA : countA - countB;
      } else {
        return sortOrder === "desc"
          ? keyB.localeCompare(keyA)
          : keyA.localeCompare(keyB);
      }
    });

    return entries;
  }, [distribution, searchTerm, sortBy, sortOrder, selectedTags]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-48">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!distribution || Object.keys(distribution).length === 0) {
    return (
      <div className="flex justify-center items-center h-48 text-gray-500">
        No data available
      </div>
    );
  }

  const visibleEntries = showAll
    ? processedEntries
    : processedEntries.slice(0, INITIAL_VISIBLE_TAGS);

  const data = {
    labels: visibleEntries.map(([key]) => key),
    datasets: [
      {
        label: isSyncChart ? "Synchronized Tags" : "Tag Count",
        data: visibleEntries.map(([, value]) => value),
        backgroundColor: isSyncChart
          ? visibleEntries.map(([, value]) =>
              value > 0 ? "rgba(34, 197, 94, 0.5)" : "rgba(239, 68, 68, 0.5)",
            )
          : "rgba(59, 130, 246, 0.5)",
        borderColor: isSyncChart
          ? visibleEntries.map(([, value]) =>
              value > 0 ? "rgb(34, 197, 94)" : "rgb(239, 68, 68)",
            )
          : "rgb(59, 130, 246)",
        borderWidth: 1,
        barThickness: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            if (isSyncChart) {
              return `${context.raw > 0 ? "Synchronized" : "Not synchronized"}`;
            }
            return `Count: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: 8,
          },
        },
      },
    },
  };

  const totalTags = processedEntries.length;

  return (
    <div className="flex flex-col space-y-2 h-[40vh]">
      <div className="flex flex-col space-y-2">
        <div className="flex items-center gap-2">
          <input
            type="text"
            placeholder="Search tags..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-1 px-3 py-1 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
          />
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="px-3 py-1 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
          >
            <option value="count">Sort by Count</option>
            <option value="name">Sort by Name</option>
          </select>
          <button
            onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
            className="p-1 text-gray-500 hover:text-gray-700"
          >
            {sortOrder === "asc" ? "↑" : "↓"}
          </button>
        </div>
        {selectedTags.length > 0 && (
          <div className="text-sm text-gray-500">
            Showing {selectedTags.length} selected tags
          </div>
        )}
      </div>

      <div className="flex-1 overflow-x-auto h-48">
        <div
          style={{
            minWidth: `${Math.max(visibleEntries.length * 40, 100)}px`,
            height: "100%",
          }}
        >
          <Bar data={data} options={options} />
        </div>
      </div>

      {totalTags > INITIAL_VISIBLE_TAGS && (
        <div className="text-center">
          <button
            onClick={() => setShowAll(!showAll)}
            className="text-sm text-primary hover:text-primary-dark"
          >
            {showAll
              ? "Show Less"
              : `Show ${totalTags - INITIAL_VISIBLE_TAGS} More Tags`}
          </button>
        </div>
      )}

      <div className="text-xs text-gray-500 text-center">
        Showing {visibleEntries.length} of {totalTags} tags
      </div>
    </div>
  );
};

export default TagDistributionChart;
