import React, { useContext } from "react";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { FaDatabase } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { WorkflowType } from "../../../../../models/WorkflowModel";
import { getPathFromWorkflowType } from "../../../../../components/Navigation/NavUtils";
const BlankMetadataScreen = () => {
  const { deasyUserConfig } = useContext(BaseContext);
  const dataSource =
    deasyUserConfig.vdbmConfig.Configs[deasyUserConfig.vdbmConfig.LastActive];
  const navigate = useNavigate();
  const handleStartExtraction = () => {
    const newPath = getPathFromWorkflowType(
      WorkflowType.EXTRACT_METADATA,
      dataSource?.name,
    );
    navigate(newPath);
  };

  return (
    <div className="w-full bg-white rounded-lg shadow-lg p-8 mb-6 text-center">
      <div className="max-w-2xl mx-auto">
        <div className="bg-gray-50 p-4 rounded-full w-fit mx-auto mb-3">
          <FaDatabase className="w-8 h-8 text-gray-400" />
        </div>

        <h2 className="text-2xl font-semibold text-gray-800 mb-3">
          No Extracted Metadata Yet
        </h2>

        <p className="text-gray-600 mb-6">
          Start by extracting metadata from your files to enable powerful
          filtering, searching, and organization capabilities in this panel.
        </p>

        <button
          onClick={handleStartExtraction}
          className="bg-primary hover:bg-primary-dark text-white font-medium py-2 px-6 rounded-lg transition-colors inline-flex items-center gap-2"
        >
          <HiPlus className="h-4 w-4" />
          Start Metadata Extraction
        </button>
      </div>
    </div>
  );
};

export default BlankMetadataScreen;
