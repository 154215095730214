import ReactFlow, { Background, ReactFlowProvider } from "reactflow";
import "reactflow/dist/style.css";
import { nodeTypes } from "../../Graph/GraphNodes";
import { BaseContext } from "../../../../../../../contexts/BaseContext";
import { GraphContext } from "../../../../../../../contexts/GraphContext";
import { useContext } from "react";
import { tagService } from "../../../../../../../services/api";
import { processData } from "../../GraphUtils";
import {
  excludeKey,
  checkNodeOverlap,
  removeNodeFromStructure,
  FitViewOnNodesChange,
} from "./Utils";

export const ExtractMetaGraphPage = ({
  // Graph data
  nodes,
  edges,

  // Graph state setters
  setNodes,
  setEdges,

  // Graph event handlers
  onNodesChange,
  onEdgesChange,
  handleAddNodeApplied,

  // Selection state
  setSelectedClassifyFiles,

  // View control
  shouldFitView,
}) => {
  // Context data
  const { savedTags, deasyUserConfig } = useContext(BaseContext);
  const {
    setGraphs,
    selectedNodeData,
    setSelectedNodeData,
    setSidePanelOpen,
    hierarchyStats,
    discoveryGraphData,
    updateDiscoveryGraphData,
    selectedDiscoveryGraph,
    activeDragNode,
    activeOverlappingNode,
    setActiveDragNode,
    setActiveOverlappingNode,
    setSelectedNodes,
    handleSaveGraph,
    setShouldFitView,
  } = useContext(GraphContext);

  const { deasyApiKey } = deasyUserConfig;

  // Graph Functions
  const handleNodeDeletion = (nId) => {
    // Get all descendant nodes recursively
    const nodesToDelete = new Set();

    const collectDescendants = (currentId) => {
      nodesToDelete.add(currentId);
      const childEdges = edges.filter((edge) => edge.source === currentId);
      childEdges.forEach((edge) => collectDescendants(edge.target));
    };

    collectDescendants(nId);

    // Update the discoveryGraphData by removing nodes
    const updatedData = JSON.parse(JSON.stringify(discoveryGraphData));

    nodesToDelete.forEach((nodeId) => {
      const node = nodes.find((n) => n.id === nodeId);
      if (node) {
        removeNodeFromStructure(updatedData, node.data.nodePath);
      }
    });

    // Re-process the graph
    const { nodes: updatedNodes, edges: updatedEdges } = processData(
      updatedData,
      1000,
    );

    updateDiscoveryGraphData(updatedData);
    setGraphs((prev) =>
      prev.map((graph) =>
        graph.graph_id === selectedDiscoveryGraph.graph_id
          ? { ...graph, graph_data: updatedData }
          : graph,
      ),
    );
    setNodes(updatedNodes);
    setEdges(updatedEdges);
    handleSaveGraph({ ...selectedDiscoveryGraph, graph_data: updatedData });

    // Fit view after deleting nodes
    setShouldFitView(true);
  };

  const handleNodeAddClicked = (nodeData, nodeType) => {
    setSelectedNodeData({ ...nodeData, nodeType });
    setSidePanelOpen(true);
    handleSaveGraph({
      ...selectedDiscoveryGraph,
      graph_data: discoveryGraphData,
    });
  };

  const handleAddAvailableValue = async (selectedNodeData, newValue) => {
    // Create a deep copy of the data structure
    const updatedData = JSON.parse(JSON.stringify(discoveryGraphData));
    let current = updatedData;

    // Navigate to the tag node
    for (let i = 0; i < selectedNodeData.nodePath.length; i++) {
      const pathItem = selectedNodeData.nodePath[i];
      current = current[pathItem];
    }

    // Initialize TagAvailableValues array if it doesn't exist
    if (!current.TagAvailableValues) {
      current.TagAvailableValues = [];
    }

    // Handle both single value and array of values
    const valuesToAdd = Array.isArray(newValue) ? newValue : [newValue];

    // Filter out any "<ANY>" value and add new values if they don't already exist
    current.TagAvailableValues = current.TagAvailableValues.filter(
      (value) => value !== "<ANY>",
    );

    // Add all new values that don't already exist
    valuesToAdd.forEach((value) => {
      if (!current.TagAvailableValues.includes(value)) {
        current.TagAvailableValues.push(value);
      }
    });

    // Save the updated tag
    const completeTag = savedTags.find(
      (t) => t.name === selectedNodeData.label,
    );
    completeTag.available_values = Array.from(
      new Set([
        ...(completeTag.available_values || []),
        ...current.TagAvailableValues,
      ]),
    );
    await tagService.updateTag(completeTag, deasyApiKey);

    if ("<ANY>" in current) {
      delete current["<ANY>"];
    }

    // Add value nodes for each new value if they don't exist
    valuesToAdd.forEach((value) => {
      if (!current[value]) {
        current[value] = {};
      }
    });

    // Update the graph
    const { nodes: updatedNodes, edges: updatedEdges } = processData(
      updatedData,
      hierarchyStats?.file_count || 0,
    );

    updateDiscoveryGraphData(updatedData);
    setGraphs((prev) =>
      prev.map((graph) =>
        graph.graph_id === selectedDiscoveryGraph.graph_id
          ? { ...graph, graph_data: updatedData }
          : graph,
      ),
    );
    setNodes(updatedNodes);
    setEdges(updatedEdges);

    // Fit view after adding values
    setShouldFitView(true);
  };

  const handleRemoveAvailableValue = async (availableValueToRemove) => {
    // Create a deep copy of the data structure
    const updatedData = JSON.parse(JSON.stringify(discoveryGraphData));
    let current = updatedData;

    // Navigate to the tag node
    const tagPath = availableValueToRemove.nodePath.slice(0, -1); // Remove the value itself
    for (const pathItem of tagPath) {
      current = current[pathItem];
    }

    // Remove the value from TagAvailableValues array
    if (current.TagAvailableValues) {
      current.TagAvailableValues = current.TagAvailableValues.filter(
        (value) => value !== availableValueToRemove.label,
      );
    }

    // Remove the value node and its subtree
    delete current[availableValueToRemove.label];

    // If TagAvailableValues is now empty, add the "<ANY>" node
    if (
      !current.TagAvailableValues ||
      current.TagAvailableValues.length === 0
    ) {
      current["<ANY>"] = {};
    }

    // DISABLED updating tag definition when deleting a value node
    // const tagName = tagPath[0];
    // const completeTag = savedTags.find((t) => t.name === tagName);
    // if (completeTag) {
    //   completeTag.available_values = current.TagAvailableValues;
    //   try {
    //     await tagService.updateTag(completeTag, deasyApiKey);
    //   } catch (error) {
    //     console.error("Error updating tag:", error);
    //     toast.error("Failed to update tag definition");
    //     return;
    //   }
    // }

    // Update the graph
    const { nodes: updatedNodes, edges: updatedEdges } = processData(
      updatedData,
      hierarchyStats?.file_count || 0,
    );

    updateDiscoveryGraphData(updatedData);
    setGraphs((prev) =>
      prev.map((graph) =>
        graph.graph_id === selectedDiscoveryGraph.graph_id
          ? { ...graph, graph_data: updatedData }
          : graph,
      ),
    );
    setNodes(updatedNodes);
    setEdges(updatedEdges);

    // Fit view after deleting values
    setShouldFitView(true);
  };

  const nodesWithCallbacks = nodes.map((node) => ({
    ...node,
    data: {
      ...node.data,
      onDeleteNode: handleNodeDeletion,
      onAddNode: handleNodeAddClicked,
      handleAddNodeApplied: handleAddNodeApplied,
      onAddAvailableValue: handleAddAvailableValue,
      onRemoveAvailableValue: handleRemoveAvailableValue,
    },
  }));

  // Drag and drop functions
  const handleNodeDrag = (_, node) => {
    setActiveDragNode(node.id);
    setActiveOverlappingNode(checkNodeOverlap(node, nodesWithCallbacks));
  };

  const resetDragNode = () => {
    if (!activeOverlappingNode) return;
    const activeNodePath = nodesWithCallbacks.filter(
      (node) => node.id === activeDragNode,
    )[0]?.data?.nodePath;
    const overlappingNodePath = nodesWithCallbacks.filter(
      (node) => node.id === activeOverlappingNode,
    )[0]?.data?.nodePath;
    let activeNode = discoveryGraphData;
    for (let i = 0; i < activeNodePath.length - 1; i++) {
      activeNode = activeNode[activeNodePath[i]];
    }
    const newActiveNode = JSON.parse(
      JSON.stringify({
        [activeNodePath.at(-1)]: activeNode[activeNodePath.at(-1)],
      }),
    );

    let overlappingNode = discoveryGraphData;
    for (let i = 0; i < overlappingNodePath.length - 1; i++) {
      overlappingNode = overlappingNode[overlappingNodePath[i]];
    }
    if (Object.keys(overlappingNode).includes(overlappingNodePath.at(-1))) {
      overlappingNode[overlappingNodePath.at(-1)] = {
        ...newActiveNode,
        ...overlappingNode[overlappingNodePath.at(-1)],
      };
    } else {
      overlappingNode[overlappingNodePath.at(-1)] = newActiveNode;
    }

    let oldNode = discoveryGraphData;
    for (let i = 0; i < activeNodePath.length - 2; i++) {
      oldNode = oldNode[activeNodePath[i]];
    }
    oldNode[activeNodePath.at(-2)] = excludeKey(
      oldNode[activeNodePath.at(-2)],
      activeNodePath.at(-1),
    );
    updateDiscoveryGraphData({ ...discoveryGraphData });
    setActiveDragNode(null);
    setActiveOverlappingNode(null);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = (event) => {
    event.preventDefault();

    let tag;
    try {
      const draggedItem = event.dataTransfer.getData("draggedItem");
      tag = draggedItem ? JSON.parse(draggedItem) : null;
    } catch (e) {
      console.error("Error parsing dragged item:", e);
      return;
    }

    if (!tag) return;

    // Set the selected node data for the root if no node is selected
    if (!selectedNodeData) {
      setSelectedNodeData({
        label: "Root",
        nodeType: "root",
        nodePath: [],
      });
    }

    setTimeout(() => {
      handleAddNodeApplied(selectedNodeData, tag);
    }, 0);
  };

  // Node click functions
  const handleNodeClick = (event, node) => {
    // Check if it's a root node
    if (node.data.nodeType === "root") {
      // Select all files
      setSelectedClassifyFiles(null); // null indicates all files are selected
      // Clear any selected nodes since we're selecting all files
      setSelectedNodes([]);
    } else {
      // Regular node selection logic
      setSelectedNodes((prev) => {
        const isSelected = prev.some((n) => n.id === node.id);
        // Clear file selection when selecting nodes
        if (!isSelected) {
          setSelectedClassifyFiles([]);
        }
        return isSelected
          ? prev.filter((n) => n.id !== node.id)
          : [...prev, node];
      });
    }
  };

  const onPaneClick = () => {
    setSidePanelOpen(false);
    setSelectedNodeData(null);
  };

  return (
    <ReactFlowProvider>
      <ReactFlow
        nodes={nodesWithCallbacks}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        fitViewOnInit={shouldFitView}
        onNodeDrag={handleNodeDrag}
        onNodeDragStop={resetDragNode}
        onDragOver={onDragOver}
        onDrop={onDrop}
        minZoom={0.1}
        maxZoom={4}
        defaultEdgeOptions={{
          type: "step",
          style: { stroke: "#d1d5db" },
          animated: false,
        }}
        proOptions={{
          hideAttribution: true,
        }}
        nodesDraggable={true}
        elementsSelectable={true}
        animateNodes={true}
        edgesFocusable={false}
        autoPanOnNodeDrag={false}
        smoothEdges
        useStrictMode={false}
        onNodeClick={handleNodeClick}
        onPaneClick={onPaneClick}
      >
        <FitViewOnNodesChange
          nodes={nodes}
          edges={edges}
          shouldFitView={shouldFitView}
        />
        <Background />
      </ReactFlow>
    </ReactFlowProvider>
  );
};
