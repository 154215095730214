import React, { useState, useContext, useEffect } from "react";
import {
  FaBook,
  FaPlug,
  FaKey,
  FaTags,
  FaChartLine,
  FaSignOutAlt,
  FaSearchPlus,
  FaFileExport,
  FaLayerGroup,
  FaFileDownload,
  FaVideo,
} from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../../config/firebase";
import { signOut } from "firebase/auth";
import { BaseContext } from "../../contexts/BaseContext";
import { GraphContext } from "../../contexts/GraphContext";
import { MainItemType, WorkflowType } from "../../models/WorkflowModel";
import {
  getLocationStateFromPath,
  getPathFromWorkflowType,
  getPathFromMainItem,
} from "./NavUtils";
import { WorkflowContextBanner } from "../MainContent/Workflows/WorkflowContextBanner";
import TutorialModal from "./TutorialModal";
import { TokenContext } from "../../contexts/TokenContext";
import ProfileModal from "./ProfileModal";

const MainNavigation = () => {
  const { deasyUserConfig, selectedDataSlice } = useContext(BaseContext);
  const { setShouldFitView } = useContext(GraphContext);
  const { subscriptionData, isProfileComplete } = useContext(TokenContext);
  const location = useLocation();
  const navigate = useNavigate();

  const { page, workflowType } =
    location.state || getLocationStateFromPath(location.pathname);

  const [selectedWorkflowItem, setSelectedWorkflowItem] =
    useState(workflowType);

  const [activePage, setActivePage] = useState(page);

  const [isTutorialOpen, setIsTutorialOpen] = useState(false);

  const [profileStatusLoaded, setProfileStatusLoaded] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  useEffect(() => {
    if (subscriptionData !== null) {
      setProfileStatusLoaded(true);
      setIsProfileModalOpen(!isProfileComplete);
    }
  }, [subscriptionData, isProfileComplete]);

  useEffect(() => {
    setActivePage(page);
  }, [page]);

  const storageOverLimit =
    subscriptionData?.storage_usage >= subscriptionData?.storage_limit;
  const monthlyOverLimit =
    subscriptionData?.monthly_usage >= subscriptionData?.monthly_limit;
  const isOverLimit = storageOverLimit || monthlyOverLimit;

  const dataSource =
    deasyUserConfig.vdbmConfig.Configs[deasyUserConfig.vdbmConfig.LastActive];

  const dataSliceId = selectedDataSlice?.id || null;

  const navItems = [
    {
      id: "workflows-header",
      label: "Workflow",
      isHeader: true,
    },
    // {
    //   id: WorkflowType.CONNECT_DATA,
    //   label: "Connect Data",
    //   icon: FaDatabase,
    //   onClick: () => {
    //     // Can ignore dataSourceName and dataSliceId for this page
    //     setSelectedWorkflowItem(WorkflowType.CONNECT_DATA);
    //     setActivePage(MainItemType.WORKFLOWS);
    //     const path = getPathFromWorkflowType(
    //       WorkflowType.CONNECT_DATA,
    //       dataSource?.name,
    //     );

    //     navigate(path, {
    //       state: {
    //         page: MainItemType.WORKFLOWS,
    //         workflowType: WorkflowType.CONNECT_DATA,
    //       },
    //     });
    //   },
    // },
    {
      id: WorkflowType.EXTRACT_METADATA,
      label: "Extract Metadata",
      icon: FaFileExport,
      onClick: () => {
        // Need dataSourceName and dataSliceId for this page
        // dataSliceId should be set automatically to the whole dataset
        setSelectedWorkflowItem(WorkflowType.EXTRACT_METADATA);
        setActivePage(MainItemType.WORKFLOWS);
        setShouldFitView(true);
        const path = getPathFromWorkflowType(
          WorkflowType.EXTRACT_METADATA,
          dataSource?.name,
          dataSliceId,
        );
        navigate(path, {
          state: {
            page: MainItemType.WORKFLOWS,
            workflowType: WorkflowType.EXTRACT_METADATA,
          },
        });
      },
    },
    {
      id: WorkflowType.VIEW_METADATA,
      label: "View Metadata",
      icon: FaSearchPlus,
      onClick: () => {
        // Can dataSliceId for this page
        setSelectedWorkflowItem(WorkflowType.VIEW_METADATA);
        setActivePage(MainItemType.WORKFLOWS);
        const path = getPathFromWorkflowType(
          WorkflowType.VIEW_METADATA,
          dataSource?.name,
        );
        navigate(path, {
          state: {
            page: MainItemType.WORKFLOWS,
            workflowType: WorkflowType.VIEW_METADATA,
          },
        });
      },
    },
    {
      id: WorkflowType.DATA_SLICE_MANAGEMENT,
      label: "Data Slices",
      icon: FaLayerGroup,
      onClick: () => {
        // Need dataSourceName and dataSliceId for this page
        setSelectedWorkflowItem(WorkflowType.DATA_SLICE_MANAGEMENT);
        setActivePage(MainItemType.WORKFLOWS);
        const path = getPathFromWorkflowType(
          WorkflowType.DATA_SLICE_MANAGEMENT,
          dataSource?.name,
          dataSliceId,
        );
        navigate(path, {
          state: {
            page: MainItemType.WORKFLOWS,
            workflowType: WorkflowType.DATA_SLICE_MANAGEMENT,
          },
        });
      },
    },
    {
      id: WorkflowType.EXPORT_METADATA,
      label: "Export Metadata",
      icon: FaFileDownload,
      onClick: () => {
        // Need dataSourceName and dataSliceId for this page
        setSelectedWorkflowItem(WorkflowType.EXPORT_METADATA);
        setActivePage(MainItemType.WORKFLOWS);
        const path = getPathFromWorkflowType(
          WorkflowType.EXPORT_METADATA,
          dataSource?.name,
          dataSliceId,
        );
        navigate(path, {
          state: {
            page: MainItemType.WORKFLOWS,
            workflowType: WorkflowType.EXPORT_METADATA,
          },
        });
      },
    },
    {
      id: "separator",
      isSeparator: true,
    },
    {
      id: "tools-header",
      label: "Tools",
      isHeader: true,
    },
    {
      id: MainItemType.DATA_CONNECTIONS,
      label: "Data Connectors",
      icon: FaPlug,
      description: "Configure your vector database and data connectors.",
      onClick: () => {
        // Can ignore dataSourceName and dataSliceId for this page
        setSelectedWorkflowItem(null);
        setActivePage(MainItemType.DATA_CONNECTIONS);
        const path = getPathFromMainItem(
          MainItemType.DATA_CONNECTIONS,
          dataSource?.name,
        );
        navigate(path, { state: { page: MainItemType.DATA_CONNECTIONS } });
      },
    },
    {
      id: MainItemType.METADATA_LIBRARY,
      label: "Metadata Library",
      icon: FaBook,
      description:
        "Browse and manage your metadata schemas. Create and edit tags for data classification.",
      onClick: () => {
        // Can ignore dataSourceName and dataSliceId for this page
        setSelectedWorkflowItem(null);
        setActivePage(MainItemType.METADATA_LIBRARY);
        const path = getPathFromMainItem(
          MainItemType.METADATA_LIBRARY,
          dataSource?.name,
        );
        navigate(path, { state: { page: MainItemType.METADATA_LIBRARY } });
      },
    },
    {
      id: MainItemType.METADATA_STUDIO,
      label: "Test Metadata",
      icon: FaTags,
      description:
        "Test and validate your metadata tags on sample data. Fine-tune your classification.",
      onClick: () => {
        // Can ignore dataSourceName and dataSliceId for this page
        setSelectedWorkflowItem(null);
        setActivePage(MainItemType.METADATA_STUDIO);
        const path = getPathFromMainItem(
          MainItemType.METADATA_STUDIO,
          dataSource?.name,
          dataSliceId,
        );
        navigate(path, { state: { page: MainItemType.METADATA_STUDIO } });
      },
    },
    {
      id: MainItemType.TOKEN_DASHBOARD,
      label: "API Keys & Tier",
      icon: FaKey,
      description:
        "Manage your API keys and subscription tier for accessing Deasy services.",
      onClick: () => {
        // Can ignore dataSourceName and dataSliceId for this page
        setSelectedWorkflowItem(null);
        setActivePage(MainItemType.TOKEN_DASHBOARD);
        const path = getPathFromMainItem(MainItemType.TOKEN_DASHBOARD);
        navigate(path, { state: { page: MainItemType.TOKEN_DASHBOARD } });
      },
    },
    {
      id: MainItemType.PROGRESS_TRACKING,
      label: "Progress Tracking",
      icon: FaChartLine,
      description: "Monitor your workflow progress and metadata health scores.",
      onClick: () => {
        // Can ignore dataSourceName and dataSliceId for this page
        setSelectedWorkflowItem(null);
        setActivePage(MainItemType.PROGRESS_TRACKING);
        const path = getPathFromMainItem(MainItemType.PROGRESS_TRACKING);
        navigate(path, { state: { page: MainItemType.PROGRESS_TRACKING } });
      },
    },
  ];

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleTutorials = () => {
    setIsTutorialOpen(true);
  };

  const getItemClass = (itemId) => {
    // For workflow items
    if (Object.values(WorkflowType).includes(itemId)) {
      return `flex cursor-pointer text-left p-1 gap-2 transition-colors w-full justify-between items-center duration-200 whitespace-nowrap ${
        selectedWorkflowItem === itemId
          ? "text-emerald-400"
          : "text-gray-400 hover:text-emerald-300"
      }`;
    }

    // For main menu items
    const isSelected = activePage === itemId;
    return `flex cursor-pointer text-left p-1 gap-2 transition-colors w-full justify-between items-center duration-200 whitespace-nowrap ${
      isSelected ? "text-emerald-400" : "text-gray-400 hover:text-emerald-300"
    }`;
  };

  return (
    <>
      <nav className="h-screen w-30 overflow-y-auto overflow-x-hidden bg-[#1a1f2b] flex flex-col items-center py-4 px-4">
        <div className="mb-8">
          <img src={"/deasy_shrimp.png"} alt="Deasy Labs" className="h-10" />
        </div>
        <div className="flex flex-col space-y-6 flex-grow w-full">
          {subscriptionData && isOverLimit && (
            <div className="px-4 py-3 bg-red-900/40 border-2 border-red-500/50 text-red-200 text-sm rounded-lg mb-4">
              <div className="flex items-center gap-2 font-semibold mb-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-red-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Tier Limit Exceeded</span>
              </div>
              <div className="text-xs opacity-90 space-y-1 pl-7">
                {storageOverLimit && (
                  <div className="text-red-300 font-medium">
                    Storage: {subscriptionData.storage_usage.toLocaleString()}
                    <span className="opacity-75">
                      /{subscriptionData.storage_limit.toLocaleString()}
                    </span>
                  </div>
                )}
                {monthlyOverLimit && (
                  <div className="text-red-300 font-medium">
                    Monthly: {subscriptionData.monthly_usage.toLocaleString()}
                    <span className="opacity-75">
                      /{subscriptionData.monthly_limit.toLocaleString()}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
          {navItems.map((item) => {
            if (item.isSeparator) {
              return (
                <hr
                  key="separator"
                  className="w-full border-t border-gray-600 my-4"
                />
              );
            }

            if (item.isHeader) {
              return (
                <div key={item.id} className="mt-2">
                  <p className="text-gray-500 text-xs font-semibold uppercase tracking-wider text-left">
                    {item.label}
                  </p>
                </div>
              );
            }

            const Icon = item.icon;
            return (
              <div key={item.id}>
                <div
                  className={getItemClass(item.id)}
                  onClick={() => item.onClick()}
                  title={item.description}
                >
                  <div className="flex items-center gap-2">
                    <Icon size={15} />
                    <p>{item.label}</p>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="flex flex-col flex-grow">
            <hr className="w-full border-t border-gray-600 my-4" />
            <WorkflowContextBanner />
          </div>
          <div
            className="flex cursor-pointer text-left p-1 gap-2 transition-colors w-full justify-between items-center duration-200 whitespace-nowrap text-gray-400 hover:text-emerald-300"
            onClick={handleTutorials}
            title="View onboarding videos and tutorials"
          >
            <div className="flex items-center gap-2 border-2 border-primary rounded-2xl px-4 py-3 hover:bg-emerald-400/10 w-full justify-center text-emerald-400">
              <FaVideo size={15} />
              <p className="font-medium text-sm">View Tutorials</p>
            </div>
          </div>
        </div>
        <div
          className="flex cursor-pointer text-left p-1 gap-2 transition-colors w-full justify-between items-center duration-200 whitespace-nowrap text-gray-400 hover:text-emerald-300 mt-4"
          onClick={handleLogout}
          title="Sign out"
        >
          <div className="flex items-center gap-2 ml-4">
            <FaSignOutAlt size={15} />
            <p>Sign Out</p>
          </div>
        </div>
      </nav>
      <TutorialModal
        isOpen={isTutorialOpen}
        onClose={() => setIsTutorialOpen(false)}
      />
      {profileStatusLoaded && (
        <ProfileModal
          isOpen={isProfileModalOpen}
          onClose={() => setIsProfileModalOpen(false)}
        />
      )}
    </>
  );
};

export default MainNavigation;
