import React, { useState, useRef, useEffect } from "react";

export const StandardizedValuesSection = ({
  standardizedValues,
  setStandardizedValues,
  valueMapping,
  setValueMapping,
  handleDrop,
  handleDeleteCategory,
  handleRemoveValueFromCategory,
  setExpandedCategory,
  handleAddCategory,
  handleClearAllCategories,
  setShowTaxonomyLibrary,
  setShowContextModal,
  isLoading,
  filteredValues,
  uniqueTagCount,
}) => {
  const [draggedOverCategory, setDraggedOverCategory] = useState(null);
  const [autoScroll, setAutoScroll] = useState({
    scrolling: false,
    direction: null,
  });
  const containerRef = useRef(null);

  useEffect(() => {
    if (!autoScroll.scrolling) return;

    const scrollSpeed = 10;
    let animationFrame;

    const performScroll = () => {
      if (!containerRef.current || !autoScroll.scrolling) return;

      if (autoScroll.direction === "up") {
        containerRef.current.scrollTop -= scrollSpeed;
      } else if (autoScroll.direction === "down") {
        containerRef.current.scrollTop += scrollSpeed;
      }

      animationFrame = requestAnimationFrame(performScroll);
    };

    animationFrame = requestAnimationFrame(performScroll);

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [autoScroll]);

  useEffect(() => {
    const handleDragEnd = () => {
      setAutoScroll({ scrolling: false, direction: null });
      document.removeEventListener("mousemove", handleMouseMove);
    };

    document.addEventListener("dragend", handleDragEnd);

    return () => {
      document.removeEventListener("dragend", handleDragEnd);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const handleMouseMove = (e) => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const containerRect = container.getBoundingClientRect();
    const mouseY = e.clientY;

    const topTriggerZone = containerRect.top + containerRect.height * 0.15;
    const bottomTriggerZone =
      containerRect.bottom - containerRect.height * 0.15;

    if (mouseY < topTriggerZone && mouseY >= containerRect.top) {
      setAutoScroll({ scrolling: true, direction: "up" });
    } else if (
      (mouseY > bottomTriggerZone && mouseY <= containerRect.bottom) ||
      mouseY > containerRect.bottom
    ) {
      setAutoScroll({ scrolling: true, direction: "down" });
    } else if (
      mouseY < containerRect.top ||
      (mouseY >= containerRect.top + containerRect.height * 0.15 &&
        mouseY <= containerRect.bottom - containerRect.height * 0.15)
    ) {
      setAutoScroll({ scrolling: false, direction: null });
    }
  };

  const handleCategoryDragOver = (e, category, index) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    setDraggedOverCategory(category || `__temp_${index}`);
    handleMouseMove(e);
  };

  const handleDragStart = (e) => {
    document.addEventListener("mousemove", handleMouseMove);
  };

  const handleCategoryDragLeave = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setDraggedOverCategory(null);
    }
  };

  const handleCategoryDrop = (e, targetCategory, index) => {
    e.preventDefault();
    const droppedValue = e.dataTransfer.getData("text/plain");
    const sourceCategory = e.dataTransfer.getData("sourceCategory");

    // Generate a unique key for empty or default categories
    const targetKey = targetCategory || `__temp_${index}`;

    // If we're moving between categories
    if (sourceCategory && sourceCategory !== targetKey) {
      setValueMapping((prev) => {
        const newMapping = { ...prev };

        // Remove from source category
        if (newMapping[sourceCategory]) {
          newMapping[sourceCategory] = newMapping[sourceCategory].filter(
            (v) => v !== droppedValue,
          );
        }

        // Add to target category (avoiding duplicates)
        if (!newMapping[targetKey]) {
          newMapping[targetKey] = [];
        }

        if (!newMapping[targetKey].includes(droppedValue)) {
          newMapping[targetKey] = [...newMapping[targetKey], droppedValue];
        }

        return newMapping;
      });
    } else if (!sourceCategory) {
      // Regular drop from existing values section
      handleDrop(e, targetKey);
    }

    setDraggedOverCategory(null);
    setAutoScroll({ scrolling: false, direction: null });
  };

  return (
    <div className="border rounded-lg flex-1">
      <div className="flex items-center justify-between p-3 border-b bg-gray-50">
        <h3 className="text-base font-medium">Standardized Values</h3>
        <div className="flex gap-2">
          <button
            onClick={() => setShowTaxonomyLibrary(true)}
            className="px-4 py-2 bg-white border border-primary text-primary text-sm rounded-md font-medium hover:bg-primary/5 transition-colors flex items-center gap-2"
            disabled={isLoading}
          >
            <svg
              className="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
              />
            </svg>
            Taxonomy Library
          </button>
          <button
            onClick={() => {
              setShowContextModal(true);
            }}
            disabled={isLoading}
            className={`px-4 py-2 bg-primary text-white text-sm rounded-md font-medium hover:bg-primary-dark transition-colors flex items-center gap-2 ${
              filteredValues.length === 0 ? "opacity-50" : ""
            }`}
            title={
              uniqueTagCount > 1000
                ? "With large datasets (over 1,000 values), custom context may be ignored"
                : ""
            }
          >
            {isLoading ? (
              <span className="flex items-center gap-2 ">
                <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="none"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Processing...
              </span>
            ) : (
              <span className="flex items-center gap-2">
                <svg
                  className="w-4 h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
                Suggest Categories
              </span>
            )}
          </button>
        </div>
      </div>

      <div
        ref={containerRef}
        className="p-4 overflow-y-auto h-[65vh] pb-[20vh]"
      >
        <div className="grid grid-cols-2 gap-8 mb-4">
          {standardizedValues.map((value, index) => (
            <div key={`std-${index}`} className="relative">
              <div className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={value}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const oldValue = standardizedValues[index];

                    // Update standardizedValues
                    const newStandardizedValues = [...standardizedValues];
                    newStandardizedValues[index] = newValue;
                    setStandardizedValues(newStandardizedValues);

                    // Update mapping key if this category has values
                    if (valueMapping[oldValue]?.length > 0) {
                      setValueMapping((prev) => {
                        const newMapping = { ...prev };
                        const targetKey = newValue || `__temp_${index}`;
                        newMapping[targetKey] = newMapping[oldValue];
                        if (oldValue !== targetKey) {
                          delete newMapping[oldValue];
                        }
                        return newMapping;
                      });
                    }
                  }}
                  className="flex-1 p-2 border rounded text-sm"
                  placeholder={`Category ${index + 1}`}
                  onClick={(e) => e.stopPropagation()}
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleDeleteCategory(index);
                  }}
                  className="p-2 text-gray-400 hover:text-red-500 hover:bg-gray-100 rounded"
                >
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
              </div>
              <div
                className={`p-4 border-2 border-dashed rounded-lg min-h-[100px] transition-all ${
                  draggedOverCategory === (value || `__temp_${index}`)
                    ? "bg-primary/10 border-primary/50 shadow-lg transform scale-[1.02]"
                    : "bg-gray-50 border-gray-200"
                }`}
                onDragOver={(e) => handleCategoryDragOver(e, value, index)}
                onDragLeave={handleCategoryDragLeave}
                onDrop={(e) => handleCategoryDrop(e, value, index)}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex flex-wrap gap-2">
                  {(valueMapping[value || `__temp_${index}`] || [])
                    .slice(0, 20)
                    .map((mappedValue) => (
                      <div
                        key={`${value}-${mappedValue}`}
                        className="inline-flex items-center gap-1 px-2 py-1 bg-white border border-gray-200 rounded group hover:border-gray-300 hover:shadow-sm transition-all cursor-move"
                        draggable
                        onDragStart={(e) => {
                          e.stopPropagation();
                          e.dataTransfer.setData("text/plain", mappedValue);
                          e.dataTransfer.setData(
                            "sourceCategory",
                            value || `__temp_${index}`,
                          );
                          handleDragStart(e);
                        }}
                      >
                        <span className="text-sm text-gray-700">
                          {mappedValue}
                        </span>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleRemoveValueFromCategory(value, mappedValue);
                          }}
                          className="ml-1 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <svg
                            className="w-4 h-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                  {(valueMapping[value || `__temp_${index}`] || []).length >
                    20 && (
                    <div
                      className="inline-flex items-center px-2 py-1 bg-gray-100 border border-gray-200 rounded text-gray-600 text-sm cursor-pointer hover:bg-gray-200 transition-colors"
                      onClick={() => setExpandedCategory(value)}
                    >
                      +
                      {(valueMapping[value || `__temp_${index}`] || []).length -
                        20}{" "}
                      more values (total:{" "}
                      {(valueMapping[value || `__temp_${index}`] || []).length})
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-row gap-2 w-full justify-end absolute bottom-2 right-2">
          {Object.keys(valueMapping).length > 0 && (
            <button
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to clear all category mappings? This will move all values back to the existing values list.",
                  )
                ) {
                  handleClearAllCategories();
                }
              }}
              className="bg-white w-[150px] text-sm text-red-600 border-2 border-dashed border-red-200 rounded-lg hover:bg-red-50 flex items-center justify-center gap-2 transition-all"
              disabled={isLoading}
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
              Clear All Categories
            </button>
          )}
          <button
            onClick={handleAddCategory}
            className="bg-white p-3 w-[150px] text-sm text-primary border-2 border-dashed border-primary/30 rounded-lg hover:bg-primary/5 flex items-center justify-center gap-2 transition-all"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            Add New Category
          </button>
        </div>
      </div>
    </div>
  );
};
