import { useState, useEffect } from "react";

const ContextModalComponent = ({
  isOpen,
  onClose,
  onSkip,
  onApply,
  uniqueTagCount,
}) => {
  const [contextValue, setContextValue] = useState("");

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-black bg-opacity-20 backdrop-blur-[2px]"
        onClick={onClose}
      ></div>
      <div className="relative bg-white rounded-lg shadow-xl w-[60vw] h-[60vh] animate-fadeIn">
        {/* Header */}
        <div className="px-6 py-4 border-b border-gray-100 flex justify-between items-center">
          <h3 className="text-xl font-semibold text-gray-800">
            {uniqueTagCount > 1000
              ? "AI Categorization for Large Dataset"
              : "Enhance AI Categorization with Customized Context"}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 focus:outline-none"
          >
            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="px-6 py-5">
          {uniqueTagCount > 1000 ? (
            <div className="mt-2 mb-4">
              <div className="flex items-start gap-3 p-6 bg-amber-50 rounded-lg border border-amber-100">
                <svg
                  className="w-6 h-6 text-amber-500 mt-0.5 flex-shrink-0"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                <div>
                  <h4 className="font-medium text-amber-800 text-left">
                    Large Dataset Detected
                  </h4>
                  <p className="text-amber-700 text-left mt-1">
                    This dataset contains over 1,000 unique values. For optimal
                    performance, the AI will automatically categorize values
                    without using custom context.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-2">
              <textarea
                value={contextValue}
                onChange={(e) => setContextValue(e.target.value)}
                placeholder="E.g., I would like the categories to cover..."
                className="w-full min-h-[40vh] max-h-[60vh] text-lg p-3 border border-gray-300 rounded-md placeholder-gray-400 focus:border-primary shadow-inner"
              />
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="px-6 py-4 bg-gray-50 border-t border-gray-100 flex justify-end space-x-3 rounded-b-lg">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-200 transition-all"
          >
            Cancel
          </button>
          {uniqueTagCount > 1000 ? (
            <button
              onClick={() => onApply(null)}
              className="px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary-dark rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary/30 transition-all"
            >
              Proceed
            </button>
          ) : (
            <>
              <button
                onClick={onSkip}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-200 transition-all"
              >
                Skip & Continue
              </button>
              <button
                onClick={() => {
                  const trimmedContext = contextValue.trim();
                  onApply(trimmedContext.length > 0 ? trimmedContext : null);
                }}
                className="px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary-dark rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary/30 transition-all"
              >
                Apply Context
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const AnimatedCounter = ({ value, className }) => {
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    if (value !== displayValue) {
      // Add animation class to parent element
      const element = document.getElementById("counter-container");
      if (element) {
        // Apply a fade-pulse animation
        element.classList.add("animate-pulse", "text-primary", "font-medium");
        setTimeout(() => {
          element.classList.remove(
            "animate-pulse",
            "text-primary",
            "font-medium",
          );
          setDisplayValue(value);
        }, 300);
      } else {
        setDisplayValue(value);
      }
    }
  }, [value, displayValue]);

  return (
    <div
      id="counter-container"
      className={`transition-all duration-300 ${className}`}
    >
      {displayValue} left
    </div>
  );
};

const TaxonomyNameModal = ({ isOpen, onClose, onConfirm, initialName }) => {
  const [taxonomyName, setTaxonomyName] = useState(initialName || "");
  const [error, setError] = useState("");

  if (!isOpen) return null;

  const handleSubmit = () => {
    if (!taxonomyName.trim()) {
      setError("Please enter a name for this taxonomy");
      return;
    }
    onConfirm(taxonomyName.trim());
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-black bg-opacity-20 backdrop-blur-[2px]"
        onClick={onClose}
      ></div>
      <div className="relative bg-white rounded-lg shadow-xl w-[450px] max-w-[90%] animate-fadeIn">
        {/* Header */}
        <div className="px-6 py-4 border-b border-gray-100 flex justify-between items-center">
          <h3 className="text-lg font-semibold text-gray-800 text-left">
            Name Your Category Taxonomy (Optional)
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 focus:outline-none"
          >
            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="px-6 py-5">
          <p className="text-sm text-gray-600 mb-4 text-left">
            Give your standardized category set a descriptive name to help
            identify it in the future.
          </p>
          <div className="mt-2">
            <input
              value={taxonomyName}
              onChange={(e) => {
                setTaxonomyName(e.target.value);
                setError("");
              }}
              placeholder="e.g., Product Categories, Content Types, etc."
              className="w-full p-3 border border-gray-300 rounded-md text-sm placeholder-gray-400 focus:border-primary shadow-inner text-left"
            />
            {error && (
              <p className="text-red-500 text-xs mt-1 text-left">{error}</p>
            )}
          </div>

          {/* Warning message */}
          <div className="mt-4 p-3 bg-amber-50 border border-amber-200 rounded-md flex items-start gap-2">
            <p className="text-sm text-amber-800 text-left">
              Once confirmed, all existing values will be automatically
              standardized to their new value.
            </p>
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-4 bg-gray-50 border-t border-gray-100 flex justify-end space-x-3 rounded-b-lg">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-200 transition-all"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary-dark rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary/30 transition-all"
          >
            Save Taxonomy and Confirm Changes
          </button>
        </div>
      </div>
    </div>
  );
};

// Simple Standardization Confirmation Modal
const SimpleStandardizationModal = ({
  isOpen,
  onClose,
  onConfirm,
  valueCount,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-black bg-opacity-20 backdrop-blur-[2px]"
        onClick={onClose}
      ></div>
      <div className="relative bg-white rounded-lg shadow-xl w-[500px] max-w-[90%] animate-fadeIn">
        {/* Header */}
        <div className="px-6 py-4 border-b border-gray-100 flex justify-between items-center">
          <h3 className="text-lg font-semibold text-gray-800 text-left">
            Confirm One-to-One Mapping Standardization
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 focus:outline-none"
          >
            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="px-6 py-5">
          <div className="flex items-start gap-3 p-4 bg-amber-50 rounded-lg border border-amber-100 mb-4">
            <svg
              className="w-6 h-6 text-amber-500 mt-0.5 flex-shrink-0"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
            <div>
              <h4 className="font-medium text-amber-800 text-left">
                Recommendation
              </h4>
              <p className="text-amber-700 text-left mt-1">
                With {valueCount} values, it's recommended to use the 'Suggest
                Categories' button instead for more meaningful categorization.
              </p>
            </div>
          </div>

          <p className="text-sm text-gray-600 mb-4 text-left">
            One-to-One Mapping Standardization will create a mapping where each
            value becomes its own category. This is useful for direct value
            mapping but may create a large number of categories.
          </p>
        </div>

        {/* Footer */}
        <div className="px-6 py-4 bg-gray-50 border-t border-gray-100 flex justify-end space-x-3 rounded-b-lg">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-200 transition-all"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary-dark rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary/30 transition-all"
          >
            Proceed with One-to-One Mapping Standardization
          </button>
        </div>
      </div>
    </div>
  );
};

export {
  ContextModalComponent,
  TaxonomyNameModal,
  AnimatedCounter,
  SimpleStandardizationModal,
};
