import {
  Zap,
  BetweenVerticalStart,
  BarChart2,
  Undo2,
  Redo2,
  PlusCircle,
} from "lucide-react";
import { toast } from "react-hot-toast";
import { FiRefreshCw, FiSave } from "react-icons/fi";
import GraphSelector from "../../Graph/GraphSelector";
import { GraphContext } from "../../../../../../../contexts/GraphContext";
import { BaseContext } from "../../../../../../../contexts/BaseContext";
import { useContext, useState } from "react";
import ProgressModal from "../../../../../ProgressTracking/ProgressModal";
import { Tooltip } from "@mui/material";

export const ExtractBanner = ({
  // State values
  isRefreshing,
  searchTerm,
  showTagNodes,
  showValueNodes,

  // Core functions
  initialRefresh,

  // UI state setters
  setIsRefreshing,
  setSearchTerm,
  setShowTagNodes,
  setShowValueNodes,
}) => {
  // Context data
  const { deasyUserConfig } = useContext(BaseContext);
  // Accessing these GraphContext values directly to allow Add Metadata button functionality
  const {
    handleSaveGraph,
    selectedDiscoveryGraph,
    refreshHierarchyStats,
    handleUndo,
    handleRedo,
    canUndo,
    canRedo,
    setSidePanelOpen,
    setShouldFitView,
    setSelectedNodeData,
  } = useContext(GraphContext);

  // States
  const [isSaving, setIsSaving] = useState(false);
  const [showProgressModal, setShowProgressModal] = useState(false);

  // Function to open add metadata panel and center the graph
  const handleAddMetadataAndCenter = () => {
    // Set the selected node data to root data
    setSelectedNodeData({
      label: "Root",
      nodeType: "root",
      nodePath: [],
    });
    // Open the side panel
    setSidePanelOpen(true);
    // Center the graph view
    setShouldFitView(true);
  };

  return (
    <div className="flex flex-col mb-4">
      <div className="flex items-end justify-between mb-2">
        {/* Left section - Graph selector */}
        <div className="flex-shrink-0 max-w-md">
          <h3 className="text-sm font-medium text-gray-700 mb-2 text-left">
            Select existing metadata schemas
          </h3>
          <GraphSelector />
        </div>

        {/* Center section - Search and filter controls */}
        <div className="flex-1 flex items-center justify-center gap-2 mx-4">
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Search nodes..."
              className="px-3 py-1.5 border border-gray-300 rounded-md w-80"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="flex items-center gap-2 ml-2">
            <button
              onClick={() => setShowTagNodes(!showTagNodes)}
              className={`px-3 py-1.5 text-sm rounded-md transition-all duration-200 flex items-center gap-2
            ${showTagNodes ? "bg-primary/5 text-primary border border-primary/30" : "bg-gray-100 text-gray-500 border border-gray-200"}`}
              title={showTagNodes ? "Hide tag nodes" : "Show tag nodes"}
            >
              <Zap size={14} />
              <span>Tags</span>
            </button>
            <button
              onClick={() => setShowValueNodes(!showValueNodes)}
              className={`px-3 py-1.5 text-sm rounded-md transition-all duration-200 flex items-center gap-2
            ${
              showValueNodes
                ? "bg-primary/5 text-primary border border-primary/30"
                : "bg-gray-100 text-gray-500 border border-gray-200"
            }`}
              title={showValueNodes ? "Hide value nodes" : "Show value nodes"}
            >
              <BetweenVerticalStart size={14} />
              <span>Values</span>
            </button>
          </div>
        </div>

        {/* Right section - Action buttons */}
        <div className="flex-shrink-0 flex items-center gap-2">
          <Tooltip title="Add Metadata & Center" arrow placement="bottom">
            <button
              onClick={handleAddMetadataAndCenter}
              className="flex items-center gap-2 px-3 py-1.5 text-sm rounded-md shadow-sm
                transition-all duration-200 border bg-primary text-white hover:bg-primary/90 border-primary"
            >
              <PlusCircle size={15} />
              <span>Add Metadata</span>
            </button>
          </Tooltip>

          <Tooltip title="Undo" arrow placement="bottom">
            <button
              onClick={handleUndo}
              className={`flex items-center gap-2 px-3 py-1.5 text-sm rounded-md shadow-sm
                transition-all duration-200 border 
                ${
                  !canUndo
                    ? "bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed"
                    : "bg-white text-gray-600 hover:text-primary border-gray-200 hover:border-primary hover:shadow"
                }`}
              disabled={!canUndo}
            >
              <Undo2 size={15} />
            </button>
          </Tooltip>

          <Tooltip title="Redo" arrow placement="bottom">
            <button
              onClick={handleRedo}
              className={`flex items-center gap-2 px-3 py-1.5 text-sm rounded-md shadow-sm
                transition-all duration-200 border
                ${
                  !canRedo
                    ? "bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed"
                    : "bg-white text-gray-600 hover:text-primary border-gray-200 hover:border-primary hover:shadow"
                }`}
              disabled={!canRedo}
            >
              <Redo2 size={15} />
            </button>
          </Tooltip>

          <Tooltip title="Save Metadata Schema" arrow placement="bottom">
            <button
              onClick={async () => {
                setIsSaving(true);
                await handleSaveGraph();
                setIsSaving(false);
                toast.success(
                  `Schema ${selectedDiscoveryGraph.graph_name} saved successfully`,
                );
              }}
              className={`flex items-center gap-2 px-3 py-1.5 text-sm rounded-md shadow-sm
                   transition-all duration-200 border
                   ${
                     isSaving
                       ? "bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed"
                       : "bg-white text-gray-600 hover:text-primary border-gray-200 hover:border-primary hover:shadow"
                   }`}
              disabled={isSaving}
            >
              <FiSave className="w-4 h-4" />
              {isSaving ? (
                <div className="flex items-center gap-2">
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-400" />
                </div>
              ) : (
                <div></div>
              )}
            </button>
          </Tooltip>

          <Tooltip title="Extraction Progress" arrow placement="bottom">
            <button
              className={`flex items-center gap-2 px-3 py-1.5 text-sm rounded-md shadow-sm
                   transition-all duration-200 border bg-white text-gray-600 hover:text-primary border-gray-200 hover:border-primary hover:shadow`}
              onClick={() => setShowProgressModal((prev) => !prev)}
            >
              <BarChart2 size={15} />
            </button>
          </Tooltip>

          <Tooltip title="Refresh metadata stats" arrow placement="bottom">
            <button
              onClick={async () => {
                setIsRefreshing(true);
                await refreshHierarchyStats(deasyUserConfig.deasyApiKey);
                setIsRefreshing(false);
              }}
              className={`flex items-center gap-2 px-3 py-1.5 text-sm rounded-md shadow-sm
                   transition-all duration-200 border
                   ${
                     isRefreshing
                       ? "bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed"
                       : "bg-white text-gray-600 hover:text-primary border-gray-200 hover:border-primary hover:shadow"
                   }`}
              disabled={isRefreshing}
            >
              <FiRefreshCw className="w-4 h-4" />
              {isRefreshing && (
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-400" />
              )}
            </button>
          </Tooltip>
        </div>
      </div>
      <ProgressModal
        isOpen={showProgressModal}
        onClose={() => setShowProgressModal(false)}
      />
    </div>
  );
};
