import { useContext, useState, memo } from "react";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { dataSliceService } from "../../../../../../services/api";
import { toast } from "react-hot-toast";
import { getVdbLogo } from "../../../../../../config/config";
import { Dialog } from "@mui/material";
import MetadataTable from "../../MetadataTable/MetadataTable";

const MetadataTableWrapper = memo(() => {
  const [tableFilters, setTableFilters] = useState({});
  const [tableConditionsNew, setTableConditionsNew] = useState(null);

  return (
    <MetadataTable
      backendFilters={tableFilters}
      onFiltersChange={setTableFilters}
      source="createSlice"
      conditionsNew={tableConditionsNew}
      setConditionsNew={setTableConditionsNew}
    />
  );
});

export const DataSliceSelect = ({ onCreateNew, onDataSliceSelect }) => {
  const { deasyUserConfig, dataSlices, refreshDataSlicesHook } =
    useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;
  const [showMetadataModal, setShowMetadataModal] = useState(false);

  const handleDeleteDataSlice = async (dataSliceId, e) => {
    e.stopPropagation();
    if (window.confirm("Are you sure you want to delete this data slice?")) {
      try {
        await dataSliceService.deleteDataslice(dataSliceId, deasyApiKey);
        refreshDataSlicesHook();
        toast.success("Data slice deleted successfully");
      } catch (error) {
        toast.error("Failed to delete data slice");
        console.error("Delete data slice error:", error);
      }
    }
  };

  const CreateDataSliceModal = () => (
    <Dialog
      open={showMetadataModal}
      onClose={() => setShowMetadataModal(false)}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          height: "90vh",
          maxHeight: "90vh",
          borderRadius: "12px",
        },
      }}
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between p-6 border-b border-gray-200 text-left">
          <div className="text-left">
            <h2 className="text-xl font-semibold text-gray-900">
              Create Data Slice
            </h2>
            <p className="text-sm text-gray-500 mt-1">
              Filter and select data to create a new data slice
            </p>
          </div>
          <button
            onClick={() => setShowMetadataModal(false)}
            className="text-gray-400 hover:text-gray-500"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="flex-1 overflow-auto p-6">
          <MetadataTableWrapper />
        </div>
      </div>
    </Dialog>
  );

  const formatSliceName = (dataSlice) => {
    // Check if it's a default slice (starts with "Deasy_Data_")
    if (dataSlice.name.startsWith("Deasy_Data_")) {
      const parts = dataSlice.name.split("_");
      const dbType = parts[2];
      const collection = parts.slice(3).join("_");
      return `All data from ${dbType} ${collection}`;
    }
    return dataSlice.name;
  };

  // Filter out the default slices (ones that start with "Deasy_Data_")
  const displayedDataSlices = dataSlices.filter(
    (dataSlice) => !dataSlice.name.startsWith("Deasy_Data_"),
  );

  return (
    <div className="px-6 pt-2 pb-6">
      <div className="flex items-center mb-4 w-full text-right justify-start">
        <button
          onClick={() => setShowMetadataModal(true)}
          className="inline-flex items-center px-4 py-2 border shadow-sm text-sm font-medium rounded-md bg-primary text-white border-primary hover:bg-primary/90 hover:text-primary hover:bg-white hover:border-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          <svg
            className="-ml-1 mr-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          Create Data Slice using Metadata
        </button>
      </div>

      {displayedDataSlices.length === 0 ? (
        <div className="bg-white rounded-lg border border-gray-200 p-8 text-center">
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            No Data Slices Yet
          </h3>
          <p className="text-gray-500 mb-4">
            Create your first data slice to start organizing your data.
          </p>
        </div>
      ) : (
        <div className="bg-white rounded-lg border border-gray-200 text-left">
          <div className="overflow-x-auto rounded-lg">
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Data Source Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Data Source Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    # Documents
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Last Updated
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-left">
                {displayedDataSlices.map((dataSlice) => (
                  <tr
                    key={dataSlice.id}
                    className="hover:bg-gray-50 cursor-pointer text-left"
                    onClick={() => onDataSliceSelect(dataSlice)}
                  >
                    <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
                      <div className="truncate">
                        {formatSliceName(dataSlice)}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 text-left">
                      <div className="truncate">{dataSlice.description}</div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 text-left">
                      <div className="truncate">
                        {dataSlice.vector_db_config?.collection_name || "-"}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 text-left">
                      <div className="truncate">
                        {dataSlice.vector_db_config ? (
                          <img
                            src={getVdbLogo(dataSlice.vector_db_config.type)}
                            alt={dataSlice.vector_db_config.type}
                            className="h-10 w-25 rounded-md"
                          />
                        ) : (
                          "-"
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 text-left">
                      {dataSlice.dataPoints.toLocaleString()}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 text-left">
                      {new Date(dataSlice.lastUpdated).toLocaleString()}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-right">
                      <div className="flex justify-end items-center gap-2">
                        <button
                          onClick={(e) =>
                            handleDeleteDataSlice(dataSlice.id, e)
                          }
                          className="inline-flex items-center px-2 py-1 border border-red-500 text-red-500 hover:bg-red-500 hover:text-white rounded-md transition-colors duration-200"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <CreateDataSliceModal />
    </div>
  );
};
